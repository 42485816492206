import { Route } from "react-router-dom";
import Redirect from "./redirect";

const checkPan=(path,Component,props)=>{
  const userdata = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  if(!token){
    return (
      <Route path={path} element={<Redirect path="/" />} />
    );
  }
  if(userdata?.pan && userdata?.iin){
      return (
         <Route path={path} element={<Component {...props} />} />
      )
  }else{
    return (
      <Route path={path} element={<Redirect path="/dashboard/Profile-creation" />} />
    );
  }
}

function Guard(Component, path, permission, props = {},pan=false) {
  const token = localStorage.getItem("token");

  if(pan){
   return checkPan(path,Component,props)
  }

  if (permission.authRequire && !token) {
    return (
      <Route path={path} element={<Redirect path="/" />} />
    );
  } else if (permission.unAuthRequire && token) {
    return <Route path={path} element={<Redirect path="/dashboard" />} />;
  } else {
    return <Route path={path} element={<Component {...props} />} />;
  }
}

export default Guard;
