import React, { useState, useEffect,useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  getMandateList,
  getUserProfileViaPortfolio,
  sipTransact,
  getNSEProductCode,
  saveNseTransaction
} from "../apisMethods";
import { notify } from "../pages/toastCreater";
import TransactSuccess from "./transact-success";
import SWW from "./something-went-wrong";
import { globalInfo } from "../App";
import { addSevenDaysFromCurrent, addThreeMonthsFromCurrent } from "../currentMonth/FinancialYear";
import ConfirmationMandatePopup from "./mandateConfirmationPopup";
const Sip_Popup = ({ show, setShow, data }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [amount, setAmount] = useState()
  const [paymentMode, setPaymentMode] = useState('')
  const [mandateListData, setMandateListData] = useState([]);
  const [mandateUrn, setMandateUrn] = useState('');
  const [tempMandateData, setTempMandateData] = useState('');
  const [headerState, setHeaderState] = useState({});
  const [useProfileData, setUseProfileData] = useState({});
  const [perpetual, setPerpetual] = useState("Y");
  const [todayDate, setTodayDate] = useState("");
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [sipFrom, setSipFrom] = useState("");
  const [sipTo, setSipTo] = useState("");
  const [mandateLoder, setMandateLoder] = useState(false);
  const [nseData, setNseData] = useState({});
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)



  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    let d = addSevenDaysFromCurrent()
    setTodayDate(d)
   
    Object.keys(data).length > 0 && userProfileDataCall()

  }, [data])

  const refreshState = () => {
    setAmount('')
    setMandateUrn('')
    setTempMandateData({})

  }
  const userProfileDataCall = async () => {
    let reqBodyForIin = {
      folio: data?.folio,
      productcode: data?.productcode,
      rta: data?.rta,
      gpan: data?.gpan ? data?.gpan : ""
    }

    let res = await getUserProfileViaPortfolio(reqBodyForIin, headerState)

    if (!res.data) {
      return
    } else {
      setUseProfileData(res.data?.data)
      getMandateListCall(res.data?.data?.iin, res.data?.data?.schemecode)
     
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }

  const amountHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    setAmount(sanitizedValue);
  };
  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value)
      let urnValue = JSON.parse(e.target.value)
      setTempMandateData(urnValue)

    }
  }

  const getMandateListCall = (iin,schemecode) => {
    setMandateLoder(true)
    getMandateList({ iin }, headerState).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
        getNSEProductCode({ schemecode: schemecode }, headerState).then(nseRes => {
          if (!nseRes.data) {
            // notify("warn", "nse product code not found please try again")
            return
          } else {
            setNseData({
              productcode: nseRes.data?.data?.productcode,
              reinvestTag: nseRes.data?.data?.REINVEST_TAG,
              sipDates: nseRes.data?.data?.sip_dates,
            })
          }
        })
      }
      setMandateLoder(false)
    })
  }
  const changeSipFromHandler = (e) => {
    let selectedDay = (e.target.value).split("-");
    if (nseData?.sipDates?.includes(selectedDay[2]) === false) {
      notify("error", "Selected day is not available");
      setSipFrom("");
      return;
    }
    setSipTo("")
    setSipFrom(e.target.value);
  };
  const changeSipToHandler = (e) => {
    if (sipFrom === e.target.value) {
      notify(
        "error",
        "From date and to date should not be same. "
      );
      setSipTo("");
      return;
    }
    if (sipFrom > e.target.value) {
      notify("error", "SIP To date should not be less than SIP From. ");
      setSipTo(""); 
      return;
    }
    setSipTo(e.target.value);
  };
  function formatDate(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "JAN";
      month[1] = "FEB";
      month[2] = "MAR";
      month[3] = "APR";
      month[4] = "MAY";
      month[5] = "JUN";
      month[6] = "JUL";
      month[7] = "AUG";
      month[8] = "SEP";
      month[9] = "OCT";
      month[10] = "NOV";
      month[11] = "DEC";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + "-" + month[date.getMonth()] + "-" + date.getFullYear();
    }
  }
  const orderSip = async () => {
    if (!amount) {
      notify("warn", "Please enter amount")
      return
    }

    if (!mandateUrn) {
      notify("error", "Please select mandate");
      return
    }
    if (!sipFrom) {
      notify("warn", "Please select SIP From date")
      return
    }
    if (perpetual === "N" && !sipTo) {
      notify("warn", "Please select SIP To date")
      return
    }
    if (amount < 1000) {
      notify("error", "Amount should be greater than min amount");
      return
    }
setLoader("block")
    const fromDateParts = sipFrom.split('-');
    const toDateParts = sipTo.split('-');
    const fromDate = new Date(parseInt(fromDateParts[0]), parseInt(fromDateParts[1]) - 1, parseInt(fromDateParts[2]));
    const toDate = new Date(parseInt(toDateParts[0]), parseInt(toDateParts[1]) - 1, parseInt(toDateParts[2]));
    let reqBody = {
      email: useProfileData.email,
      holder_name: useProfileData.name,
      tax_status: useProfileData.taxstatus,
      umrn: tempMandateData.UMRN_NO,
      iin: useProfileData.iin,
      instrm_amount: amount,
      ach_amt: amount,
      instrm_date: tempMandateData.MANDATE_DATE, //============
      accountNo: tempMandateData.ACCOUNT_NO,
      debit_amount_type: "Amount",
      bank_code: tempMandateData.BANK_CODE,
      sub_trxn_type: "S",
      Return_paymnt_flag: "N",
      Client_callback_url: "",
      ach_exist: "Y",
      trxn_acceptance: "OL",
      payment_mode: "M",
      schemes: [
        {
          amc: useProfileData.amccode,
          folio: data.folio,
          product_code: nseData?.productcode,//nse
          reinvest: nseData?.reinvestTag,//nse
          sip_from_date: formatDate(fromDate),
          sip_end_date: sipTo ? formatDate(toDate) : "",
          sip_freq: "OM",
          sip_amount: amount,
          sip_period_day: fromDateParts[2],
          input_ref_no: "",
          perpetual_flag: perpetual,
         amt_unit_type:"Amount",

        }
      ]
    }
    sipTransact(reqBody, headerState).then((res) => {
      if (res.data?.status === 200) {
        let nseData = {
          Unique_No: res.data?.data?.Unique_No,
          Trxn_No: res.data?.data?.Trxn_No,
          Application_No: res.data?.data?.Application_No,
          Fund: res.data?.data?.Fund,
          Scheme: res.data?.data?.Scheme,
          Scheme_Name: res.data?.data?.Scheme_Name,
          Amt: res.data?.data?.Amt,
          Status_Desc: res.data?.data?.Status_Desc,
          Status_code: res?.data?.data?.Status_code,
          Folio: res.data?.data?.Folio,
          Platform: "Web",
          transaction_type: "SIP",
          iin: useProfileData?.iin,
          Pan: useProfileData?.pan,
          Gpan: useProfileData?.gpan,
          Joint1pan: useProfileData?.Joint1pan,
          Joint2pan: useProfileData?.Joint2pan,
          name: data?.name,
        };
        setConfirmShow(true);
        setConfirmationData(res.data?.data)
        setShow(false);
        setConfirmationDataArr([]);
        saveNseTransaction(nseData, headerState).then((res) => {

          refreshState()

        });
        setLoader('none')
        if (res.data?.data?.Paymentlink) {
          window.open(res.data?.data?.Paymentlink);
        }
      } else {
        setLoader('none')
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }

    });
  }
  const checkMandate = () => {
    if (useProfileData?.email && mandateListData?.length === 0) {
      setIsMandateAvailable(true)
    }
  }
  return (
    <>
      <Modal show={show} onHide={() =>
        setShow(false)} scrollable={true} className="animate__animated animate__zoomIn animate__fast">
        <Modal.Header closeButton>
          <Modal.Title>SIP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='text-red'>{data.name}</h5>
          {/* <div className="col-3 bg-light-red text-center rounded">
            <p className='py-1 '>{data.assettype}</p>
          </div> */}
          <p className='fs-13 text-red'>{data.scheme}({data.folio})</p>
          <Form className="portfolio">
            <Row>


              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Enter Amount </Form.Label>
                <Form.Control type="text" placeholder="Enter Amount" className="border-0 single-drop" value={amount} onChange={amountHandler} />
                <small className="text-label float-right">
                      Min Amount : 1000
                    </small>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Select Mandate</Form.Label>
                <Form.Select aria-label="Default select example" className="border-0 single-drop" value={mandateUrn} onChange={changeMandateHandler} disabled={mandateLoder} onClick={checkMandate}>
                  <option value={""}>Select Mandate</option>
                  {mandateListData.length > 0 && mandateListData.map((data) => (
                    <option value={JSON.stringify({ UMRN_NO: data.UMRN_NO, BANK_CODE: data.BANK_CODE, ACCOUNT_NO: data.ACCOUNT_NO, MANDATE_DATE: data.MANDATE_DATE, AC_TYPE: data.AC_TYPE })}>{`${data.BANK_NAME} | Ac No. ${data.ACCOUNT_NO} | Amount- ${data.AMOUNT > 0 ? data.AMOUNT : "0"} `}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="formBasicDate">
                <Form.Label>SIP From </Form.Label>
                <Form.Control type="date" className="border-0 single-drop" min={todayDate} max={addThreeMonthsFromCurrent()} value={sipFrom}
                  onChange={changeSipFromHandler}
                  id="anyWhere5"
                  onClick={() => dateAny("anyWhere5")}
                />
                <small className='fs-13 text-red'>{nseData.sipDates ? nseData.sipDates : ""}</small>
              </Form.Group>
              {perpetual === "N" ? <Form.Group as={Col} className="mb-3" controlId="formBasicDate">
                <Form.Label>SIP To </Form.Label>
                <Form.Control type="date" className="border-0 single-drop" min={addThreeMonthsFromCurrent()} id="anyWhere6"
                  onClick={() => dateAny("anyWhere6")} value={sipTo}
                  onChange={changeSipToHandler} />
              </Form.Group> : null}

              <Form.Group className="mb-3 d-flex" controlId="formBasicRadio">
                <Form.Check
                  label="Perpetual*"
                  name="formHorizontalRadios"
                  id="perpetual"
                  checked={perpetual === "Y"}
                  onChange={(e) =>
                    setPerpetual(perpetual === "N" ? "Y" : "N")

                  }
                  className="pe-3"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="danger" onClick={orderSip}>
            Order
          </Button>
        </Modal.Footer>
      </Modal>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
      <ConfirmationMandatePopup
        show={isMandateAvailable}
        setShow={setIsMandateAvailable}
        profileDetail={{name:useProfileData?.name, iin:useProfileData?.iin,pan:useProfileData?.pan,gpan:useProfileData?.gpan}}
      />
    </>
  )
}
export default Sip_Popup;