export const Goals = {
  1: "Retirement",
  2: "Child Education",
  3: "House Purchase",
  4: "Car Purchase",
  5: "Wealth Creation",
  6: "Home Renovation",
  7: "Vacation",
  8: "Child Marriage",
  9: "Customize Goal",
};

export const GoalsImages = {
  Retirement: "retiment",
  "Child Education": "eduction",
  "House Purchase": "home-go",
  "Car Purchase": "car-g",
  "Wealth Creation": "wealth-g",
  "Home Renovation": "renovetion",
  Vacation: "vacation",
  "Child Marriage": "marrigeg",
  "Customize Goal": "othergoal",
};

export const GoalRouting = {
  Retirement: "retirement-goal",
  "Child Education": "child-education-goal",
  "House Purchase": "house-purchase-goal",
  "Car Purchase": "car-purchase-goal",
  "Wealth Creation": "wealth-creation-goal",
  "Home Renovation": "home-renovetion-goal",
  Vacation: "vacation-goal",
  "Child Marriage": "child-marriage-goal",
  "Customize Goal": "customize-goal",
};


export  const interestRates = {
  1: 5,
  2: 6,
  3: 7,
  4: 7,
  5: 10,
  6: 11,
  7: 12,
  8: 14,
  9: 14,
  10: 16
}

export  const yearInString = {
  1: "oneyrret",
  3: "threeyearret",
  5: "fiveyearret",
  7:"sevenyearret"
}