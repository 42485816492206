import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "react-tooltip/dist/react-tooltip.css";
import { portfolioCapitalReview, familyWisePortfolio, getAllFamily } from "../apisMethods";

import { notify } from "../pages/toastCreater";
import { disableFutureDate } from "../currentMonth/FinancialYear";
import { useNavigate } from "react-router-dom";
import Thanku from "./thanku";




const PortfolioViaEmail = ({ show, setShow, userFamilyData }) => {
    const initialObj = { pan: "", gpan: "", name: "", }
    const [successMessage, setSuccessMessage] = useState('');
    const [confirmShow, setConfirmShow] = useState(false);
    const [headerState, setHeaderState] = useState({})
    const [profileName, setProfileName] = useState('')
    const [tempProfileName, setTempProfileName] = useState(initialObj)
    const [asOnDate, setasOnDate] = useState('')
    const [userData, setUserData] = useState({})
    const [futureDisable, setFutureDisable] = useState('')


    useEffect(() => {
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        setHeaderState(header)
        const user = JSON.parse(localStorage.getItem("user"));
        if (user?.pan) {
            setUserData(user)
            // familyWisePortfolioCall(user?.pan, header)
        }
        setFutureDisable(disableFutureDate())
    }, [])

    // const familyWisePortfolioCall = (pan, headers) => {
    //     getAllFamily({ adminPan: pan }, headers).then((res) => {
    //         if (res.data) {
    //             if (res?.data?.success === false) {
    //                 setUserFamilyData([])
    //             } else {
    //                 setUserFamilyData(res.data?.data)
    //             }
    //         } else {
    //             setUserFamilyData([])
    //             return
    //         }

    //     })
    // }

    const profileChangeHandler = (e) => {
        if (e.target.value) {
            if (e.target.value === "all") {
                setProfileName(e.target.value)
                setTempProfileName(initialObj)
                return
            }
            let json = JSON.parse(e.target.value)
            setTempProfileName(json)
            setProfileName(e.target.value)
        }

    }

    const submitGainStatement = (e) => {
        e.preventDefault()
        if (!profileName) {
            notify("error", "Please select profile")
            return
        }
        if (!asOnDate) {
            notify("error", "Please select year")
            return
        }
        let reqBody = {
            primaryName: userData?.displayName,
            phone: userData?.mobile,
            name: profileName === "all" ? "All" : tempProfileName.name,
            pan: profileName === "all" ? "" : tempProfileName.pan || "",
            gpan: profileName === "all" ? "" : tempProfileName.gpan || "",
            email: userData?.email,
            type: "portfolio",
            asondate: asOnDate,
            financialyear: "",
            all: profileName === "all" ? true : false
        }
        portfolioCapitalReview(reqBody, headerState).then(res => {
            if (!res.data) {
                notify("error", "something went wrong")
            } else {
                // notify("success", "Mail send successfully")
                setConfirmShow(true);
                setSuccessMessage(
                    "Your request has been received and acknowledged at our end, your query shall be responded shortly."
                );
                setasOnDate('')
                setProfileName('')
                setTempProfileName({})
                setShow(false)
            }
        })

    }

    const asOnDateChangeHandler = (e) => {
        setasOnDate(e.target.value)
    }

    const dateAny = (id) => {
        const inputDateElement = document.getElementById(id);
        inputDateElement.showPicker();
    }

    return (
        <>
            <Modal show={show} onHide={() =>
                setShow(false)} className="border-red" centered scrollable={true} >
                <Modal.Header closeButton >
                    <Modal.Title>Portfolio Via Email</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Form onSubmit={submitGainStatement}>
                        <div className="">
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    Select Profile <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select className="bg-c"
                                    value={profileName}
                                    onChange={profileChangeHandler}
                                    required
                                >
                                    <option value="">Select Profile</option>
                                    {userFamilyData.length > 0 ? userFamilyData.map((data) => (
                                        <option value={JSON.stringify({ pan: data.pan, gpan: data.gpan, name: data.name })} key={data.pan}>
                                            {data.name}
                                        </option>
                                    )) : null}
                                    {userFamilyData.length > 1 ? <option value={"all"}>All</option> : null}
                                </Form.Select>


                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    As on Date <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="bg-c"
                                    type="date"
                                    value={asOnDate}
                                    max={futureDisable}
                                    required
                                    onChange={asOnDateChangeHandler}
                                    id="anyWhere5"
                                    onClick={() => dateAny("anyWhere5")}
                                    placeholder="Enter Amount"
                                />
                            </Form.Group>
                            <Form.Group >
                                <button type='submit' className="otp-btn mt-3 br-15 float-right" >Submit</button>
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                </Modal.Footer>
            </Modal> <Thanku
                show={confirmShow}
                setShow={setConfirmShow}
                successMessage={successMessage}
            />
        </>
    )
}
export default PortfolioViaEmail