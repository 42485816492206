
import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import NoDataImage from "../../src/assets/images/img_no_investment_transaction.png";

import { Link } from "react-router-dom";
import { nseTransactionReport, userProfile } from "../apisMethods";
import { globalInfo } from "../App";



const My_Orders = () => {
  const { setLoader } = useContext(globalInfo)
  const [userProfileData, setUserProfileData] = useState([]);
  const [pendingOrAuthorise, setPendingOrAuthorise] = useState("P");
  const [headerToken, setHeaderToken] = useState({});
  const [transactionsData, setTransactionsData] = useState([]);

  const [profile, setProfile] = useState('');
  const [iin, setIin] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [addClass, setAddClass] = useState('pb-f');


  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setHeaderToken(headers);
    userProfileCall(userdata?.pan, headers);
    // nseTransactionReportCall()
  }, []);

  const userProfileCall = (pan, header) => {

    let tempArr = []
    userProfile({ pan }, header).then(res => {
      res.data?.data.forEach((data, i) => {
        let obj = { value: data?.PAN ? `${i}${data?.PAN}` : `${i}Gpan_${data?.GUARD_PAN}`, label: `${data?.NAME} ${data.JOINT_NAME1 && '|'} ${data.JOINT_NAME1}`, body: { iin: data?.IIN } }
        tempArr.push(obj)
      })
    })
    setUserProfileData(tempArr)
  }
  // const data = [
  //   { value: 'All', label: 'All' },
  //   { value: 'Pranesh Singh', label: 'Pranesh Singh' },
  // ];

  const profileChangeHandler = (e) => {

    setProfile(e?.value)
    setIin(e?.body?.iin)
    nseTransactionReportCall(e?.body?.iin, pendingOrAuthorise)
  }
  const dataHandler = (e) => {
    setPendingOrAuthorise(e.target.value)
    nseTransactionReportCall(iin, e.target.value)
  }
  const nseTransactionReportCall = (iin, pendingOrAuth) => {
    setLoader("block");
    nseTransactionReport({ iin, trxn_status: pendingOrAuth }, headerToken).then(res => {
      if (!res.data) {
        setTransactionsData([])
        setLoader("none");
        setAddClass('')
        setErrorMsg("No transactions!")
      } else {
        setAddClass('')
        setTransactionsData(res.data?.data)
        setErrorMsg("")
      }
      setLoader("none");
    });
  };
  return (
    <>
      <div className="wrapper px-3 me-4">
        <div className="report px-lg-5 ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                My Orders
              </li>
            </ol>
          </nav>
        </div>
        <section className={`mb-5 ${addClass}`}>
          <div className="container-fluid">
            <div className="row px-lg-4 pt-3 mx-lg-4">
              <div className="col-md-4">
                <div className="my-order px-lg-3">
                  <label className="pb-2">Select Applicant Name</label>
                  <Select
                    options={userProfileData}
                    onChange={profileChangeHandler}
                    className="orderprofile"
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center pt-4">
                <Form.Check
                  type="radio"
                  label="Pending"
                  name="pending"
                  onChange={dataHandler}
                  value={"P"}
                  checked={pendingOrAuthorise === "P"}
                  id="formHorizontalRadios2"
                  className="pe-3"
                />
                <Form.Check
                  type="radio"
                  label="Authorized"
                  name="pending"
                  onChange={dataHandler}
                  value={"A"}
                  checked={pendingOrAuthorise === "A"}
                  id="formHorizontalRadios3"
                  className="pe-3"
                />
              </div>
            </div>
          </div>
        </section>

        {transactionsData.length > 0 ? (
          transactionsData.map((data) => (
            <section className=" mx-lg-5">
              <div className="">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="order-shadow p-4 mb-4 table-responsive">
                        <h6 className="">{data.SCHEME_NAME.split("/")[1].split("(")[0]}</h6>
                        <Table>
                          <thead>
                            <tr className="text-red">
                              <th className="wd-5rem">Date </th>
                              <th className="wd-15rem">Target Scheme</th>
                              <th className="wd-5rem">Trxn No.</th>
                              <th className="wd-5rem">Amount</th>
                              <th className="wd-7rem">Type</th>
                              {/* <th>Unit</th> */}
                              <th>Folio</th>
                              <th className="wd-5rem">Ref. No.</th>
                              {/* <th >Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{data.ENTRY_DATE.split(" ")[0]}</td>
                              <td>
                                {data.TARGET_PRODUCT
                                  ? data.TARGET_PRODUCT.split("/")[1]
                                  : "n/a"}
                              </td>
                              <td>{data.USER_TRXNNO}</td>
                              <td>₹{data.AMOUNT}</td>
                              <td>{data.TRXN_TYPE}</td>
                              {/* <td>{data.UNITS == 0 ? "n/a" : data.UNITS}</td> */}
                              <td>
                                {!data?.FOLIO_NO ? "n/a" : data?.FOLIO_NO}
                              </td>
                              <td>
                                {data?.PAYMENT_REF_NO
                                  ? data?.PAYMENT_REF_NO
                                  : "n/a"}
                              </td>
                              {/* <td><a href="#" className='fw-600'> Authorize</a></td> */}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Loader loader={loader} /> */}
            </section>
          ))
        ) : (
          <h3 style={{ color: "red", textAlign: "center" }}></h3>
        )}

        {errorMsg ? (
          <section>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-11 py-3">
                  <div className="sipstpswp_error shadow-custom py-5 text-center">
                    <div className="sipstpswp_error-img">
                      <img src={NoDataImage} className="img-fluid mw-170" />
                    </div>
                    <h5 className="pt-3 text-red fw-600">{errorMsg}</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {/* </section> */}
      </div>
    </>
  );
};
export default My_Orders;
