import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import searchImage from "../assets/images/search.png";
import SIP_Form from "../components/sip-form";
import { getGoalPlanningSchemes } from "../apisMethods";
import { useLocation } from "react-router-dom";
import Cart_Delete from '../components/delete-confirmation';
import { apis } from "../request";
import { CheckPathRediction } from "./helpers/redirection";

function GoalWiseScheme() {
  const navigate = useNavigate()
  const [sipformShow, setSipformShow] = useState(false);
  const [schemeList, setSchemeList] = useState([]);
  const [notFoundImage, setNotFoundImage] = useState(null);
  const { state } = useLocation();

  useEffect(() => {
    getGoalPlanningSchemes({ durationValues: state }).then((res) => {
      if (!res.data) {
        setSchemeList([]);
        setNotFoundImage(
          <>
            <img
              src={searchImage}
              alt=""
              style={{ width: "35%", height: "35%" }}
            />
            <h4 style={{ color: "#f06d70" }}>
              Sorry, we couldn't find any schemes as per your goal
            </h4>
          </>
        );
      } else {
        setSchemeList(res.data.data);
      }
    });
  }, []);

  const openSipForm = ()=>{
      const isLoggedIn = CheckPathRediction()
      if (!isLoggedIn?.token) {
        navigate("/");
        // window.location.href = "/"
        return
      } else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
        // window.location.href = "/dashboard/Profile-creation"
        navigate("/dashboard/Profile-creation")
      } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
        navigate("/dashboard/Profile-creation")
      } else {
        setSipformShow(true)
      }
  
    
  }

  const deleteData = (id) => {
    schemeList.splice(id, 1);
    setSchemeList([...schemeList]);
  };

  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5">
          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ms-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                Goal Wise Recommendation
              </li>
            </ol>
          </nav>

          <section>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-9 col-10 px-2 pb-3  shadow-custom">
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="col-12">
                        <div className="table-responsive">
                          {schemeList.length > 0 ? (
                            <table class="table custom text-start text-black-table">
                              <thead>
                                <tr>
                                  <th>
                                    <h6 className="text-red ">
                                      Image
                                    </h6>
                                  </th>
                                  <th>
                                    <h6 className="text-red ">
                                      Recommended Schemes
                                    </h6>
                                  </th>
                                  <th><h6 className="text-red ">
                                      Delete
                                    </h6></th>
                                </tr>
                              </thead>
                              <tbody>
                                {schemeList.map((el, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <img
                                     src={
                                      // `${apis.imageLink}growth.png`
                                      `${apis.imageLink}${el?.AMC_CODE}.png`
                                    } alt="" className='img-fluid min-w-3em mx-wd-45'
                                        />
                                      </td>
                                      <td>
                                        {/* <Link
                                          to="/dashboard/nav-single"
                                          className=" dividend-popup text-dark"
                                        > */}
                                          {el.PRODUCT_LONG_NAME}
                                        {/* </Link> */}
                                      </td>
                                      <td className='min-w-35em' style={{cursor:"pointer"}}> <Cart_Delete id={i} deleteData={deleteData} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            notFoundImage
                          )}
                        </div>
                      </div>

                      {schemeList.length > 0 ? (
                        <div className="col-12 mt-4 mb-3 text-lg-end text-center">
                          <button
                          type="button"
                            onClick={openSipForm}
                            className="btn-custom"
                          >
                            Continue
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={schemeList} />
      </section>
    </>
  );
}
export default GoalWiseScheme;
