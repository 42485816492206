import React, { useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { portfolioCapitalReview } from "../apisMethods";
import { globalInfo } from "../App";
import Thanku from "./thanku";

const ConfirmationMandatePopup = ({ show, setShow,profileDetail}) => {
    const {setLoader} = useContext(globalInfo)
    const [successMessage,setSuccessMessage] = useState(false)
    const [confirmShow,setConfirmShow] = useState(false)
   const mailSendForMandate = ()=>{
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        // setHeaders(header);
        console.log("profileDetail",profileDetail)
        const userdata = JSON.parse(localStorage.getItem("user"));
        let reqBody = {
            primaryName: userdata?.displayName,
            phone: userdata?.mobile,
            name: profileDetail?.name,
            email: userdata?.email,
            pan: profileDetail?.pan ? profileDetail?.pan : "",
            gpan: profileDetail?.gpan ? profileDetail?.gpan :"",
            type: "emandate",
            iin:profileDetail?.iin
        };
        setLoader("block")
        portfolioCapitalReview(reqBody, header).then((res) => {
            if (!res.data) {
            } else {
                setShow(false)
                setConfirmShow(true)
                setSuccessMessage("Your request has been received and acknowledged at our end, your query shall be responded shortly.")
            }
            setLoader("none")
        });

   }
    return (
        <>

            <Modal show={show} dialogClassName="modal-37w" onHide={() => setShow(false)} centered className="animate__animated animate__zoomIn animate__fast">
                <Modal.Body className="text-center py-4">
                    <p className="fw-500 text-center">
                    You have not created any e-mandate yet for this profile. Please submit your request for e-mandate creation.
                    </p>
                </Modal.Body>

                <div className=" order-b d-flex justify-content-around pb-4">
                    <div className="">
                        <button className='rounded border-dash px-3 py-1 single-drop'onClick={()=>setShow(false)} >Cancel</button>
                    </div>
                    <div className="">
                        <button className='rounded border-dash single-drop px-3 py-1' onClick={mailSendForMandate}>OK</button>
                    </div>
                </div>
            </Modal>
            <Thanku
                show={confirmShow}
                setShow={setConfirmShow}
                successMessage={successMessage}
            />
        </>
    )
}
export default ConfirmationMandatePopup;