import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BsArrowLeft } from "react-icons/bs";
import success from "../assets/images/others/successfully.png";
import { userProfile, getMandateList, folioViaAmc, sipTransact, saveNseTransaction } from "../apisMethods";
import Form from "react-bootstrap/Form";
import { notify } from '../pages/toastCreater';
import SWW from "./something-went-wrong";
import TransactSuccess from "./transact-success";
import { add15DaysFromCurrent, addSevenDaysFromCurrent, addThreeMonthsFromCurrent, disableFutureDate, formatDate } from '../currentMonth/FinancialYear';
import { globalInfo } from '../App';
import Cart_Delete from './delete-confirmation';
import ConfirmationMandatePopup from './mandateConfirmationPopup';
const SIP_Form = ({ show, setShow, sipSchemes }) => {
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [SIP, setSIP] = useState("d-block");
  const [Sipcart, setSipcart] = useState("d-none");
  const [Confirmp, setConfirmp] = useState("d-none");
  const [Detail, setDetail] = useState("d-none");
  const [userProfileData, setUserProfileData] = useState([])
  const [headerState, setHeaderState] = useState({})
  const [profileName, setProfileName] = useState('')
  const [tempProfileName, setTempProfileName] = useState({})
  const [mandateListData, setMandateListData] = useState([])
  const [mandateUrn, setMandateUrn] = useState('')
  const [tempMandateData, setTempMandateData] = useState({})
  const [totalInvestment, setTotalInvestment] = useState('')
  const [folioList, setFolioList] = useState([])
  const [defaultDisabled, setDefaultDisabled] = useState('')
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)


  // loder
  const [mandateLoder, setMandateLoder] = useState(false)
  const [folioLoader, setFolioLoader] = useState(false)

  // const cart = () => {
  // setSIP("d-block")
  // setCart("d-none")
  // }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers)
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
    } 
     let day = addSevenDaysFromCurrent()
      setDefaultDisabled(day)
    

  }, []);
  useEffect(() => {
    setSipcart('d-none')
    setConfirmp('d-none')
    setSIP('d-block')
    setDetail('d-none')
    // setTempMandateData({})
    refreshForProfile()

  }, [show])

  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if (!res.data) {
        setUserProfileData([])
      } else {
        setUserProfileData(res.data?.data)
       
      }
    })
  }

  const getMandateListCall = (iin) => {
    setMandateLoder(true)
    getMandateList({ iin }, headerState).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
      }
      setLoader("none")
      setMandateLoder(false)
    })
  }

  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      // refreshForProfile();
      setLoader("block")
      setMandateListData([])
      setMandateUrn('')
      setTempMandateData({})
      let reqData = JSON.parse(e.target.value);
      setProfileName(e.target.value);
      setTempProfileName(reqData);
      getMandateListCall(reqData.IIN)
      amcChangeHandler(reqData)
      console.log("sipSchemes[0]===",sipSchemes[0]);
      if (sipSchemes[0]?.fromNfo) {
       const day = add15DaysFromCurrent(sipSchemes[0]?.nfo_close)
        setDefaultDisabled(day)
      } 
    }
  };
  const amcChangeHandler = async (reqData) => {
    let arr = []
    setLoader("block")
    for (let i = 0; i < sipSchemes.length; i++) {
      let value = sipSchemes[i]?.AMC_CODE;
      if (value) {
        setFolioLoader(true);
        let joint1stPan = "";
        let joint2ndPan = "";

        if (reqData.JOINT1_PAN) {
          joint1stPan = reqData.JOINT1_PAN;
        } else if (reqData.JOINT1_VALIDPAN.length > 1) {
          joint1stPan = reqData.JOINT1_VALIDPAN;
        } else {
          joint1stPan = "";
        }
        if (reqData.JOINT2_PAN) {
          joint2ndPan = reqData.JOINT2_PAN;
        } else if (reqData.JOINT2_VALIDPAN.length == 10) {
          joint2ndPan = reqData.JOINT2_VALIDPAN;
        } else {
          joint2ndPan = "";
        }

        const reqBody = {
          investor_pan: reqData.pan,
          jh1_pan: joint1stPan,
          jh2_pan: joint2ndPan,
          amccode: value,
          gpan: reqData?.gpan ? reqData?.gpan : "",
        };
        const res = await folioViaAmc(reqBody, headerState)
        if (!res.data) {
          // notify("error", res.error.response.data.msg);
          arr.push({
            folioArr: [], scheme: sipSchemes[i],

            amc: sipSchemes[i].AMC_CODE,
            profileName: reqData.name,
            folio: "",
            product_code: sipSchemes[i]?.PRODUCT_CODE,
            reinvest: sipSchemes[i]?.REINVEST_TAG === "X" ? "Y" : sipSchemes[i]?.REINVEST_TAG,
            // sip_from_date: formatDate(fromDate),
            sip_from_date: "",
            sip_end_date: "",
            sip_freq: "OM",
            sip_amount: "",
            // sip_period_day: fromDateParts[2],
            input_ref_no: "",
            perpetual_flag: "Y",
            schemeName: sipSchemes[i]?.PRODUCT_LONG_NAME,
            amt_unit_type: "Amount"
          })
        } else {
          arr.push({
            folioArr: res.data?.data, scheme: sipSchemes[i],

            amc: sipSchemes[i].AMC_CODE,
            profileName: reqData.name,
            folio: "",
            product_code: sipSchemes[i]?.PRODUCT_CODE,
            reinvest: sipSchemes[i]?.REINVEST_TAG,
            // sip_from_date: formatDate(fromDate),
            sip_from_date: "",
            temp_sip_from_date: "",
            sip_end_date: "",
            temp_sip_end_date: "",
            sip_freq: "OM",
            sip_amount: "",
            sip_period_day: "",
            input_ref_no: "",
            perpetual_flag: "Y",
            schemeName: sipSchemes[i]?.PRODUCT_LONG_NAME,
            amt_unit_type: "Amount"

          })

        }

      } else {
        setFolioList([]);
      }
      setLoader("none")
      setFolioLoader(false);
    }
    setFolioList(arr)

  };



  const changeFolioHandler = (e, i) => {
    if (e.target.value) {
      const updatedData = [...folioList];
      updatedData[i].folio = e.target.value;
      setFolioList(updatedData);
    } else {
      const updatedData = [...folioList];
      updatedData[i].folio = "";
      setFolioList(updatedData);
    }
  };
  const mandateChangeHandler = (e) => {
    if (e.target.value) {
      let mandateData = JSON.parse(e.target.value)
      setMandateUrn(e.target.value)
      setTempMandateData(mandateData)
    }
  }
  const amountHandler = (e, i) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters

      const updatedData = [...folioList];
      updatedData[i].sip_amount = sanitizedValue;
      setFolioList(updatedData);

    } else if (!isNaN(inputValue)) {
      const updatedData = [...folioList];
      updatedData[i].sip_amount = 10000000;
      setFolioList(updatedData);
    }
  };

  const dateAny = (id, i) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }



  const sipFromHandler = (e, i) => {
    const updatedData = [...folioList]
    let onlyDay = e.target.value.split('-')
    if (folioList[i].scheme?.SIP_DATES.includes(onlyDay[2]) === false) {
      // updatedData[i].sip_from_date = ""
      // updatedData[i].temp_sip_from_date = ""
      notify("error", "Selected day is not available");
      // setFolioList(updatedData)
      return;
    }

    const fromDateParts = e.target.value.split("-");
    const formattedDate = formatDate(e.target.value);

    updatedData[i].sip_from_date = formattedDate
    updatedData[i].temp_sip_from_date = e.target.value
    updatedData[i].temp_sip_end_date = ""
    updatedData[i].sip_end_date = ""
    updatedData[i].sip_period_day = fromDateParts[2]
    setFolioList(updatedData)

  }
  const sipToHandler = (e, i) => {
    const todayDate = new Date();
    const yearC = todayDate.getFullYear();
    const monthC = (todayDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
    const dayC = todayDate.getDate().toString().padStart(2, '0'); // Add leading zero

    const todayaDateFormatted = `${yearC}-${monthC}-${dayC}`;
    const inputDate = new Date(e.target.value);

    // if (inputDate > todayaDateFormatted) {
    const formattedDate = formatDate(e.target.value);
    const updatedData = [...folioList]
    updatedData[i].sip_end_date = formattedDate
    updatedData[i].temp_sip_end_date = e.target.value
    setFolioList(updatedData)
    // } else {
    //   notify("error", "SIP To date 3 month diffrence between SIP From date")
    // }

  }


  const perpetualHandler = (e, i) => {
    const updatedData = [...folioList]
    updatedData[i].perpetual_flag = updatedData[i].perpetual_flag === "N" ? "Y" : "N"
    setFolioList(updatedData)
  }

  const continueFromProfile = (e) => {
    e.preventDefault()
    if (!profileName) {
      notify("error", "Please select profile")
      return
    }
    if (!mandateUrn) {
      notify("error", "Please select mandate")
      return
    }
    setSipcart("d-block")
    setSIP("d-none")
  }
  const detail = () => {
    setSipcart("d-block")
    setDetail("d-none")
  }
  const sipTransactOrder = (e) => {
    e.preventDefault()

    let arrayCount = folioList.length
    for (let i = 0; i < arrayCount; i++) {
      if (!folioList[i].sip_from_date) {
        notify("error", "Please select SIP From Date")
        return
      }
      if (folioList[i].perpetual_flag === "N" && !folioList[i].sip_end_date) {
        notify("error", "Please select SIP To date")
        return
      }
      if (!folioList[i].sip_amount) {
        notify("error", "Please enter your amount")
        return
      }
      if (folioList[i].sip_amount < 1000) {
        notify("error", "Amount should be greater than min amount")
        return
      }
    }
    const totalAmount = folioList.reduce((accumulator, currentObject) => {
      return accumulator + Number(currentObject.sip_amount);
    }, 0);
    setTotalInvestment(totalAmount)
    setConfirmp("d-block")
    setSipcart("d-none")
  }

  const refreshForProfile = () => {
    setFolioList([]);
    setMandateUrn('')
    setTempMandateData({})
    setMandateListData([])
    setTempProfileName({})
    setProfileName('')
  };


  const finalOrderSip = () => {
    let reqBody = {}
    let nseArr = []
    let paymentUrl = ""
    // for (let value of folioList) {
    setLoader('block')
    const newData = folioList.map((obj) => {
      const { folioArr, scheme, ...objWithoutAAndB } = obj;
      return { ...objWithoutAAndB };
    });

    reqBody = {
      email: tempProfileName.email,
      holder_name: tempProfileName.name,
      tax_status: tempProfileName.taxStatus,
      umrn: tempMandateData.UMRN_NO,
      iin: tempProfileName.IIN,
      instrm_amount: totalInvestment,
      ach_amt: totalInvestment,
      instrm_date: tempMandateData.MANDATE_DATE, //============
      accountNo: tempMandateData.ACCOUNT_NO,
      debit_amount_type: "Amount",
      bank_code: tempMandateData.BANK_CODE,
      sub_trxn_type: "S",
      Return_paymnt_flag: "N",
      Client_callback_url: "",
      ach_exist: "Y",
      trxn_acceptance: "OL",
      payment_mode: "M",
      schemes: newData
    }

    sipTransact(reqBody, headerState).then((res) => {
      if (res.data?.status === 200) {
        setLoader('none')
        let objOfData = res.data?.data
        if (newData.length === 1) {
          let nseData = {
            Unique_No: res.data?.data?.Unique_No,
            Trxn_No: res.data?.data?.Trxn_No,
            Application_No: res.data?.data?.Application_No,
            Fund: res.data?.data?.Fund,
            Scheme: res.data?.data?.Scheme,
            Scheme_Name: res.data?.data?.Scheme_Name,
            Amt: res.data?.data?.Amt,
            Status_Desc: res.data?.data?.Status_Desc,
            Status_code: res?.data?.data?.Status_code,
            Folio: res.data?.data?.Folio,
            Platform: "Web",
            transaction_type: "SIP",
            iin: tempProfileName?.IIN,
            Pan: tempProfileName?.pan,
            Gpan: tempProfileName?.gpan,
            Joint1pan: tempProfileName?.JOINT1_PAN,
            Joint2pan: tempProfileName?.JOINT2_PAN,
            name: tempProfileName?.name,
          };

          // loaclArr.push(res.data?.data)

          saveNseTransaction(nseData, headerState).then((res) => {
            setConfirmShow(true);
            setConfirmationData(objOfData)
            setShow(false);
            setConfirmationDataArr([]);
            refreshForProfile()
            setTempProfileName({})
            setProfileName('')
          });
        } else {
          nseArr = res.data?.data
          for (let i = 0; i < nseArr.length; i++) {
            let nseData = {
              Unique_No: nseArr[i].Unique_No,
              Trxn_No: nseArr[i].Trxn_No,
              Application_No: nseArr[i].Application_No,
              Fund: nseArr[i].Fund,
              Scheme: nseArr[i].Scheme,
              Scheme_Name: nseArr[i].Scheme_Name,
              Amt: nseArr[i].Amt,
              Status_Desc: nseArr[i].Status_Desc,
              Status_code: nseArr[i].Status_code,
              Platform: "Web",
              transaction_type: "SIP",
              iin: tempProfileName?.IIN,
              Pan: tempProfileName?.pan,
              Gpan: tempProfileName?.gpan,
              Joint1pan: tempProfileName?.JOINT1_PAN,
              Joint2pan: tempProfileName?.JOINT2_PAN,
              name: tempProfileName?.name,
            }
            saveNseTransaction(nseData, headerState).then(res => {

            })

          }
          setConfirmShow(true);
          setLoader('none')
          setConfirmationData({})
          setShow(false);
          setConfirmationDataArr(nseArr);
          refreshForProfile()
          setTempProfileName({})
          setProfileName('')

        }


        if (res.data?.data?.Paymentlink) {
          window.open(res.data?.data?.Paymentlink);
          paymentUrl = res.data?.data?.Paymentlink;
        }
      } else {
        setLoader('none')
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }

    });
    // }
    // setSuccessp("d-block")
    // setConfirmp("d-none")
  }
  const deleteData = (id) => {
    folioList.splice(id, 1);
    setFolioList([...folioList])
  }
  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
    }
  }
  const backToDetail = () => {
    setConfirmp("d-none")
    setSipcart("d-block")
  }
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="animate__animated animate__zoomIn animate__fast invest-sip"
          centered
        >
          {/* =========SIP======== */}
          <div className={`${SIP}`}>
            <Modal.Header className="bg-light-red" closeButton>
              <Modal.Title className="fs-5">SIP</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light-red">
              <Form onSubmit={continueFromProfile}>
                <div className="col-mb-3">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={profileChangeHandler}
                      required
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                          <option
                            value={JSON.stringify({
                              email: el.EMAIL,
                              taxStatus: el.TAX_STATUS,
                              IIN: el.IIN,
                              name: el.NAME,
                              pan: el.PAN,
                              gpan: el?.GUARD_PAN,
                              jtname1: el.JOINT_NAME1,
                              jtname2: el.JOINT_NAME2,
                              nominee: el.NOM_NAME,
                              JOINT1_PAN: el.JOINT1_PAN,
                              JOINT2_PAN: el.JOINT2_PAN,
                              JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                              JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                              bankName: el.BANK_NAME,
                              bankCode: el.BANK_CODE,
                              accountNo: el.AC_NO,
                              accountType: el.AC_TYPE,
                              ifscCode: el.IFSC_CODE,
                              branchName: el.BRANCH_NAME,
                              branchAddress: el.BRANCH_ADDRESS1,
                              hold_nature_desc: el.hold_nature_desc,
                            })}
                            key={el.PAN}
                          >
                            {`${el.NAME} ${el.JOINT_NAME1 && "|"} ${el.JOINT_NAME1
                              }`}
                          </option>
                        ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div> <div className="col mb-3 ">
                  <Form.Group>
                    <Form.Label>
                      Select Mandate <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      required
                      className="bg-c"
                      value={mandateUrn}
                      onChange={mandateChangeHandler}
                      disabled={mandateLoder}
                      onClick={checkMandate}
                    >
                      <option value={""}>Select Mandate</option>
                      {mandateListData.length > 0 &&
                        mandateListData.map((data) => (
                          <option
                            value={JSON.stringify({
                              UMRN_NO: data.UMRN_NO,
                              BANK_CODE: data.BANK_CODE,
                              ACCOUNT_NO: data.ACCOUNT_NO,
                              MANDATE_DATE: data.MANDATE_DATE,
                              AC_TYPE: data.AC_TYPE,
                            })}
                          >{`${data?.BANK_NAME} | Ac No-  ${data?.ACCOUNT_NO
                            } |  Amount- ${data?.AMOUNT > 0 ? data?.AMOUNT : "0"
                            }`}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>

                {/* <div className="col mb-3 ">
                  <Link to="/dashboard/bank-and-mandate" className='text-red fs-13'>Create Mandate</Link>
                </div> */}
                <Modal.Footer className="border-0 bg-light-red">
                  <button type="submit" className="btn btn-danger shadow-sm">
                    Continue
                  </button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
          {/* =========investment details======== */}
          <div className={`${Detail}`}>
            <Modal.Header className="bg-light-red" closeButton>
              <Modal.Title className="fs-5">Investment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light-red">
              <div className="col-md-12">
                <p>
                  <span className="text-red fw-500">New Folio :</span> ‘First
                  Installment shall be deducted on current date & subsequent
                  installments shall be deducted on selected SIP date.’
                  <br /> <br />
                  <span className="text-red fw-500">
                    Existing Folio :{" "}
                  </span>{" "}
                  ‘Your SIP installments shall be deducted on the selected SIP
                  date.’
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 bg-light-red">
              <a
                type="button"
                className="btn btn-danger shadow-sm"
                href="javascript:void(0);"
                onClick={detail}
              >
                OK
              </a>
            </Modal.Footer>
          </div>
          {/* =========SIP Cart======== */}
          <div className={`${Sipcart}`}>
            <Modal.Header className="bg-light-red" closeButton>
              <Modal.Title className="fs-5">SIP Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light-red">
              <Form onSubmit={sipTransactOrder}>
                {folioList.length > 0
                  ? folioList.map((data, i) => {
                    return (
                      <div className="cartitemwith bg-gray m-2">
                        <div className="row p-4">
                          <div className="col-md-11  red ">
                            {data?.scheme.PRODUCT_LONG_NAME}
                          </div>
                          <div className="col-1">
                            {folioList.length > 1 ? <Cart_Delete
                              id={i}
                              deleteData={deleteData}
                            /> : ""
                            }
                          </div>
                        </div>
                        <div className="col bg-white py-1 px-4">
                          <Form.Label>
                            Select Folio{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            className="bg-c"
                            value={folioList[i].folio}
                            onChange={(e) => changeFolioHandler(e, i)}
                            disabled={folioLoader}
                          >
                            <option value="">New Folio</option>
                            {data.folioArr &&
                              data.folioArr.map((el) => (
                                <option value={el.folio} key={el.PAN}>
                                  {el.folio}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                        <div className="col  bg-white pb-1 px-4">
                          <label htmlFor="amount" className="fs-14">
                            Enter Amount{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border-pop"
                            name="amount"
                            value={folioList[i].sip_amount}
                            onChange={(e) => amountHandler(e, i)}
                            placeholder="Enter Amount"
                            required
                          />
                          <small className="text-label float-right">
                            Min Amount : 1000
                          </small>
                        </div>

                        <div className="col bg-white py-1 px-4">
                          <label htmlFor="date" className="fs-14">
                            SIP From <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            id={`sip-from${i}`}
                            onClick={() => dateAny(`sip-from${i}`)}
                            min={defaultDisabled}
                            max={addThreeMonthsFromCurrent()}
                            className="form-control border-pop"
                            value={folioList[i].temp_sip_from_date}
                            onChange={(e) => sipFromHandler(e, i)}
                            required
                          />
                        </div>
                        {/* <div className="col bg-white py-1 px-4">
                    <label htmlFor="date" className='fs-14' >Select Period (Months) <span className='text-danger'>*</span></label>
                    <Select className='border-pop' options={selectperiod} />
                  </div> */}
                        {folioList[i].perpetual_flag === "N" ? (
                          <div className="col bg-white py-1 px-4">
                            <label htmlFor="date" className="fs-14">
                              SIP To <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              id={`sip-to${i}`}
                              onClick={() => dateAny(`sip-to${i}`)}
                              min={addThreeMonthsFromCurrent()}
                              className="form-control border-pop"
                              value={folioList[i].temp_sip_end_date}
                              onChange={(e) => sipToHandler(e, i)}
                              required
                            />
                          </div>
                        ) : null}
                        <div className="col bg-white py-1 px-4">
                          <label htmlFor="date" className="fs-14">
                            Dates Available{" "}
                          </label>
                          <small
                            style={{ fontSize: "12px" }}
                            className="text-gray fs-sm-10 word-break"
                          >
                            {data?.scheme?.SIP_DATES}
                          </small>
                        </div>
                        <div className="col p-2">
                          <input
                            className="input-text "
                            type="checkbox"
                            name={"perpetual" + i}
                            id={"perpetual" + i}
                            checked={folioList[i].perpetual_flag === "Y"}
                            value={folioList[i].perpetual_flag}
                            onChange={(e) => perpetualHandler(e, i)}
                          />
                          <label htmlFor={"perpetual" + i} className="ms-2">
                            Perpetual
                            <spna className="text-danger">*</spna>
                          </label>
                        </div>
                      </div>
                    );
                  })
                  : null}

                <Modal.Footer className="border-0 bg-light-red">
                  <button type="submit" className="btn btn-danger shadow-sm">
                    Continue
                  </button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
          {/* =========SIP Confirmation Purchase======== */}
          <div className={`${Confirmp}`}>
            <Modal.Header className="bg-light-red" closeButton>
              <span onClick={backToDetail}>
                {" "}
                <BsArrowLeft className="sip-popup-rignt-icon" />
              </span>
              <Modal.Title className="fs-5">
                SIP Confirmation Purchase
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light-red">
              <div className="col mb-3 border-bottom">
                <h6 className="text-red">{tempProfileName?.name}</h6>
                <p>
                  Mode of Holding : {tempProfileName?.hold_nature_desc}
                  {/* <a href="#" className='p-4 ml-5' onClick={edit}>Edit</a> */}
                </p>
              </div>
              {folioList.length > 0
                ? folioList.map((data, i) => {
                  return (
                    <>
                      <p className="text-red">{data?.schemeName}</p>
                      <table className="table">
                        {data?.folio ? (
                          <>
                            {" "}
                            <tr className="text-center">
                              <td className="text-start ps-5">Folio </td>
                              <td> :</td>
                              <td className="text-start ps-5">
                                {data?.folio}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        <tr className="text-center">
                          <td className="text-start ps-5">Amount </td>
                          <td> :</td>
                          <td className="text-start ps-5">
                            {data.sip_amount}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <td className="text-start ps-5">SIP From </td>
                          <td> :</td>
                          <td className="text-start ps-5">
                            {data.sip_from_date}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <td className="text-start ps-5">SIP To </td>
                          <td> :</td>
                          <td className="text-start ps-5">
                            {folioList[i].perpetual_flag === "N"
                              ? data.sip_end_date
                              : "Perpetual"}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <td className="text-start ps-5">SIP Date </td>
                          <td> :</td>
                          <td className="text-start ps-5">
                            {data.sip_period_day}
                          </td>
                        </tr>
                      </table>
                    </>
                  );
                })
                : null}

              <p className="text-center pt-5">
                <b>Total : {totalInvestment}</b>
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0 bg-light-red">
              <a
                type="button"
                className="btn btn-danger shadow-sm"
                href="javascript:void(0);"
                onClick={finalOrderSip}
              >
                Order
              </a>
            </Modal.Footer>
          </div>
        </Modal>
        <SWW
          show={errorConfirmationShow}
          setShows={setErrorConfirmationShow}
          errorMsg={errorMsg}
        />
        <TransactSuccess
          show={confirmShow}
          setShow={setConfirmShow}
          confirmData={confirmationData}
          confirmationDataArr={confirmationDataArr}
        />
        <ConfirmationMandatePopup
          show={isMandateAvailable}
          setShow={setIsMandateAvailable}
          profileDetail={{ name: tempProfileName?.name, iin: tempProfileName?.IIN, pan: tempProfileName?.pan, gpan: tempProfileName?.gpan }}
        />
      </div>
    </>
  );
}
export default SIP_Form