import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from "react-router-dom";
import {
    personalDetail,
  personalDetailByFolio,
  transactionDetail,
} from "../apisMethods";
import { globalInfo } from "../App";


const FolioDetailPopup = ({ show, setShow ,data}) => {
  const {setLoader} = useContext(globalInfo)
    const [transactionData, setTransactionData] = useState([])
    const [personalDetaildataOfFolio, setPersonalDetaildataOfFolio] = useState([])
  const [personalDetaildata, setPersonalDetaildata] = useState([{
      BANKACNO: "",
      BNAME: "",
      BNKACTYPE: "",
      FOLIO: "",
      MOH: "AS",
      NAME: "", NOMINEE2: "", NOMINEE3: "", NOMINEE1: "", PAN: "", SCHEME: "", STATUS:
        ""
    }])
  const [totalBalance, setTotalBalance] = useState()
  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
  
    // let urlData = location.state
    transactionDetailCall(header, data)
  }, [data])

  const transactionDetailCall = (header, reqData) => {
    setLoader('block')
    transactionDetail(reqData, header).then(res => {
      if (!res.data) {
      } else {
        setTransactionData(res.data?.data)
        let balanceUnit = 0;
        (res.data?.data).map((data) => {
          if (data.NATURE === "SIP") {
            balanceUnit += data.UNITS
          } else {
            balanceUnit -= data.UNITS
          }
        })
        setTotalBalance(balanceUnit)
      }
      setLoader('none')
    })
    personalDetail(reqData, header).then(res => {
      if (!res.data) {
      } else {
        setPersonalDetaildata(res.data?.data)
      }
    })
    personalDetailByFolio(reqData, header).then(res => {
      if (!res.data) {
      } else {
        setPersonalDetaildataOfFolio(res.data?.data)
      }
    })

  }


  const transactonReview = (array) => {
    let balanceUnit = 0;
    // setTotalcnav(array[0].cnav) 

    return array.map((data) => {
      if (data.NATURE === "SIP") {
        balanceUnit += data.UNITS
      } else {
        balanceUnit -= data.UNITS
      }
      return <>  <tr>
        <td>{data.TD_TRDT}</td>
        <td>{data.DESC}</td>
        <td>{data.AMOUNT?.toFixed(2)}</td>
        <td>{data.TD_NAV}</td>
        <td>{data.NATURE === "SIP" ? data.UNITS : "-" + data.UNITS}</td>
        <td>{balanceUnit?.toFixed(3)}</td>
        {/* <td>{data.RTA}</td> */}
      </tr>
      </>

    })
  }
  return (
    <>
      <div className="">
        <Modal
        
          show={show}
          size="lg"
          onHide={() => setShow(false)}
          // onClick={refreshForProfile}
          className="invest-gray mx-80"
          scrollable={true}
          centered
        >
          <Modal.Header className="" closeButton>
            <Modal.Title className="fs-5">Folio Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4" style={{ scrollBehavior: "smooth" }}>
          <div className="col-md-12">
                <div className="folio-detail single-drop bg-gray ps-2 mb-5 table-responsive">
                  <Table>
                    <thead>

                      <tr>
                        <th>Name </th>
                        <th>:</th>
                        <td>{personalDetaildata[0].NAME}</td>
                        <th className="wd-5rem">Folio Number</th>
                        <th className="wd-1rem">:</th>
                        <td>{personalDetaildata[0].FOLIO}</td>
                      </tr>
                      <tr>
                        <th className="wd-6rem">Scheme Name</th>
                        <th className="wd-1rem">:</th>
                        <td className="wd-23rem">{personalDetaildata[0].SCHEME}</td>
                        <th>MOH </th>
                        <th>:</th>
                        <td>{personalDetaildata[0].MOH ==="SINGLE" ||personalDetaildata[0].MOH ==="single" ||personalDetaildata[0].MOH ==="Single" || personalDetaildata[0].MOH==="SINGLY" || personalDetaildata[0].MOH ==="singly"|| personalDetaildata[0].MOH==="Singly" ? "SI" : personalDetaildata[0].MOH }</td>
                      </tr>
                      <tr>
                        <th>Nominee 1</th>
                        <th>:</th>
                        <td>{personalDetaildata[0].NOMINEE1 ? personalDetaildata[0].NOMINEE1 : ""}</td>
                        <th>Bank </th>
                        <th>:</th>
                        <td className="wd-11rem">{personalDetaildata[0].BNAME} ({personalDetaildata[0].BANKACNO})</td>
                      </tr>
                      <tr>
                        <th>Nominee 2 </th>
                        <th>:</th>
                        <td>{personalDetaildata[0].NOMINEE2 ? personalDetaildata[0].NOMINEE2 : ""}</td>
                        <th>Nominee 3 </th>
                        <th>:</th>
                        <td>{personalDetaildata[0].NOMINEE3 ? personalDetaildata[0].NOMINEE3 : ""} </td>
                      </tr>
                      <tr>
                        <th>2nd Holder </th>
                        <th>:</th>
                        <td>{personalDetaildataOfFolio?.JTNAME1 ? personalDetaildataOfFolio?.JTNAME1 : ""}</td>
                        <th>3rd Holder </th>
                        <th>:</th>
                        <td>{personalDetaildataOfFolio?.NOMINEE3 ? personalDetaildataOfFolio?.NOMINEE3 : ""} </td>
                      </tr>
                    </thead>
                  </Table>
                </div>

                <div className="folio-detail-info single-drop mt-4">
                  <Table bordered responsive>
                    <thead>
                      <tr className="text-red">
                        <th className="wd-5rem">Date</th>
                        <th className="wd-7rem">Nature</th>
                        <th>Amount</th>
                        <th>NAV</th>
                        <th>Units</th>
                        <th className="wd-7rem">Balance Units</th>
                        {/* <th>RTA</th> */}
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {
                        transactionData.length > 0 && transactonReview(transactionData).map((data) => {
                          return data
                        })}
                      <tr className="bg-light-red">
                        <td></td>
                        <th>Current Value</th>
                        <th>{(totalBalance * transactionData[0]?.cnav).toFixed(2)}</th>
                        <th>{Number(transactionData[0]?.cnav).toFixed(4)}</th>
                        <th>{Number(totalBalance).toFixed(4)}</th>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer className="border-0" >
          
          </Modal.Footer>
        </Modal>
      </div>
     
    </>
  );
};
export default FolioDetailPopup;
