import React, { useState,useEffect } from "react";
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
import CalculatorHeader from "../components/calculator-header";
import Loader from "./Loader";
import { notify } from "./toastCreater";
import { Link , useNavigate } from "react-router-dom";
import { getMachine } from "./helpers/calculatorHelper";
import SIPTransact from "../components/sip-transact";
import { getAmcList, userProfile } from "../apisMethods";



const SipCalculator = () => {
  const history = useNavigate()
  const [monthlySavings, setMonthlySavings] = useState("10000");
  const [investmentPeriod, setInvestmentPeriod] = useState(5);
  const [expectedRateReturn, setExpectedRateReturn] = useState(10);
  let [totalSaving, setTotalSaving] = useState("6,00,000");
  const [gains, setGains] = useState("7,74,370");
  const [totalMonth, setTotalMonth] = useState(5);
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [sipShow, setSipShow] = useState(false);
  const [amountForSip, setAmountForSip] = useState("10000");
  const [yearForSip,setYearForSip] = useState(5)
  


  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  
  }, []);

  
  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if(!res.data){
        setUserProfileData([])
      }else{
        setUserProfileData(res.data?.data)
      }
    })

  }

  const investInSip = ()=>{
    const token = localStorage.getItem("token");
    const userdata = JSON.parse(localStorage.getItem("user"));
    if(!token){
      history("/")
    }else if(!userdata?.IIINStatus){
      history("/dashboard/Profile-creation")
    }
    let value =Number(monthlySavings?.toString().replace(/\,/g,''))
    let roundedNumber = Math.ceil(value / 100) * 100;
    if(roundedNumber <1000){
      setAmountForSip(1000)
    }else{
      setAmountForSip(roundedNumber)
    }
    setYearForSip(totalMonth)
    setSipShow(true)
  }

  const [data, setData] = useState([
    {
      name: "Market Value",
      data: {
        "0 Year": 0,
        "1 Year": 125665,
        "2 Year": 264469,
        "3 Year": 417818,
        "4 Year": 587225,
        "5 Year": 774370,
      },
    },
    {
      name: "Amount Invested",
      data: {
        "0 Year": 0,
        "1 Year": 120000,
        "2 Year": 240000,
        "3 Year": 360000,
        "4 Year": 480000,
        "5 Year": 600000,
      },
    },
  ]);

  const sipCalculate = (e) => {
    e.preventDefault();
    if (monthlySavings < 500) {
      notify("warn", "Amount should not be less than 500")
      return
    }
    if (investmentPeriod < 1) {
      notify("warn", "Investment Period should not be less than 1")
      return
    }
    if (expectedRateReturn < 1) {
      notify("warn", "Expected Rate of Return should not be less than 1")
      return
    }
    setLoader("block");

   


    let monthlyRate = expectedRateReturn / 12 / 100;
    var months = investmentPeriod * 12;
    var futureValue = 0;
    // var futureValue = (monthlySavings  (1 + monthlyRate)  ((Math.pow((1 + monthlyRate), months)) - 1) / monthlyRate);
    var futureValue = monthlySavings * (Math.pow(1 + monthlyRate, months) - 1) / monthlyRate;

    var mainresults = futureValue.toFixed(2)
    var totalSaving = monthlySavings * months
    var gains = (mainresults - monthlySavings * months).toFixed(2)
    var totalMonth = investmentPeriod
    var a = parseInt(totalSaving)
    var g = parseInt(gains)
    var gainss = a + g
    setTotalSaving(getMachine(Math.round(totalSaving)))
    setTotalMonth(getMachine(Math.round(totalMonth)))
    setGains(getMachine(Math.round(gainss)))
    document.getElementById('scroll').scrollIntoView(true);



    // var monthlyRate = expectedRateReturn / 12 / 100;

    let mainArray = [];
    let mainFirstObj = { name: " Amount Invested" };
    let mainSecondObj = { name: "Market Value" };
    let firstSubObj = {};
    let secondSubObj = {};
    if (parseInt(investmentPeriod) <= 50) {
      for (let i = 0; i < parseInt(investmentPeriod) + 1; i++) {
        if (i == 0) {
          firstSubObj[i + "Year"] = 0;
        } else {
          firstSubObj[i + "Year"] = (monthlySavings * i * 12).toFixed();
        }

        if (i == 0) {
          secondSubObj[i + "Year"] = 0;
        } else {
          // let marketData = monthlySavings * ((1 + monthlyRate) * ((Math.pow((1 + monthlyRate), i * 12)) - 1) / monthlyRate)
          let marketData =
            (monthlySavings * (Math.pow(1 + monthlyRate, i * 12) - 1)) /
            monthlyRate;
          let marketDataooo = marketData / 12; //(marketData + monthlySavings * i * 12)/ 12
          secondSubObj[i + "Year"] = (marketDataooo * 12).toFixed();
        }
      }
      mainFirstObj.data = firstSubObj;
      mainSecondObj.data = secondSubObj;
      mainArray.push(mainSecondObj);
      mainArray.push(mainFirstObj);
      setData(mainArray);

        //  window.scrollTo(500, 500);
      setCalculateCheck(1)
      setLoader("none");
    }



  };
  let incNum = () => {
    if (monthlySavings < 1000000) {
      setMonthlySavings(Number(monthlySavings) + 500);
    } else if (monthlySavings == 0) {
      setMonthlySavings(Number(monthlySavings) + 500);
    }
  };
  let decNum = () => {
    if (monthlySavings >= 500) {
      setMonthlySavings(monthlySavings - 500);
    } else if (monthlySavings < 499) {
      setMonthlySavings(0);
    }
  };

  let incMonth = () => {
    if (investmentPeriod < 50) {
      setInvestmentPeriod(Number(investmentPeriod) + 1);
    }
  };
  let decMonth = () => {
    if (investmentPeriod > 1) {
      setInvestmentPeriod(investmentPeriod - 1);
    }
  };

  let incPer = () => {
    if (isNaN(expectedRateReturn)) {
      return setExpectedRateReturn(0);
    }
    if (expectedRateReturn < 50) {
      setExpectedRateReturn(Number(expectedRateReturn) + 0.5);
    }
  };
  let decPer = () => {
    if (expectedRateReturn > 1) {
      setExpectedRateReturn(expectedRateReturn - 0.5);
    }
  };
  return (
    <>

      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}


          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Calculators</li>
            </ol>
          </nav>

          <Loader loader={loader} />

          <div className="container-fluid px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class="mt-3">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-4 pb-5">
                              <section className="pt-5 pb-5">
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label for="m-saving" className="text-label fw-500 fs-14 py-2">
                                      Monthly Savings <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decNum}></img>
                                      <input type="text" className="form-control " name="m-saving" value={monthlySavings}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 1000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setMonthlySavings(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setMonthlySavings(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 1000000) {
                                            setMonthlySavings(1000000);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incNum}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="year" className="text-label fs-14 fw-500 py-2">
                                      Investment Period (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decMonth}></img>
                                      <input type="text" className="form-control " name="year" value={investmentPeriod}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setInvestmentPeriod(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setInvestmentPeriod(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 50) {
                                            setInvestmentPeriod(50);
                                          }
                                        }} />

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incMonth}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 fs-14 py-2">
                                      Expected Rate of Return (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decPer} ></img>
                                      <input type="text" className="form-control " name="return" value={expectedRateReturn}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedRateReturn(value)
                                            
                                          } else if(e.target.value>50){
                                            setExpectedRateReturn(50);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incPer}></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 pt-2 mt-1 text-lg-end text-center">
                                  <button className="btn-custom mt-3" onClick={(e) => sipCalculate(e)}>{calculateCheck == 0 ? "Calculate" : "Re-Calculate"} </button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-lg-3" id="scroll">
                                <div className="col-md-4">
                                  <div className="result-content result-content-shadow">
                                    <ul className="text-center lst-none ps-0">
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Amount Invested<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">{totalSaving}</div>
                                      </li>
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Period (Year)
                                        </div>
                                        <div className="inputf transcard bg-white py-2"> {totalMonth}</div>
                                      </li>
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Gains<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">  {gains}</div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-8  py-5">
                                  <div className="result-title text-center">
                                    <h2 className="fw-500">Result</h2>
                                  </div>
                                  <div className="pt-4">
                                    <LineChart data={data} />
                                  </div>
                                </div>
                              </div>
                              <div className="row px-lg-5 pt-3" onClick={()=>investInSip(totalSaving)}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">Invest Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SIPTransact
              show={sipShow}
              setShow={setSipShow}
              amcList={amcList}
              userProfileData={userProfileData}
              isLookingForAnother={false}
              amountForSip={amountForSip}
              yearForSip={yearForSip}
              

            />
    </>
  )

}
export default SipCalculator