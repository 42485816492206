import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import OderSuccess from "../../src/assets/images/successfully.png"
import { Link,useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const KycCheckPopPup = ({ show, setShow, successMessage,user }) => {
const navigate = useNavigate()

    const goToIINCompleteProcess = () => {
        if (!user?.pan || !user?.iin) {
          navigate("/dashboard/Profile-creation");
        }
    }
    return (
        <>

            <Modal show={show} onHide={() => setShow(false)} centered className="animate__animated animate__zoomIn animate__fast  ">
                <Modal.Body className="text-center">

                    {/* <h3 className="text-successc fw-500 py-3"> Thankyou !!!</h3> */}
                    <p className="text-black">
                        {/* We have received your query, we will get back to you shortly. */}
                        {successMessage}
                    </p>
                    <div onClick={goToIINCompleteProcess} className="text-red fs-15 show-goal" >
                        Go to KYC<FaArrowRight className="ms-1" />
                    </div>
                </Modal.Body>
                <div className="bg-red text-center py-2 order-b">
                    <div style={{ cursor: "pointer" }} class="text-white font-weight-bold" onClick={() => setShow(false)}><h4>OK</h4></div>
                </div>
            </Modal>
        </>
    )
}
export default KycCheckPopPup;