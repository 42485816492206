import { getNotifications } from "../../apisMethods/notification"

export const fetchNotification = (setNotification,setIsNotificationRead)=>{
  let tokenuser = localStorage.getItem("token");
      const header = {
        headers:
          { Authorization: `Bearer ${tokenuser}` }
      };
  getNotifications(header).then(res=>{
    if(res.data){
      setNotification(res.data?.data?.reverse())
      // setIsNotificationRead(res.data.data?.find((el)=>!el.READ))
    }else{
      setNotification([])
    }
  })
}
export const fetchNotificationBack = ()=>{
  let tokenuser = localStorage.getItem("token");
      const header = {
        headers:
          { Authorization: `Bearer ${tokenuser}` }
      };
  getNotifications(header).then(res=>{
    if(res.data){
      // setNotification(res.data?.data?.reverse())
    }else{
      // setNotification([])
    }
  })
}