import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "animate.css";
import {
  FaUser,
  FaCogs,
  FaThumbtack,
  FaList,
  FaSignOutAlt,
  FaNewspaper,
  FaBriefcase,
  FaShoppingCart,
  FaBell,
  FaPhoneAlt,
  FaStar,
} from "react-icons/fa";
import Right_Icons from "../components/right-navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import ChangePassword from "../components/change-password";
import ChangePin from "../components/change-pin";
import ContactUs from "../components/contact-us";
import Notification_Popup from "../components/notification-popup";
import {
  connectRm,
  getAllFamily,
  getTime,
  sendResetOtp,
  setTime,
} from "../apisMethods";
import { useEffect } from "react";
import { notify } from "./toastCreater";
import PortfolioViaEmail from "../components/portfolio-via-email";
import CapitalGainStatement from "../components/capital-gain-statement";
// import PortfolioReviewPopup from "./portfolio-review-popup";
import { useData } from "./helpers/DataProvide";
import { globalInfo } from "../App";
import ConfirmationPopUP from "../components/confirmation-popup";
import Thanku from "../components/thanku";
import { useCount } from "./helpers/CartCountProvide";
import {
  deleteNotifications,
  markNotifications,
} from "../apisMethods/notification";
import { fetchNotification } from "./helpers/notification";
import Cart_Delete from "../components/delete-confirmation";
import RatingPopUp from "../components/RatingPopUp";

const Header = () => {
  const navigate = useNavigate();
  const { setLoader, notification, setNotification } = useContext(globalInfo);
  const { data } = useData();
  const {  getCartData } = useCount();
  const [notificationshow, setNotificationShow] = useState(false);
  const [editPassword, setEditPasswordShow] = useState(false);
  const [user, setUser] = useState({ displayName: "" });
  const [editPin, setEditPinShow] = useState(false);
  const [contact, setContactShow] = useState(false);
  const [showDrop, setShowDrop] = useState("none");
  const [userComes, setUserComes] = useState(false);
  const [showGainStatementPopup, setShowGainStatementPopup] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [showPortoflioVaiEmailPopup, setShowPortoflioVaiEmailPopup] =
    useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [thankuShow, setThankuShow] = useState(false);
  const [thankuMsg, setThankuMsg] = useState("");
  const [userFamilyData, setUserFamilyData] = useState([]);
  const [notificationData, setNotificationData] = useState({});
  const [userToken, setUserToken] = useState({});
  const [isNotificationRead,setIsNotificationRead] = useState(false)
  const [ratingRM,setRatingRM] = useState(false)

  const location = useLocation();

  useEffect(() => {
    if (showDrop == "block") {
      setShowDrop("none");
    }
    let userData = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setUserToken(headers);
    setUserDetails(userData);
    if (userData?.displayName) {
      getCartData();
      setUser(userData);
      familyWisePortfolioCall(userData.pan, headers);
      userData.profilePic = data ? data : userData?.profilePic;
    } else {
      setUser({ displayName: "Hi, User" });
    }
  }, [location, data]);



  const userLogOut = () => {
    localStorage.removeItem("userFamily");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("kycValidation");
    localStorage.removeItem("portfolioData");
    localStorage.removeItem("secondMobile");
    localStorage.removeItem("secondEmail");
    navigate("/");
  };

  const sendOTPOnEmail = (forMpin) => {
    if (!userDetails?.pan) {
      notify("warn", "Please Complete Your KYC");
      return;
    }

    if (editPassword) {
      forMpin = false;
      setEditPasswordShow(false);
    } else if (editPin) {
      forMpin = true;
      setEditPinShow(false);
    }

    setLoader("block");
    let data = JSON.parse(localStorage.getItem("user"));
    sendResetOtp({ email: data?.email, formpin: forMpin }).then((res) => {
      if (!res.data) {
        notify("error", res.error.response?.data?.msg);
      } else {
        notify("success", "OTP sent on your Mobile and Email");
        setUserComes(true);
        if (forMpin === false) {
          setEditPasswordShow(true);
        } else {
          setEditPinShow(true);
        }
        setLoader("none");
      }
    });
  };
  const deleteData = (id) => {
    deleteNotifications({ ids: [id] }, userToken).then((res) => {
      if (!res.data) {
        notify("error", "Something went wrong..");
      } else {
        notify("success", res.data.msg);
        fetchNotification(setNotification,setIsNotificationRead);
      }
    });
  };

  const profileDropDown = (name) => {
    return (
      <li className="px-lg-4 prof">
        <div className="text-decoration-none">
          <span className="d-flex align-items-baseline ">
            <span className="text-secondary d-smc-none">{name}</span>
            <span className="user-icon">
              <img
                src={
                  userDetails?.profilePic
                    ? userDetails?.profilePic
                    : "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/userImg.png"
                }
                alt="logo"
                className="img-fluid"
              />
            </span>
          </span>
        </div>
      </li>
    );
  };

  const sendMailForReview = () => {
    let token = localStorage.getItem("token");
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (!userDetails?.iin) {
      setConfirmShow(true);
      setSuccessMessage("Are you sure to submit a Portfolio Review Request?");
    } else if (userFamilyData.length == 0) {
      setConfirmShow(true);
      setSuccessMessage("Are you sure to submit a Portfolio Review Request?");
    } else {
      setLoader("block");
      getTime(headers).then((res) => {
        let oneHourInMiliseconds = 1 * 60 * 60 * 1000;
        let time = new Date().getTime();
        if (!res.data.success) {
          setTime({ time }, headers).then((setRes) => {
            if (!setRes.data) {
              notify("error", "Something went wrong");
              setLoader("none");
            } else {
              setLoader("none");
              setThankuShow(true);
              setThankuMsg(
                "Your portfolio review request has been submitted successfully.Please provide few hours to our research team to analyse your portfolio.Your portfolio review will be live soon."
              );
            }
          });
          setLoader("none");
        } else {
          setLoader("none");
          let specificTime = new Date(res.data?.data).getTime();
          specificTime += oneHourInMiliseconds;
          if (specificTime < time) {
            navigate("/dashboard/portfolio-review");
          } else {
            setThankuShow(true);
            setThankuMsg(
              "Your portfolio review request has been submitted successfully.Please provide few hours to our research team to analyse your portfolio.Your portfolio review will be live soon."
            );
          }
        }
      });
    }
  };

  const handleNotificationPopup = (data) => {
    setNotificationData(data);
    markNotifications({ ids: [data?._id], read: true }, userToken).then(
      (res) => {
        if (res.data) {
          fetchNotification(setNotification);
        }
      }
    );
    setNotificationShow(true);
  };

  const openCapitalGainStatement = (check) => {
    if (userDetails?.pan && userDetails?.iin) {
      const token = localStorage.getItem("token");
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      familyWisePortfolioCall(userDetails?.pan, headers);
      if (check === 1) {
        setShowPortoflioVaiEmailPopup(true);
      } else {
        setShowGainStatementPopup(true);
      }
    } else {
      window.location.href = "/dashboard/Profile-creation";
    }
  };
  const familyWisePortfolioCall = (pan, headers) => {
    getAllFamily({ adminPan: pan }, headers).then((res) => {
      if (res.data) {
        if (res?.data?.success === false) {
          setUserFamilyData([]);
        } else {
          setUserFamilyData(res.data?.data);
        }
      } else {
        setUserFamilyData([]);
        return;
      }
    });
  };
  const openConnectToRm = () => {
    connectRm({ userEmail: userDetails?.email ? userDetails?.email : "" }).then(
      (res) => {
        if (res.data) {
          setThankuShow(true);
          setThankuMsg(`Connect to your RM : ${res.data?.data}`);
        } else {
          console.log("errror");
        }
      }
    );
  };

  const removeDot = () => {
    notification.forEach((item) => {
      item.status = false;
    });
    localStorage.setItem("notification", JSON.stringify(notification));
  };

  const rateToRM = ()=>{
    setRatingRM(true)
  }
  return (
    <>
      {/* ============desktop view============== */}
      <div className="d-smc-none">
        <Navbar
          expand="lg"
          sticky="top"
          className="custom-shadow"
          onMouseLeave={() => setShowDrop("none")}
        >
          <Container fluid>
            <Navbar.Brand as={Link} to="/dashboard">
              <img
                src={
                  "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/bfc-logo.png"
                }
                alt="logo"
                className="img-fluid logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-around"
            >
              <ul className="navbar-nav main-menu ms-auto ">
                <li>
                  <NavLink to="/dashboard/sip-calculator" activClassName="">
                    Calculators
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/tax-planning">Tax Planning</NavLink>
                </li>
                <li className="menu_has_children">
                  <Link
                    to="#"
                    onMouseOver={() => {
                      setShowDrop("block");
                    }}
                  >
                    Reports
                  </Link>

                  <ul
                    className="sub-menu sub-report"
                    style={{ display: showDrop }}
                    onMouseLeave={() => {
                      setShowDrop("none");
                    }}
                  >
                    <li>
                      <NavLink to="/dashboard/sipstpswp-report">
                        MY SIP/STP/SWP
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/transaction-report">
                        My Transactions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/tax-saving-investments">
                        Tax Saving Investments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/dividends">Dividends</NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to="/dashboard/get-right-scheme">
                    Get Right Scheme
                  </NavLink>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto ">
                <div className="topbar-divider" />
                <NavDropdown
                  title={profileDropDown(user?.displayName)}
                  id="basic-nav-dropdown"
                >
                  {userDetails?.pan ? (
                    <>
                      <NavDropdown.Item
                        as={Link}
                        to="/dashboard/profile"
                        eventKey="4.1"
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaUser />
                          </span>
                          <span className="fs-14">Profile</span>
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        eventKey="4.2"
                        onClick={() => sendOTPOnEmail(false)}
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaCogs />
                          </span>
                          <span className="fs-14">Change Password</span>
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        eventKey="4.3"
                        onClick={() => sendOTPOnEmail(true)}
                        // onClick={() => setEditPinShow(true)}
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaThumbtack />
                          </span>
                          <span className="fs-14">Change Pin</span>
                        </span>
                      </NavDropdown.Item>
                    </>
                  ) : (
                    ""
                  )}
                  <NavDropdown.Item
                    as={Link}
                    eventKey="4.4"
                    onClick={() => setContactShow(true)}
                  >
                    <span className="d-flex">
                      <span className="pe-2 fs-14 text-gray-400">
                        <FaList />
                      </span>
                      <span className="fs-14">Raise a Query</span>
                    </span>
                  </NavDropdown.Item>
                  {userDetails?.pan ? (
                    <>
                      <NavDropdown.Item
                        as={Link}
                        eventKey="4.4"
                        onClick={sendMailForReview}
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaBriefcase />
                          </span>
                          <span className="fs-14">Portfolio Review</span>
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        eventKey="4.4"
                        onClick={() => openCapitalGainStatement(1)}
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaBriefcase />
                          </span>
                          <span className="fs-14">Portolio Via Email</span>
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        eventKey="4.4"
                        onClick={() => openCapitalGainStatement(2)}
                      >
                        <span className="d-flex">
                          <span className="pe-2 fs-14 text-gray-400">
                            <FaNewspaper />
                          </span>
                          <span className="fs-14">Capital Gain Statement</span>
                        </span>
                      </NavDropdown.Item>
                    </>
                  ) : (
                    ""
                  )}

                  <NavDropdown.Item
                    as={Link}
                    eventKey="4.4"
                    onClick={() => openConnectToRm()}
                  >
                    <span className="d-flex">
                      <span className="pe-2 fs-14 text-gray-400">
                        <FaPhoneAlt />
                      </span>
                      <span className="fs-14">Connect to your RM</span>
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                      as={Link}
                      eventKey="4.4"
                      onClick={() => rateToRM()}
                    >
                      <span className="d-flex">
                        <span className="pe-2 fs-14 text-gray-400">
                          <FaStar />
                        </span>
                        <span className="fs-14">Rate to your RM</span>
                      </span>
                    </NavDropdown.Item>
                  <NavDropdown.Divider />

                  <NavDropdown.Item eventKey="4.5" onClick={userLogOut}>
                    <span className="d-flex">
                      <span className="pe-2 fs-14 text-gray-400">
                        <FaSignOutAlt />
                      </span>
                      <span className="fs-14">
                        {userDetails?.pan || localStorage.getItem("token")
                          ? "Logout"
                          : "Login"}
                      </span>
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
                <div className="topbar-divider" />
                <li className="pt-3">
                  <div className="text-decoration-none d-flex notify">
                    <NavDropdown
                      className="active"
                      title={
                        <>
                          <div
                            onClick={removeDot}
                            className="position-relative"
                          >
                            <FaBell className="header-icon bell" />
                            {isNotificationRead &&
                            <span className="position-absolute top-0 start-100 translate-middle noti-dot  border border-light rounded-circle"></span>
                            }
                          </div>
                        </>
                      }
                    >
                      {notification?.length > 0 ? (
                        notification.map((el) => {
                          return (
                            <NavDropdown.Item
                              as={Link}
                              className={`border-bottom ${
                                !el.READ && "notificationColor"
                              }`}
                              eventKey="4.1"
                            >
                              <div className="row align-items-stretch ">
                                <div className="overflow-notify col-10">
                                  <h6 className="text-black fs-14">
                                    {el.NOTIFICATION_TITLE?.slice(0, 30)}
                                  </h6>
                                  <p className="fs-13  mb-0">
                                    {el.NOTIFICATION_BODY?.slice(0, 30)}

                                    <p
                                      className="text-primary mb-0"
                                      onClick={() =>
                                        handleNotificationPopup(el)
                                      }
                                    >
                                      read more.....
                                    </p>
                                  </p>
                                </div>
                                <div className="col-2 align-self-center">
                                  <Cart_Delete
                                    id={el._id}
                                    deleteData={deleteData}
                                  />
                                </div>
                                {/* <div className="align-self-center ps-3">
                             <p className="fs-13">3 days</p>
                           </div> */}
                              </div>
                            </NavDropdown.Item>
                          );
                        })
                      ) : (
                        <p className="text-center">No notification yet.</p>
                      )}
                    </NavDropdown>

                    <NavLink
                      className="header-icon ps-3 position-relative"
                      to="/dashboard/view-cart"
                    >
                      <FaShoppingCart />
                      {/* <span class="position-absolute  start-100 translate-middle badge rounded-pill shop-badge">
                        {totalCart?.length}
                      </span> */}
                    </NavLink>
                  </div>
                </li>
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {/* ============end desktop view============== */}
      {/* ============Mobile view============== */}
      <div className="d-lg-none">
        <Navbar expand="lg" sticky="top" className="custom-shadow">
          <Container
            fluid
            className="px-smc-0 justify-content-start justify-content-md-between"
          >
            <Navbar.Brand as={Link} to="/dashboard">
              <img
                src={
                  "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/bfc-logo.png"
                }
                alt="logo"
                className="img-fluid logo"
              />
            </Navbar.Brand>

            <ul className="navbar-nav  ">
              <li className="pt-2">
                <div className="text-decoration-none d-flex  align-items-center">
                  {notification.length > 0 && (
                    <NavDropdown
                      className="active notify"
                      title={
                        <>
                          <div className="position-relative">
                            <FaBell className="header-icon bell" />
                            {isNotificationRead && (
                              <span className="position-absolute top-0 start-100 translate-middle noti-dot  border border-light rounded-circle"></span>
                            )}
                          </div>
                        </>
                      }
                    >
                      {notification?.length > 0 ? (
                        notification.map((el) => {
                          return (
                            <NavDropdown.Item
                              as={Link}
                              className="border-bottom"
                              eventKey="4.1"
                              onClick={() => handleNotificationPopup(el)}
                            >
                              <div className="row align-items-stretch">
                                <div className="overflow-notify col-10">
                                  <h6 className="text-black fs-14">
                                    {el.NOTIFICATION_TITLE?.slice(0, 30)}
                                  </h6>
                                  <p className="fs-13 mb-0">
                                    {el.NOTIFICATION_BODY?.slice(0, 30)}
                                  </p>
                                </div>
                                <div className="col-2 align-self-center">
                                  <Cart_Delete
                                    id={el._id}
                                    deleteData={deleteData}
                                  />
                                </div>
                                {/* <div className="align-self-center ps-3">
                             <p className="fs-13">3 days</p>
                           </div> */}
                              </div>
                            </NavDropdown.Item>
                          );
                        })
                      ) : (
                        <p className="text-center">No notification yet.</p>
                      )}
                    </NavDropdown>
                  )}

                  <NavLink
                    className="header-icon ms-3"
                    to="/dashboard/view-cart"
                  >
                    <FaShoppingCart />
                  </NavLink>

                  <NavDropdown
                    title={profileDropDown(user?.displayName)}
                    id="basic-nav-dropdown "
                    className="ms-2 "
                  >
                    {userDetails?.pan ? (
                      <>
                        <NavDropdown.Item
                          as={Link}
                          to="/dashboard/profile"
                          eventKey="4.1"
                        >
                          <span className="d-flex">
                            <span className="pe-2 fs-14 text-gray-400">
                              <FaUser />
                            </span>
                            <span className="fs-14">Profile</span>
                          </span>
                        </NavDropdown.Item>

                        {userDetails?.pan ? (
                          <NavDropdown.Item
                            as={Link}
                            eventKey="4.2"
                            onClick={() => sendOTPOnEmail(false)}
                          >
                            <span className="d-flex">
                              <span className="pe-2 fs-14 text-gray-400">
                                <FaCogs />
                              </span>
                              <span className="fs-14">Change Password</span>
                            </span>
                          </NavDropdown.Item>
                        ) : (
                          ""
                        )}
                        <NavDropdown.Item
                          as={Link}
                          eventKey="4.3"
                          onClick={() => sendOTPOnEmail(true)}
                        >
                          <span className="d-flex">
                            <span className="pe-2 fs-14 text-gray-400">
                              <FaThumbtack />
                            </span>
                            <span className="fs-14">Change Pin</span>
                          </span>
                        </NavDropdown.Item>
                      </>
                    ) : (
                      ""
                    )}
                    <NavDropdown.Item
                      as={Link}
                      eventKey="4.4"
                      onClick={() => setContactShow(true)}
                    >
                      <span className="d-flex">
                        <span className="pe-2 fs-14 text-gray-400">
                          <FaList />
                        </span>
                        <span className="fs-14">Raise a Query</span>
                      </span>
                    </NavDropdown.Item>
                    {userDetails?.pan ? (
                      <>
                        <NavDropdown.Item
                          as={Link}
                          eventKey="4.4"
                          onClick={sendMailForReview}
                        >
                          <span className="d-flex">
                            <span className="pe-2 fs-14 text-gray-400">
                              <FaBriefcase />
                            </span>
                            <span className="fs-14">Portfolio Review</span>
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          eventKey="4.4"
                          onClick={() => openCapitalGainStatement(1)}
                        >
                          <span className="d-flex">
                            <span className="pe-2 fs-14 text-gray-400">
                              <FaBriefcase />
                            </span>
                            <span className="fs-14">Porfolio Via Email</span>
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          eventKey="4.4"
                          onClick={() => openCapitalGainStatement(2)}
                        >
                          <span className="d-flex">
                            <span className="pe-2 fs-14 text-gray-400">
                              <FaNewspaper />
                            </span>
                            <span className="fs-14">
                              Capital Gain Statement
                            </span>
                          </span>
                        </NavDropdown.Item>
                      </>
                    ) : (
                      ""
                    )}
                    <NavDropdown.Item
                      as={Link}
                      eventKey="4.4"
                      onClick={() => openConnectToRm()}
                    >
                      <span className="d-flex">
                        <span className="pe-2 fs-14 text-gray-400">
                          <FaPhoneAlt />
                        </span>
                        <span className="fs-14">Connect to your RM</span>
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      eventKey="4.4"
                      onClick={() => rateToRM()}
                    >
                      <span className="d-flex">
                        <span className="pe-2 fs-14 text-gray-400">
                          <FaStar />
                        </span>
                        <span className="fs-14">Rate to your RM</span>
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.5" onClick={userLogOut}>
                      <span className="d-flex">
                        <span className="pe-2 fs-14 text-gray-400">
                          <FaSignOutAlt />
                        </span>
                        <span className="fs-14">
                          {userDetails?.pan || localStorage.getItem("token")
                            ? "Logout"
                            : "Login"}
                        </span>
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </li>
            </ul>
          </Container>
        </Navbar>
      </div>

      {/* ============end Mobile view============== */}

      <section>
        <Right_Icons />
        <ChangePassword
          show={editPassword}
          setShow={setEditPasswordShow}
          sendOtp={sendOTPOnEmail}
          userComes={userComes}
        />
        <ChangePin
          show={editPin}
          setShow={setEditPinShow}
          sendOtp={sendOTPOnEmail}
          userComes={userComes}
        />
        <ContactUs show={contact} setShow={setContactShow} />
      </section>
      <section>
        <Notification_Popup
          show={notificationshow}
          setShow={setNotificationShow}
          data={notificationData}
        />
      </section>
      <CapitalGainStatement
        show={showGainStatementPopup}
        setShow={setShowGainStatementPopup}
        userFamilyData={userFamilyData}
      />
      <PortfolioViaEmail
        show={showPortoflioVaiEmailPopup}
        setShow={setShowPortoflioVaiEmailPopup}
        userFamilyData={userFamilyData}
      />
      {/* <PortfolioReviewPopup show={showPortoflioReviewPopup} setShow={setShowPortoflioReviewPopup}/> */}
      <ConfirmationPopUP
        show={confirmShow}
        setShow={setConfirmShow}
        successMessage={successMessage}
      />
      <Thanku
        show={thankuShow}
        setShow={setThankuShow}
        successMessage={thankuMsg}
      />
      <RatingPopUp show={ratingRM} setShow={setRatingRM}/>
    </>
  );
};
export default Header;
