import { apis, request } from "../request";


const httpGetAMC = () => {
  return request(apis.get_productBasketAmcList);  
}

const httpGetCategories = () => {
    return request(apis.get_productBasketCategories);  
}

const httpGetAssetType = () => {
   return request(apis.get_productBasketAsset);  
}

const httpGetFilteredScheme = (body,queryParams=3,pages) => {
  return request(
    `${apis.post_productBasketFilteredScheme}`,
    body,
    null,
    `?page=${pages}&returns=${queryParams}`
  );
}

export {
  httpGetAMC,
  httpGetCategories,
  httpGetAssetType,
  httpGetFilteredScheme,
};