export const CheckPathRediction = () => {
    const userdata = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token")
    if (userdata?.pan && userdata?.iin ) {
        return { pan: true, iin: true ,token:true}
    } else if (userdata?.pan && !userdata?.iin) {
        return { pan: true, iin: false ,token:true}
    } else if(!userdata?.pan && !userdata?.iin && !token){
        return { pan: false, iin: false,token:false }
    }else{
        return { pan: false, iin: false ,token:true}
    }
}

export const createInvRedirection = (kyc)=>{
    if(!kyc?.taxStatus){
        return true
    }else{
        return false
    }
}