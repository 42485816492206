import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
const ExistingMandate = ({ show, setShow, filteredMandate, getLink }) => {
  return (
    <>
      <Modal
        show={show}
        size={"lg"}
        onHide={() => setShow(false)}
        className="animate__animated animate__zoomIn animate__fast"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {filteredMandate?.trigger ? "Pending Mandate" : "Existing Mandate"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr className="text-center text-red">
                <th>UMRN No.</th>
                <th>Amount</th>
                <th>From</th>
                <th>To</th>
                {filteredMandate?.trigger && <th>Re Trigger</th>}
              </tr>
            </thead>
            <tbody>
              {filteredMandate.length > 0 ? (
                filteredMandate.map((data) => {
                  return (
                    <tr className="text-center">
                      <td>{data.UMRN_NO}</td>
                      <td>{data.AMOUNT}</td>
                      <td>{data.FROM_DATE}</td>
                      <td>{data.TO_DATE}</td>
                      {filteredMandate?.trigger && (
                        <td>
                          <button
                            onClick={() => getLink(data.UNIQUE_REF_NO)}
                            className="btn btn-danger btn-sm shadow-sm text-decoration-none"
                          >
                            click here
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center"><span className="pl-2 text-danger ">
                  No Mandate available
                </span></td>
                </tr>
                
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ExistingMandate;
