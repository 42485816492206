import React, { useState } from "react";
import { Link } from 'react-router-dom';
import riskp from "../assets/images/others/risk-profile-vector.png";
const KnowYourRiskProfile = () => {
  const [myInvestment, setMyInvestment] = useState(1)
  const [validityMyPortfolio, setValidityMyPortfolio] = useState(1)
  const [sourceOfIncome, setSourceOfIncome] = useState(1)
  const [provistionFor, setProvistionFor] = useState(1)
  const [graphValue, setGraphValue] = useState("https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/Riskometer-2.png")

  const checkRisk = (e) => {
    e.preventDefault()
    if(myInvestment==1 && validityMyPortfolio==1 && sourceOfIncome==1 && provistionFor==1){
      setGraphValue("https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/Riskometer-2.png")
    }else if(myInvestment==3 && validityMyPortfolio==3 && sourceOfIncome==3 && provistionFor==3){
      setGraphValue("https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/Riskometer-1.png")
    }else if(myInvestment==2 || validityMyPortfolio==2 || sourceOfIncome==2 || provistionFor==2){
      setGraphValue("https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/Riskometer-4.png")
    }else{
      setGraphValue("https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/Riskometer-4.png")
    } 
  }

  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-smc-1">

          {/* Page Heading */}
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb-nav d-flex ">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Know Your Risk Profile</li>
            </ol>
          </nav>
          <section>
            <div className='px-lg-5'>
              <div className="row p-lg-5 p-sm-3 bg-c bg-light-red py-3">
                <div className='col-md-6'>
                  <form className="text-start" onSubmit={checkRisk}>
                    <div className=" risk-shadow mb-3 fs-sm-10">
                      <p className="mb-2 fw-bold">1. I seek above average returns from my investments</p>
                      <div className="d-flex justify-content-between">
                        <label>
                          <input type="radio" className="" name="optradio1" checked={myInvestment==1} value={myInvestment} required onChange={(e) => {

                            setMyInvestment(1)
                          }} /> Agree
                        </label>
                        <label>
                          <input type="radio" className="" name="optradio1"  value={myInvestment} required onChange={(e) => 
                            setMyInvestment(2)
                          } /> Somewhat Agree
                        </label>
                        <label>
                          <input type="radio" className="" name="optradio1"  value={myInvestment} required onChange={(e) => {
                            setMyInvestment(3)
                            
                          }} /> Disagree
                        </label>
                      </div>

                    </div>
                    <div className="col-12 mb-4 risk-shadow fs-sm-10">
                      <p className="mb-2 fw-bold " >2. I am patient with my investments and can bear short term volatility in my portfolio</p>
                      <div className="d-flex justify-content-between">
                        <label>
                          <input type="radio" className=""required name="optradio2" checked={validityMyPortfolio==1} value={validityMyPortfolio}  onChange={(e) => {
                            setValidityMyPortfolio(1)
                          }} /> Agree
                        </label>
                        <label>
                          <input type="radio" className=""required name="optradio2" value={validityMyPortfolio}  onChange={(e) => {
                            setValidityMyPortfolio(2)
                          
                          }} /> Somewhat Agree
                        </label>
                        <label>
                          <input type="radio" className=""required name="optradio2" value={validityMyPortfolio}  onChange={(e) => {
                            setValidityMyPortfolio(3)
                          }} /> Disagree
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-4 risk-shadow fs-sm-10">
                      <p className="mb-2 fw-bold ">3. I have regular and stable source of income</p>
                      <div className="d-flex justify-content-between">
                        <label>
                          <input type="radio" className="" name="optradio3" checked={sourceOfIncome==1} value={sourceOfIncome} required onChange={(e) => {
                            setSourceOfIncome(1)
                          }} /> Agree
                        </label>
                        <label>
                          <input type="radio" className="" name="optradio3" value={sourceOfIncome} required onChange={(e) => {
                            setSourceOfIncome(2)
                           
                          }} /> Somewhat Agree
                        </label>
                        <label>
                          <input type="radio" className="" name="optradio3" value={sourceOfIncome} required onChange={(e) => {
                            setSourceOfIncome(3)
                          }} /> Disagree
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-4 risk-shadow fs-sm-10">
                      <p className="mb-2 fw-bold " >4. My outstanding debt/loan is low or that has been provisioned for</p>
                      <div className="d-flex justify-content-between">
                        <label>
                          <input type="radio" className="" name="optradio4" checked={provistionFor==1} value={provistionFor} required onChange={(e) => {
                            setProvistionFor(1)
                          }} /> Agree
                        </label>
                        <label>
                          <input type="radio" required className="" name="optradio4" value={provistionFor}  onChange={(e) => {
                            setProvistionFor(2)
                          }} /> Somewhat Agree
                        </label>
                        <label>
                          <input type="radio" className="" name="optradio4" value={provistionFor} required  onChange={(e) => {
                            setProvistionFor(3)
                          }} /> Disagree
                        </label>
                      </div>
                    </div>
                    <div className="text-lg-end text-center pb-5">
                      <button type="submit" className="btn-custom col-3">Submit</button>
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  <div className='col-md-12 text-center'>
                    <img src={riskp} alt="" className='img-fluid mb-5' />
                  </div>

                  <div className="col-md-12 text-center" >
                    <img src={graphValue} alt="" className="img-fluid riskpic-img"/>
                    {/* <div className='speedo' style={{
                      width: "100%",
                      height: "220px",
                    }}>
                      <ReactSpeedometer
                        maxValue={160}
                        value={graphValue}
                        currentValueText="Risk Profile"
                        customSegmentLabels={[
                          {
                            text: "CONSERVATIVE",
                            position: "INSIDE",
                            color: "#fff",
                          },
                          {
                            text: "MODERATE",
                            position: "INSIDE",
                            color: "#fff",
                          },
                          {
                            text: "AGGRESSIVE",
                            position: "INSIDE",
                            color: "#fff",
                          },
                        ]}
                        segmentColors={[
                          "#32bb29",
                          "#ffda4d",
                          "#fd0034",
                        ]}
                        segments={3}
                        endColor="blue"
                        width={400}
                        height={400}
                        needleTransitionDuration={2000}
                      />
                    </div> */}
                    <h5 className='text-center py-4  fs-sm-13'>Your Risk Profile : Moderate</h5>
                  </div>



                </div>
              </div>
              <div className="row">
                <div className="col-6 mt-4 mb-3 text-start">
                  <Link className="btn-custom shadow-sm" to="/dashboard/get-right-scheme" >
                    Back</Link>
                </div>
                {/* <div className="col-6 mt-4 mb-3 text-end">
                  <Link className="btn-custom" to="javascript:void(0);">Continue</Link>
                </div> */}
              </div>
            </div>

          </section>
        </div>
      </div>
    </>
  )
}
export default KnowYourRiskProfile