import React, { useEffect, useState, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import RedemptionConfirm from "../components/redemption-confirm-pr";
import SwitchConfirm from "../components/switch-confirm-pr";
import UnderwatchConfirm from "../components/underwatch-pr";
import SatisfactoryperformanceConfirm from "../components/satisfactory-performance-pr";
import {  recommendationRedemptionTransact, recommendationSatisfactory, recommendationSwitch, recommendationUnderwatch, userProfile } from "../apisMethods";
import { notify } from "./toastCreater";
import { globalInfo } from "../App";
const PortfolioReview = () => {
  const { setLoader } = useContext(globalInfo);

  const [switchshow, setSwitchShow] = useState(false);
  const [redemptionshow, setRedemptionShow] = useState(false);
  const [Underwatchshow, setUnderwatchShow] = useState(false);
  const [Satisfactoryshow, setSatisfactoryShow] = useState(false);
  const [loggedInData, setLoggedInData] = useState({});
  const [redumptionData, setRedumptionData] = useState([]);
  const [satisfactoryPerformanceData, setSatisfactoryPerformanceData] = useState([]);
  const [underWatchDataData, setUnderWatchDataData] = useState([]);
  const [headers, setHeaders] = useState({});
  const [familyData, setFamilyData] = useState()
  const [singleFamilyData, setSingleFamilyData] = useState({})
  const [switchData, setSwitchData] = useState([])
  const [chartData, setChartData] = useState({
    // labels: ["Equity", "Debt", "Gold"],
    datasets: [
      {
        data: [25, 25, 25,25 ],
        backgroundColor: ["#97C5FB", "#F06D70", "#FBDE80", "#8BC24A"],
      },
    ],

  })

  useEffect(() => {
    // randomValues()
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    setHeaders(header)
    const userdata = JSON.parse(localStorage.getItem("user"));
    setLoggedInData(userdata)
    getFamilyData(userdata, header)
  }, [])

  const getFamilyData = (userData, header) => {
    setLoader("block")
    userProfile({ pan: userData?.pan }, header).then((res) => {
      if (!res.data) {
        setFamilyData([])
      } else {
        setFamilyData(res.data?.data)
      }
      setLoader("none")
    })
  }

  // const randomValues = () => {
  //   let values = [];
  //   let remainingTotal = 100;
  //   for (let i = 0; i <= 3; i++) {
  //     let random = Math.random() * remainingTotal;
  //     let intValue = Math.round(random); // Round to the nearest integer
  //     values.push(intValue);
  //     remainingTotal -= intValue;
  //   }
  //   values.push(remainingTotal);

  //   return {
  //     datasets: [
  //       {
  //         data: [values[0], values[1], values[2], values[3]],
  //         backgroundColor: ["#97C5FB", "#F06D70", "#FBDE80", "#8BC24A"],
  //       },
  //     ],
  //   };
  // }

  const redumpstionTransact = (pan, name, gpan, singleData) => {
    let reqBody = {
      pan: pan ? pan : "",
      name,
      gpan: gpan ? gpan : ""
    }
    setLoader("block")
    recommendationRedemptionTransact(reqBody, headers).then((res) => {
      if (!res.data || res.data?.data?.length == 0) {
        notify("error", "You have no schemes for Redumption..!")
        // setSwitchData()
      } else {
        setRedumptionData(res.data?.data)
        setSingleFamilyData(singleData)
        setRedemptionShow(true)
        // setRedumptionKey()
      }
      setLoader("none")
    })
  }

  const satisfactoryPerformance = (pan, name, gpan) => {
    let reqBody = {
      pan: pan ? pan : "",
      name,
      gpan: gpan ? gpan : ""
    }
    recommendationSatisfactory(reqBody, headers).then((res) => {
      if (!res.data || res.data?.data?.length == 0) {
        notify("error", "You have no schemes for Satisfactory performance ..!")
      } else {
        setSatisfactoryShow(true)
        setSatisfactoryPerformanceData(res.data?.data)
      }
    })
  }

  const underWatch = (pan, name, gpan) => {
    let reqBody = {
      pan: pan ? pan : "",
      name,
      gpan: gpan ? gpan : ""
    }
    recommendationUnderwatch(reqBody, headers).then((res) => {
      if (!res.data || res.data?.data?.length == 0) {
        notify("error", "You have no schemes for Underwatch ..!")
      } else {
        setUnderwatchShow(true)
        setUnderWatchDataData(res.data.data)
      }
    })
  }
  const switchPopUp = (pan, name, gpan, singleData) => {
    let reqBody = {
      pan: pan ? pan : "",
      name,
      gpan: gpan ? gpan : ""
    }
    setLoader("block")
    recommendationSwitch(reqBody, headers).then((res) => {
      if (!res.data || res.data?.data?.length == 0) {
        notify("error", "You have no schemes for Switch..!")
      } else {
        setSwitchData(res.data?.data)
        setSingleFamilyData(singleData)
        setSwitchShow(true)
      }
      setLoader("none")
    })
  }
  return (

    <>
      <div className="wrapper portfolio-review">
        <div className="px-lg-5 px-2 pe-5">

          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="home">Home</Link></li>
              <li className="breadcrumb-item active">Portfolio Review</li>
            </ol>
          </nav>

          <section className="mb-5">

            <div className="container p-smc-0">
              {familyData?.map((item) => {
                return <div className="row justify-content-center">
                  <div className="col-md-6  box py-3 rounded">
                    <div className="row justify-content-sm-center">

                      <div className="col-md-5 col-8 text-center">
                        <h6 className="text-red">{`${item?.NAME} ${item?.JOINT_NAME1 && "|"} ${
                                item?.JOINT_NAME1
                              }`}</h6>
                        <Doughnut data={chartData} />
                      </div>

                      <div className="col-md-6 col-10  align-self-center mt-5 ms-lg-3 ms-3">
                        <div className="mb-3">
                          <div onClick={() => redumpstionTransact(item.PAN, item.NAME, item.GUARD_PAN, item)} className="text-black crPointer" >  <span className="redemption circle"></span>Redemption <span> <FaChevronRight className="text-red" /> </span></div>
                        </div>
                        <div className="mb-3">
                          <div onClick={() => underWatch(item.PAN, item.NAME, item.GUARD_PAN)} className="text-black crPointer">  <span className="underwatch circle"></span>Underwatch <span> <FaChevronRight className="text-red" /> </span></div>
                        </div>
                        <div className="mb-3">
                          <div onClick={() => switchPopUp(item.PAN, item.NAME, item.GUARD_PAN, item)} className="text-black crPointer">  <span className="switch circle"></span>Switch <span> <FaChevronRight className="text-red" /> </span></div>
                        </div>
                        <div className="mb-3">
                          <div onClick={() => satisfactoryPerformance(item.PAN, item.NAME, item.GUARD_PAN)} className="text-black crPointer">  <span className="satisfactory circle"></span>Satisfactory performance <span> <FaChevronRight className="text-red" /> </span></div>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>

              })}


              {/* 2 */}
              {/* <div className="row justify-content-center mt-4">
                <div className="col-md-6 box py-3 rounded">
                  <div className="row">

                    <div className="col-md-5 text-center">
                      <h6 className="text-red">Shivam Shrivastav</h6>
                      <Doughnut data={chartData} />
                    </div>

                    <div className="col-md-6  align-self-center mt-5 ms-lg-3 ms-3">
                      <div className="mb-1">
                        <a className="text-black" href="javascript:void(0);" onClick={() => setRedemptionShow(true)}>  <span className="redemption circle"></span>Redemption <span> <FaChevronRight className="text-red" /> </span></a>
                      </div>
                      <div className="mb-1">
                        <a href="javascript:void(0);" onClick={() => setUnderwatchShow(true)} className="text-black">  <span className="underwatch circle"></span>Underwatch <span> <FaChevronRight className="text-red" /> </span></a>
                      </div>
                      <div className="mb-1">
                        <a href="javascript:void(0);" onClick={() => setSwitchShow(true)} className="text-black">  <span className="switch circle"></span>Switch <span> <FaChevronRight className="text-red" /> </span></a>
                      </div>
                      <div className="mb-1">
                        <a href="javascript:void(0);" onClick={() => setSatisfactoryShow(true)} className="text-black">  <span className="satisfactory circle"></span>Satisfactory performance <span> <FaChevronRight className="text-red" /> </span></a>
                      </div>


                    </div>

                  </div>
                </div>
              </div> */}

            </div>
          </section>
        </div>
      </div>
      <RedemptionConfirm show={redemptionshow} setShow={setRedemptionShow} redumptionData={redumptionData} singleFamilyData={singleFamilyData} />
      <SwitchConfirm show={switchshow} setShow={setSwitchShow} switchData={switchData} singleFamilyData={singleFamilyData} />
      <UnderwatchConfirm show={Underwatchshow} setShow={setUnderwatchShow} underWatchDataData={underWatchDataData} />
      <SatisfactoryperformanceConfirm show={Satisfactoryshow} setShow={setSatisfactoryShow} satisfactoryPerformanceData={satisfactoryPerformanceData} />

    </>
  )
}
export default PortfolioReview