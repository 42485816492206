import axios from "axios";
import { baseUrl } from "./urls";
import { apis } from "./urls";
import { requestBodyTrimmer } from "./request-body-trimmer";

function findKeyByValue(value) {
  const key = Object.keys(apis).find((key) => apis[key] === value);
  return key.split("_")[0];
}

export const request = async (api, body = null, headers = null,queryParams=null) => {
  const method = findKeyByValue(api);
  
  if (queryParams) {
    api = api+queryParams
  }

  if (body != null) {
    body = requestBodyTrimmer(body);
  }
  try {
    if (method === "get") {
      const res = await axios[method](
        baseUrl + api,
        headers !== null ? headers : ""
      );
      return { data: res.data, error: null };
    } else {
      const res = await axios[method](
        baseUrl + api,
        body,
        headers !== null ? headers : ""
      );
      return { data: res.data, error: null };
    }
  } catch (err) {
    return { data: null, error: err };
  }
};
