import React, { useState } from "react";
import CalculatorHeader from "../components/calculator-header";
import { Doughnut } from "react-chartjs-2";
import { baseUrl } from "../request";
import Loader from "./Loader";
import axios from "axios";
import { notify } from "./toastCreater";
import { Link } from "react-router-dom";
import ContactUsForCalculator from "../components/contact-us-for-calcuclator";
const EmiCalculator = () => {
  const [requestShow, setRequestShow] = useState(false);
  const [messages, setMessages] = useState("Query regarding Home Loan");
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(10);
  const [tenure, setTenure] = useState(10);
  const [totalAmount, setTotalAmount] = useState("15,85,800");
  const [totalIntrest, setTotalIntrest] = useState("5,85,800");
  const [loan, setLoan] = useState("10,00,000");
  const [emi, setEmi] = useState("13,215");
  const [chartTotalIntrest, setChartTotalIntrest] = useState(585800);
  const [chatPrincipleAmount, setChartPrincipleAmount] = useState(1000000);
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);

  const data1 = {
    datasets: [
      {
        data: [chartTotalIntrest, chatPrincipleAmount],
        backgroundColor: ["#F06D70", "#97C5FB"],
      },
    ],
    labels: ["Total Interest", "Principle Amount"],
  };

  
  const loanEMiCalculater = () => {

      const data = {
        loanAmount: loanAmount,
        interestRate: interestRate,
        tenure: tenure,
      };
      if(loanAmount < 500){
        notify("warn", "Amount should not be less than 500")
        return
      }
      if(interestRate < 1){
        notify("warn", "Enter Interest rate should not be less than 1")
        return
      }
      if(tenure < 1){
        notify("warn", "Tenure should not be less than 1")
        return
      }
      setLoader("block");

      axios.post(baseUrl + "/calculators/emi", data).then((res) => {
        setEmi(res.data.monthlyEmiAmount);
        setLoan(res.data.principalAmount);
        setTotalIntrest(res.data.totalInterest);
        setTotalAmount(res.data.totalAmount);

        window.scrollTo(500, 500);
        setLoader("none");
        let s = res.data.totalInterest.replace(/,/g, "");
        s = parseInt(s);
        let g = res.data.principalAmount.replace(/,/g, "");
        g = parseInt(g);
        setChartTotalIntrest(s);
        setCalculateCheck(1)
        setChartPrincipleAmount(g);
      });
  
   
  };

  let incLoanAmount = () => {
    if (loanAmount < 50000000) {
      setLoanAmount(Number(loanAmount) + 500);
    } else if (loanAmount == 0) {
      setLoanAmount(Number(loanAmount) + 500);
    }
  };
  let decLoanAmount = () => {
    if (loanAmount >= 500) {
      setLoanAmount(loanAmount - 500);
    } else if (loanAmount < 499) {
      setLoanAmount(0);
    }
  };

  let incInterestRate = () => {
    if(interestRate){
    if (isNaN(interestRate)) {
      return setInterestRate(0);
    }
    if (interestRate < 50) {
      setInterestRate((parseFloat(interestRate) + 0.05).toFixed(2));
    }}else{
      setInterestRate(0.05);
    }
  };
  let decInterestRate = () => {
    if (interestRate > 1) {
      setInterestRate((parseFloat(interestRate) - 0.05).toFixed(2));
    }
  };

  let incTenure = () => {
    if (tenure < 50) {
      setTenure(Number(tenure) + 0.5);
    }
  };
  let decTenure = () => {
    if (tenure > 1) {
      setTenure(tenure - 0.5);
    }
  };

//   const requestCallBack = ()=>{
//     let token = localStorage.getItem("token");
//     const header = { headers: { Authorization: `Bearer ${token}` } };
//     let user = JSON.parse(localStorage.getItem("user"));
//     // user={}
// if(Object.keys(user).length === 0){

//     contactUs({mobile:user.mobile,email:user.mobile,query:"Calculator Query",message:"From Request a call back"}).then(res=>{
//       if(!res.data){
//         // notify("error", "Your form is not submitted, Please try again")
//       }else{
//         setSuccessMessage("We have received your query, we will get back to you shortly.")
//         setConfirmShow(true)

//       }
//     })
//   }}
  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Calculators
              </li>
            </ol>
          </nav>
          <Loader loader={loader} />

          <div className="container-fluid  px-lg-5 pb-lg-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-14"
                                    >
                                      Enter loan amount
                                      <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"   onClick={decLoanAmount}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={loanAmount}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setLoanAmount(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setLoanAmount(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 50000000) {
                                            setLoanAmount(50000000);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"  onClick={incLoanAmount}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-14"
                                    >
                                      Enter Interest rate (%)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27" onClick={decInterestRate}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={interestRate}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setInterestRate(value);
                                            
                                          } else if(e.target.value>50){
                                            setInterestRate(50);
                                          }else if(e.target.value=="."){
                                            setInterestRate(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"onClick={incInterestRate}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-14"
                                    >
                                      Tenure (years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27" onClick={decTenure}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={tenure}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setTenure(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setTenure(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 50) {
                                            setTenure(50);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27" onClick={incTenure}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 pt-2 text-lg-end text-center">
                                  <button className="btn-custom mt-3" onClick={loanEMiCalculater}>
                                    
                                  {calculateCheck==0? "Calculate" : "Re-Calculate"}
                                  </button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-lg-3 mx-3">
                                <div className="col-md-4">
                                  <div className="result-content result-content-shadow">
                                    <ul className="text-center ps-0 lst-none">
                                      <li>
                                        <div className="text-label fw-500 py-2">
                                          EMI
                                          <span className="text-roboto">
                                            (₹)
                                          </span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">
                                        {emi}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="text-label fw-500 py-2">
                                          Principal
                                          <span className="text-roboto">
                                            (₹)
                                          </span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">
                                        {loan}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="text-label fw-500 py-2">
                                          Total Interest
                                          <span className="text-roboto">
                                            (₹)
                                          </span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">
                                        {totalIntrest}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="text-label fw-500 py-2">
                                          Total Amount
                                          <span className="text-roboto">
                                            (₹)
                                          </span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">
                                        {totalAmount}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-8  py-lg-5 pt-3">
                                  <div className="result-title text-center">
                                    <h2>Result</h2>
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <div className="pt-4 future">
                                      <Doughnut data={data1} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row px-lg-5 pt-3">
                                <div className="col-md-12 text-lg-end text-center">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn-custom "
                                    onClick={()=>setRequestShow(true)}
                                    type="button"
                                  >
                                    Contact Us
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="request-a-call-back">
                        <ContactUsForCalculator
                          show={requestShow}
                          setShow={setRequestShow}
                          messages={messages}
                        />
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmiCalculator;
