import React from "react";
import { Link,useNavigate } from "react-router-dom";
import { FaUsers, FaBook, FaShoppingBag, FaBriefcase, FaUniversity, FaUserPlus,FaNewspaper } from "react-icons/fa";

const Right_Icons = () =>{

 

    return (
      <>
        <div className="right-icon-s">
          <div class="ng-star-inserted">
            <div class="right-icon">
              <div class="ng-star-inserted">
                <Link to='/dashboard/portfolio'>
                  <ul class="ul-right ng-star-inserted" style={{ marginTop: "-5em" }}>
                    <li class="li1 rightmenu">

                      <FaBriefcase className='fa-2x  img-i' />
                      <div class="over-text1">Portfolio</div>

                    </li>
                  </ul>
                </Link>
                <Link to="/dashboard/transact" >
                  <ul class="ul-right ng-star-inserted"  style={{ marginTop: "-1.6em " }}>
                    <li class="li1 rightmenu">

                      <FaBook className='fa-2x  img-i' />
                      <div class="over-text1">Transact</div>

                    </li>
                  </ul>
                </Link>
                <Link to="/dashboard/Profile-creation">
                  <ul class="ul-right bank ng-star-inserted" style={{ marginTop: "1.8em" }}>
                    <li class="li1 rightmenu">

                      <FaUserPlus className='fa-2x  img-i' />
                      <div class="over-text3">Create Inv Account</div>

                    </li>
                  </ul>
                </Link>
                <Link to='/dashboard/add-family-member'>
                  <ul class="ul-right bank ng-star-inserted" style={{ marginTop: "5.2em" }}>
                    <li class="li1 rightmenu">

                      <FaUsers className='fa-2x  img-i' />
                      <div class="over-text2">Add Family Member</div>

                    </li>
                  </ul>
                </Link>
                <Link to='/dashboard/bank-and-mandate'>
                  <ul class="ul-right bank ng-star-inserted" style={{ marginTop: "8.6em" }}>
                    <li class="li1 rightmenu">

                      <FaUniversity className='fa-2x  img-i' />
                      <div class="over-text2">Bank Details & Mandate</div>

                    </li>
                  </ul>
                </Link>
                <Link to='/dashboard/my-orders'>
                  <ul class="ul-right ng-star-inserted" style={{ marginTop: "12em" }}>
                    <li class="li1 rightmenu">

                      <FaShoppingBag className='fa-2x  img-i' />
                      <div class="over-text1">Orders</div>
                      
                    </li>
                  </ul>
                </Link>
               
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
  export default Right_Icons