import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import "react-tabs/style/react-tabs.css";
import Filter_schemes from "../components/filter";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { httpGetFilteredScheme } from "../apisMethods";
import { globalInfo } from "../App"
import LumpsumForm from "../components/lumpsum-form";
import { Tab, TabList, Tabs } from "react-tabs";
import { apis } from "../request";
import SIP_Form from "../components/sip-form";
import { useCount } from "./helpers/CartCountProvide";
const SchemePerformance = ({ assetType }) => {
  const {addToCart} = useCount();
  const navigate = useNavigate()

  const [filterObject, setFilterObject] = useState(null)
  const { setLoader } = useContext(globalInfo)
  const [rightSchemes, setRightSchemes] = useState([])
  const [show, setShow] = useState(false)
  const { state } = useLocation();
  const index = state ? parseInt(state.type) : assetType;
  const [tabIndex, setTabIndex] = useState(index - 1);
  const [queryParams, setQueryParams] = useState(3)
  const [pages, setPages] = useState(1)
  const [sipformShow, setSipformShow] = useState(false)

  const getColumns = (params = 1) => {
    return [
      {
        label: " ",
        field: "img",
        sort: "disabled",
      },
      {
        label: "Scheme Name",
        field: "name",
        sort: "disabled",
      },
      {
        label: (
          <span
            className={params == 1 ? "activeScheme" : ""}
            onClick={() => paramsHandler(1)}
          >
            1Y
          </span>
        ),
        field: "one",
      },
      {
        label: (
          <span
            className={params == 3 ? "activeScheme" : ""}
            onClick={() => paramsHandler(3)}
          >
            3Y
          </span>
        ),
        field: "three",
      },
      {
        label: (
          <span
            className={params == 5 ? "activeScheme" : ""}
            onClick={() => paramsHandler(5)}
          >
            5Y
          </span>
        ),
        field: "five",
      },
      {
        label: (
          <span
            className={params == 7 ? "activeScheme" : ""}
            onClick={() => paramsHandler(7)}
          >
            7Y
          </span>
        ),
        field: "seven",
      },
      {
        label: "",
        field: "action",
        sort: "disabled",
      },
      ,
      {
        label: "",
        field: "action2",
        sort: "disabled",
      },
      {
        label: "",
        field: "action3",
        sort: "disabled",
      },
    ];
  }

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: " ",
        field: "img",
        sort: "disabled",
      },
      {
        label: "Scheme Name",
        field: "name",
        sort: "disabled",
      },
      {
        label: (
          <span
            className={queryParams == 1 ? "activeScheme" : ""}
            onClick={() => paramsHandler(1)}
          >
            1Y
          </span>
        ),
        field: "one",
      },
      {
        label: (
          <span
            className={queryParams == 3 ? "activeScheme" : ""}
            onClick={() => paramsHandler(3)}
          >
            3Y
          </span>
        ),
        field: "three",
      },
      {
        label: (
          <span
            className={queryParams == 5 ? "activeScheme" : ""}
            onClick={() => paramsHandler(5)}
          >
            5Y
          </span>
        ),
        field: "five",
      },
      {
        label: (
          <span
            className={queryParams == 7 ? "activeScheme" : ""}
            onClick={() => paramsHandler(7)}
          >
            7Y
          </span>
        ),
        field: "seven",
      },
      {
        label: "",
        field: "action",
        sort: "disabled",
      },
      {
        label: "",
        field: "action2",
        sort: "disabled",
      },
      {
        label: "",
        field: "action3",
        sort: "disabled",
      },
      
    ],
    rows: [],
  });


  const paramsHandler = (params) => {
    console.log("uu",params);
    setDatatable({ ...datatable, columns: getColumns(params) });
    setQueryParams(params)
  }


  const findFileteredScheme = (reqBody) => {
    setLoader("block");
    httpGetFilteredScheme(reqBody, queryParams, pages).then((res) => {
      if (!res.data) {
        datatable.rows = [];
        setDatatable({ ...datatable });
        setLoader("none");
      } else {
        const contentArray = createRow(res.data.data);
        res.data.data.length = 10
        datatable.rows = [...contentArray];
        setDatatable({ ...datatable });
        setLoader("none");
      }
    });
  }


  useEffect(() => {
    hidePageCount()
    if (filterObject && (filterObject.amc_code.length > 0 || filterObject.asset_code.length >0|| filterObject.classcode.length>0) ) {
      findFileteredScheme(filterObject);
    } else {
      findFileteredScheme({
        amc_code: [],
        asset_code: [state ? parseInt(state.type) : assetType],
        classcode: []
      });
    }
  }, [filterObject, queryParams, pages]);

  const openModel = (el) => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      navigate("/");
      return;
    }

    if (user?.pan && user?.iin) {
      setRightSchemes([el]);
      setShow(true)
    } else {
      navigate("/dashboard/Profile-creation")
    }

  }

  const openModelForSip = (el) => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      navigate("/");
      return;
    }

    if (user?.pan && user?.iin) {
      setRightSchemes([el]);
      setSipformShow(true)
    } else {
      navigate("/dashboard/Profile-creation")
    }
  }
  const showGraph = (item,year)=>{
    console.log("=========",item);
    item["annualizedYear"] =year
        navigate("/dashboard/nav-single",{state:item})
  }  
  
  

  const createRow = (array) => {
    return array.map(el => {
      return {
        img: (
          <img
          alt="img"
            src={
              // `${apis.imageLink}growth.png`
              `${apis.imageLink}${el?.AMC_CODE}.png`
            }
            className="img-fluid min-w-3em mx-wd-45"
          />
        ),
        name: <div className="min-w-30 onHover" onClick={()=>showGraph(el,queryParams)}>{el.s_name}</div>,
        one: (
          <div className="min-w-8em">
            {el.oneyrret ? el.oneyrret.toFixed(2) + "%" : "NA"}
          </div>
        ),
        three: (
          <div className="min-w-8em">
            {el.threeyearret
              ? el.threeyearret.toFixed(2) + "%"
              : "NA"}
          </div>
        ),
        five: (
          <div className="min-w-8em">
            {el.fiveyearret ? el.fiveyearret.toFixed(2) + "%" : "NA"}
          </div>
        ),
        seven: (
          <div className="min-w-8em">
            {el.sevenyearret
              ? el.sevenyearret.toFixed(2) + "%"
              : "NA"}
          </div>
        ),
        action: (
          <div className="min-w-15" onClick={() => openModel(el)}>
            <button
              className="btn-outline "
              data-target="#invest-sip"
              data-toggle="modal"
              type="button"
            >
              LUMPSUM
            </button>
          </div>
          
      
        ), action2: (
          
           <div className="min-w-15" onClick={() => openModelForSip(el)}>
           <button
             className="btn-outline "
             data-target="#invest-sip"
             data-toggle="modal"
             type="button"
           >
             SIP
           </button>
         </div>
      
        ), action3: (
          
          <div className="min-w-15" onClick={() => addToCart(el)}>
          <button
            className="btn-outline "
            data-target="#invest-sip"
            data-toggle="modal"
            type="button"
          >
            Add To Cart
          </button>
        </div>
     
       )
      };
    })
  }


  const assetTypeChangeHandler = (index) => {
    setTabIndex(index);
    setFilterObject({
      amc_code: [],
      asset_code: [parseInt(index + 1)],
      classcode: [],
    });
  }

  const hidePageCount = () => {
    const element = document.getElementsByClassName("mdb-datatable-info d-flex align-items-center")
    const childElement = element[0].children
    childElement[0].style.display = "none";
  }

  return (
    <>
      <div>
        <div className="px-5">
          {/* Page Heading */}
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Schemes Performance</li>
            </ol>
          </nav> */}

          <Tabs
            style={{ textAlign: "center" }}
            selectedIndex={tabIndex}
            onSelect={assetTypeChangeHandler}
          >
            <div className="col-md-12  offset-lg-1 col-lg-10 filter-all-mf mb-5">
              <TabList className="border-0 p-0">
                <Tab>Equity</Tab>

                <Tab>Hybrid</Tab>

                <Tab>Debt</Tab>
                <Tab>Commodity</Tab>
                <Tab>Other</Tab>
              </TabList>
            </div>
          </Tabs>

          <section className="main-btn-f px-3">
            <div className="row ">
              <div className="col-md-4 pt-4 mb-5">
                <Filter_schemes
                  setFilterObject={setFilterObject}
                  setPages={setPages}
                  setTabIndex={setTabIndex}
                />
              </div>

              <div className="col-md-12 mdb-table-main text-center mt-n4  table-responsive ">
                <MDBDataTableV5
                  hover
                  entriesOptions={[20, 30, 45]}
                  entries={10}
                  data={datatable}
                  pagingTop
                  searchTop={false}
                  searchBottom={false}
                  barReverse
                  sortable={false}
                  onPageChange={(obj) => {

                    if (obj.activePage == 6 && obj.pagesAmount == 6) {
                      setPages(pages + 1);
                    } else if (obj.activePage == 1 && pages > 1) {
                      setPages(pages - 1);
                    }
                  }}
                />
              </div>
            </div>
          </section>
        </div>
        <LumpsumForm
          show={show}
          setShow={setShow}
          rightSchemes={rightSchemes}
        />
              <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={rightSchemes} />
      </div>
    </>
  );
};
export default SchemePerformance;
