import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Cart_Delete from "./delete-confirmation";
import Form from "react-bootstrap/Form";
import TransactSuccess from "./transact-success";
import SWW from "./something-went-wrong";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { multiplePurchase, getSchemeList, newPurchase, saveNseTransaction, usersBank, getMandateList, getElssScheme, updateNseMultiUserBankdetails } from "../apisMethods";
import { notify } from "../pages/toastCreater";
import { globalInfo } from "../App";
import { disableFutureDate } from "../currentMonth/FinancialYear";
import ConfirmationProfileChangePopup from "./confirmationProfileChangePopup";
import ConfirmationMandatePopup from "./mandateConfirmationPopup";

const NewPurchaseTransact = ({ show, setShow, amcList, userProfileData, purchase,isLookingForAnother=false,amountForLumpsum }) => {
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [assetClass, setAssetClass] = useState("1");
  const [option, setOption] = useState("1");
  const [amc, setAmc] = useState('');
  const [tempAmc, setTempAmc] = useState({});
  const [schemeList, setSchemeList] = useState([]);
  const [scheme, setScheme] = useState('');
  const [tempScheme, setTempScheme] = useState({});
  const [profileName, setProfileName] = useState('');
  const [tempProfileName, setTempProfileName] = useState({});
  const [headers, setHeaders] = useState('');
  const [amount, setAmount] = useState();
  const [newPurchaseDetails, setNewPurchaseDetails] = useState([]);
  const [paymentMode, setPaymentMode] = useState('');
  const [hideRTGS, setHideRTGS] = useState('d-none');
  const [hideMandate, setHideMandate] = useState('d-none');
  const [hideImidiatePayment, setHideImidiatePayment] = useState('d-none');
  const [imediateOrEmail, setImediateOrEmail] = useState("Y");
  const [userDetailsLocal, setUserDetailsLocal] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [usersBankDetail, setUsersBankDetail] = useState([]);
  const [bankName, setBankName] = useState('');
  const [hideBank, setHideBank] = useState('d-none');
  const [tempBankName, setTempBankName] = useState({});
  const [mandateListData, setMandateListData] = useState([]);
  const [mandateUrn, setMandateUrn] = useState('');
  const [tempMandateUrn, setTempMandateUrn] = useState({});
  const [utrNo, setUtrNo] = useState("")
  const [transferDate, setTransferDate] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [tempOrderData, setTempOrderData] = useState({})
  const [removeFutureDAte, setRemoveFutureDAte] = useState('')
  const [isProfileChange, setIsProfileChange] = useState(false);
  const [massageContent, setMassageContent] = useState("");
  const [profileTargetValue,setProfileTargetValue] = useState("")
  const [isMandateAvailable,setIsMandateAvailable] = useState(false)

  // Loader
  const [schemeLoader, setSchemeLoader] = useState(false)


  const refreshState = () => {
    setAmc('')
    setScheme('')
    // setAmount('')
    setSchemeList([])
    setPaymentMode("")
    setBankName("")
    setTempBankName({})
    setUtrNo('')
    setTempMandateUrn({})
    setMandateUrn('')
    setNewPurchaseDetails([])
    setHideRTGS("d-none")
    setHideMandate("d-none")
    setHideBank("d-none")
    setHideImidiatePayment("d-none")

  }

  function convertMonthNumberToName(monthNumber) {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const monthIndex = monthNumber - 1;

    if (monthIndex >= 0 && monthIndex < monthNames.length) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }
  const orderNowHandle = () => {
    if (newPurchaseDetails.length === 0) {
      if (!amount || !amc || !profileName || !scheme) {
        notify("error", "All Fields Required.")
        return
      }
      if (amount < 5000) {
        notify("warn", `Amount should not be less than 5000`)
        return
      }
    }
    if (!paymentMode) {
      notify("warn", "Please select payment mode")
      return
    }
    if (!bankName && paymentMode !== "M") {
      notify("warn", "Please select bank name")
      return
    }
    if (paymentMode === "M" && !mandateUrn) {
      notify("warn", "Please select mandate list")
      return
    }
    let arrayDate = []
    if (transferDate) {
      arrayDate = transferDate.split('-')
    }
    if (newPurchaseDetails.length === 0) {
      setLoader("block")
      let reqData = {
        email: tempProfileName.email,
        tax_status: tempProfileName.taxStatus,
        sub_trxn_type: "N",
        trxn_acceptance: "ALL",
        payment_mode: paymentMode,
        instrm_amount: amount,
        debit_amount_type: "",
        Return_paymnt_flag: paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
        Client_callback_url: "API URL",
        ach_exist: "Y",
        amc: tempAmc.rtaAmcCode,
        product_code: tempScheme.productCode,
        reinvest: tempScheme.reinvest,
        amount: amount,
        input_ref_no: "",
        perpetual_flag: "N",
        insurance_enabled: "N",
        instrm_date: "",
        rtgs_code: paymentMode === "TR" ? tempBankName.IFSC_CODE : "",
        transfer_date: transferDate ? arrayDate[2] + "-" + convertMonthNumberToName(arrayDate[1]) + "-" + arrayDate[0] : "",
        utr_no: utrNo,
        umrn: paymentMode === "M" ? tempMandateUrn.UMRN_NO : "",
        folio: "",
        iin: tempProfileName.IIN,
        accountNo: paymentMode === "M" ? tempMandateUrn.ACCOUNT_NO : tempBankName.AC_NO,
        bank_code: paymentMode === "M" ? tempMandateUrn.BANK_CODE : tempBankName.BANK_CODE,
        ifsc_code: paymentMode === "M" ? "" : tempBankName.IFSC_CODE,
        branch: paymentMode === "M" ? "" : tempBankName.BRANCH_NAME,
        holder_name: tempProfileName.name
      }

      newPurchase(reqData, headers).then(res => {
        if (res.data?.status === 200) {
          // setNewFolio(res.data?.message_full?.Folio)
          setConfirmationData(res.data?.data)
          setConfirmShow(true);
          setShow(false);

          let nseData = {
            Unique_No: res.data.data.Unique_No,
            Trxn_No: res.data.data.Trxn_No,
            Application_No: res.data.data.Application_No,
            Fund: res.data.data.Fund,
            Scheme: res.data.data.Scheme,
            Scheme_Name: res.data.data.Scheme_Name,
            Amt: res.data.data.Amt,
            Status_Desc: res.data.data.Status_Desc,
            Status_code: res.data.data.Status_code,
            Platform: "Web",
            transaction_type: "New",
            iin: tempProfileName.IIN,
            Pan: tempProfileName?.pan,
            Gpan: tempProfileName?.gpan,
            Joint1pan: tempProfileName?.jtname1,
            Joint2pan: tempProfileName?.jtname2,
            name: tempProfileName?.name,

          }
          saveNseTransaction(nseData, headers).then(res => {
            refreshState()
            setProfileName("");
            setTempProfileName({});
          })
          if (res.data?.data?.Paymentlink) {
            window.open(res.data.data.Paymentlink)
          }
          setLoader("none")
        } else {
          setConfirmationDataArr([])
          // notify("error",res?.data?.msg)
          setConfirmationData({})
          setErrorMsg(res.data?.msg)
          setErrorConfirmationShow(true);
          setLoader("none")

        }

      })
    } else {
      setLoader("block")
      const totalAmount = newPurchaseDetails.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.amount);
      }, 0);
      let reqData = {
        email: tempProfileName.email,
        tax_status: tempProfileName.taxStatus,
        sub_trxn_type: "N",
        trxn_acceptance: "ALL",
        payment_mode: paymentMode,
        instrm_amount: totalAmount,
        debit_amount_type: "",
        Return_paymnt_flag: paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
        Client_callback_url: "API URL",
        ach_exist: "Y",
        amc: tempAmc.rtaAmcCode,
        product_code: tempScheme.productCode,
        reinvest: tempScheme.reinvest,
        amount: totalAmount,
        input_ref_no: "",
        perpetual_flag: "N",
        insurance_enabled: "N",
        instrm_date: "",
        rtgs_code: paymentMode === "TR" ? tempBankName.IFSC_CODE : "",
        transfer_date: transferDate ? arrayDate[2] + "-" + convertMonthNumberToName(arrayDate[1]) + "-" + arrayDate[0] : "",
        utr_no: utrNo,
        umrn: paymentMode === "M" ? tempMandateUrn.UMRN_NO : "",
        folio: "",
        iin: tempProfileName.IIN,
        accountNo: paymentMode === "M" ? tempMandateUrn.ACCOUNT_NO : tempBankName.AC_NO,
        bank_code: paymentMode === "M" ? tempMandateUrn.BANK_CODE : tempBankName.BANK_CODE,
        ifsc_code: paymentMode === "M" ? "" : tempBankName.IFSC_CODE,
        branch: paymentMode === "M" ? "" : tempBankName.BRANCH_NAME,
        holder_name: tempProfileName.name,
        schemes: newPurchaseDetails
      }
      multiplePurchase(reqData, headers).then(res => {
        if (res.data?.status === 200) {
          // setNewFolio(res.data?.message_full?.Folio)
          setConfirmationData(res.data?.data)
          setConfirmShow(true);
          setShow(false);
          setConfirmationDataArr(res.data?.data)
          let nseArr = res.data?.data
          for (let i = 0; i < nseArr.length; i++) {
            let nseData = {
              Unique_No: nseArr[i].Unique_No,
              Trxn_No: nseArr[i].Trxn_No,
              Application_No: nseArr[i].Application_No,
              Fund: nseArr[i].Fund,
              Scheme: nseArr[i].Scheme,
              Scheme_Name: nseArr[i].Scheme_Name,
              Amt: nseArr[i].Amt,
              Status_Desc: nseArr[i].Status_Desc,
              Status_code: nseArr[i].Status_code,
              Platform: "Web",
              transaction_type: "New",
              iin: tempProfileName?.IIN,
              Pan: tempProfileName?.pan,
              Gpan: tempProfileName?.gpan,
              Joint1pan: tempProfileName?.JOINT1_PAN,
              Joint2pan: tempProfileName?.JOINT2_PAN,
              name: tempProfileName?.name,
            }
            saveNseTransaction(nseData, headers).then(res => {
              refreshState()
              setProfileName("");
              setTempProfileName({});
            })

          }
          setNewPurchaseDetails([])
          if (res.data?.data[0].Paymentlink) {
            window.open(res.data.data[0].Paymentlink)
          }

        } else {
          setNewPurchaseDetails([])
          setConfirmationDataArr([])
          // notify("error",res?.data?.msg)
          setConfirmationData({})
          setErrorMsg(res.data?.msg)
          setErrorConfirmationShow(true);


        }
        setLoader("none")
      })
    }




  };

  const amcChangeHandler = (e) => {
    if (e.target.value) {
      let reqData = JSON.parse(e.target.value)
      setAmc(e.target.value)
      setTempAmc(reqData)
    }

  }
  const isWantProfileChange = (e)=>{
    if(newPurchaseDetails?.length > 0){
      setMassageContent("Change of profile will lead to deletion of added schemes. Are you sure to proceed?")
      setProfileTargetValue(e.target.value)
      setIsProfileChange(true)
    }else{
      profileChangeHandler(e.target.value)
    }

  }
  const profileChangeHandler = (e) => {
    if (e) {
      let reqData = JSON.parse(e)
      setProfileName(e)
      setTempProfileName(reqData)
      updateNseMultiUserBankdetails({iin:reqData.IIN},headers).then(res=>{
        usersBankCall(reqData.IIN)
        refreshState()
      })
    }

  }




  const usersBankCall = (iin) => {
    usersBank({ iin }, headers).then(res => {
      if (!res.data) {
        setUsersBankDetail([])
      } else {
        setUsersBankDetail(res.data?.data)
      }
    })
  }

  const findScheme = () => {
    setSchemeList([]);
    setScheme('')
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setSchemeLoader(true)
    if(!isLookingForAnother){
      const reqBody = {
        amc_code: Number(tempAmc.amcCode),
        asset_code: Number(assetClass),
        opt_code: Number(option),
        rta: tempAmc.rta,
        rtamccode: tempAmc.rtaAmcCode,
      };
  
      if (assetClass && tempAmc && option) {
        getSchemeList(reqBody, headers).then((res) => {
          if (!res.data) {
            setSchemeList([]);
            // notify("error","Something went wrong...!")
            setSchemeLoader(false)
          } else {
            setSchemeList(res.data?.data);
            setSchemeLoader(false)
          }
        });
      }
    }else{
      const reqBody = {
        amc_code: Number(tempAmc.amcCode),
      }
      getElssScheme(reqBody,headers).then(res=>{
        if(!res.data){
          setSchemeList([]);
          setSchemeLoader(false)
        }else{
          setSchemeList(res.data?.data);
            setSchemeLoader(false)
        }
      })
    }
 
  };

  const amountChangeHandler = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000 ) {
     
      const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    } 
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setUserDetailsLocal(userdata)
    let d = disableFutureDate()
    setRemoveFutureDAte(d)
    setHeaders(header)
    if (amcList.length > 0) {
      findScheme();
    }
  }, [assetClass, option, tempAmc, amcList]);

  useEffect(()=>{
    setAmount(amountForLumpsum)
  },[amountForLumpsum])

  const addNewPurchase = () => {
    if (!amount || !amc || !profileName || !scheme) {
      notify("error", "All Fields Required.")
      return
    }
    if (amount < 5000) {
      notify("warn", `Amount should be greater than min amount`)
      return
    }
    document.getElementById('scroll').scrollIntoView(true);
    setNewPurchaseDetails([...newPurchaseDetails, { profileName: tempProfileName.name, amcName: tempAmc.amcName, amount, schemeName: tempScheme.s_name, reinvest: tempScheme.reinvest, product_code: tempScheme.productCode, amc: tempAmc.rtaAmcCode, perpetual_flag: "N", folio: "", input_ref_no: "" }])

    setTempOrderData({ amc, scheme, amount })
    setAmc('')
    setScheme('')
    setAmount('')
    setSchemeList([])
    setPaymentMode("")
    setBankName("")
    setTempBankName({})
    setUtrNo('')
    setTempMandateUrn({})
    setMandateUrn('')
    setTransferDate('')

  }

  const deleteData = (i) => {
    newPurchaseDetails.splice(i, 1);
    setNewPurchaseDetails([...newPurchaseDetails]);
  };

  const changePaymentModeHandler = (e) => {
    let value = e.target.value
    setPaymentMode(e.target.value)
    if (value === "OL" || value === "UPI") {
      setHideRTGS('d-none')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('')
      setMandateUrn('')
      setMandateUrn("")
      setBankName("")
      setTempBankName({})
      setTempMandateUrn({})
      setTransferDate('')
      setUtrNo('')
    } else if (value === "TR") {
      setHideRTGS('')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('d-none')
      setMandateUrn('')
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})

    } else if (value === "M") {
      setTransferDate('')
      setUtrNo('')
      setHideBank("d-none")
      setMandateUrn("")
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})
      setHideMandate('')
      setHideRTGS('d-none')
      setHideImidiatePayment('d-none')
      getMandateListCall()

    }
    document.getElementById('transferId').scrollIntoView(true);


  }
  const getMandateListCall = () => {
    getMandateList({ iin: tempProfileName.IIN }, headers).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
      }
    })
  }
  const changBankHandler = (e) => {
    if (e.target.value) {
      let bankDetailObj = JSON.parse(e.target.value)
      setBankName(e.target.value)
      setTempBankName(bankDetailObj)
    }
  }
  const changeSchemeHandler = (e) => {
    if (e.target.value) {
      let schemeData = JSON.parse(e.target.value)
      setTempScheme(schemeData)
      setScheme(e.target.value)
    }

  }

  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value)
      let urnValue = JSON.parse(e.target.value)
      setTempMandateUrn(urnValue)

    }
  }
  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
      setHideMandate("d-none")
      setPaymentMode("");
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          className="invest-gray"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">{purchase}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4" style={{scrollBehavior: "smooth"}}>
            <Form>
              <div className="row mb-2">
                <div className={`col-lg-${isLookingForAnother? "6":"4"} col-md-6`}>
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={isWantProfileChange}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                            <option
                              value={JSON.stringify({
                                email: el.EMAIL,
                                taxStatus: el.TAX_STATUS,
                                IIN: el.IIN,
                                name: el.NAME,
                                pan: el.PAN,
                                gpan: el?.GUARD_PAN,
                                jtname1: el.JOINT_NAME1,
                                jtname2: el.JOINT_NAME2,
                                nominee: el.NOM_NAME,
                              })}
                              key={el.PAN}
                            >
                              {`${el.NAME} ${el.JOINT_NAME1 && "|"} ${
                                el.JOINT_NAME1
                              }`}
                            </option>
                          ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className={`col-lg-${isLookingForAnother? "6":"4"} col-md-6`}>
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={amc}
                      onChange={amcChangeHandler}
                    >
                      <option value="">Select AMC</option>
                      {amcList.map((el) => (
                        <option
                          value={JSON.stringify({
                            amcName: el.s_name,
                            amcCode: el.amc_code,
                            rtaAmcCode: el.rtamccode,
                            rta: el.rta,
                          })}
                          key={el.s_name}
                        >
                          {el.s_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                {isLookingForAnother ? "": <div className="col-lg-4 col-md-6">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Asset Class <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Equity"
                        name="formHorizontalRadios1"
                        id="equity"
                        className="pe-3"
                        value={"1"}
                        checked={assetClass == "1"}
                        onChange={(e) => setAssetClass(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Debt"
                        name="formHorizontalRadios1"
                        id="debt"
                        className="pe-3"
                        value={"3"}
                        checked={assetClass == "3"}
                        onChange={(e) => setAssetClass(e.target.value)}
                      />
                       <Form.Check
                        type="radio"
                        label="Other"
                        name="formHorizontalRadios1"
                        id="other"
                        className="pe-3"
                        value={"2"}
                        checked={assetClass == "2"}
                        onChange={(e) => setAssetClass(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                </div>}
               
              </div>

             <div className="row">
             {isLookingForAnother ? "":  <div className="col-lg-4 col-md-6">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Option <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Growth"
                        name="formHorizontalRadios"
                        id="Growth"
                        className="pe-3"
                        value={"1"}
                        checked={option == "1"}
                        onChange={(e) => setOption(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="IDCW"
                        name="formHorizontalRadios"
                        id="IDCW"
                        className="pe-3"
                        value={"2"}
                        checked={option == "2"}
                        onChange={(e) => setOption(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                </div>}
                <div className={`col-lg-${isLookingForAnother? "6":"4"} col-md-6`}>
                  <Form.Group>
                    <Form.Label>
                      Select Scheme <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={scheme}
                      onChange={changeSchemeHandler}
                      disabled={schemeLoader}
                    >
                      <option value="">Select Scheme</option>

                      {schemeList.map((el) => {
                        return (
                          <option
                            value={JSON.stringify({
                              s_name: el.s_name,
                              minimumAmount: el.mininvt,
                              productCode: el.productcode,
                              reinvest: el.reinvest,
                            })}
                            key={el.s_name}
                          >
                            {el.s_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className={`col-lg-${isLookingForAnother? "6":"4"} col-md-6`}>
                  <Form.Group>
                    <Form.Label>
                      Enter Amount <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      value={amount}
                      onChange={amountChangeHandler}
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                  <small className="text-label float-right">
                    Min Amount: 5000
                  </small>
                </div>
              </div>
              <div className="col-md-12 " onClick={addNewPurchase}>
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger float-right mt-4"
                >
                  + Add
                </a>
              </div>
              <div className="col-md-12 px-0" id="tbt">
                <div className="table-responsive w-100">
                  <table className="table custom bg-white mt-4 mb-3" >
                    <tr>
                      <th className="wd-7rem">Profile</th>
                      <th className="wd-7rem">Scheme Name</th>
                      <th className="wd-7rem">AMC Name</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                    {newPurchaseDetails.length > 0
                      ? newPurchaseDetails.map((item, i) => {
                          return (
                            <tr>
                              <td>{item.profileName}</td>
                              <td>{item.schemeName}</td>
                              <td>{item.amcName}</td>
                              <td>{item.amount}</td>
                              <td>
                                <Cart_Delete id={i} deleteData={deleteData} />
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </table>
                </div>
                <div className="row mb-2">
                  <div className="col-md-5" >
                    <Form.Group>
                      <Form.Label >
                        Select Payment Mode
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select 

                        className="bg-c"
                        value={paymentMode}
                        onChange={changePaymentModeHandler}
                      >
                        <option value="">Select Payment Mode</option>
                        <option value={"OL"}>Net Banking</option>
                        <option value={"UPI"}>UPI</option>
                        <option value={"TR"}>RTGS/NEFT</option>
                        <option value={"M"} >Debit Mandate</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className={`col-md-7 ${hideImidiatePayment}`}>
                    <Form.Group
                      className="pt-lg-4 mt-lg-3 d-flex"
                      controlId="formBasicRadio"
                    >
                      <Form.Check
                        type="radio"
                        label="Immediate Payment"
                        name="formHorizont"
                        id="ImmediatePayment"
                        className="pe-3 fs-sm-14"
                        value={"Y"}
                        checked={imediateOrEmail == "Y"}
                        onChange={(e) => setImediateOrEmail(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Link On Email"
                        name="formHorizont"
                        id="Link On Email"
                        className="pe-3 fs-sm-14"
                        value={"N"}
                        checked={imediateOrEmail == "N"}
                        onChange={(e) => setImediateOrEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className={`col-md-5 ${hideBank}`}>
                    <Form.Group>
                      <Form.Label>
                        Select Bank <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={bankName}
                        onChange={changBankHandler}
                      >
                        <option value="">Select Bank</option>
                        {usersBankDetail &&
                          usersBankDetail.map((el) => (
                         el.STATUS ==="Activated" ? <option  value={JSON.stringify({
                                AC_NO: el.AC_NO,
                                BANK_CODE: el.BANK_CODE,
                                IFSC_CODE: el.IFSC_CODE,
                                BRANCH_NAME: el.BRANCH_NAME,
                                BANK_NAME:el.BANK_NAME
                              })}
                            >{`${el.BANK_NAME} | Ac No- ${el.AC_NO}`}</option>:""
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                   <div className={`col-md-5 ${hideMandate}`}>
                    <Form.Group>
                      <Form.Label>
                        Select Mandate <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={mandateUrn}
                        onChange={changeMandateHandler}
                        onClick={checkMandate}
                      >
                        <option value={""}>Select Mandate</option>
                        {mandateListData.length > 0 &&
                          mandateListData.map((data) => (
                            <option
                              value={JSON.stringify({
                                UMRN_NO: data.UMRN_NO,
                                BANK_CODE: data.BANK_CODE,
                                ACCOUNT_NO: data.ACCOUNT_NO,
                                MANDATE_DATE: data.MANDATE_DATE,
                                AC_TYPE: data.AC_TYPE,
                              })}
                            >{`${data.BANK_NAME} | Ac No-  ${
                              data.ACCOUNT_NO
                            } |  Amount- ${
                              data?.AMOUNT > 0 ? data?.AMOUNT : "0"
                            } `}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
              
                  <div className={`col-md-5 ${hideRTGS}`}>
                    <Form.Group>
                      <Form.Label>UTR No.</Form.Label>
                      <Form.Control
                        className="bg-c"
                        type="text"
                        placeholder="Enter UTR No"
                        value={utrNo}
                        onChange={(e) => {
                          if (e.target.value.length < 30) {
                            setUtrNo(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className={`col-md-5 ${hideRTGS}`} >
                  <Form.Group>
                    <Form.Label>Enter Transfer Date</Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="date"
                      max={removeFutureDAte}
                      placeholder="Enter Amount"
                      value={transferDate}
                      id="anyWhere5"
                      onClick={() => dateAny("anyWhere5")}
                      onChange={(e) => setTransferDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                {paymentMode==="TR"? <div className="col-md-12 mt-4">
                  <ul className="fs-13 mb-0  ps-0 lst-none">
                  <li>
                      <h6 className="text-red fs-14">Note :-</h6>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Name 
                      <span className="text-black">
                        - NSE Clearing – New Mutual Fund Platform
                      </span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Bank Name
                      <span className="text-black">- HDFC Bank Ltd</span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Branch Name
                      <span className="text-black">-  Fort, Mumbai</span>
                    </li>
                  
                    <li className="text-label">
                      <span className="text-red">*</span>Virtual Account No
                      <span className="text-black">
                        - ({`NSEMF221399${tempProfileName?.IIN ? tempProfileName?.IIN:""}`})
                      </span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Account Type
                      <span className="text-black">- Current Account</span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>IFSC Code (For RTGS / NEFT Transfers)
                      <span className="text-black">- HDFC0000060</span>
                    </li>
                  </ul>
                </div>:""}
                <div id="transferId"></div>
               
              </div>
            </Form>
          
          <div className="border-0 trans" > 
            <button
              className="btn-custom"
              type="button"
              onClick={orderNowHandle}
            >
              Order Now
            </button>
          </div>
          <div className="py-5" id="scroll" ></div>
          </Modal.Body>
          
        </Modal>
      </div>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW
        show={errorConfirmationShow}
        setShows={setErrorConfirmationShow}
        errorMsg={errorMsg}
      />
        <ConfirmationProfileChangePopup 
       show={isProfileChange}
       setShow={setIsProfileChange}
       massageContent={massageContent}
       profileChangeHandler={profileChangeHandler}
       profileTargetValue={profileTargetValue}
      /> 
       <ConfirmationMandatePopup
      show={isMandateAvailable}
      setShow={setIsMandateAvailable}
      profileDetail={{name:tempProfileName?.name,iin:tempProfileName?.IIN,pan:tempProfileName?.pan,gpan:tempProfileName?.gpan}}
      />
    </>
  );
};
export default NewPurchaseTransact;
