import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import success from "../assets/images/successfully.png";
import Video_blog from './video-blog';
import Select_Scheme from './switch-select-scheme';
import Cart_Delete from './delete-confirmation';
import { globalInfo } from "../App";
import { saveNseTransaction, switchMulti } from '../apisMethods';
import TransactSuccess from './transact-success';
import { notify } from '../pages/toastCreater';
import { BsArrowLeft } from 'react-icons/bs';
import SWW from './something-went-wrong';
import PortfolioContent from './portfolio-content';
const content = " <p>Bravo!Bravo! That was a praiseworthy initiative given the level of financial literacy and the lack of interest in investors to know about it. We have to hand it to you that you are one of those curious and courageous investor.</p> <p>We provide suggestion to switch your funds from one scheme to another in the same AMC because of the following reasons:</p><p>1.	When the scheme you invested in is not performing well in comparison to its peer schemes. Say, for example, you invested in a mid-cap scheme and the returns this scheme is generating in eight years period (basis of return calculation- rolling return- monthly observation) are lower than the average expected returns.</p> <p>2.	Although we are suggesting you to withdraw from this scheme but since there are other better performing schemes in the same AMC, we are, at the same time, showing you these schemes along with their ideal investment horizon and average expected returns, so that you can take an informed decision.</p> <p>And please understand that combing off non performing schemes by shifting into the performing one is going to be a journey. It’s a regular process to boost portfolio returns.</p> <p>Thanking you again for reading this….</p><p>We might sound greedy….</p>"
 const heading="Switch"
const SwitchConfirm = ({ show, setShow, switchData, singleFamilyData }) => {

    const { setLoader } = useContext(globalInfo)
    const [isPortfolioContentShow, setIsPortfolioContentShow] = useState(false)
    const [Videoblogshow, setVideoblogShow] = useState(false);
    const [selectschemeshow, setSelectschemeShow] = useState(false);
    const [Purchase, setPurchase] = useState("d-block");
    const [Confirm, setConfirm] = useState("d-none");
    const [Success, setSuccess] = useState("d-none");
    const [schemeList, setSchemeList] = useState([])
    const [switchBody, setSwitchBody] = useState([])
    const [sourceSchemes, setSourceSchemes] = useState([])
    const [loggedInData, setLoggedInData] = useState({})
    const [headers, setHeaders] = useState({})
    const [selectedTargetData, setSelectedTargetData] = useState({})
    const [targetScheme, setTargetScheme] = useState([])
    const [confirmShow, setConfirmShow] = useState(false);
    const [confirmationDataArr, setConfirmationDataArr] = useState([]);
    const [errorConfirmationShow, setErrorConfirmationShow] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    useEffect(() => {
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        setHeaders(header)
        const userdata = JSON.parse(localStorage.getItem("user"));
        setLoggedInData(userdata)
        setSchemeList(switchData)
        let data = filterSchemes(switchData)
        setSourceSchemes(data)

        let arr = []
        data.map((el, index) => {
            arr.push({
                email: singleFamilyData?.EMAIL,
                tax_status: singleFamilyData?.TAX_STATUS,
                trxn_acceptance: "ALL",
                amc: el?.Source_AMC_CODE,
                product_code: el?.Source_PRODUCT_CODE,
                sourceScheme: el.Source_s_name,
                targetScheme: "",
                //incase of amount selected for switching minimum amount is Rs.1000
                amt_unit: el?.unit,
                remark: "",
                folio: el?.folio,
                amountsType: "Y" + index,
                amt_unit_type: "Unit",
                all_units: "Y",
                //Static
                input_ref_no: "",
                //Static
                trxn_execution: "",
                target_product_code: el.Target_PRODUCT_CODE,
                //Static
                target_ft_acc_no: "",
                //reinvest flag of target scheme
                reinvest: el?.Target_REINVEST_TAG,
            })
        })
        console.log("eee", filterSchemes(switchData));
        setSwitchBody(arr)
        setPurchase("d-block")
        setConfirm("d-none")
    }, [switchData])


    function filterSchemes(data) {
        // const alreadyvisit = new Set();
        // const tempMap = new Map();

        // data.forEach((el, i) => {
        //     if (alreadyvisit.has(el.productcode)) {
        //         const obj = tempMap.get(el.productcode);
        //         obj.targetSchemes.push({
        //             Target_s_name: el.Target_s_name,
        //             productcode: el.productcode,
        //             Target_isin_code: el.Target_isin_code,
        //             Target_AMC_CODE: el.Target_AMC_CODE,
        //             Target_PRODUCT_CODE: el.Target_PRODUCT_CODE,
        //             Target_STP_DATES: el.Target_STP_DATES,
        //             Target_SWITCH_ALLOWED: el.Target_SWITCH_ALLOWED,
        //             Target_REINVEST_TAG: el.Target_REINVEST_TAG,
        //             investment_horizon: el.investment_horizon,
        //             average_return: el.average_return,
        //             recommendation: el.recommendation
        //         });
        //     } else {
        //         alreadyvisit.add(el.productcode);
        //         tempMap.set(el.productcode,
        //             {
        //                 folio: el.folio,
        //                 productcode: el.productcode,
        //                 currentvalue: el.currentvalue,
        //                 unit: el.unit,
        //                 Source_s_name: el.Source_s_name,
        //                 Source_isin_code: el.Source_isin_code,
        //                 Source_AMC_CODE: el.Source_AMC_CODE,
        //                 Source_PRODUCT_CODE: el.Source_PRODUCT_CODE,
        //                 Source_STP_DATES: el.Source_STP_DATES,
        //                 Source_SWITCH_ALLOWED: el.Source_SWITCH_ALLOWED,
        //                 Source_REINVEST_TAG: el.Source_REINVEST_TAG,
        //                 targetSchemes: [{
        //                     Target_s_name: el.Target_s_name,
        //                     productcode: el.productcode,
        //                     Target_isin_code: el.Target_isin_code,
        //                     Target_AMC_CODE: el.Target_AMC_CODE,
        //                     Target_PRODUCT_CODE: el.Target_PRODUCT_CODE,
        //                     Target_STP_DATES: el.Target_STP_DATES,
        //                     Target_SWITCH_ALLOWED: el.Target_SWITCH_ALLOWED,
        //                     Target_REINVEST_TAG: el.Target_REINVEST_TAG,
        //                     investment_horizon: el.investment_horizon,
        //                     average_return: el.average_return,
        //                     recommendation: el.recommendation
        //                 }]
        //             });
        //     }
        // });
        // return Array.from(tempMap, ([key, value]) => {
        //     return value
        // });

                               
       

        let op = [];
        data.forEach((el) => {
            let index = op.findIndex(it => it.productcode === el.productcode && it.folio === el.folio);
            if (index !== -1) {
                op[index].targetSchemes.push( {
                    Target_s_name: el.Target_s_name,
                    productcode: el.productcode,
                    Target_isin_code: el.Target_isin_code,
                    Target_AMC_CODE: el.Target_AMC_CODE,
                    Target_PRODUCT_CODE: el.Target_PRODUCT_CODE,
                    Target_STP_DATES: el.Target_STP_DATES,
                    Target_SWITCH_ALLOWED: el.Target_SWITCH_ALLOWED,
                    Target_REINVEST_TAG: el.Target_REINVEST_TAG,
                    investment_horizon: el.investment_horizon,
                    average_return: el.average_return,
                    recommendation: el.recommendation
                })
            } else {
                op.push({ folio: el.folio,
                    productcode: el.productcode,
                    currentvalue: el.currentvalue,
                    unit: el.unit,
                    Source_s_name: el.Source_s_name,
                    Source_isin_code: el.Source_isin_code,
                    Source_AMC_CODE: el.Source_AMC_CODE,
                    Source_PRODUCT_CODE: el.Source_PRODUCT_CODE,
                    Source_STP_DATES: el.Source_STP_DATES,
                    Source_SWITCH_ALLOWED: el.Source_SWITCH_ALLOWED,
                    Source_REINVEST_TAG: el.Source_REINVEST_TAG, targetSchemes: [ {
                    Target_s_name: el.Target_s_name,
                    productcode: el.productcode,
                    Target_isin_code: el.Target_isin_code,
                    Target_AMC_CODE: el.Target_AMC_CODE,
                    Target_PRODUCT_CODE: el.Target_PRODUCT_CODE,
                    Target_STP_DATES: el.Target_STP_DATES,
                    Target_SWITCH_ALLOWED: el.Target_SWITCH_ALLOWED,
                    Target_REINVEST_TAG: el.Target_REINVEST_TAG,
                    investment_horizon: el.investment_horizon,
                    average_return: el.average_return,
                    recommendation: el.recommendation
                }] });
            }
        })
return op

    }
    const purchaseContinue = () => {
        for (let i = 0; i < switchBody.length; i++) {
            if (!switchBody[i].target_product_code || !switchBody[i].targetScheme) {
                notify("error", "Plaese select Target Schemes..!")
                return
            } else if (!switchBody[i].amt_unit) {
                notify("error", "Plaese enter amount or unit..!")
                return
            }

        }

        for (let i = 0; i < switchBody.length; i++) {
            if (switchBody[i].amt_unit_type === "Unit" && switchBody[i].amt_unit > sourceSchemes[i].unit) {
                notify("error", "Unit Can not exceed from your available units..!")
                return
            } else if (switchBody[i].amt_unit_type === "Amount" && switchBody[i].amt_unit > sourceSchemes[i].currentvalue) {
                notify("error", "Amount Can not exceed from your available Amount..!")
                return
            }
        }
        setConfirm("d-block")
        setPurchase("d-none")
    }
    const finalOrder = () => {
        setLoader("block")
        let reqBody = {
            iin: singleFamilyData?.IIN,
            input_ref_no: "",
            remark: "",
            trxn_execution: "",
            trxn_acceptance: "ALL",
            data: switchBody
        }

        switchMulti(reqBody, headers).then((res) => {
            if (!res.data) {
                setErrorConfirmationShow(true)
                setErrorMsg(res.error.response.data?.msg ? res.error.response.data?.msg : "Something went wrong..!")
            } else {
                if (res.data?.status === 200 || res.data?.success) {
                    setLoader("none")
                    res.data?.data.map((item) => {
                        let nseData = {
                            Unique_No: item.Unique_No,
                            Trxn_No: item.Trxn_No,
                            Application_No: item.Application_No,
                            Fund: item.Fund,
                            Scheme: item.Scheme,
                            Scheme_Name: item.Scheme_Name,
                            Amt: item.Amt,
                            Status_Desc: item.Status_Desc,
                            Status_code: item.Status_code,
                            Folio: item.Folio,
                            Platform: "Web",
                            transaction_type: "Switch",
                            iin: singleFamilyData.IIN,
                            Pan: singleFamilyData.PAN,
                            Gpan: singleFamilyData?.GUARD_PAN ? singleFamilyData?.GUARD_PAN : "",
                            Joint1pan: singleFamilyData.JOINT1_PAN,
                            Joint2pan: singleFamilyData.JOINT1_PAN,
                            name: singleFamilyData.NAME,
                        };
                        // setConfirmationData(res.data?.data);
                        setConfirmShow(true);
                        setShow(false)
                        setConfirm("d-none")
                        setConfirmationDataArr(res.data?.data);
                        saveNseTransaction(nseData, headers)
                    })


                } else {
                    setErrorMsg(res.data?.msg)
                    setErrorConfirmationShow(true)
                }
            }
            setLoader("none")
        })
    }



    const innerModal = () => {
        setVideoblogShow(true)
        setShow(false)
    }

    const closeModal = () => {
        setVideoblogShow(false);
        setShow(true)
    }

    const openSchemeModal = (item) => {
        setTargetScheme(item)
        setSelectschemeShow(true)
        setShow(false)
    }

    const closeSchemeModal = () => {
        setSelectschemeShow(false)
        setShow(true)
    }

    const deleteData = (id) => {
        sourceSchemes.splice(id, 1);
        switchBody.splice(id, 1);
        setSourceSchemes([...sourceSchemes])
        for (let i = id; i < switchBody.length; i++) {
            switchBody[i].amountsType = "Y" + i
            switchBody[i].amt_unit_type = "Unit"
        }
        setSwitchBody([...switchBody])
    }

    const typeHandler = (e, index, amount) => {
        console.log("index", index);
        let updateData = [...switchBody]
        updateData[index].amountsType = e.target.value
        updateData[index].amt_unit_type = e.target.value === "Y" + index || e.target.value === "Unit" + index ? "Unit" : "Amount"
        updateData[index].all_units = e.target.value !== "Y" + index ? "N" : "Y"
        updateData[index].amt_unit = 0
        if (e.target.value === "Y" + index) {
            updateData[index].amt_unit = amount
        }
        setSwitchBody(updateData)
    }
    const amountHandler = (e, i) => {
        const inputValue = e.target.value;
        if (inputValue < 10000000) {
            const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters

            const updatedData = [...switchBody];
            updatedData[i].amt_unit = updatedData[i].amt_unit_type === "Amount" ? Math.round(sanitizedValue) : sanitizedValue;
            setSwitchBody(updatedData);

        } else if (!isNaN(inputValue)) {
            const updatedData = [...switchBody];
            updatedData[i].amt_unit = 10000000;
            setSwitchBody(updatedData);
        }
    }

    const handleDataFromChild = (data, index) => {
        console.log(sourceSchemes);
        console.log("from child", data);
        sourceSchemes.map((item, index) => {
            console.log(item.productcode, data.Target_s_name);
            if (item.productcode === data.productcode) {
                switchBody[index].target_product_code = data.Target_PRODUCT_CODE
                switchBody[index].reinvest = data.Target_REINVEST_TAG
                switchBody[index].targetScheme = data.Target_s_name
            }
        })
        setSelectedTargetData(data);
    };
    const goBack = () => {
        setPurchase("d-block")
        setConfirm("d-none")
    }

    return (
        <>
            <div >
                <Modal show={show} onHide={() => {
                    setShow(false)
                    setSelectedTargetData({})
                }} centered >
                    <div className={` rounded  ${Purchase}`}>
                        <Modal.Header closeButton >
                            <Modal.Title className='fs-6'>Switch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-3">

                            {sourceSchemes.map((item, index) => {
                                return <Form>
                                    <div className="row mx-1   bg-c br-15">
                                        <div className="col-12 bg-gray bg-gray-raduis px-3 pt-3 ">
                                            <div className="row">
                                                <div className="col-11">
                                                    <Form.Group>
                                                        <p className=' text-red '> {item.Source_s_name} | {item.folio}</p>
                                                    </Form.Group>
                                                </div>
                                                {sourceSchemes?.length > 1 ? <div className="col-1 px-0" >
                                                    <span className='delbtn'>
                                                        <Cart_Delete id={index} deleteData={deleteData} />
                                                    </span>
                                                </div> : ""}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <Form.Group>
                                                <Form.Label className='text-black'>Select Target Scheme  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control className="bg-c-b fs-13" type="text" placeholder='Select Target Scheme' value={switchBody[index].targetScheme} onClick={() => openSchemeModal(item.targetSchemes)} />


                                            </Form.Group>
                                        </div>
                                        {item?.unit >= 0 ? (
                                            <small className="text-red pull-left">
                                                <i>
                                                    {`Available  Units: ${item?.unit},     Total Amount:  Rs ${item?.currentvalue} `}
                                                </i>
                                            </small>
                                        ) : null}
                                        <div className="col-12  px-3 pt-1">
                                            <Form.Group>
                                                <div className="d-flex mb-2 justify-content-center">
                                                    <Form.Check type="radio" label="By Amount" name={"formHorizontalRadios" + index} value={"Amount" + index}
                                                        onChange={(e) => typeHandler(e, index)}
                                                        checked={switchBody[index].amountsType === "Amount" + index} id={"ByAmount" + index} className="pe-3 fs-sm-12" />
                                                    <Form.Check type="radio" label="By Units" name={"formHorizontalRadios" + index} value={"Unit" + index}
                                                        onChange={(e) => typeHandler(e, index)}
                                                        checked={switchBody[index].amountsType === "Unit" + index} id={"ByUnits" + index} className="pe-3 fs-sm-12" />
                                                    <Form.Check type="radio" label="All Units" name={"formHorizontalRadios" + index} value={"Y" + index}
                                                        onChange={(e) => typeHandler(e, index, item?.unit)}
                                                        checked={switchBody[index].amountsType === "Y" + index} id={"AllUnits" + index} className="pe-3 fs-sm-12" />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <Form.Group>
                                                <Form.Label className='text-black'>{switchBody[index].amountsType == "Y" + index ? "All Units" : switchBody[index].amountsType == "Unit" + index ? "Enter Unit" : "Enter Amount"}  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control className="bg-c-b fs-13" type="text" placeholder={switchBody[index].amountsType == "Y" + index ? "All Units" : switchBody[index].amountsType == "Unit" + index ? "Enter Unit" : "Enter Amount"} onChange={switchBody[index].amountsType == "Y" + index ? () => { } : (e) => amountHandler(e, index)} value={switchBody[index]?.amt_unit} />

                                            </Form.Group>
                                        </div>
                                    </div>
                                </Form>
                            })}

                        </Modal.Body>
                        <Modal.Footer className='border-0'>
                            <p className='fs-10 text-justify'>The populated schemes are not performing as expected. Since there are other schemes in the
                                same fund house performing well, we are recommending to switch to them provided the
                                investment horizon and average returns meet your expectations. There may be multiple
                                options out of which you can pick one best suited to you.
                                <span className='text-red crPointer' onClick={()=>setIsPortfolioContentShow(true)}> Read more..</span>
                            </p>
                            <p className='fs-10 text-justify'>Suggested Action: Shift to better performing scheme.</p>
                            <button className="btn btn-danger " type="button" onClick={purchaseContinue}   >Continue</button>
                        </Modal.Footer>
                    </div>
                    <div className={`rounded ${Confirm}`}>
                        <Modal.Header closeButton >
                            <Modal.Title className='fs-6'><span onClick={goBack}>
                                <BsArrowLeft className="sip-popup-rignt-icon" />   </span>Confirmation Switch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4">
                            <div className="col mb-3 border-bottom">
                                <h6 className='text-red'>{singleFamilyData?.NAME}</h6>
                            </div>
                            {switchBody.map((item) => {
                                return <>   <div className="col">
                                    <p className='source-btn'>Source</p>
                                    <p className='text-red fs-13'>{item?.sourceScheme}</p>
                                </div>
                                    <div className="col">
                                        <p className='source-btn'>Target</p>
                                        <p className='text-red fs-13'>{item.targetScheme}</p>
                                    </div>
                                    <table className='mx-auto'>
                                        <tr className='text-center fs-13'>
                                            <td className='pe-4'>Folio </td>
                                            :
                                            <td className='ps-4'>{item.folio}</td>
                                        </tr>
                                        <tr className='text-center fs-13'>
                                            <td className='pe-4'>Amount/Unit  </td>
                                            :
                                            <td className='ps-4'>{item.amt_unit}</td>
                                        </tr>

                                    </table></>

                            })}

                        </Modal.Body>
                        <Modal.Footer className='border-0'>
                            <button className="btn btn-danger" type="button" onClick={finalOrder}   >Order</button>
                        </Modal.Footer>
                    </div>

                </Modal>
                <Select_Scheme show={selectschemeshow} setShow={closeSchemeModal} targetSchemeList={targetScheme} onData={handleDataFromChild} />

                <TransactSuccess
                    show={confirmShow}
                    setShow={setConfirmShow}
                    confirmData={{}}
                    confirmationDataArr={confirmationDataArr}
                />
                <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
                <Video_blog show={Videoblogshow} setShow={closeModal} />
                <PortfolioContent show={isPortfolioContentShow} setShow={setIsPortfolioContentShow} content={content} heading ={heading}/>
            </div>

        </>
    )
}
export default SwitchConfirm