import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import OTPInput from "otp-input-react";
import Otp_Input from "./otp";
import { resetPin, verifyResetOtp } from "../apisMethods";
import { notify } from "../pages/toastCreater";
import { validatorsMethods, Validator } from "../validations";
import Thanku from "./thanku";

const ChangePin = ({ show, setShow, sendOtp, userComes }) => {
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [OTP, setOTP] = useState("");
  const [Otpfield, setOtpfield] = useState("d-block");
  const [Change, setChange] = useState("d-none");
  const [tokenForChangePin, setTokenForChangePin] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (show === false) {
      setChange("d-none");
      setOtpfield("d-block");
      setOTP("");
      setNewPin("");
      setConfirmNewPin("");
    }
  }, [show]);

  const otp = () => {
    setChange("d-block");
    setOtpfield("d-none");
  };
  const verifyEmailOtp = (e) => {
    e.preventDefault();
    if (!OTP) {
      notify("warn", "Please enter your OTP");
      return;
    }
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.email) {
      verifyResetOtp({
        email: user.email,
        otp: OTP,
        formpin: true,
      }).then((res) => {
        if (!res.data) {
          notify("error", res.error.response.data.msg);
        } else {
          setTokenForChangePin(res.data?.data);
          otp();
        }
      });
    }
  };

  const updatePin = async (e) => {
    e.preventDefault();

    if (!newPin) {
      notify("error", "Please enter pin");
      return;
    }
    if (!confirmNewPin) {
      notify("error", "Please enter confirm pin");
      return;
    }
    if (newPin !== confirmNewPin) {
      notify("error", "Confirm pin not matched");
      return;
    }

    const header = {
      headers: { Authorization: `Bearer ${tokenForChangePin}` },
    };

    resetPin({ mpin: newPin }, header).then((res) => {
      if (!res.data) {
        notify("error", res.error.response?.data?.msg);
      } else {
        setChange("d-none");
        setOtpfield("d-block");
        setOTP("");
        setNewPin("");
        setConfirmNewPin("");
        setShow(false);
        setConfirmShow(true);
        setSuccessMessage("Your Pin Changes SuccessFully.");
      }
    });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        className="border-red"
        size="md"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 ">
          <Form onSubmit={updatePin}>
            <div className=" text-center">
              {" "}
              <div className={`text-center ${Otpfield}`}>
                <Form.Group>
                  <Otp_Input
                    OTP={OTP}
                    setOTP={setOTP}
                    sendOtp={sendOtp}
                    userComes={userComes}
                  />
                </Form.Group>
                <Form.Group>
                  <button
                    type="submit"
                    className="otp-btn mt-3 br-15 float-right"
                    onClick={verifyEmailOtp}
                  >
                    Verify OTP
                  </button>
                </Form.Group>
              </div>
              {/* <Form.Group className="mb-3">
                    <Form.Label>Exiting Pin<span className="text-red">*</span></Form.Label>
                    <OTPInput className="ps-4 otp-inp justify-content-center" value={Pin} onChange={setPin} autoFocus OTPLength={4} otpType="number" disabled={false}  />
                  </Form.Group> */}
              <div className={` ${Change} `}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-black">
                    Set New Pin<span className="text-danger">*</span>
                  </Form.Label>
                  <OTPInput
                    className="ps-4 otp-inp justify-content-center"
                    value={newPin}
                    onChange={setNewPin}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                  />
                </Form.Group>
                {/* <small className="text-danger pull-left">
                  {formError.newPinErr
                  }
                </small> */}
                <Form.Group className="mb-3">
                  <Form.Label className="text-black">
                    Confirm New Pin<span className="text-danger">*</span>
                  </Form.Label>
                  <OTPInput
                    className="ps-4 otp-inp justify-content-center"
                    value={confirmNewPin}
                    onChange={setConfirmNewPin}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                  />
                </Form.Group>
                <Form.Group className="text-center pt-4">
                  <button type="submit" className="otp-btn br-15">
                    Submit
                  </button>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
      </Modal>
      <Thanku
        show={confirmShow}
        setShow={setConfirmShow}
        successMessage={successMessage}
      />
    </>
  );
};
export default ChangePin;
