import React, { useEffect, useState ,useContext} from "react";
import { Link,useNavigate } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SIP_Form from "../components/sip-form";
import LumpsumForm from "../components/lumpsum-form";
import { getNfos } from "../apisMethods";
import { apis } from "../request";
import { globalInfo } from "../App";
import img_no_investment_transaction from "../../src/assets/images/img_no_investment_transaction.png";
import { notify } from "./toastCreater";


function NfoLive() {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo);
  const [sipformShow, setSipformShow] = useState(false);
  const [lumpsumformShow, setLumpsumformShow] = useState(false);
  const [haeders, setHeaders] = useState({});
  const [nfoData, setNfoData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState({});


  useEffect(()=>{
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    setHeaders(header)
    setLoader("block")
       getNfos(header).then((res)=>{
        if(!res.data){
          setNfoData([])
        }else{
          setNfoData(res.data.data)
        }
        setLoader("none")
       })
  },[])

  const nfoDetail  = (data)=>{
    navigate("/dashboard/nfo-detail", {
      state: data
    });
  } 
const investInLumpsun = (item)=>{
  const user = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  if (user?.iin) {
    setSelectedScheme([item]);
    setLumpsumformShow(true)
  } else if(token) {
    navigate("/dashboard/Profile-creation")
  }else{
    navigate("/")
  }
}
const investInSip = (item)=>{
  const user = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  if (user?.iin) {
    if(item?.SIP_ALLOWED==="Y"){
      item["fromNfo"] =true
      setSelectedScheme([item]);
      setSipformShow(true)
    }else{
     notify("error","SIP not Allowed")
    }
  } else if(token) {
    navigate("/dashboard/Profile-creation")
  }else{
    navigate("/")
  }
}
  
  return (

    <>

      <div className="wrapper nfo-live-s">
        <div className="px-lg-5 px-3 pe-5">

          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active">NFO Live</li>
            </ol>
          </nav>

          <section className={`mt-5 ${nfoData.length > 4 ? "": "mb-11"}`} >
                <div className="row justify-content-center">
                  <div className="col-xl-9 col-lg-9 col-md-12">
                    <div className="card shadow-custom border-0">
                      <div className="card-body custom-tab-bg">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table class="table custom text-table-black text-start">
                              <tbody>
                                {nfoData.length > 0 ? nfoData.map((item)=>{
                               return <tr>
                                  <td className="wd-6rem" ><img src={`${apis.imageLink}${item?.AMC_CODE}.png`}  alt="" className='img-fluid mx-3em' /></td>
                                   <td className='wd-15rem onHover' onClick={()=>nfoDetail(item)}>
                                  {item.PRODUCT_LONG_NAME}  
                                  </td>
                                  <td onClick={()=>investInLumpsun(item)}><div class="btn-invest text-center"> LUMPSUM</div></td>
                                 {item?.SIP_ALLOWED==="Y" ?  <td onClick={()=>investInSip(item)}><div class="btn-invest text-center"> SIP</div></td>:<td></td>}
                                </tr>
                                    }): <section >
                                    <div className="container-fluid">
                                      <div className="row justify-content-center">
                                        <div className="col-md-11 py-3">
                                          <div className="sipstpswp_error py-5 text-center">
                                            <div className="sipstpswp_error-img">
                                              <img src={img_no_investment_transaction} className="img-fluid mw-170" />
                                            </div>
                                            <h5 className="pt-3 text-red fw-600">No schemes available</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>}
                                
                               
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

          </section>
          <LumpsumForm
          show={lumpsumformShow}
          setShow={setLumpsumformShow}
          rightSchemes={selectedScheme}
        />
              <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={selectedScheme} />

        </div>
      </div>

    </>
  )
}
export default NfoLive