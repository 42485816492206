import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Otp_Input from "./otp";
import { nseUserRelation, updateUserProfile,getUserStatus } from "../apisMethods";
import { notify } from "../pages/toastCreater";
import { formatDate ,convertMonthInNumberFormat} from "../currentMonth/FinancialYear";
import SuccessNotification from "./successNotification";
const ChangeMobileNo = ({ show, setShow }) => {
  const [headerState, setHeaderState] = useState({});
  const [relationsData, setRelationsData] = useState([]);
  const [mobileRelation, setMobileRelation] = useState('');
  const [emailRelation, setEmailRelation] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [userDetail, setUserDetail] = useState({})
  const [formattedDate, setFormattedDate] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [successMessagePopUp, setSuccessMessagePopUp] = useState('')

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers)
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      nseUserRelationCall(headers)
      if(userdata?.dob){
        let formatted = convertMonthInNumberFormat(userdata?.dob)
        setFormattedDate(formatted)
      setDob(formatted)

      }
     
      setEmail(userdata?.email)
      setMobile(userdata?.mobile)
      setUserDetail(userdata)
      setEmailRelation(userdata?.email_rel)
      setMobileRelation(userdata?.mobile_rel)
    }

  }, [])

  const nseUserRelationCall = (headers) => {
    nseUserRelation(headers).then(res => {
      if (!res.data) {
        setRelationsData([])
      } else {
        setRelationsData(res.data?.data)
      }
    })
  }
  const emailRelationHandler = (e) => {
    if (e.target.value) {
      setEmailRelation(e.target.value)
    }
  }
  const mobileRelationHandler = (e) => {
    if (e.target.value) {
      setMobileRelation(e.target.value)
    }
  }
  const mobileHandler = (e) => {
    let value = e.target.value
    if (value.length <= 10) {
      const sanitizedValue = value.replace(/^0+/, ""); // Remove non-numeric characters
      setMobile(sanitizedValue)
    }
  }
  const emailHandler = (e) => {
    let value = e.target.value.replace(/\s/g, "");
    if (value.length < 40) {
      setEmail(value)
    }
  }
  const dobHandler = (e) => {
    setDob(e.target.value)
  }

  const updateHandler = (e) => {
    e.preventDefault();
    if (!mobile) {
      notify("error", "Please enter Mobile Number");
      return;
    }
    if (mobile.length !==10) {
      notify("error", "Please enter valid Mobile Number");
      return;
    }
    if (!mobileRelation) {
      notify("error", "Please enter Mobile Relation");
      return;
    }
    if (!email) {
      notify("error", "Please enter Email Id");
      return;
    }
    if (!mobileRelation) {
      notify("error", "Please enter Email Relation");
      return;
    }
    if (!dob) {
      notify("error", "Please select Dob");
      return;
    }

    // let reqBody = {
    //   iin: userDetail?.iin,
    //   email: email,
    //   dob: formatedDob,
    //   phone: mobile,
    //   mobile_relation: mobileRelation,
    //   email_relation: emailRelation
    // }

    let reqBody = {
      iin: userDetail?.iin,
      email:"",
      dob: "",
      phone: "",
      mobile_relation: "",
      email_relation: ""
    }

 
    const userdata = JSON.parse(localStorage.getItem("user"));
    let localDate = convertMonthInNumberFormat(userdata?.dob)
    let isChanged = false;

    if (dob != localDate) {
      let formatedDob = formatDate(dob);
      reqBody["dob"] = formatedDob;
      isChanged = true;
    }

    if (email != userdata.email || emailRelation != userdata.email_rel) {
      reqBody["email"] = email;
      reqBody["email_relation"] = emailRelation;
      isChanged = true;
    }

    if (mobile != userdata.mobile || mobileRelation != userdata.mobile_rel) {
      reqBody["phone"] = mobile;
      reqBody["mobile_relation"] = mobileRelation
      isChanged = true;
    }

    if (!isChanged) {
      notify("info","nothing we changed")
      return;
    }

    
    updateUserProfile(reqBody, headerState).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        // notify("success", "Updated succssfully"); 
        setSuccessMessage(res.data?.data)
        setShow(false)
        setSuccessMessagePopUp(true)
      }
      setDob(formattedDate)
      setEmail(userDetail?.email)
      setMobile(userDetail?.mobile)
      setUserDetail(userDetail)
      setEmailRelation(userDetail?.email_rel)
      setMobileRelation(userDetail?.mobile_rel)
    });
  }

  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  };
  return (
    <>
      <Modal show={show} onHide={() =>
        setShow(false)} className="border-red" centered scrollable={true} >
        <Modal.Header closeButton >
          <Modal.Title className='fs-5'>Profile Update</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form onSubmit={updateHandler}>
            <div className="">
              <Form.Group>
              <Form.Label>
                        Molile No.<span className="text-danger">*</span>
                      </Form.Label>
                <Form.Control className="single-drop border-0 mb-3" type="text" required placeholder="Enter New Mobile Number *" value={mobile} onChange={mobileHandler} />
              </Form.Group>
              <div>
              <Form.Label>
                        Mobile Relation.<span className="text-danger">*</span>
                      </Form.Label>
                <Form.Select
                  className="bg-c mb-3 single-drop"
                  value={mobileRelation}
                  onChange={mobileRelationHandler}
                  
                >
                  
                  {relationsData &&
                    relationsData.map((el) => (
                      <option
                        value={el.FAMILY_CODE}
                        key={el.FAMILY_CODE}
                      >
                        {el.FAMILY_DESC}
                      </option>
                    ))}
                </Form.Select>
              </div>
              <Form.Group>
              <Form.Label>
                        Email Id.<span className="text-danger">*</span>
                      </Form.Label>
                <Form.Control className="single-drop border-0 mb-3" value={email} onChange={emailHandler} type="email" placeholder="Enter New Email ID *" />
              </Form.Group>
              <div>
              <Form.Label>
                        Email Relation.<span className="text-danger">*</span>
                      </Form.Label>
                <Form.Select
                  className="bg-c mb-3 single-drop"
                  value={emailRelation}
                  onChange={emailRelationHandler}
                >
                
                  {relationsData &&
                    relationsData.map((el) => (
                      <option
                        value={el.FAMILY_CODE}
                        key={el.FAMILY_CODE}
                      >
                        {el.FAMILY_DESC}
                      </option>
                    ))}
                </Form.Select>
              </div>
              <Form.Group>
              <Form.Label>
                        Date of birth.<span className="text-danger">*</span>
                      </Form.Label>
                <Form.Control className="single-drop border-0" id="anyWhere10"
                  onClick={() => dateAny("anyWhere10")} value={dob} onChange={dobHandler} type="date" placeholder="Enter New Dob*" />
              </Form.Group>
              <div className="text-center">
                <Form.Group >
                  <button type='submit' className="otp-btn mt-3 br-15"  >Update</button>
                </Form.Group>
              </div>
            </div>


          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0'>
        </Modal.Footer>
      </Modal>
      <SuccessNotification show={successMessagePopUp} setShow={setSuccessMessagePopUp} successMessage={successMessage}/>
    </>
  )
}
export default ChangeMobileNo