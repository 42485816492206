import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../assets/images/others/successfullynew.svg";
const TransactSuccessForRedumption = ({ show, setShow, confirmData,  confirmationDataArr }) => {

  return (
    <>
      <div className="" on>
        <Modal
          onHide={()=>setShow(false)}
          show={show}
          size="lg"
          className="invest-gray"
          centered
          backdrop="static"
        keyboard={false}
        >
           <Modal.Header closeButton className="border-0">
           <Modal.Title></Modal.Title>
        </Modal.Header>
          <Modal.Body  className="px-4 text-center">
          <img src={success}  className="img-fluid mw-170 pb-2"/>
    <h3 class="text-successc font-weight-500 py-2 "> Order Placed</h3>
            {confirmationDataArr.length === 0 && <div className="row">
              <div className="col-md-5 border-right">
                <table className="table mx-3 text-start fs-15 fs-sm-14">
                  <tr>
                    <td>Unique Number </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Unique_No}</td>
                  </tr>
                  <tr>
                    <td>Trxn Number </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Trxn_No}</td>
                  </tr>

                  <tr>
                    <td>Amount </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Amt}</td>
                  </tr>
                  <tr>
                    <td>Status </td>
                    <td>:</td>
                    <td className="ps-3">Success</td>
                  </tr>
                </table>
              </div>
              <div className="col-md-7">
                <table className="table mx-3 text-start fs-15 fs-sm-14">
                 <tr>
                    <td>Folio </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData?.Folio  ? confirmData?.Folio:""}</td>
                  </tr> 
                 
                  <tr>
                    <td>Fund</td>
                    <td>:</td>
                    <td className="ps-3">{confirmData?.Fund}</td>
                  </tr>

                  {
                    confirmData.Source_Scheme_Name ? <> <tr>
                      <td>Source Scheme </td>
                      <td>:</td>
                      <td className="ps-3">
                        {confirmData?.Source_Scheme_Name}
                      </td>
                    </tr>
                      <tr>
                        <td>Target Scheme </td>
                        <td>:</td>
                        <td className="ps-3">
                          {confirmData?.Target_Scheme_Name}
                        </td>
                      </tr></> : <tr><td>Scheme Name </td>
                      <td>:</td>
                      <td className="ps-3">
                        {confirmData?.Scheme_Name}
                      </td>
                    </tr>
                  }

                </table>
              </div>
            </div>}

            {
              confirmationDataArr.length > 0 && confirmationDataArr.map((item) => (
                <div className="row">
                  <div className="col-md-5 border-right">
                    <table className="table mx-3 text-start fs-15 fs-sm-14">
                      <tr>
                        <td>Unique Number </td>
                        <td>:</td>
                        <td className="ps-3">{item.Unique_No}</td>
                      </tr>
                      <tr>
                        <td>Trxn Number </td>
                        <td>:</td>
                        <td className="ps-3">{item.Trxn_No}</td>
                      </tr>

                      <tr>
                        <td>Amount </td>
                        <td>:</td>
                        <td className="ps-3">{item.Amt}</td>
                      </tr>
                      <tr>
                        <td>Status </td>
                        <td>:</td>
                        <td className="ps-3">Successful</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-7">
                    <table className="table mx-3 text-start fs-15 fs-sm-14">
                     <tr>
                    <td>Folio </td>
                    <td>:</td>
                    <td className="ps-3">{item?.Folio ? item?.Folio:"N/A"}</td>
                  </tr> 
                      <tr>
                        <td>Fund</td>
                        <td>:</td>
                        <td className="ps-3">{item?.Fund}</td>
                      </tr>
                    

                      <tr>
                        <td>Scheme Name </td>
                        <td>:</td>
                        <td className="ps-3">
                          {item.Scheme_Name}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))
            }


            <hr />
            <div className="py-2">
              <p className="text-black fs-13">
                <span className="text-red text-left fw-500 fs-16">Note: </span>
                Payment and/or transaction authorization link has been sent on registered mail id(s) or mobile no(s) of primary/all the holder(s), The Link shall remain active for next 48 hours. Kindly Authorize
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0"></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default TransactSuccessForRedumption;
