
import { apis, request } from "../request";

export const getNotifications = async (headers) => {
    return await request(apis.get_getNotifications, null, headers);
  };

  export const markNotifications = async (body,headers) => {
    return await request(apis.post_markNotifications, body,headers);
  };
  export const deleteNotifications = async (body,headers) => {
    return await request(apis.post_deleteNotifications, body,headers);
  };