export const validation = (name,value,setError)=>{
    if(value === "" && name!=="micrNo"){
        setError((old) => {
            return {
                ...old,
                [name]: "Mandatory Field"
                // [name]: name.slice(0, 1).toUpperCase() + name.slice(1, name.length) + ' is required'
            }
        })
    }
    else{
        setError((old) => {
            return {
                ...old,
                [name]: ''
            }
        })
    }
}