import { apis, request } from "../request";

export const getAmcList = async (headers) => {
  return await request(apis.get_amcList, null, headers);
};

export const getSchemeList = async (body, headers) => {
  return await request(apis.post_getScheme, body, headers);
};

export const getFolioByAmc = async (body, headers) => {
  return await request(apis.post_getFolioByAmc, body, headers);
};
export const getNSEProductCode = async (body, headers) => {
  return await request(apis.post_getNSEProductCode, body, headers);
};
export const newPurchase = async (body, headers) => {
  return await request(apis.post_newPurchase, body, headers);
};
export const amcByProfile = async (body, headers) => {
  return await request(apis.post_amcByProfile, body, headers);
};
export const folioViaAmc = async (body, headers) => {
  return await request(apis.post_folioViaAmc, body, headers);
};
export const schemeViaFolio = async (body, headers) => {
  return await request(apis.post_schemeViaFolio, body, headers);
};
export const usersBank = async (body, headers) => {
  return await request(apis.post_usersBank, body, headers);
};
export const saveNseTransaction = async (body, headers) => {
  return await request(apis.post_saveNseTransaction, body, headers);
};
export const multiplePurchase = async (body, headers) => {
  return await request(apis.post_multiplePurchase, body, headers);
};
export const redeem = async (body, headers) => {
  return await request(apis.post_redeem, body, headers);
};
export const getTransactionByFolioProductcode = async (body, headers) => {
  return await request(apis.post_getTransactionByFolioProductcode, body, headers);
};
export const switchTransact = async (body, headers) => {
  return await request(apis.post_switch, body, headers);
};
export const swpTransact = async (body, headers) => {
  return await request(apis.post_swp, body, headers);
};
export const stpTransact = async (body, headers) => {
  return await request(apis.post_stp, body, headers);
};
export const sipTransact = async (body, headers) => {
  return await request(apis.post_sip, body, headers);
};
export const nseTransactionReport = async (body, headers) => {
  return await request(apis.post_nseTransactionReport, body, headers);
};
export const getMandateList = async (body, headers) => {
  return await request(apis.post_getMandateList, body, headers);
};
export const getUserProfileViaPortfolio = async (body, headers) => {
  return await request(apis.post_getUserProfileViaPortfolio, body, headers);
};
export const getElssScheme = async (body, headers) => {
  return await request(apis.post_getElssScheme, body, headers);
};

export const getPendingMandate = (body,headers) => {
  return request(apis.post_pendingMandateList, body, headers);
}

export const getRetriggerMandate = (body,headers) => {
  return request(apis.post_retriggerMandate,body,headers)
}
export const redeemMulti = (body,headers) => {
  return request(apis.post_redeemMulti,body,headers)
}
export const switchMulti = (body,headers) => {
  return request(apis.post_switchMulti,body,headers)
}
export const getNfos = (headers) => {
  return request(apis.get_getNfos,null,headers)
}
