export const bankShortName = {
    "Current Account":"CA",
    "Cash/Credit":"CC",
    "Foreign Currency Non Resident":"FCNR",
    "Non Resident External Account":"NRE",
    "Non Resident Ordinary":"NRO",
    "Overdraft Account":"OD",
    "Post Office Savings Bank Account":"PSB",
    "Savings Account":"SB",
    
}