import React, { useState, useContext } from 'react';
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import img_no_investment_transaction from "../../src/assets/images/img_no_investment_transaction.png";
import LumpsumForm from '../components/lumpsum-form';
import { useEffect } from "react";
import Cart_Delete from '../components/delete-confirmation';
import { getRightSchemesNse, rightSchemeDurationRisk } from "../apisMethods";
import { globalInfo } from '../App';
import { CheckPathRediction } from './helpers/redirection';
import { apis } from '../request';

const GetRightScheme = () => {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo)
  const [lumpsumformShow, setLumpsumformShow] = useState(false);
  const [headerState, setHeaderState] = useState({});
  const [years, setYears] = useState([])
  const [allRiskData, setAllRiskData] = useState([])
  const [durationValues, setDurationValues] = useState('')
  const [durationValuesError, setDurationValuesError] = useState('')
  const [riskProfile, setRiskProfile] = useState('')
  const [riskProfileError, setRiskProfileError] = useState('')
  const [rightSchemes, setRightSchemes] = useState([])
  const [userDetails, setUserDetails] = useState({})


  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userdata)
    setHeaderState(header)
    getRightSchemeDurationRiskCall(header)
  }, [])

  const getRightSchemeDurationRiskCall = (header) => {
    rightSchemeDurationRisk(header).then(res => {
      if (!res.data) {
      } else {
        let finalArray = [];
        let finalDataRisk = []

        res.data?.dataDuration.forEach(el => {
          finalArray.push({ label: el.duration, value: el.durationValues })
        })
        res.data?.dataRisk.forEach(el => {
          if (!el.Constellation) {
            return
          }
          finalDataRisk.push({ label: el.Constellation, value: el.duration })
        })
        finalArray.sort((a, b) => a.value - b.value);
        setYears(finalArray)
        finalDataRisk.sort((a, b) => a.value - b.value);
        setAllRiskData(finalDataRisk)
      }


    })
  }

  const submitHandler = () => {
    if (!riskProfile && !durationValues) {
      setDurationValuesError("Please select Horizon Year")
      setRiskProfileError("Please select Risk Profile")
      return
    }
    if (!riskProfile) {
      setDurationValuesError("")
      setRiskProfileError("Please select Risk Profile")
      return
    }
    if (!durationValues) {
      setDurationValuesError("Please select Horizon Year")
      setRiskProfileError("")
      return
    }
    setLoader('block')
    let reqBody = {
      durationValues: durationValues.toString(),
      RiskProfile: riskProfile.toString()
    }

    getRightSchemesNse(reqBody, headerState).then(res => {
      if (!res.data) {
        setRightSchemes([])
      } else {
        setRightSchemes(res.data?.data)
      }
      setLoader('none')
    })
  }
  const deleteData = (id) => {
    rightSchemes.splice(id, 1);
    setRightSchemes([...rightSchemes])
  }

  const continueLumpsum =  (check) => {
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    }else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
 
      navigate("/dashboard/Profile-creation");
    }  else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      setLumpsumformShow(true)
    } 
  }

  const showGraph = (item,year)=>{
    console.log("=========",item);
    item["annualizedYear"] =year
        navigate("/dashboard/nav-single",{state:item})
  }  
  
  return (
    <>
      <div className="wrapper get-scheme">
        <div className="px-lg-5 px-smc-1">
          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Get Right Scheme
              </li>
            </ol>
          </nav>
          <div className="row px-lg-4 pt-4">
            {/* Area Chart */}
            <div className="col-xl-12 col-lg-12 mt-2 pe-4">
              <div className="card shadowc bg-gray mb-4 p-1 br-38">
                <div className="card-body pe-3">
                  <div className="row align-items-stretch">
                    <div className="col-lg-4 col-md-6  mb-4">
                      <label>
                        <span className="text-black fs-sm-14">
                          
                          Select Your Investment Horizon in Years
                        </span>
                      </label>
                      <Select
                        className="bg-c mt-1"
                        required
                        options={years}
                        onChange={(e) => setDurationValues(e.value)}
                      />
                      <small className="text-danger pull-left">
                        {durationValues ? "" : durationValuesError}
                      </small>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <label>
                        <span className="text-black fs-sm-14">
                          
                          Select Your Risk Profile
                        </span>
                      </label>
                      <Select
                        className="bg-c mt-1"
                        required
                        options={allRiskData}
                        onChange={(e) => setRiskProfile(e.value)}
                      />
                      <small className="text-danger pull-left">
                        {riskProfile ? "" : riskProfileError}
                      </small>
                    </div>
                    <div className="col-lg-2 col-md-3 align-self-center mt-2">
                      <Link to="/dashboard/know-your-risk-profile">
                        Know Your Risk Profile
                      </Link>
                    </div>
                    <div className="col-lg-2 col-md-4 align-self-center mt-3 mb-3">
                      <a className="btn-custom" onClick={submitHandler}>
                        Continue
                      </a>
                    </div>
                  </div>

                  <Tabs>
                    {/* <div className="row justify-content-center mt-4">
                      <div className="col-md-4 mb-4">
                        <TabList>
                          <Tab>Equity</Tab>
                          <Tab>Debt</Tab>
                        </TabList>
                      </div>
                    </div> */}

                    {rightSchemes.length > 0 ? (
                      <div className="row">
                        <div className="col-xl-10 col-lg-10 offset-lg-1 col-md-12">
                          <div className="card shadowc br-50">
                            <div className="border-bottom py-4 px-5 d-flex flex-row align-items-center justify-content-between">
                              <h6 className="m-0 fw-bold text-red">
                                Product Basket
                              </h6>
                            </div>
                            <div className="card-body">
                              <div className="col-12 px-lg-4 table-responsive">
                                <table class="table custom text-table-black">
                                  {rightSchemes.length > 0 ? (
                                    <thead>
                                      <tr>
                                        <th scope="col" colSpan="2">
                                          Recommended Schemes
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                  ) : (
                                    ""
                                  )}
                                  <tbody>
                                    {rightSchemes.length > 0 ? (
                                      rightSchemes.map((data, i) => {
                                        return (
                                          <>
                                            
                                            <tr className="text-black">
                                              <td>
                                                <img
                                                  src={
                                                    // `${apis.imageLink}growth.png`
                                                    `${apis.imageLink}${data?.AMC_CODE}.png`
                                                  }
                                                  alt="img"
                                                  className="img-fluid min-w-3em mx-wd-45"
                                                />
                                              </td>
                                              <td className="min-w-35em onHover"onClick={()=>showGraph(data,3)} >
                                                  {data.PRODUCT_LONG_NAME}
                                               
                                              </td>
                                              {rightSchemes.length > 1 ?
                                              <td
                                                className="min-w-35em"
                                                style={{ cursor: "pointer" }}
                                              >
                                                
                                                <Cart_Delete
                                                  id={i}
                                                  deleteData={deleteData}
                                                />
                                              </td>:""}
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <section>
                                        <div className="container-fluid">
                                          <div className="row justify-content-center">
                                            <div className="col-md-11 py-3">
                                              <div className="sipstpswp_error py-5 text-center">
                                                <div className="sipstpswp_error-img">
                                                  <img
                                                    src={
                                                      img_no_investment_transaction
                                                    }
                                                    className="img-fluid mw-170"
                                                  />
                                                </div>
                                                <h5 className="pt-3 text-red fw-600">
                                                  No schemes available
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {rightSchemes.length > 0 ? (
                                <div className="col-12 mt-4 text-lg-end text-center mb-3">
                                  <a
                                    className="btn-custom text-color"
                                    onClick={continueLumpsum}
                                  >
                                    Continue
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End of Main Content */}

      <div class="px-2 pb-f" role="alert">
        <p className="text-disclaimer text-black text-center fs-13 fs-sm-10">
          *Mutual Fund investments are subject to market risks, please read the
          scheme related documents carefully before investing.
        </p>
      </div>

      <LumpsumForm
        show={lumpsumformShow}
        setShow={setLumpsumformShow}
        rightSchemes={rightSchemes}
      />
    </>
  );
}
export default GetRightScheme