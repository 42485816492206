

export const FinancialYear = () => {
  const data = [];
  const d = new Date();
  let year = d.getFullYear() + 1;
  year = Number(year)
  let m = d.getMonth() + 1
  for (let i = 40; i > 0; i--) {
    year = year - 1;
    let a = { value: m > 4 ? `${year} -${year + 1}` : year - 1 + "-" + year, label: m > 4 ? `${year} - ${year + 1}` : year - 1 + "-" + year };
    data.push(a);
  }
  return data
}

export const disableFutureDate = () => {
  const currentDate = new Date();

  // Get the current date components
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  // Format the date as "YYYY-MM-DD"
  return `${year}-${month}-${day}`;
}

export const disableFutureYearMonth = () => {
  const currentDate = new Date();

  // Get the current date components
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  // Format the date as "YYYY-MM-DD"
  return `${year}-${month}`;
}

export const formatDate = (date) => {
  const inputDate = new Date(date);
  const monthAbbreviations = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  let day = inputDate.getDate();
  day = (day < 10) ? `0${day}` : day;
  const month = monthAbbreviations[inputDate.getMonth()];
  const year = inputDate.getFullYear();


  return `${day}-${month}-${year}`;
}
export const removeDashFromDate = (value) => {
  let parts = value.split("-");
  const convertedDate = parts[0] + parts[1] + parts[2];
  return convertedDate;
}

export const addSevenDaysFromCurrent = () => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
  const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero 
  let current = `${year}-${month}-${day}`;

  return current;
};
export const add15DaysFromCurrent = (date) => {
  let currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 16);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
  const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero 
  let current = `${year}-${month}-${day}`;

  return current;
};
export const addThirtyDaysFromCurrent = (mandateFromDate) => {
  let currentDate = new Date(mandateFromDate);
  currentDate.setDate(currentDate.getDate() + 30);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
  const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero 
  let current = `${year}-${month}-${day}`;

  return current;
};

export const convertMonthInNumberFormat = (inputDate) => {
  const months = {
    'JAN': '01',
    'FEB': '02',
    'MAR': '03',
    'APR': '04',
    'MAY': '05',
    'JUN': '06',
    'JUL': '07',
    'AUG': '08',
    'SEP': '09',
    'OCT': '10',
    'NOV': '11',
    'DEC': '12'
  };

  const parts = inputDate.split('-');
  const day = parts[0];
  const month = months[parts[1].toUpperCase()];
  const year = parts[2];

  return `${year}-${month}-${day}`;
}
export const addDashInDate = (value) => {
  const date = value.slice(0, 4) + "-" + value.slice(4, 6) + "-" + value.slice(6, 8);
  return date

}

export const addThreeMonthsFromCurrent = () => {
  let currentDate = new Date();

  // Add 3 months to the current date
  currentDate.setMonth(currentDate.getMonth() + 3);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
  const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero
  const current = `${year}-${month}-${day}`;

  return current;
};



// convert string month in normal html selected format


export const stringMonthInNumber = (inputDate) => {
  console.log("inputDate", inputDate)
  // Define an array of month abbreviations
  var monthAbbreviations = {
    'JAN': '01',
    'FEB': '02',
    'MAR': '03',
    'APR': '04',
    'MAY': '05',
    'JUN': '06',
    'JUL': '07',
    'AUG': '08',
    'SEP': '09',
    'OCT': '10',
    'NOV': '11',
    'DEC': '12',
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12',
  };

  // Split the input date into day, month abbreviation, and year
  var parts = inputDate?.split('-');

  // Convert the month abbreviation to a numeric month
  var month = monthAbbreviations[parts[1]];

  // Create a new date in the desired format "YYYY-MM-DD"
  var newDate = parts[2] + '-' + month + '-' + parts[0];
  return newDate
}

export const addYears = (value, year = 30) => {
  const currentDate = new Date(value);

  const futureDate = new Date(currentDate);
  futureDate.setFullYear(currentDate.getFullYear() + year);
  const months = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ];

  // Format the date
  const currentDateFormate = `${("0" + currentDate.getDate()).slice(-2)}-${months[currentDate.getMonth()]}-${currentDate.getFullYear()}`;
  const thirtyYearAdded = `${("0" + futureDate.getDate()).slice(-2)}-${months[futureDate.getMonth()]}-${futureDate.getFullYear()}`;
  return thirtyYearAdded
}

export const currentDateForRemovePast = () => {
  let currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
  const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero
  const current = `${year}-${month}-${day}`;

  return current;
}

export const addThirtyFromGivenDate = (date) => {
  var currentDate = new Date(date);
  // Adding 30 years to the current date
  currentDate.setFullYear(currentDate.getFullYear() + 30);

  // Formatting the output as "YYYY-MM-DD"
  var formattedDate = currentDate.toISOString().split('T')[0];

  return formattedDate;
}

export const formatDateString = (date) => {
  const inputDate = new Date(date);
  const monthAbbreviations = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  let day = inputDate.getDate();
  day = (day < 10) ? `0${day}` : day;
  const month = monthAbbreviations[inputDate.getMonth()];
  const year = inputDate.getFullYear();


  return `${day}-${month}-${year}`;
}

export const convertInDash = (date) => {
  if (date?.length === 8) {
    let formattedDate = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
    return formattedDate
  } else {
    return date
  }
}

export const addOnlyYears = (dates, years) => {
  let date = new Date(dates);
  date.setFullYear(date.getFullYear() + Number(years));
  return date.toISOString().split('T')[0];
}


// 2024-07-25T00:00:00.000Z  convert in  25-July-2024
export const inString = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};