import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useLocation, useNavigate } from "react-router-dom";
import { getHistoricNav } from "../apisMethods";

const Nav_Chart = ({series}) => {

  const location = useLocation()
  const navigate = useNavigate()
 
  const options = {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    // title: {
    //   text: 'Stock Price Movement',
    //   align: 'left'
    // },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      labels:  {
       show:false
      },
      // title: {
      //   text: 'Price'
      // },
    },
    xaxis: {
      type: 'category',
      labels: {
        show:false
      }
    },
    tooltip: {
      shared: false,
      y:  {
        formatter: function (val) {
          return val;
        }
      }
    }
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("token");
  //   const header = {
  //     headers:
  //       { Authorization: `Bearer ${token}` }
  //   };
  //   // setHeaders(header);
  //   console.log("---",location.state);
  //   fetchGraphData(header)
  // }, [])

  // const fetchGraphData = (header) => {
  //   if (location?.state?.Schemecode) {
  //     getHistoricNav(location.state, header).then((res) => {
  //       if (res.data) {
  //         let dates = []
  //         for (let i = res.data.navhistory.length-1; i > 0; i--) {
  //           dates.push({x:new Date(res.data.navhistory[i].navdate).toLocaleDateString('en-GB'), y:Number(res.data.navhistory[i].adjustednav_c.toFixed(2))})
  //         }
  //         // setSeries(dates)
  //         series[0].data = dates
  //         console.log("datesss",series);
  //         setSeries([...series])
  //       }
  //     })

  //   } else {
  //     navigate("/dashboard")
  //   }
  // }





 

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart" style={{ width: "100%" }} >
          <Chart options={options} series={series} type="area" height={350} />
        </div>
      </div>
    </div>
  );

}
export default Nav_Chart;
