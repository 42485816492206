import { createContext, useContext, useEffect, useState } from 'react';
import { getUserBasket, removeBasketData, saveUpdateBasket } from '../../apisMethods';
import { notify } from '../toastCreater';
import { globalInfo } from "../../App"
import { useNavigate } from 'react-router-dom';

const CartCount = createContext();

export const CartCountProvide = ({ children }) => {
    const navigate = useNavigate()
    const [cartData, setCartData] = useState(null);
    const [totalCart, setTotalCart] = useState([]);
    const [sipCart, setSipCart] = useState([]);
    const [lumpsumCart, setLumpsumCart] = useState([]);
    const { setLoader } = useContext(globalInfo)


    const deleteData = (ISIN) => {
        setLoader("block")
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        removeBasketData({ ISIN }, header).then(res => {
            if (!res.data) {
                notify("error", "Something went wromg")
            } else {
                getCartData()
                notify("success", "Deleted Succesfully")
            }
            setLoader("none")
        })
    }

    const getCartData = () => {
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        setLoader("block")
        getUserBasket(header).then((res) => {
            console.log("=====");
            
            setLoader("none")
            if (!res.data) {
                setCartData(0)
                setTotalCart([])
                setSipCart([])
                setLumpsumCart([])
            } else {
                setCartData(res.data.data.length)
                setTotalCart(res.data.data)
                setSipCart(res.data?.data.filter(item=>item.SIP_ALLOWED==="Y"))
                setLumpsumCart(res.data?.data.filter(item=>item.PURCHASE_ALLOWED==="Y"))
            }
              setLoader("none")
        })
    }

    const addToCart = (data, SIP = true,) => {
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        if(!token){
            navigate("/")
            return
        }
        if(data?.PURCHASE_ALLOWED ==="Y" ||    data?.SIP_ALLOWED==="Y" ){
            let reqBody = {
                // productcode: data?.PRODUCT_CODE ? data?.PRODUCT_CODE : data?.productcode,
                Schemecode: data?.schemecode ? data?.schemecode : data?.Schemecode,
                ISIN: data?.ISIN ? data.ISIN : data?.isin,
                s_name: data?.s_name ? data.s_name : data?.PRODUCT_LONG_NAME,
                SIP,
                LUMPSUM: true
            }
    
            setLoader("block")
            saveUpdateBasket(reqBody, header).then((res) => {
                if (!res.data) {
                    setLoader("none")
                    notify("error", "Already added to cart..!")
                } else {
                    getCartData()
                    notify("success", "Added to card successfully")
                }
                setLoader("none")
            })
        }else{
            notify("error","This scheme is not allowed for transactions..!")
        }

    }

    const updateCart = newData => {
        setCartData(newData);
    };


    return (
        <CartCount.Provider value={{ cartData,totalCart,sipCart,lumpsumCart, updateCart, addToCart, getCartData, deleteData }}>
            {children}
        </CartCount.Provider>
    );
};

export const useCount = () => {
    return useContext(CartCount);
};
