import React, { useState, useEffect,useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TransactSuccess from "./transact-success";
import SWW from "./something-went-wrong";
import { globalInfo } from "../App";

import {
  getUserProfileViaPortfolio,
  getNSEProductCode,
  saveNseTransaction,
  schemeViaFolio,
  switchTransact
} from "../apisMethods";
import { notify } from "../pages/toastCreater";
const Switch_Popup = ({ show, setShow, data }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [assetClass, setAssetClass] = useState("1");
  const [switchType, setSwitchType] = useState("Amount");
  const [option, setOption] = useState("1");
  const [targetSchemeDataArr, setTargetSchemeDataArr] = useState([]);
  const [targetSchemeLoader, setTargetSchemeLoader] = useState(false);
  const [targetScheme, setTargetScheme] = useState('');
  const [schemeProductCodeForNse, setSchemeProductCodeForNse] = useState('');
  const [targetNseData, setTargetNseData] = useState({});
  const [amount, setAmount] = useState();
  const [headerState, setHeaderState] = useState(false);
  const [useProfileData, setUseProfileData] = useState({});


  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    Object.keys(data).length > 0 && userProfileDataCall()

  }, [data])


  const userProfileDataCall = async () => {
    let reqBodyForIin = {
      folio: data?.folio,
      productcode: data?.productcode,
      rta: data?.rta,
      gpan: data?.gpan ? data?.gpan : ""
    }

    let res = await getUserProfileViaPortfolio(reqBodyForIin, headerState)

    if (!res.data) {
      return
    } else {
      setUseProfileData(res.data?.data)
      targetSchemeDataCall(res.data?.data?.amccode)
    }
  }
  const targetSchemeDataCall = (amccode) => {
    const reqBody = {
      amc_code: amccode,
      myscheme: false,
      rta: data.rta,
      folio: data.folio,
      opt_code: Number(option),
      asset_code: Number(assetClass),
      isAssetOptCodeAvailable: true
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        // notify("warn", "this scheme is not available")
        setTargetSchemeDataArr([])
      } else {
        const filteredArray = res.data?.data.filter(item => item.productCode !== data?.productcode);
        setTargetSchemeDataArr(filteredArray)
      }
      setTargetSchemeLoader(false)
    })
  }

  const assetClasHandler = (e) => {
    setAssetClass(e.target.value);

    setTargetSchemeLoader(true);
    const reqBody = {
      amc_code: useProfileData.amccode,
      myscheme: false,
      rta: data.rta,
      folio: data.folio,
      opt_code: Number(option),
      asset_code: Number(e.target.value),
      isAssetOptCodeAvailable: true
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        // notify("warn", "this scheme is not available")
      } else {
        setTargetSchemeDataArr(res.data?.data)
      }
      setTargetSchemeLoader(false)
    });
  };

  const optionHandler = (e) => {
    setOption(e.target.value);
    setTargetSchemeLoader(true);
    const reqBody = {
      amc_code: useProfileData.amccode,
      myscheme: false,
      folio: data.folio,
      rta: data.rta,
      opt_code: Number(e.target.value),
      asset_code: Number(assetClass),
      isAssetOptCodeAvailable: true
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        // notify("error", "Something went wrong")
        setTargetSchemeDataArr([]);
      } else {
        setTargetSchemeDataArr(res.data?.data);
      }
      setTargetSchemeLoader(false);

    });
  };

  const changeTargetSchemehandler = (e) => {
    // let targetData = JSON.parse(e.target.value);
    // setTempTargetScheme(targetData)
    let reqBody = {
      schemecode: Number(e.target.value),
    };
    setTargetScheme(e.target.value);
    getNSEProductCode(reqBody, headerState).then((res) => {
      if (!res.data) {
        setTargetScheme("")
        // setTempTargetScheme({})
        notify("warn", "This scheme is not available please select another")
      } else {
        setTargetNseData({
          productcode: res.data?.data?.productcode,
          reinvestTag: res.data?.data?.REINVEST_TAG,
        });

        getNSEProductCode({ schemecode: useProfileData?.schemecode }, headerState).then((nres) => {
          if (!nres.data) {
            setSchemeProductCodeForNse('')
            setTargetScheme("")
            notify("warn", "This scheme is not available please select another")
          } else {
            setSchemeProductCodeForNse(nres.data?.data?.productcode);
          }

        });
      }

    });

  };

  const amountHandler = (e) => {
    if (switchType === "Y") {
      setAmount(data?.unit)
      return
    }
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    setAmount(sanitizedValue);
  };

  const orderSwitch = () => {
    let checkAmount = Number(amount);
    if (!checkAmount) {
      notify("warn", "Please enter amount.");
      return;
    }
    if (!targetScheme) {
      notify("warn", "Please select scheme.");
      return;
    }

    if ((switchType === "Unit" || switchType === "Y") && checkAmount <= 0) {
      notify("error", "Unit should not be less than 1.");
      return
    }
    if (checkAmount > data?.unit && switchType === "Unit") {
      notify("warn", "Unit should not be graeter than balance Unit.");
      return;
    }
    if (
      checkAmount > Number(data?.currentvalue) &&
      switchType === "Amount"
    ) {
      notify("warn", "Amount should not be graeter than Market Value.");
      return;
    }
    setLoader("block")
    const reqBody = {
      email: useProfileData?.email,
      tax_status: useProfileData?.taxstatus,
      trxn_acceptance: "ALL",
      amc: useProfileData.amccode,
      product_code: schemeProductCodeForNse,
      //incase of amount selected for switching minimum amount is Rs.1000
      amt_unit: amount,
      remark: "",
      folio: data?.folio,
      amt_unit_type: switchType === "Y" || switchType === "Unit" ? "Unit" : "Amount",
      all_units: switchType !== "Y" ? "N" : "Y",
      //Static
      input_ref_no: "",
      //Static
      trxn_execution: "",
      target_product_code: targetNseData?.productcode,
      //Static
      target_ft_acc_no: "",
      //reinvest flag of target scheme
      reinvest: targetNseData?.reinvestTag,
      iin: useProfileData?.iin,
    };
    switchTransact(reqBody, headerState).then((res) => {
      if (res.data?.success === true) {
        let nseData = {
          Unique_No: res.data.data.Unique_No,
          Trxn_No: res.data.data.Trxn_No,
          Application_No: res.data.data.Application_No,
          Fund: res.data.data.Fund,
          Target_Scheme_Name: res.data.data.Target_Scheme_Name,
          Source_Scheme_Name: res.data.data.Source_Scheme_Name,
          Target_Scheme: res.data.data.Target_Scheme,
          Amt: res.data.data.Amt,
          Status_Desc: res.data.data.Status_Desc,
          Status_code: res.data.data.Status_code,
          Folio: res.data.data.Folio,
          Platform: "Web",
          transaction_type: "Switch",
          iin: useProfileData.iin,
          Pan: useProfileData.pan,
          Gpan: useProfileData.gpan,
          Joint1pan: useProfileData.Joint1pan,
          Joint2pan: useProfileData.Joint2pan,
          name: useProfileData.name,
        };
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        setConfirmationDataArr([]);
        saveNseTransaction(nseData, headerState).then((res) => {
          setAmount('')
          setTargetNseData({})
          setTargetScheme()
        });
      } else {
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }
setLoader("none")
    });
  }

  return (
    <>
      <Modal show={show} onHide={() =>
        setShow(false)} scrollable={true} className="animate__animated animate__zoomIn animate__fast">
        <Modal.Header closeButton>
          <Modal.Title>Switch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='text-red'>{data.name}</h5>
          {/* <div className="col-3 bg-light-red text-center rounded">
            <p className='py-1 '>{data.assettype}</p>
          </div> */}
          <p className='fs-13 text-red'>{data.scheme}({data.folio})</p>
          <Form className="portfolio">
            <div className="row mb-3">
              <div className="col-md-6">
                <Form.Group controlId="formBasicRadio">
                  <Form.Label>
                    Asset Class <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label="Equity"
                      name="assetClass"
                      value={"1"}
                      id="equity"
                      checked={assetClass === "1"}
                      onChange={assetClasHandler}
                      className="pe-3"
                    />
                    <Form.Check
                      type="radio"
                      label="Debt"
                      name="assetClass"
                      value={"3"}
                      checked={assetClass === "3"}
                      onChange={assetClasHandler}
                      id="debt"
                      className="pe-3"
                    />
                     <Form.Check
                      type="radio"
                      label="Other"
                      name="assetClass"
                      value={"2"}
                      checked={assetClass === "2"}
                      onChange={assetClasHandler}
                      id="other"
                      className="pe-3"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className=" " controlId="formBasicRadio">
                  <Form.Label>
                    Option <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label="Growth"
                      name="options"
                      value={1}
                      onChange={optionHandler}
                      checked={option == 1}
                      id="Growth"
                      className="pe-3"
                    />
                    <Form.Check
                      type="radio"
                      label="IDCW"
                      name="options"
                      value={2}
                      onChange={optionHandler}
                      checked={option == 2}
                      id="IDCW"
                      className="pe-3"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-4" controlId="formBasicDate">
              <Form.Label>Select Scheme </Form.Label>
              <Form.Select aria-label="Default select example" className="border-0 single-drop"
                value={targetScheme}
                disabled={targetSchemeLoader}
                onChange={changeTargetSchemehandler}>
                <option value="select">Select</option>
                {targetSchemeDataArr.length > 0 ? targetSchemeDataArr.map((data) => {
                  return <option value={data.schemecode}>{data.shortName}</option>
                }) : null}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicRadio">
              <Form.Check
                type="radio"
                label="By Amount"
                name="formHorizontalRadios"
                id="ByAmount"
                value={"Amount"}
                onChange={(e) => {
                  setAmount("");
                  setSwitchType(e.target.value);
                }}
                checked={switchType == "Amount"}
                className="pe-3"
              />
              <Form.Check
                type="radio"
                label="By Units"
                value={"Unit"}
                onChange={(e) => {
                  setAmount("");
                  setSwitchType(e.target.value);
                }}
                checked={switchType == "Unit"}
                name="formHorizontalRadios"
                id="ByUnits"
                className="pe-3"
              />
              <Form.Check
                type="radio"
                label="All Units"
                name="formHorizontalRadios"
                value={"Y"}
                onChange={(e) => {
                  setAmount(data?.unit);
                  setSwitchType(e.target.value);
                }}
                checked={switchType == "Y"}
                id="AllUnits"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label className="d-flex justify-content-between">Enter Amount
                {/* <small className="color-small"> Min Amount : 1000</small> */}
              </Form.Label>
              <Form.Control type="text" placeholder={switchType === "Y" ? data.unit : switchType} className="border-0 single-drop" value={amount} onChange={amountHandler} />
              <Form.Text className="text-muted">
                Total Amount : {data.currentvalue} and Total Unit : {data.unit} As On : {new Date(data?.currentDate).toLocaleDateString()}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="danger" onClick={orderSwitch}>
            Order
          </Button>
        </Modal.Footer>
      </Modal>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
    </>
  )
}
export default Switch_Popup;