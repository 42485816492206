import { Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ModalFooter } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
const Select_Scheme = ({ show, setShow, targetSchemeList,onData }) => {
    const [schemeList, setSchemeList] = useState([])
    const [radioButton, setRadioButton] = useState(0)

    useEffect(() => {
        setSchemeList(targetSchemeList)
    }, [targetSchemeList])
    const selectHandler = (e,data,index) => {
        onData(data,index)
    }
  
      

    return (
        <>

            <Modal show={show} onHide={()=>setShow(false)} size="lg">

                <Modal.Body className="text-start">
                    <table className="table align-middle">
                        <thead>
                            <tr className="text-red">
                                <th>Scheme Name  </th>
                                <th>Investment Horizon</th>
                                <th>Average Return</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {schemeList.map((item,index) => {
                                return <tr>
                                    <td>{item.Target_s_name}</td>
                                    <td>{item.investment_horizon} Years</td>
                                    <td>{item.average_return} %</td>
                                    <td> <Form.Check type="radio" name="formHorizontalRadios"   onClick={(e) => selectHandler(e,item,index)} className="pe-3 fs-sm-14" /></td>
                                </tr>
                            })}

                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button className="btn btn-danger " type="button" onClick={setShow} >OK</button>

                </Modal.Footer>

            </Modal>
        </>
    )
}
export default Select_Scheme;