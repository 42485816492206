import React, { useState,useContext } from "react";
import { Link } from "react-router-dom";
import ChangeMobileNo from "../components/change-mobile-no";
import { useEffect } from "react";
import edit from "../assets/images/others/edit-p.png";
import { notify } from "./toastCreater";
import { updateProfilePic } from "../apisMethods";
import { globalInfo } from "../App";
import { useData } from "./helpers/DataProvide";
const Profile = () => {
  const { data, updateData } = useData();
  const {setLoader} = useContext(globalInfo)
  const [editmobile, setEditMobileShow] = useState(false);
  const [dob, setDob] = useState('');
  const [hideEditButton, setHideEditButton] = useState('');
  const [user, setUser] = useState({ displayName: "", email: "" });
  const [image, setImage] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    if (user?.pan && user?.iin) {
      setHideEditButton("d-none");
      if (user?.dob) {
        setDob(user?.dob)
      } else if (user?.dob) {
        setDob(user?.dob)
      } else {
        setDob('')
      }
      setImage(user?.profilePic)
    } else {
      setHideEditButton('d-none')
    }
  }, []);
  const ProfileImageHandler = (e) => {
    const maxFileSizeInMB = 1;
    console.log("e?.target?.files[0]?.type", e?.target?.files[0]?.type);
    const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
    let typeOfImage = e.target?.files[0]?.type
    if (typeOfImage == "image/jpeg" ||typeOfImage == "image/png" ||typeOfImage == "image/jpg") {
      if (e?.target?.files[0]?.size > maxFileSizeInKB) {
        e.target.value=null
        notify("warn", `Size should be less than ${maxFileSizeInMB}MB`);
        return
      }
      let token = localStorage.getItem("token");
      const header = { headers: { Authorization: `Bearer ${token}` } };
      const formData = new FormData();
      formData.append("fileUpload", e.target.files[0]);
      // profileImage(e.target.files[0]);
      setLoader("block")
      updateProfilePic(formData, header).then((res) => {
        if (!res.data) {
          notify("error", "errror")
        } else {
          notify("success", res.data.msg)
          setImage(res.data.data)
          updateData(res.data.data);
          user.profilePic=res.data.data
          localStorage.setItem("user", JSON.stringify(user))
          console.log(user);
        }
        setLoader("none")
      })
    } else {
      notify("warn", "Please select files in JPG or JPEG formats only.");
    }
  }
  const handleFileButtonClick = () => {
   document.getElementById('fileInput').click();
  };

  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-3">
          {/* Page Heading */}
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb-nav d-flex ">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Profile
              </li>
            </ol>
          </nav>

          <section>
            <div className="row justify-content-center pb-2">
              <div className="col-xl-6 col-lg-6 ">
                <div className="card shadow-custom m-sm-auto">
                  {/* Card Body */}
                  <div className="card-body">
                    <form method="post">
                      <div className="">
                        <div className="col-md-12 pb-2">
                          <div className="profile-img ">
                            <img
                              className="img-profile rounded-circle"
                              src={image ? image:"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/userImg.png"}
                              height={100}
                              width={100}
                            />
                          </div>
                          <div className="file red text-center  ">
                            <img
                              src={edit}
                              alt="" className="img-fluid img-profile-p edit-p"
                              onClick={handleFileButtonClick}
                            />
                            <input type="file" id="fileInput" name="file" onChange={ProfileImageHandler} />
                          </div>
                        </div>
                        <div className="px-lg-5 ">
                          <table className="table text-start custom">
                            <tr>
                              <td className="">Name</td>
                              <td>:</td>
                              <th>{user.displayName}</th>
                            </tr>
                            <tr>
                              <td>Mobile Number</td>
                              <td>:</td>
                              <th>
                                {user?.mobile}
                              </th>
                            </tr>
                            <tr>
                              <td>PAN</td>
                              <td>:</td>
                              <th>{user?.pan}</th>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>:</td>
                              <th className="min-w-18em">
                                {user.email}
                                {/* <Link
                                  type="button"
                                  onClick={() => setEditEmailShow(true)}
                                >
                                  
                                  <FaPen />
                                </Link> */}
                              </th>
                            </tr>
                            {/* <tr>
                              <td>IIN</td>
                              <td>:</td>
                              <th>{user?.iin}</th>
                            </tr> */}
                            <tr>
                              <td>Date of Birth</td>
                              <td>:</td>
                              <th>
                                {dob}
                                {/* <Link
                                  type="button"
                                  onClick={() => setEditDobShow(true)}
                                >
                                  
                                  <FaPen />
                                </Link> */}
                              </th>
                            </tr>
                            {/* <tr>
                              <td>Gender</td>
                              <td>:</td>
                              <th>MALE</th>
                            </tr> */}
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ChangeMobileNo show={editmobile} setShow={setEditMobileShow} user={user} />

      {/* <ChangeEmail show={editEmail} setShow={setEditEmailShow} />
      <ChangeDob show={editDob} setShow={setEditDobShow} /> */}
    </>
  );
};
export default Profile;