import Nav from 'react-bootstrap/Nav';
import {NavLink } from 'react-router-dom';
function CalculatorHeader() {
return (
<div className="col-md-12 calculator-h">
  <Nav className="navtop row">
    <div className="col-md-3 mb-3">
      <NavLink  to="/dashboard/sip-calculator" activeClassName="active" ><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/budget.png"} className="new-icon" alt='' />  <span>   SIP Calculator</span></NavLink>
    </div>
    <div className="col-md-3 mb-3">
      <NavLink  to="/dashboard/marriage-calculator" > <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/rings.png"} className="new-icon" alt='' />  <span>Marriage  Planning</span></NavLink>
    </div>
    <div className="col-md-3 mb-3">
      <NavLink to="/dashboard/education-calculator" ><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/education.png"} className="new-icon" alt='' /> <span>Education  Planning</span></NavLink>
    </div>
    <div className="col-md-3">
      <NavLink to="/dashboard/future-value-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/calculator.png"} className="new-icon" alt='' /><span>Future Value Calculator</span>
      </NavLink>
    </div>
    <div className="col-md-3 mt-3">
      <NavLink to="/dashboard/retirement-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/retirement.png"} className="new-icon" alt='' /><span>Retirement Planning</span>
      </NavLink>
    </div>
    <div className="col-md-3 mt-3">
      <NavLink to="/dashboard/emi-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/calculatorfuture.png"} className="new-icon" alt='' /><span>EMI Calculator</span>
      </NavLink>
    </div>
    <div className="col-md-3 mt-3">
      <NavLink to="/dashboard/fd-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/fd.png"} className="new-icon" alt='' /><span>FD Calculator</span>
      </NavLink>
    </div>
    <div className="col-md-3 mt-3">
      <NavLink to="/dashboard/elss-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/elss.png"} className="new-icon" alt='' /><span>ELSS Calculator</span>
      </NavLink>
    </div>
    <div className="col-md-4 mt-4">
      </div>
    <div className="col-md-4 mt-4">
      <NavLink to="/dashboard/swp-calculator" >
        <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/swp.png"} className="new-icon" alt='' /><span>SWP Calculator</span>
      </NavLink>
    </div>
    <div className="col-md-4 mt-4">
      </div>
  </Nav>
</div>
);
}
export default CalculatorHeader;