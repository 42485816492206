import React from "react";

const Footer =() =>{
    return (
        <>
        <section className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="m-0">Copyright © BFC Capital {new Date().getFullYear()}</p>
                    </div>
                </div>
            </div>
        </section>
   
        </>
    )
}
export default Footer