import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Disclaimer from './disclaimer';

function PortfolioContent({show, setShow,content,heading}) {

  return (
    <>
  
   
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          {heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body><div dangerouslySetInnerHTML={{ __html: content }} /><Disclaimer/></Modal.Body>
      </Modal>
    </>
  );
}

export default PortfolioContent;