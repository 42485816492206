import React, { useState } from "react";
import { UpperdivOthers, LoginLogo } from "../components/reusable-content";
import { FaEyeSlash, FaEye, FaArrowLeft } from "react-icons/fa";
import Otp_Input from "../components/otp";
import { sendResetOtp, verifyResetOtp, resetPassword } from "../apisMethods";
import { notify } from "./toastCreater";
import { Validator, validatorsMethods } from "../validations";
import { useNavigate } from "react-router-dom";


const initialFormState = {
  newPassword: "",
  confirm_password: "",
};

function Forgot_Password() {
  let history = useNavigate();
  const [otpInputField, setOtpInputField] = useState("d-none");
  const [emailInputField, setEmailInputField] = useState("d-block");
  const [Password, setPassword] = useState("d-none");
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [formData, setFormData] = useState(initialFormState);
  const [formError, setFormError] = useState(initialFormState);
  const [token, setToken] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [userComes, setUserComes] = useState(false)

  const navigate = useNavigate();

  const Otp = () => {
    setOtpInputField("d-block");
    setEmailInputField("d-none");
  };
  const Newpass = () => {
    setPassword("d-block");
    setOtpInputField("d-none");
  };

  const sendOTPOnEmail = () => {
    sendResetOtp({ email, formpin: false }).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        notify("success", "An OTP has been sent to your registered email id.");
        Otp();
        setUserComes(true)
      }
    });
  };

  const verifyEmailOtp = () => {
    verifyResetOtp({
      email:email.toLowerCase(),
      otp: OTP,
      formpin: false,
    }).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        setToken(res.data.data);
        Newpass();
      }
    });
  };

  const changePassword = async () => {
    const newPassword = await Validator(
      formData.newPassword,
      "newPassword",
      (a, b) => {
        validatorsMethods.start(a, b).isRequired().checkPassword();
      }
    );
    const confirmPassword = await Validator(
      formData.confirm_password,
      "confirm_password",
      (a, b) => {
        validatorsMethods
          .start(a, b)
          .isRequired()
          .checkConfirmPassword(formData.newPassword);
      }
    );

    if (newPassword.message || confirmPassword.message) {

      setFormError({
        [newPassword.field]: newPassword.message,
        [confirmPassword.field]: confirmPassword.message,
      });
      return;
    }
    resetPassword(
      {
        password: formData.confirm_password,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    ).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        notify("success", "Password changed successfully");
        navigate("/login-with-email");
      }
    });
  };

  const changeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "confirm_password" || name === "newPassword") {
      value = value.replace(/\s/g, "");
    }
    if (value.length < 50) {
      setFormData({
        ...formData,
        [e.target.name]: value,
      });
    }
  };
  const showNumberField = () => {
    setOTP("")
    if (emailInputField == "d-block") {
      history(-1)
    } else if (Password === "d-block") {
      setPassword("d-none")
      setEmailInputField("d-block")
    } else {
      setEmailInputField("d-block")
      setOtpInputField("d-none")
      setPassword("d-none")
    }
  }

  return (
    <>
      <div className="login-main position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="login-box position-absolute">
                <div className="row">
                  <div className="col-md-7 col-sm-12 ">
                    <div className="d-flex">
                      <button
                        class="btn btn-light back-btn"
                        onClick={showNumberField}
                      >
                        <FaArrowLeft />
                      </button>

                      <LoginLogo />
                    </div>
                    <div className="login-inner-form ps-lg-4">
                      <div className="details py-3">
                        <form action="#">
                          <div className={`email-box  ${emailInputField}`}>
                            <h5 className="py-3">Recover your password</h5>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter Registered Email Address *"
                              />
                            </div>
                            <div className="form-group">
                              <button
                                type="button"
                                className="login-btn col-12"
                                onClick={sendOTPOnEmail}
                              >
                                Sent OTP
                              </button>
                            </div>
                          </div>
                          <div className={`otp-box  ${otpInputField}`}>
                            <Otp_Input
                              OTP={OTP}
                              setOTP={setOTP}
                              sendOtp={sendOTPOnEmail}
                              userComes={userComes}
                            />
                            <div className="form-group">
                              <button
                                type="button"
                                className="login-btn col-12"
                                onClick={verifyEmailOtp}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className={`pass-box  ${Password} text-start`}>
                            <div className="form-group">
                              <div className="d-flex">
                                <input
                                  type="password"
                                  name="newPassword"
                                  className="form-control"
                                  placeholder="Enter New Password *"
                                  value={formData.newPassword}
                                  onChange={changeHandler}
                                />
                              </div>
                              <small className="text-danger fs-13 text-start">
                              {formError.newPassword}
                            </small>
                            </div>
                            
                            <div className="form-group position-relative text-start">
                              <div className="">
                                <input
                                  type={passwordType}
                                  name="confirm_password"
                                  id="new_pass"
                                  className="form-control"
                                  placeholder="Corfirm New Password *"
                                  value={formData.confirm_password}
                                  onChange={changeHandler
                                  }
                                />
                                 <small className="text-danger fs-13 ">
                                {formError.confirm_password}
                              </small>
                              </div>
                             
                              <p className="text-gray fs-12 text-start pt-2">
                                <b>Note:</b> <br /> The password should have at least eight characters , alpha-numeric, at least one capital & one special character.
                              </p>
                              <div
                                className="rounded-circle d-icon newpass"
                                onClick={() =>
                                  passwordType == "password"
                                    ? setPasswordType("text")
                                    : setPasswordType("password")
                                }
                              >
                                {passwordType === "password" ? (
                                  <FaEyeSlash
                                    className="fs-4"
                                    toggle="#password-field"
                                  />
                                ) : (
                                  <FaEye
                                    className="fs-4"
                                    toggle="#password-field"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <button
                                type="button"
                                className="login-btn col-12"
                                onClick={changePassword}
                              >
                                Update Password
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 align-self-center d-none d-lg-block">
                    <div className="upper-div">
                      <UpperdivOthers />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Forgot_Password;
