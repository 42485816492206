import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../assets/images/others/successfullynew.svg";
const TransactSuccess = ({ show, setShow, confirmData, confirmationDataArr }) => {
  return (
    <>
      <div className="" on>
        <Modal
          onHide={() => setShow(false)}
          show={show}
          size="lg"
          className="invest-gray"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4 text-center">
            <img src={success} className="img-fluid mw-170 pb-2" />
            <h3 class="text-successc font-weight-500 py-2 "> Order Placed</h3>
            <p class="text-black pb-3">Units will be alloted subject to realization of funds in AMC's account.</p>
            {confirmationDataArr.length === 0 && <div className="row">
              <div className="col-md-5 border-right">
                <table className="table mx-3 text-start fs-15 fs-sm-14">
                  <tr>
                    <td>Unique Number </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Unique_No}</td>
                  </tr>
                  <tr>
                    <td>Trxn Number </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Trxn_No}</td>
                  </tr>

                  <tr>
                    <td>Amount </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Amt}</td>
                  </tr>
                  <tr>
                    <td>Status </td>
                    <td>:</td>
                    <td className="ps-3">Success</td>
                  </tr>
                </table>
              </div>
              <div className="col-md-7">
                <table className="table mx-3 text-start fs-15 fs-sm-14">
                  <tr>
                    <td>Folio </td>
                    <td>:</td>
                    <td className="ps-3">{confirmData?.Folio ? confirmData?.Folio : "N/A"}</td>
                  </tr>

                  <tr>
                    <td>Fund</td>
                    <td>:</td>
                    <td className="ps-3">{confirmData.Fund}</td>
                  </tr>

                  {
                    confirmData.Source_Scheme_Name ? <> <tr>
                      <td>Source Scheme </td>
                      <td>:</td>
                      <td className="ps-3">
                        {confirmData.Source_Scheme_Name}
                      </td>
                    </tr>
                      <tr>
                        <td>Target Scheme </td>
                        <td>:</td>
                        <td className="ps-3">
                          {confirmData?.Target_Scheme_Name}
                        </td>
                      </tr></> : <tr><td>Scheme Name </td>
                      <td>:</td>
                      <td className="ps-3">
                        {confirmData?.Scheme_Name?.split("(")[0]}
                      </td>
                    </tr>
                  }

                </table>
              </div>
            </div>}

            {
              confirmationDataArr.length > 0 && confirmationDataArr.map((item) => (
                <div className="row">
                  <div className="col-md-5 border-right">
                    <div className="row  text-start fs-15 fs-sm-14 mt-3">

                      <div className="col-5 col-md-6">Unique Number </div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-5">{item.Unique_No}</div>


                      <div className="col-5 col-md-6">Trxn Number </div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-5 ">{item.Trxn_No}</div>



                      <div className="col-5 col-md-6">Amount </div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-5">{item.Amt}</div>

                      <div className="col-5 col-md-6">Status </div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-5
                        ">Successful</div>

                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="row mx-lg-3 text-start fs-15 fs-sm-14 mt-3">

                      <div className="col-5 col-md-3">Folio </div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-8">{item?.Folio ? item?.Folio : "N/A"}</div>


                      <div className="col-5 col-md-3">Fund</div>
                      <div className="col-1 col-md-1">:</div>
                      <div className="col-6 col-md-8 ">{item.Fund}</div>


                      {item.Scheme_Name ? <> <div className="col-5 col-md-3">Scheme Name</div>
                        <div className="col-1 col-md-1">:</div>
                        <div className=" col-6 col-md-8">
                          {item.Scheme_Name?.split("(")[0]}
                        </div></> : <> <div className="col-5 col-md-3">Source Scheme </div>
                        <div className="col-1 col-md-1">:</div>
                        <div className=" col-6 col-md-8">
                          {item?.Source_Scheme_Name?.split("(")[0]}
                        </div><div className="col-5 col-md-3">Target Scheme</div>
                        <div className="col-1 col-md-1">:</div>
                        <div className=" col-6 col-md-8">
                          {item.Target_Scheme_Name?.split("(")[0]}
                        </div></>}



                    </div>
                  </div>
                </div>
              ))
            }


            <hr />
            <div className="py-2">
              <p className="text-black fs-13">
                <span className="text-red text-left fw-500 fs-16">Note: </span>
                Payment and/or transaction authorization link has been sent on registered mail id(s) or mobile no(s) of primary/all the holder(s), The Link shall remain active for next 48 hours. Kindly Authorize
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0"></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default TransactSuccess;
