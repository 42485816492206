import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { firebaseConfig } from "./config"
import axios from "axios";
import { storeDeviceToken } from "../apisMethods";
import { getNotifications } from "../apisMethods/notification";
import { fetchNotification } from "../pages/helpers/notification";


export const webApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(webApp);

export const setupNotification = async (notificationList, setNotification) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission == "granted") {
      const token = await getToken(messaging);
      let tokenuser = localStorage.getItem("token");
      const header = {
        headers:
          { Authorization: `Bearer ${tokenuser}` }
      };

      storeDeviceToken(  {  iosTOkens: [], androidTOkens: [], webTOkens: [token], platform: [], }, header ).then((res) => {
          console.log("store token response",res.data);
        });

        fetchNotification(setNotification)
      onMessage(messaging, (payload) => {
        console.log("payload",payload.messageId);
        fetchNotification(setNotification)
       
      });

    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};


