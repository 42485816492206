import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link, useNavigate, useLocation } from "react-router-dom";
import car from "../assets/images/goal/dash/purchase.png";
import vacation from "../assets/images/goal/dash/sunbed.png";
import home from "../assets/images/goal/dash/renovation.png";
import wealth from "../assets/images/goal/dash/money.png";
import marriage from "../assets/images/goal/dash/wedding-couple.png";
import othergoals from "../assets/images/goal/dash/othergoals.png";
import retirement from "../assets/images/goal/dash/retirement.png";
import child from "../assets/images/goal/dash/education.png";
import house from "../assets/images/goal/dash/discount.png";
import taxp from "../assets/images/others/taxs.png";
import reportsp from "../assets/images/others/seo-report.png";
import getright from "../assets/images/others/infographic.png";
import "react-tabs/style/react-tabs.css";
import ReportsMobile from "../components/reports-for-mobile";
import "react-toastify/dist/ReactToastify.css";

import {
  createFatcaOne,
  createIINnse,
  familySnapshot,
  familyWisePortfolio,
  getAmcList,
  getTime,
  getUserIinsPending,
  setTime,
  userProfile,
} from "../apisMethods";
import { globalInfo } from "../App";
import NewPurchaseTransact from "../components/new-purchase-transact";
import KycCheckPopPup from "../components/kyc-check-popPup";
import LumpsumForm from "../components/lumpsum-form";
import SchemePerformance from "./scheme-performance";
import ConfirmationPopUP from "../components/confirmation-popup";
import ContactUsForCalculator from "../components/contact-us-for-calcuclator";
import { CheckPathRediction } from "./helpers/redirection";
import { notify } from "./toastCreater";
import Thanku from "../components/thanku";
import { useCount } from "./helpers/CartCountProvide";
import Welcome from "../components/wlecome";

const Dashboard = () => {
  const location = useLocation();
  const { setLoader } = useContext(globalInfo);
  const { getCartData } = useCount();
  const [newPurchaseShow, setNewPurchaseShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [reports, setReports] = useState(false);
  const [snapshotData, setSnapshotData] = useState({});
  const [familyWiseData, setFamilyWiseData] = useState([]);
  const [userDataLocalStoarge, setUserDataLocalStoarge] = useState({});
  const [headers, setHeaders] = useState({});
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [queryPopShow, setQueryPopShow] = useState("");
  const [thankuShow, setThankuShow] = useState(false);
  const [thankuMsg, setThankuMsg] = useState("");
  const [welcomeShow, setWelcomeShow] = useState(false);

  const [rightSchemes, setRightSchemes] = useState([]);
  const [show, setShow] = useState(false);
  const [kycCheckShow, setKycCheckShow] = useState(false);
  const [data, setData] = useState({
    labels: ["Equity", "Debt", "Gold"],
    datasets: [
      {
        data: [33.33, 33.33, 33.34],
        backgroundColor: ["#97C5FB", "#F06D70", "#FBDE80"],
      },
    ],
  });

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setHeaders(header);
    const userdata = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userdata);
    if (userdata?.pan) {
      getCartData();
      setUserDataLocalStoarge(userdata);
      setHeaders(header);
      familySnapshopCall(userdata?.pan, header);
      familyWisePortfolioCall(userdata?.pan, header);
      userProfileCall(userdata?.pan, header);
      isIinPending(header);
      getAmcList(header).then((res) => {
        if (!res.data) {
          setAmcList([]);
        } else {
          setAmcList(res.data?.data);
        }
      });
    }
    console.log("location",location);
    
    if (!token && location.state?.url === "skip" ) {
      console.log(location.state);

      setWelcomeShow(true);
      navigate("/dashboard", {
        replace: true,
        state: {
          ...location.state,
          url: "",
        },
      });
    }
  }, []);

  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then((res) => {
      if (!res.data) {
        setUserProfileData([]);
      } else {
        setUserProfileData(res.data?.data);
      }
    });
  };
  const familySnapshopCall = async (pan, headers) => {
    setLoader("block");
    await familySnapshot({ pan }, headers).then((res) => {
      if (!res.data) {
        // setSnapshotData(defaultSnapshot)
      } else {
        setSnapshotData(res.data?.finalArray);
        let snapData = res.data?.finalArray;
        setData({
          labels: ["Equity", "Debt", "Gold"],
          datasets: [
            {
              data: [
                snapData?.equityPercentFinal,
                snapData?.debtPercentFinal,
                snapData?.goldPercentFinal,
              ],
              backgroundColor: ["#97C5FB", "#F06D70", "#FBDE80"],
            },
          ],
        });
      }
      setLoader("none");
    });
  };
  const familyWisePortfolioCall = async (pan, headers) => {
    await familyWisePortfolio({ pan }, headers).then((res) => {
      if (res.data) {
        if (res?.data?.success === false) {
          setFamilyWiseData([]);
        } else {
          setFamilyWiseData(res.data?.finalArray);
          localStorage.setItem(
            "userFamily",
            JSON.stringify(res.data?.finalArray)
          );
        }
      } else {
        return;
      }
    });
  };

  const goToReviewData = (pan, gpan, userId, applicantName) => {
    let portfolioData = {
      pan,
      gpan,
      name: applicantName,
    };
    localStorage.setItem("portfolioData", JSON.stringify(portfolioData));
    navigate("/dashboard/portfolio", {
      state: { pan, gpan, userId, name: applicantName },
    });
  };

  const QueckInvestHandler = () => {
    const isLoggedIn = CheckPathRediction();
    if (!isLoggedIn?.token) {
      navigate("/");
      return;
    } else if (!isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      setNewPurchaseShow(true);
    }

    // }
  };

  const sendMailForReview = () => {
    if (!userDetails?.pan) {
      setQueryPopShow(true);
    } else if (!userDetails?.iin) {
      setConfirmShow(true);
      setSuccessMessage("Are you sure to submit a Portfolio Review Request?");
    } else if (familyWiseData.length == 0) {
      setConfirmShow(true);
      setSuccessMessage("Are you sure to submit a Portfolio Review Request?");
    } else {
      setLoader("block");
      getTime(headers).then((res) => {
        let oneHourInMiliseconds = 1 * 60 * 60 * 1000;
        let time = new Date().getTime();
        if (!res.data.success) {
          setTime({ time }, headers).then((setRes) => {
            if (!setRes.data) {
              notify("error", "Something went wrong");
              setLoader("none");
            } else {
              setLoader("none");
              setThankuShow(true);
              setThankuMsg(
                "Your portfolio review request has been submitted successfully.Please provide few hours to our research team to analyse your portfolio.Your portfolio review will be live soon."
              );
            }
          });
          setLoader("none");
        } else {
          setLoader("none");
          let specificTime = new Date(res.data?.data).getTime();
          specificTime += oneHourInMiliseconds;
          if (specificTime < time) {
            navigate("/dashboard/portfolio-review");
          } else {
            setThankuShow(true);
            setThankuMsg(
              "Your portfolio review request has been submitted successfully.Please provide few hours to our research team to analyse your portfolio.Your portfolio review will be live soon."
            );
          }
        }
      });
    }
  };

  const isIinPending = (header) => {
    getUserIinsPending(header).then((res) => {
      if (!res.data) {
      } else {
        let statusOfKyc = {
          primaryHolderKycStatus: data?.primaryHolderKycStatus
            ? data?.primaryHolderKycStatus
            : false,
          secondHolderKycStatus: data?.secondHolderKycStatus
            ? data?.secondHolderKycStatus
            : false,
          thirdHolderKycStatus: data?.thirdHolderKycStatus
            ? data?.thirdHolderKycStatus
            : false,
          tax_status: data?.tax_status,
          holding_nature: data?.holding_nature,
          pan: data?.pan,
          guard_pan: data?.guard_pan ? data?.guard_pan : "",
          jh1PAN: data?.jh1PAN ? data?.jh1PAN : "",
          jh2PAN: data?.jh2PAN ? data?.jh2PAN : "",
          IINCreated: false,
          primaryFATCAStatus: false,
          secondaryFATCAStatus: false,
          thirdFATCAStatus: false,
        };
        res.data?.data.map((data) => {
          if (
            data?.holding_nature === "SI" &&
            data?.primaryHolderKycStatus === true
          ) {
            let reqData = {
              tax_status: data?.tax_status,
              name: data?.name,
              guard_pan: data?.guard_pan ? data?.guard_pan : "",
              _id: data?._id,
            };
            setLoader("block");
            createIINnse(reqData, header).then((res) => {
              if (!res.data) {
                // notify("error", res?.error?.response?.data?.msg);
                setLoader("none");
              } else {
                if (res.data?.IINstatus === "1") {
                  setLoader("none");
                } else {
                  let iin = res.data?.nseData?.split(":")?.slice(-1)[0];
                  if (iin != undefined) {
                    statusOfKyc.primaryHolderKycStatus = true;
                    statusOfKyc.IINCreated = true(statusOfKyc);
                    let reqBodyForFatca = {
                      holders: [
                        {
                          primaryPAN: data?.pan ? data?.pan : "",
                          guard_pan: data?.guard_pan ? data?.guard_pan : "",
                          name: data?.name ? data?.name : "",
                          not_politically: "Y",
                          tax_status: "01",
                          incomeRange: data?.inc_range,
                          occupation: data?.occupation,
                          dob: data?.dob,
                          userEmail: data?.email,
                          pob: data?.pob,
                        },
                      ],
                    };
                    setLoader("block");
                    createFatcaOne(reqBodyForFatca, header);
                    setLoader("none");
                    // notify("success", res.data?.msg);
                  }
                }
              }
            });
          }
        });
      }
    });
  };
  const updateDataOnTempIin = (reqBodyOfUpdateTempIin, nominee = false) => {
    setLoader("block");
    updateDataOnTempIin(reqBodyOfUpdateTempIin, headers).then((res) => {
      if (!res.data) {
        // notify("error", res.error.response.data.msg);
        return false;
      } else {
        // if ( kycDetails?.taxStatus!=="02") {
        //   nomineedata(res.data?.data?.nominees);
        // }
      }
      setLoader("none");
    });
  };

  return (
    <>
      <div className="wrapper home-page">
        <div className="">
          <div className="container px-lg-0">
            {/* assets allocation */}
            <section>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="portfolio-s p-portfolio">
                    <div className="row">
                      <div className="col-md-6 col-sm-12 py-3 ">
                        <div className="row">
                          <div className="col-md-12">
                            <h5 className="text-label">Market Value</h5>
                            <h3>
                              <span className="text-roboto">₹</span>
                              <span className="lc text-black">
                                {snapshotData?.Totalmarketvalue || 0}
                              </span>
                            </h3>
                            <hr className="border-bottom" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 border-right">
                            <span className="text-label fs-16">
                              Purchase Cost
                            </span>
                            <br />
                            <h5>
                              <span className="text-roboto">₹</span>
                              <span className="lc text-black">
                                {Math.round(snapshotData?.Totalpurchase) || 0}
                              </span>
                            </h5>
                          </div>
                          <div className="col-6">
                            <span className="fs-16 pb-2 text-label">
                              GAIN/LOSS
                            </span>
                            <br />
                            <span className="lc text-black">
                              {snapshotData?.Gainloss > 0 ? (
                                <div>
                                  {snapshotData?.Gainloss}
                                  <span className="fa fa-arrow-up text-successc"></span>
                                </div>
                              ) : (
                                <div>
                                  {snapshotData?.Gainloss || 0}
                                  <span className="fa fa-arrow-down text-red"></span>
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                        <hr className="border-bottom" />
                        <div className="row">
                          {/* <div className="col-4 border-right">
                            <span className=" fs-16 text-label">Dividend</span>
                            <br />
                            <h5>
                              <span className="text-black">
                                {snapshotData?.Dividend || 0}
                              </span>
                            </h5>
                          </div> */}
                          <div className="col-5 border-right">
                            <span className="fs-16 pb-2  text-label">
                              Day's Change
                            </span>
                            <br />
                            <span className="text-black">
                              {snapshotData?.Totaldayschange > 0 ? (
                                <div>
                                  ₹{snapshotData?.Totaldayschange}
                                  <span className="fa fa-arrow-up text-successc"></span>
                                </div>
                              ) : (
                                <div>
                                  {snapshotData?.Totaldayschange < 0 ? (
                                    <>
                                      ₹{snapshotData?.Totaldayschange}
                                      <span className="fa fa-arrow-down text-red"></span>
                                    </>
                                  ) : (
                                    "₹0"
                                  )}
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="col-3">
                            <span className="fs-16 pb-2  text-label">CAGR</span>
                            <br />
                            <span className="text-black">
                              {snapshotData?.Finalcagr || 0} %
                            </span>
                          </div>
                        </div>
                      </div>
                      {data.datasets[0].data[0] ? (
                        <div className="col-md-6 pt-2 text-center ">
                          <Doughnut
                            data={data}
                            options={{ plugins: { legend: { onClick: null } } }}
                          />
                          <h5 className="pt-3 text-center text-label">
                            Asset Allocation
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-12 ">
                  <div className="quick-invest mt-smc-2  ">
                    <div className="quick-access-title mt-1">
                      <h3 className="fs-21">Quick Invest</h3>
                    </div>
                    <div className="text-decoration-none">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="quick-c py-3">
                            Avoid the hassle and Deploy your funds in less than
                            a Minute !
                          </div>
                          <button
                            type="button"
                            className="btn-custom my-4"
                            onClick={QueckInvestHandler}
                          >
                            Invest Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ====================family Porfolio====================== */}
            {familyWiseData.length > 0 ? (
              <section className="family-portfolio-s pt-4">
                <div className="py-4">
                  <h3 className="fs-21 text-brown">Family Portfolio</h3>
                </div>
                <div className="family-portfolio table-responsive">
                  <table className="table custom text-center bg-gray rounded">
                    <tr className="text-red">
                      <th className="wd-8rem">Name</th>
                      <th className="wd-8rem">
                        Purchase Cost <span className="text-roboto">(₹)</span>
                      </th>
                      <th className="wd-8rem">
                        Market Value<span className="text-roboto">(₹)</span>
                      </th>
                      <th>Gain/Loss</th>
                      <th>CAGR(%)</th>
                    </tr>
                    {familyWiseData &&
                      familyWiseData.map((data) => {
                        return (
                          <tr>
                            <td
                              onClick={() =>
                                goToReviewData(
                                  data?.Pan,
                                  data?.Gpan,
                                  data?.userid,
                                  data?.Name
                                )
                              }
                              className="underline curser"
                            >
                              {/* <Link to="/dashboard/portfolio" className="underline"> */}
                              {data?.Name}
                              {/* </Link> */}
                            </td>
                            <td>{Math.round(data?.Totalpurchase)}</td>
                            <td>{data?.TotalMarketValue}</td>
                            <td>
                              {data?.Gainloss > 0 ? (
                                <div>
                                  {data?.Gainloss}
                                  <span className="fa fa-arrow-up text-successc"></span>
                                </div>
                              ) : (
                                <div>
                                  {data?.Gainloss}
                                  <span className="fa fa-arrow-down text-red"></span>
                                </div>
                              )}
                            </td>
                            <td>{data?.Cagr.toFixed(2)}</td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </section>
            ) : null}

            {/*================= Quick access============ */}
            <section className="quick-access  pt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="quick-access-title pb-4">
                    <h3 className="fs-21 text-brown">Quick Access</h3>
                  </div>
                </div>
              </div>
              <div className="row d-smc-none d-tab-none">
                <div className="col-lg-3 col-6" onClick={sendMailForReview}>
                  <div className="quick-card">
                    <div
                      className="quick-link"
                      // to="/dashboard/portfolio-review"
                    >
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/portr.png"
                        }
                        className="me-2 new-icon"
                        alt=""
                      />
                      <span>Portfolio Review</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <Link className="quick-link" to="/dashboard/nfo-live">
                    <div className="quick-card">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/nfo.png"
                        }
                        className="me-2 new-icon"
                        alt=""
                      />
                      <span>NFO Live</span>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-6">
                  <Link className="quick-link" to="/dashboard/simply-sip">
                    <div className="quick-card">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/simplysip.png"
                        }
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Simply Sip</span>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-6">
                  <Link className="quick-link" to="/dashboard/simply-save">
                    <div className="quick-card">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/simplysave.png"
                        }
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Simply Save</span>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="row d-lg-none access">
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link className="quick-link" to="/dashboard/simply-sip">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/simplysip.png"
                        }
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Simply Sip</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link className="quick-link" to="/dashboard/simply-save">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/simplysave.png"
                        }
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Simply Save</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link className="quick-link" to="/dashboard/tax-planning">
                      <img
                        src={taxp}
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Tax Planning</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link
                      className="quick-link"
                      to="/dashboard/get-right-scheme"
                    >
                      <img
                        src={getright}
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Get Right Scheme</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Link className="quick-link" to="/dashboard/nfo-live">
                    <div className="quick-card mt-smc-2   mt-lg-0">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/nfo.png"
                        }
                        className="me-2 new-icon"
                        alt=""
                      />
                      <span>NFO Live</span>
                    </div>
                  </Link>
                </div>
                <div className="col-6">
                  <div
                    className="quick-card mt-smc-2   mt-lg-0"
                    onClick={sendMailForReview}
                  >
                    <div
                      className="quick-link"

                      // to="/dashboard/portfolio-review"
                    >
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/portr.png"
                        }
                        className="me-2 new-icon"
                        alt=""
                      />
                      <span>Portfolio Review</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link className="quick-link" to="/dashboard/sip-calculator">
                      <img
                        src={
                          "https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/calculator.png"
                        }
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Calculators</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="quick-card mt-smc-2   mt-lg-0">
                    <Link
                      className="quick-link"
                      to="#"
                      onClick={() => setReports(true)}
                    >
                      <img
                        src={reportsp}
                        className="me-2 img-fluid new-icon"
                        alt=""
                      />
                      <span>Reports</span>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* =============Goal Planning============ */}
            <section className="goal-plan">
              <div className="row">
                <div className="col-md-12">
                  <div className=" pb-5 pt-5">
                    <h3 className="fs-21 text-brown">Goal Planning</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1 col-6 col-md-2 col-md-2 ms-0 ">
                  <Link
                    className=" bg-white goal-link"
                    to="/dashboard/retirement-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={retirement}
                        className="mx-3 new-icon bg-c p-3"
                        alt=""
                        loading="lazy"
                      />
                      <br />
                      <h4 className="pt-4">Retirement</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link"
                    to="/dashboard/child-education-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={child}
                        className="mx-3 new-icon bg-c p-3"
                        alt=""
                      />
                      <h4 className="pt-4">Child Education</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link"
                    to="/dashboard/house-purchase-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={house}
                        className="mx-3 new-icon bg-c p-3"
                        alt=""
                        loading="lazy"
                      />
                      <h4 className="pt-4">House Purchase</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link"
                    to="/dashboard/car-purchase-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={car}
                        className="mx-3 new-icon bg-c p-3"
                        alt=""
                      />
                      <h4 className="pt-4">Car Purchase</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link"
                    to="/dashboard/wealth-creation-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={wealth}
                        className="mx-3 new-icon bg-c p-3"
                        alt=""
                      />
                      <h4 className="pt-4">Wealth Creation</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link"
                    to="/dashboard/home-renovetion-goal"
                  >
                    <div className="goal-card text-center">
                      <img src={home} className="mx-3 new-icon bg-c p-3" />
                      <h4 className="pt-4">Home Renovation</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className=" bg-white goal-link"
                    to="/dashboard/vacation-goal"
                  >
                    <div className="goal-card text-center">
                      <img src={vacation} className="mx-3 new-icon bg-c p-3" />
                      <h4 className="pt-4">Vacation</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-6 col-md-2">
                  <Link
                    className="bg-white goal-link "
                    to="/dashboard/child-marriage-goal"
                  >
                    <div className="goal-card text-center">
                      <img src={marriage} className="mx-3 new-icon bg-c p-3" />
                      <h4 className="pt-4">Child Marriage</h4>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-1 col-12 col-md-2">
                  <Link
                    className="bg-white goal-link "
                    to="/dashboard/customize-goal"
                  >
                    <div className="goal-card text-center">
                      <img
                        src={othergoals}
                        className="mx-3 new-icon bg-c p-3"
                      />
                      <h4 className="pt-4">Customize Goal</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </section>
            {/* =============All mutual funds Section=============== */}

            <section className="all-mf-s">
              <div className="row  justify-content-center">
                <div className="col-md-12">
                  <div className=" pb-5 pt-5">
                    <h3 className="fs-21 text-brown">Scheme Performance</h3>
                  </div>
                </div>
              </div>
            </section>
            <SchemePerformance assetType={1} />
          </div>
        </div>
      </div>
      <NewPurchaseTransact
        show={newPurchaseShow}
        setShow={setNewPurchaseShow}
        amcList={amcList}
        userProfileData={userProfileData}
        purchase={"Quick Invest"}
      />
      <ReportsMobile show={reports} setShow={setReports} />
      <ConfirmationPopUP
        show={confirmShow}
        setShow={setConfirmShow}
        successMessage={successMessage}
      />
      <KycCheckPopPup
        show={kycCheckShow}
        setShow={setKycCheckShow}
        successMessage={successMessage}
        user={userDetails}
      />
      <ContactUsForCalculator
        show={queryPopShow}
        setShow={setQueryPopShow}
        messages={successMessage}
      />
      <LumpsumForm show={show} setShow={setShow} rightSchemes={rightSchemes} />
      <Thanku
        show={thankuShow}
        setShow={setThankuShow}
        successMessage={thankuMsg}
      />
      <Welcome
        show={welcomeShow}
        setShow={setWelcomeShow}
        successMessage={""}
      />
    </>
  );
};
export default Dashboard;
