import React, { useState, useEffect } from "react";
import CalculatorHeader from "../components/calculator-header";
import Loader from "./Loader";
import { Link ,useNavigate} from "react-router-dom";
import { notify } from "./toastCreater";
import { RetirementPresentValue, getMachine, pmtvalue } from "./helpers/calculatorHelper";
import { getAmcList, userProfile } from "../apisMethods";
import SIPTransact from "../components/sip-transact";
const RetirementCalculator = () => {
  const history = useNavigate()
  const [CurrentAge, setCurrentAge] = useState(30);
  const [ExpectedRetirementAge, setExpectedRetirementAge] = useState(60);
  const [MonthlyExpenses, setMonthlyExpenses] = useState(30000);
  const [ExpectedInflation, setExpectedInflation] = useState(6);
  const [CurrentSavingPerMonth, setCurrentSavingPerMonth] = useState(5000);
  const [ExistingCorpus, setExistingCorpus] = useState(200000);
  const [ExpectedPreRetirement, setExpectedPreRetirement] = useState(12);
  const [ExpectedPostRetirement, setExpectedPostRetirement] = useState(7);
  const [LifeExpectancy, setLifeExpectancy] = useState(20);

  const [RetirementYear, setRetirementYear] = useState("30");
  const [Fv, setFv] = useState("1,72,305");
  const [SipAmount, setSipAmount] = useState("4,953");
  const [CorpusMonth, setCorpusMonth] = useState("1,74,74,821");
  const [CorpusExist, setCorpusExist] = useState("50,81,744");
  const [CorpusAch, setCorpusAch] = useState("3,76,72,712");
  const [SHortfall, setSHortfall] = useState("1,51,16,089");
  const [note, setNote] = useState();
  const [popupNegative, setPopupNegative] = useState("");
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [positiveAdditional, setPositiveAdditional] = useState(
    "Additional Saving P.M."
  );
  const [shortfallContent, setShortfallContent] = useState(
    "Shortfall in amount"
  );
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [sipShow, setSipShow] = useState(false);
  const [amountForSip, setAmountForSip] = useState("4953");
  const [yearForSip,setYearForSip] = useState(30)



  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }

  }, []);


  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if (!res.data) {
        setUserProfileData([])
      } else {
        setUserProfileData(res.data?.data)
      }
    })

  }

  const investInSip = () => {
    const token = localStorage.getItem("token");
    const userdata = JSON.parse(localStorage.getItem("user"));
    if(!token){
      history("/")
    }else if(!userdata?.IIINStatus){
      history("/dashboard/Profile-creation")
    }
    let value =Number(SipAmount?.toString().replace(/\,/g,''))
    let roundedNumber = Math.ceil(value / 100) * 100;
    if(roundedNumber <1000){
      setAmountForSip(1000)
    }else{
      setAmountForSip(roundedNumber)
    }
    setYearForSip(ExpectedRetirementAge-CurrentAge)
    setSipShow(true)
  }



  const CalculateRetirement = async () => {
    if (CurrentAge < 1) {
      notify("warn", "Age should not be less than 1");
      return;
    }
    if (CurrentAge >= ExpectedRetirementAge) {
      notify(
        "warn",
        "Current age should not be greater than or equal to retirement age"
      );
      return;
    }
    if (ExpectedRetirementAge < 1) {
      notify("warn", "Retirement age should not be less than 1");
      return;
    }
    if (MonthlyExpenses < 1) {
      notify(
        "warn",
        "Monthly expenses for current lifestyle should not be less than 1"
      );
      return;
    }
    if (ExpectedInflation < 1) {
      notify("warn", "Expected inflation rate should not be less than 1");
      return;
    }
    if (CurrentSavingPerMonth < 1) {
      notify("warn", "Current saving per month should not be less than 1");
      return;
    }
    if (ExistingCorpus < 1) {
      notify("warn", "Existing Corpus should not be less than 1");
      return;
    }
    if (ExpectedPreRetirement < 1) {
      notify(
        "warn",
        "Expected pre retirement returns should not be less than 1"
      );
      return;
    }
    if (ExpectedPostRetirement < 1) {
      notify(
        "warn",
        "Expected post retirement returns  should not be less than 1"
      );
      return;
    }
    if (LifeExpectancy < 1) {
      notify("warn", "Life Expectancy should not be less than 1");
      return;
    }
    setLoader("block");




    var retirement_yr = parseInt(ExpectedRetirementAge) - parseInt(CurrentAge);

    let inflationR = 0.01 * parseFloat(ExpectedInflation)
    let postRetirementReturn = 0.01 * parseFloat(ExpectedPostRetirement)
    let onePlusinflationR = 1 + inflationR
    let onePluspostRetirementReturn = 1 + postRetirementReturn
    let inflationAdjust = (onePluspostRetirementReturn / onePlusinflationR) - 1
    console.log("value2=", inflationAdjust, onePlusinflationR, onePluspostRetirementReturn);
    let inflationAdjustReturn = inflationAdjust / 12
    inflationAdjustReturn = parseFloat(inflationAdjustReturn.toFixed(9))
    var lifeExpectancy_yr = LifeExpectancy * 12
    // let lumpsumRequired = presentValue(adj_rate, yearsleft, 0, fv);
    var fvvalue = (MonthlyExpenses * (Math.pow(onePlusinflationR, retirement_yr)));
    fvvalue = parseFloat(fvvalue.toFixed(2))
    //let corpusAchieved = (fvvalue * pow) / inflationAdjustReturn;
    let onePlusinflationAdjustReturn = 1 + inflationAdjustReturn
    let powValue = 1 - Math.pow(onePlusinflationAdjustReturn, -lifeExpectancy_yr);
    let corpusAchieved = 0;

    if (onePlusinflationAdjustReturn == 0) {
      corpusAchieved = 0
    } else {
      corpusAchieved = await RetirementPresentValue(inflationAdjustReturn, lifeExpectancy_yr, -fvvalue, 0, 1)
    }

    var investment = CurrentSavingPerMonth;
    var monthlyRate = ExpectedPreRetirement / 12 / 100;
    var months = retirement_yr * 12;
    var corpus_month = investment * (Math.pow(1 + monthlyRate, months) - 1) / monthlyRate;
    var corpus_month = Math.round(corpus_month)

    var pre_ret = 0.01 * ExpectedPreRetirement;
    var log = (1 + pre_ret);
    var n = 1 / 12.0;
    var pow = Math.pow(log, n);
    var nom = 12.0 * (pow - 1);

    var logg = (1 + parseFloat(nom));
    var pow = Math.pow(logg, retirement_yr);
    var corpus_exist = ExistingCorpus * pow;
    var corpus_exist = Math.round(corpus_exist)
    var shortfall_amt = corpusAchieved - (parseFloat(corpus_month) + parseFloat(corpus_exist))
    var shortfall_amt = parseFloat(shortfall_amt.toFixed(2))

    var nomialRate = 12.0 * (Math.pow((1 + (ExpectedPreRetirement * 0.01)), (1 / 12.0)) - 1);
    var nominalRateMonthly = parseFloat((nomialRate / 12).toFixed(9))
    let newsipamt = await pmtvalue(nominalRateMonthly, months, 0, -shortfall_amt, 0)
    if (newsipamt < 0) {
      setSipAmount(newsipamt);
    } else {
      setSipAmount(getMachine(Math.round(newsipamt)));
    }

    if (shortfall_amt < 0) {
      setPopupNegative(
        "Your existing savings are enough to meet this goal, you can utilize the excess saving to achieve your other goals"
      );
      setNote("Note:");
      setPositiveAdditional("Extra savings P.M.");
      setShortfallContent("Excess in Amount ");
    } else {
      setPopupNegative();
      setNote();
      setPositiveAdditional("Additional Saving P.M.");
      setShortfallContent("Shortfall in amount");
    }

    setSHortfall(getMachine(Math.round(shortfall_amt)));
    setRetirementYear(retirement_yr);
    setFv(getMachine(Math.round(fvvalue)));
    setCorpusMonth(getMachine(Math.round(corpus_month)));
    setCorpusExist(getMachine(Math.round(corpus_exist)));
    setCorpusAch(getMachine(Math.round(corpusAchieved)));
    setLoader("none");
    setCalculateCheck(1);
    window.scrollTo(500, 700);







    // await axios
    //   .post(baseUrl + "/calculators/retirment", data)
    //   .then((res) => {
    //     setSHortfall(res.data.shortfallAmount);
    //     setRetirementYear(res.data.retirementYears);
    //     setFv(res.data.amountRequiredPostRetirement);
    //     setSipAmount(res.data.extraSavings);
    //     setCorpusMonth(res.data.corpusAccumulated);
    //     setCorpusExist(res.data.corpusAccumulatedWithSavings);
    //     setCorpusAch(res.data.corpusAchieved);
    //     setLoader("none");
    //     setCalculateCheck(1);
    //     window.scrollTo(500, 700);

    //     let s = res.data.extraSavings.replace(/,/g, "");
    //     s = parseInt(s);
    //     if (res.data.extraSavings.length == 5 && s < 0) {
    //       setSipAmount(res.data.extraSavings.replace(/,/g, ""));
    //     }

    //     // setNote('')
    //     let withoutComma = res.data.shortfallAmount.replace(/,/g, "");
    //     withoutComma = parseInt(withoutComma);
    //     if (withoutComma < 0) {
    //       setPopupNegative(
    //         "Your existing savings are enough to meet this goal, you can utilize the excess saving to achieve your other goals"
    //       );
    //       setNote("Note:");
    //       setPositiveAdditional("Extra savings P.M.");
    //       setShortfallContent("Excess in Amount ");
    //     } else {
    //       setPopupNegative();
    //       setNote();
    //       setPositiveAdditional("Additional Saving P.M.");
    //       setShortfallContent("Shortfall in amount");
    //     }
    //   });
  };

  let incCurrentAge = () => {
    if (CurrentAge < 60) {
      setCurrentAge(Number(CurrentAge) + 1);
    }
  };
  let decCurrentAge = () => {
    if (CurrentAge > 1) {
      setCurrentAge(CurrentAge - 1);
    }
  };

  // for Approx cost

  let incMonthlyExpenses = () => {
    if (MonthlyExpenses < 1000000) {
      setMonthlyExpenses(Number(MonthlyExpenses) + 500);
    } else if (MonthlyExpenses >= 1000000) {
      setMonthlyExpenses(Number(1000000));
    } else if (MonthlyExpenses == 0) {
      setMonthlyExpenses(Number(MonthlyExpenses) + 500);
    }
  };
  let decMonthlyExpenses = () => {
    if (MonthlyExpenses >= 500) {
      setMonthlyExpenses(MonthlyExpenses - 500);
    } else if (MonthlyExpenses < 499) {
      setMonthlyExpenses(0);
    }
  };

  // for Start college Age

  let incExpectedRetirementAge = () => {
    if (ExpectedRetirementAge < 70) {
      setExpectedRetirementAge(Number(ExpectedRetirementAge) + 1);
    }
  };
  let decExpectedRetirementAge = () => {
    if (ExpectedRetirementAge > 1) {
      setExpectedRetirementAge(ExpectedRetirementAge - 1);
    }
  };

  let incExpectedInflation = () => {
    if (ExpectedInflation < 50) {
      setExpectedInflation(Number(ExpectedInflation) + 1);
    }
  };
  let decExpectedInflation = () => {
    if (ExpectedInflation > 1) {
      setExpectedInflation(ExpectedInflation - 1);
    }
  };

  let incCurrentSavingPerMonth = () => {
    if (CurrentSavingPerMonth < 1000000) {
      setCurrentSavingPerMonth(Number(CurrentSavingPerMonth) + 500);
    } else if (CurrentSavingPerMonth == 0) {
      setCurrentSavingPerMonth(Number(CurrentSavingPerMonth) + 500);
    }
  };
  let decCurrentSavingPerMonth = () => {
    if (CurrentSavingPerMonth >= 500) {
      setCurrentSavingPerMonth(CurrentSavingPerMonth - 500);
    } else if (CurrentSavingPerMonth < 499) {
      setCurrentSavingPerMonth(0);
    }
  };

  let incExistingCorpus = () => {
    if (ExistingCorpus < 10000000) {
      setExistingCorpus(Number(ExistingCorpus) + 500);
    } else if (ExistingCorpus == 0) {
      setExistingCorpus(Number(ExistingCorpus) + 500);
    }
  };
  let decExistingCorpus = () => {
    if (ExistingCorpus >= 500) {
      setExistingCorpus(ExistingCorpus - 500);
    } else if (ExistingCorpus < 499) {
      setExistingCorpus(0);
    }
  };
  let incExpectedPreRetirement = () => {
    if (isNaN(ExpectedPreRetirement)) {
      return setExpectedPreRetirement(0);
    }
    if (ExpectedPreRetirement < 50) {
      setExpectedPreRetirement(Number(ExpectedPreRetirement) + 0.5);
    }
  };
  let decExpectedPreRetirement = () => {
    if (ExpectedPreRetirement > 1) {
      setExpectedPreRetirement(ExpectedPreRetirement - 0.5);
    }
  };

  let incExpectedPostRetirement = () => {
    if (isNaN(ExpectedPostRetirement)) {
      return setExpectedPostRetirement(0);
    }
    if (ExpectedPostRetirement < 50) {
      setExpectedPostRetirement(Number(ExpectedPostRetirement) + 0.5);
    }
  };
  let decExpectedPostRetirement = () => {
    if (ExpectedPostRetirement > 1) {
      setExpectedPostRetirement(ExpectedPostRetirement - 0.5);
    }
  };

  let incLifeExpectancy = () => {
    if (isNaN(LifeExpectancy)) {
      return setLifeExpectancy(0);
    }
    if (LifeExpectancy < 100) {
      setLifeExpectancy(Number(LifeExpectancy) + 0.5);
    }
  };
  let decLifeExpectancy = () => {
    if (LifeExpectancy > 1) {
      setLifeExpectancy(LifeExpectancy - 0.5);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Calculators
              </li>
            </ol>
          </nav>

          <Loader loader={loader} />
          <div className="container-fluid  px-lg-5 pb-lg-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row">
                                  {/*first*/}
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Current Age (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decCurrentAge}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={CurrentAge}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 60
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setCurrentAge(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setCurrentAge(
                                                e.target.value != ""
                                                  ? parseInt(e.target.value)
                                                  : ""
                                              );
                                            }
                                          } else if (e.target.value > 60) {
                                            setCurrentAge(60);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incCurrentAge}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected retirement age (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27 "
                                        onClick={decExpectedRetirementAge}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={ExpectedRetirementAge}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 70
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setExpectedRetirementAge(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setExpectedRetirementAge(
                                                e.target.value != ""
                                                  ? parseInt(e.target.value)
                                                  : ""
                                              );
                                            }
                                          } else if (e.target.value > 70) {
                                            setExpectedRetirementAge(70);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incExpectedRetirementAge}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Monthly expenses for current lifestyle(
                                      <span className="text-roboto">₹</span>/M)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decMonthlyExpenses}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={MonthlyExpenses}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 1000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setMonthlyExpenses(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setMonthlyExpenses(
                                                e.target.value != ""
                                                  ? parseInt(e.target.value)
                                                  : ""
                                              );
                                            }
                                          } else if (e.target.value > 1000000) {
                                            setMonthlyExpenses(1000000);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incMonthlyExpenses}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                {/*second*/}
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected inflation rate (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decExpectedInflation}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={ExpectedInflation}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedInflation(value)
                                              
                                            
                                          } else if (e.target.value > 50) {
                                            setExpectedInflation(50);
                                          }else if(e.target.value=="."){
                                            setExpectedInflation(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incExpectedInflation}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Current saving per month
                                      <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decCurrentSavingPerMonth}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={CurrentSavingPerMonth}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 1000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setCurrentSavingPerMonth(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setCurrentSavingPerMonth(
                                                e.target.value != ""
                                                  ? parseInt(e.target.value)
                                                  : ""
                                              );
                                            }
                                          } else if (e.target.value > 1000000) {
                                            setCurrentSavingPerMonth(1000000);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incCurrentSavingPerMonth}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Existing Corpus
                                      <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decExistingCorpus}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={ExistingCorpus}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 10000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setExistingCorpus(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setExistingCorpus(
                                                e.target.value != ""
                                                  ? parseInt(e.target.value)
                                                  : ""
                                              );
                                            }
                                          } else if (
                                            e.target.value > 10000000
                                          ) {
                                            setExistingCorpus(10000000);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incExistingCorpus}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected pre retirement returns (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decExpectedPreRetirement}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={ExpectedPreRetirement}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedPreRetirement(value);
                                          } else if(e.target.value>50){
                                            setExpectedPreRetirement(50);
                                          }else if(e.target.value=="."){
                                            setExpectedPreRetirement(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incExpectedPreRetirement}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected post retirement returns (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decExpectedPostRetirement}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={ExpectedPostRetirement}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedPostRetirement(value )
                                           } else if(e.target.value>50){
                                            setExpectedPostRetirement(50);
                                          }if(e.target.value=="."){
                                            setExpectedPostRetirement(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incExpectedPostRetirement}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Life Expectancy Post retirement(year)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={decLifeExpectancy}
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={LifeExpectancy}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 100
                                          ) {
                                              setLifeExpectancy(value)
                                          } else if(e.target.value>100){
                                            setLifeExpectancy(100);
                                          }else if(e.target.value=="."){
                                            setLifeExpectancy(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={incLifeExpectancy}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12  text-lg-end text-center pt-4 ">
                                  <button
                                    className="btn-custom"
                                    onClick={CalculateRetirement}
                                  >
                                    {calculateCheck == 0
                                      ? "Calculate"
                                      : "Re-Calculate"}
                                  </button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-3 p-lg-5">
                                <div className="col-md-12 result-title text-center pt-3">
                                  <h3>Result</h3>
                                </div>
                                <div className="col-md-12 px-lg-5 pt-3">
                                  <div className="row result-content text-center">
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12 mb-18">
                                        Year to retirement (Years)
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {RetirementYear}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12">
                                        Amount Required P.M.-Post <br />
                                        Retirement
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {Fv}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12">
                                        Corpus to be Achived @ <br />
                                        Retirement
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {CorpusAch}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row result-content text-center pt-3">
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12">
                                        Corpus you will accumulate with current
                                        savings P.M.
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {CorpusMonth}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12 corpus-mb">
                                        Corpus you will accumulate with existing
                                        savings
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">
                                        {CorpusExist}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="text-label fw-500 py-2 fs-12 mb-18">
                                        {shortfallContent}
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {SHortfall}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 px-lg-5 pt-3 pb-3">
                                  <div className="col-md-12 result-title text-center py-3">
                                    <h3>Plan of action required</h3>
                                  </div>
                                  <div className="result-content text-center">
                                    <div className="text-label fw-500 py-2 fs-12">
                                      {positiveAdditional}
                                      <span className="text-roboto">(₹)</span>
                                    </div>
                                    <div className="inputf transcard bg-light-red py-2 mb-18">
                                      {SipAmount}
                                    </div>
                                    <span className="font-weight-500 fs-13 text-danger">
                                      <b>{note}</b> {popupNegative}.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row px-lg-5 pt-3" onClick={investInSip}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">Invest Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SIPTransact
        show={sipShow}
        setShow={setSipShow}
        amcList={amcList}
        userProfileData={userProfileData}
        isLookingForAnother={false}
        amountForSip={amountForSip}
        yearForSip={yearForSip}


      />
    </>
  );
};
export default RetirementCalculator;
