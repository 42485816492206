import React, { useState,useContext } from "react";
import { UpperdivOthers, LoginLogo } from "../components/reusable-content";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate ,Link} from "react-router-dom";
import Otp_Input from "../components/otp";
import { sendMobileLoginOtp, verifyMobileLoginOtp, getUserStatus } from "../apisMethods";
import { notify } from "./toastCreater";
import { globalInfo } from "../App";

function Login_With_Mobile() {
  const {setLoader} = useContext(globalInfo)
  let history = useNavigate();
  const [Show, setShow] = useState("d-none");
  const [Block, setBlock] = useState("d-block");
  const [mobile, setMobile] = useState("");
  const [OTP, setOTP] = useState("");
  const [userComes, setUserComes] = useState(false)
  const [loadingContent, setLoadingContent] = useState("Login");
  const [buttunDisable, setButtunDisable] = useState(false);

  const Otp = () => {
    setShow("d-block");
    setBlock("d-none");
  };

  const sendOtpOnMobile = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (mobile.length !== 10) {
      notify("warn", "mobile number should be of 10 digits");
      return;
    }
    setLoader("block")
    sendMobileLoginOtp({
      number: mobile,
    }).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        notify("success", "An OTP has been sent to your registered mobile");
        Otp();
        setUserComes(true)
      }
      setLoader("none")

    });
  };

  const verifyOtp = () => {
    if (OTP.length !== 6) {
      notify("warn", "OTP number should be of 6 digits");
      return;
    }
    setLoadingContent("Loading.....")
    setButtunDisable(true)
    verifyMobileLoginOtp({
      number: mobile,
      otp: OTP,
    }).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        localStorage.setItem("token", res.data.data);
        getUserStatus({ headers: { Authorization: `Bearer ${res?.data?.data}` } }).then(
          (res) => {
            if (!res.data) {
              localStorage.clear();
            } else {
              localStorage.setItem("user", JSON.stringify(res.data?.data));
              if (res.data?.data?.pan && res.data?.data?.iin) {
                history("/dashboard");
              } 
              else {
                history("/dashboard/Profile-creation");
              }
            }
            setLoadingContent("Login")
            setButtunDisable(false)
          }
        );
      }
    });
  }; const mobileHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    if (sanitizedValue.length <= 10) {
      setMobile(sanitizedValue);
    }
  };
  const showNumberField = ()=>{
    if(Block=="d-block"){
      history(-1)
    }else{

      setBlock("d-block")
      setShow("d-none")
    }
  }

  return (
    <>
      <div className="login-main position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="login-box1 position-absolute">
                <div className="row">
                  <div className="col-md-7 col-sm-12 ">
                    <div className="d-flex">
                      <button
                        class="btn btn-light back-btn"
                        onClick={showNumberField}
                      >
                        <FaArrowLeft />
                      </button>
                      <LoginLogo />
                    </div>
                    <div className="login-inner-form ps-lg-4">
                      <div className={`details m-4  ${Block}`} id="mobile-no">
                        <h5 className="py-3">Login With Mobile OTP</h5>

                        <form onSubmit={sendOtpOnMobile}>
                          <div className="form-group no-input">
                            <input
                              type="text"
                              name="mobile"
                              value={mobile}
                              onChange={mobileHandler}
                              className="input-text form-control"
                              placeholder="Enter Your Mobile Number *"
                            />
                          </div>
                          <p className="fs-14 mt-2">
                          Don't have an account?
                          <Link to="/registration" className="">
                            <b> Register here</b>
                          </Link>
                        </p>
                          <div className="form-group mt-3">
                            <button
                              className="login-btn col-12"
                            // disabled={buttunDisable}
                            >
                              Get OTP
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className={`details m-4 ${Show}`} id="Otp">

                        <Otp_Input
                          OTP={OTP}
                          setOTP={setOTP}
                          sendOtp={(e) => sendOtpOnMobile(e)}
                          userComes={userComes}
                        />
                        <div className="form-group">
                          <button
                            type="button"
                            className="login-btn col-12"
                            onClick={verifyOtp}
                            disabled={buttunDisable}
                          >
                            {loadingContent}
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 align-self-center d-none d-lg-block">
                    <div className="upper-div">
                      <UpperdivOthers />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login_With_Mobile;
