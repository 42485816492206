import React, { useState ,useEffect} from "react";
import CalculatorHeader from "../components/calculator-header";
import Loader from "./Loader";

import {
  incThirty,
  decThirty,
  incTwentyFive,
  decTwentyFive,
  incTenCrore,
  decTenLakh,
  incTwenty,
  decTwenty,
  incPointFiveFifty,
  decPointFive,
} from "./helpers/validators";
import { notify } from "./toastCreater";
import { Link ,useNavigate} from "react-router-dom";
import { getMachine,pmtvalue } from "./helpers/calculatorHelper";
import SIPTransact from "../components/sip-transact";
import { getAmcList, userProfile } from "../apisMethods";


const EducationCalculator = () => {
  const history = useNavigate()
  const [childAgeTOday, setChildAgeTOday] = useState(10);
  const [startCollegeAge, setStartCollegeAge] = useState(18);
  const [durationOfEducation, setDurationOfEducation] = useState(3);
  const [approxCurrentCost, setApproxCurrentCost] = useState(500000);
  const [expectedRateReturn, setExpectedRateReturn] = useState(12);
  const [expecetdInflation, setExpecetdInflation] = useState(6);
  const [totalAmtRequired, setTotalAmtRequired] = useState("21,43,644");
  const [lumpsum, setLumpsum] = useState("9,04,671");
  const [SipAmount, setSipAmount] = useState("13,781");
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [sipShow, setSipShow] = useState(false);
  const [amountForSip, setAmountForSip] = useState("13781");
  const [yearForSip,setYearForSip] = useState(10)



  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  
  }, []);

  
  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if(!res.data){
        setUserProfileData([])
      }else{
        setUserProfileData(res.data?.data)
      }
    })

  }

  const investInSip = ()=>{
    const token = localStorage.getItem("token");
    const userdata = JSON.parse(localStorage.getItem("user"));
    if(!token){
      history("/")
    }else if(!userdata?.IIINStatus){
      history("/dashboard/Profile-creation")
    }
    let value =Number(SipAmount?.toString().replace(/\,/g,''))
    let roundedNumber = Math.ceil(value / 100) * 100;
    if(roundedNumber <1000){
      setAmountForSip(1000)
    }else{
      setAmountForSip(roundedNumber)
    }
    setYearForSip(startCollegeAge-childAgeTOday)
    setSipShow(true)
  }


 


 
  const childCalculater = async (e) => {

    if (childAgeTOday < 1) {
      notify("warn", "Current age should not be less than 1")
      return
    }
    if (startCollegeAge < 1) {
      notify("warn", "College age should not be less than 1")
      return
    }
    if (childAgeTOday >= startCollegeAge) {
      notify("warn", "Current age should not be greater than or equal to College age")
      return
    }
    if (durationOfEducation < 1) {
      notify("warn", "Duration of education should not be less than 1")
      return
    }
    if (approxCurrentCost < 100) {
      notify("warn", "Approx current cost per year should not be less than 100")
      return
    }
    if (expectedRateReturn < 1) {
      notify("warn", "Expected rate of return should not be less than 1")
      return
    }
    if (expecetdInflation < 1) {
      notify("warn", "Expected inflation rate should not be less than 1")
      return
    }
    if (expecetdInflation === expectedRateReturn) {
      notify("warn", "Expected rate of return and Expected inflation rate should not be same")
      return
    }

    setLoader("block");
    // const data = {
    //   childAge: childAgeTOday,
    //   collegeAtAge: startCollegeAge,
    //   educationTime: durationOfEducation,
    //   currentCost: approxCurrentCost,
    //   expReturnRate: expectedRateReturn,
    //   inflationRate: expecetdInflation,
    // };



    var yearleft = startCollegeAge - childAgeTOday;

    var rate = expectedRateReturn * 0.01;

    var i = 0.01 * expecetdInflation;

    var FV = approxCurrentCost * (Math.pow((1 + i), yearleft));

    var er = expectedRateReturn * 0.01;
    var ei = expecetdInflation * 0.01;
    var Tot = (1 + er) / (1 + ei) - 1;
    var firstot = (1 + er) / (1 + er)
    var nomialRate = 12.0 * (Math.pow((1 + rate), (1 / 12.0)) - 1);


    var postinfaltion = Tot * 100;


    var totalAmtRequired = (FV * ((1 - (Math.pow((1 + Tot), (-durationOfEducation)))) / Tot));

    var lumpsum = (totalAmtRequired * (1 / (Math.pow((1 + nomialRate), yearleft))));
    var nominalRateMonthly = parseFloat((nomialRate / 12).toFixed(6))
    var monthleft = yearleft * 12
    let newsipamt = await pmtvalue(nominalRateMonthly, monthleft, 0, -Math.round(totalAmtRequired), 0)

    // axios
    //   .post(baseUrl + "/calculators/education", data)
    //   .then((res) => {
    //     setSipAmount(res.data.sipAmount);
    //     setLumpsum(res.data.lumpsumAmount);
    //     setTotalAmtRequired(res.data.corpusAmount);
    //     setCalculateCheck(1)
    //     window.scrollTo(500, 500);
    //     setLoader("none");
    //   });
    setSipAmount(getMachine(Math.round(newsipamt)))
    setTotalAmtRequired(getMachine(Math.round(totalAmtRequired)))
    setLumpsum(getMachine(Math.round(lumpsum)))
    setLoader("none");
    setCalculateCheck(1)
    document.getElementById('scroll').scrollIntoView(true);
    setLoader("none");

  };

  return (
    <>
      <div className="wrapper">
        <div className=" px-lg-5 px-2">
          {/* Page Heading */}

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Calculators
              </li>
            </ol>
          </nav>
          <Loader loader={loader} />

          <div className="container-fluid  px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row" id="marriage-1">
                                  {/*first*/}
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Child's age today (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decTwentyFive(
                                            childAgeTOday,
                                            setChildAgeTOday
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={childAgeTOday}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 25
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setChildAgeTOday(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setChildAgeTOday(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 25) {
                                            setChildAgeTOday(25);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incTwentyFive(
                                            childAgeTOday,
                                            setChildAgeTOday
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Start college at age (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decThirty(
                                            startCollegeAge,
                                            setStartCollegeAge
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={startCollegeAge}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 30
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setStartCollegeAge(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setStartCollegeAge(
                                                e.target.value != "" ? parseInt(e.target.value) : ""
                                              );
                                            }
                                          } else if (e.target.value > 30) {
                                            setStartCollegeAge(30);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incThirty(
                                            startCollegeAge,
                                            setStartCollegeAge
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Duration of education(Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decTwenty(
                                            durationOfEducation,
                                            setDurationOfEducation
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={durationOfEducation}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 20
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setDurationOfEducation(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              )
                                            } else {
                                              setDurationOfEducation(
                                                e.target.value != "" ? parseInt(e.target.value) : ""
                                              );
                                            }
                                          } else if (e.target.value > 20) {
                                            setDurationOfEducation(20);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incTwenty(
                                            durationOfEducation,
                                            setDurationOfEducation
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                {/*second*/}
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="m-saving"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Approx current cost per year
                                      <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decTenLakh(
                                            approxCurrentCost,
                                            setApproxCurrentCost
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="m-saving"
                                        value={approxCurrentCost}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 100000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setApproxCurrentCost(
                                                Math.round(
                                                  parseInt(e.target.value)
                                                )
                                              );
                                            } else {
                                              setApproxCurrentCost(
                                                e.target.value != "" ? parseInt(e.target.value) : ""
                                              );
                                            }
                                          } else if (
                                            e.target.value > 100000000
                                          ) {
                                            setApproxCurrentCost(100000000);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incTenCrore(
                                            approxCurrentCost,
                                            setApproxCurrentCost
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="year"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected rate of return(%)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decPointFive(
                                            expectedRateReturn,
                                            setExpectedRateReturn
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={expectedRateReturn}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedRateReturn(value)
                                          } else if(e.target.value>50){
                                            setExpectedRateReturn(50);
                                          }else if(e.target.value=="."){
                                            setExpectedRateReturn(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incPointFiveFifty(
                                            expectedRateReturn,
                                            setExpectedRateReturn
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label
                                      for="return"
                                      className="text-label fw-500 py-2 fs-12"
                                    >
                                      Expected inflation rate (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          decPointFive(
                                            expecetdInflation,
                                            setExpecetdInflation
                                          )
                                        }
                                      ></img>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="return"
                                        value={expecetdInflation}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpecetdInflation(value );
                                          } else if(e.target.value>50){
                                            setExpecetdInflation(50);
                                          }
                                          else if(e.target.value=="."){
                                            setExpecetdInflation(e.target.value);
                                          }
                                        }}
                                      />
                                      <img
                                        src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"}
                                        alt=""
                                        className="img-fluid max-27"
                                        onClick={() =>
                                          incPointFiveFifty(
                                            expecetdInflation,
                                            setExpecetdInflation
                                          )
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 text-lg-end text-center mt-3 ">
                                  <button
                                    className="btn-custom"
                                    onClick={childCalculater}
                                  >
                                    {calculateCheck == 0 ? "Calculate" : "Re-Calculate"}
                                  </button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-lg-3 p-lg-5" id="scroll">
                                <div className="col-md-12 px-lg-5 ">
                                  <div className="col-md-12 result-title text-center py-3">
                                    <h3>Result</h3>
                                  </div>
                                  <div className="result-content col-md-12 text-center">
                                    <div className="text-label fw-500 py-2">
                                      Corpus required at start of college{" "}
                                      <span className="text-roboto">(₹)</span>
                                    </div>
                                    <div className="inputf transcard bg-light-red py-2">

                                      {totalAmtRequired}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 px-lg-5 pt-4 pb-3">
                                  <div className="col-md-12 result-title text-center py-3">
                                    <h3>Plan of action required</h3>
                                  </div>
                                  <div className="row result-content text-center">
                                    <div className="col-md-5">
                                      <div className="text-label fw-500 py-2">
                                        One time investment required
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {lumpsum}
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="text-label fw-500 py-2">
                                        <strong className="text-black">
                                          OR
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="text-label fw-500 py-2">
                                        Monthly investment required
                                        <span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">

                                        {SipAmount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row px-lg-5 pt-3" onClick={investInSip}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">
                                    Invest Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SIPTransact
              show={sipShow}
              setShow={setSipShow}
              amcList={amcList}
              userProfileData={userProfileData}
              isLookingForAnother={false}
              amountForSip={amountForSip}
              yearForSip={yearForSip}
              

            />
    </>
  );
};
export default EducationCalculator;
