import react from "react";
import ErrorComponent from "../components/ErrorCompo"

class ErrorBoundary extends react.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorinfo) {
    console.log(error);
  }

  render() {
    return this.state.hasError ? (
     <ErrorComponent/>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
