import React from "react";
import { BsFilterLeft } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { httpGetAMC, httpGetCategories,httpGetAssetType } from "../apisMethods";
import { useEffect,useState } from "react";


export default function Filter_schemes({ setFilterObject, setPages, setTabIndex }) {
  //list
  const [amcList, setAmcList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [clear, setClear] = useState({ name: "vishal" });

  //values
  const [amc, setAmc] = useState([]);
  const [category, setCategory] = useState([]);
  const [asset, setAsset] = useState([]);

  //nav

  const [nav,setNav] = useState(false)

  useEffect(() => {
    httpGetAMC().then((res) => {
      setAmcList(res.data?.data);
    });

    httpGetCategories().then((res) => {
      setCategoriesList(res.data?.data);
    });

    httpGetAssetType().then((res) => {
      setAssetList(res.data?.data);
    });
  }, [clear]);

  const checkBoxHandler = (e) => {
    if (e.target.name == "amc") {
      if (e.target.checked) {
        amc.push(parseInt(e.target.value));
        setAmc([...amc]);
      } else {
        const index = amc.indexOf(parseInt(e.target.value));
        if (index > -1) {
          amc.splice(index, 1);
          setAmc([...amc]);
        }
      }
    }

    if (e.target.name == "category") {
      console.log("www",e.target,category);
      if (e.target.checked) {
        category.push(parseInt(e.target.value));
        setCategory([...category]);
      } else {
        const index = category.indexOf(parseInt(e.target.value));
        if (index > -1) {
          category.splice(index, 1);
          setCategory([...category]);
        }
      }
    }

    if (e.target.name == "asset") {
      if (e.target.checked) {
        asset.push(parseInt(e.target.value));
        setAsset([...asset]);
      } else {
        const index = asset.indexOf(parseInt(e.target.value));

        if (index > -1) {
          asset.splice(index, 1);
          setAsset([...asset]);
        }
      }
    }
  };

  const findScheme = () => {
    const reqBody = {
      classcode: category,
      asset_code: asset,
      amc_code: amc,
    };
    setPages(1);
    setFilterObject(reqBody);

    if (asset.length == 1) {
      setTabIndex(asset[0] - 1)
    } else {
      setTabIndex(5)
    }

    closeNav();
  };

  function closeNav() {
    setNav(false);
    // $(".tablist.sp .react-tabs__tab").removeClass("padding");
    const sidePanel = document.getElementById("mySidepanel");
    sidePanel.style.width = "0";
    sidePanel.classList.remove("mobile-responsive");
    document.getElementsByClassName("main-btn-f")[0].style.marginLeft = "0%";
  }

  function openNav() {
    setNav(true)
    // $(".tablist.sp .react-tabs__tab").addClass("padding");
    const sidePanel = document.getElementById("mySidepanel");
    sidePanel.style.width = "25%";
    sidePanel.classList.add("mobile-responsive");
    document.getElementsByClassName("main-btn-f")[0].style.marginLeft = "25%";
  }

  const clearFilters = () => {
    const reqBody = {
      classcode: [],
      asset_code: [1],
      amc_code: [],
    };

    //clear persisting data
    setAmc([])
    setAsset([])
    setCategory([])
    
    //clear list
    setAmcList([]);
    setCategoriesList([]);
    setAssetList([]);
    
    //reload apis
    setClear({ name: "random" });
    
    //fetch schemes
    setFilterObject(reqBody);
    
    //set current tab
    setTabIndex(0);
    
    closeNav()
  };

  return (
    <>
      <div className="filter-main">
        <div className="">
          <button className="btn-filter fs-19" onClick={nav?closeNav:openNav}>
            Filter
            <BsFilterLeft className="text-black fs-27" />
          </button>
        </div>
        <div id="mySidepanel" className="sidepanel">
          <div className="col-md-12 p-3">
            <a href="javascript:void(0)" className="py-2" onClick={closeNav}>
              <FaTimes className="float-right text-red fs-19" />
            </a>
            <button
              className="btn-custom"
              onClick={() => clearFilters()}
            >
              clear All
            </button>
          </div>
          <hr />
          <div className="row ">
            <div className="col-md-12 pe-4 filter-s">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="border-bottom">
                  <Accordion.Header className="bg-gray border-0">
                    <div className="col-md-9 text-start ps-2 fw-600">AMC</div>
                  </Accordion.Header>
                  <Accordion.Body className="bg-gray">
                    <div className="row ">
                      <div className="col-md-12 bg-white rounded bg-c py-3">
                        <Table className="custom">
                          {amcList && amcList.map((el) => {
                            return (
                              <tr key={el.amc_code}>
                                <td className="fs-13">{el.AMC_Name}</td>
                                <td className="text-end">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={el.amc_code}
                                    name="amc"
                                    onChange={checkBoxHandler}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="border-bottom">
                  <Accordion.Header className="bg-gray">
                    <div className="col-md-9 text-start ps-2 fw-600">
                      Categories
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="bg-gray">
                    <div className="row ">
                      <div className="col-md-12 bg-white rounded bg-c py-3">
                        <Table className="custom">
                          {categoriesList.map((el) => {
                            return (
                              <tr key={el.classcode}>
                                <td className="fs-13">
                                  {el.category}
                                  {el.sub_category && (
                                    <span style={{ fontSize: "12px" }}>
                                      ({el.sub_category})
                                    </span>
                                  )}
                                </td>
                                <td className="text-end">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="category"
                                    value={el.classcode}
                                    onChange={checkBoxHandler}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="border-bottom">
                  <Accordion.Header className="bg-gray">
                    <div className="col-md-9 text-start ps-2 fw-600">
                      Asset Type
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="bg-gray">
                    <div className="row ">
                      <div className="col-md-12 bg-white rounded bg-c py-3">
                        <Table className="custom">
                          {assetList.map((el) => {
                            return (
                              <tr key={el.asset_code}>
                                <td className="fs-13">{el.asset_type}</td>
                                <td className="text-end">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={el.asset_code}
                                    name="asset"
                                    onChange={checkBoxHandler}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="row">
            <div className="col-md-12 pb-3">
              <button className="btn-custom float-right" onClick={findScheme}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
