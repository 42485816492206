import React, { useState } from "react";
import CalculatorHeader from "../components/calculator-header";
import Select from 'react-select';
import Loader from "./Loader";
import { notify } from "./toastCreater";
import { Link } from "react-router-dom";
import { getMachine } from "./helpers/calculatorHelper";
const ElssCalculator = () => {
  const [investment, setInvestment] = useState(12500);
  const [taxSlab, setTaxSlab] = useState(5);
  const [taxTotalSaved, setTaxTotalSaved] = useState(650);
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const slab = [
    { value: "5", label: "5%" },
    { value: "20", label: "20%" },
    { value: "30", label: "30%" },
  ];

  const calculateElss = () => {
    if (investment < 500) {
      notify("warn", "Investment Amount should not be less than 500")
      return
    }
    setLoader("block");

    // axios
    //   .post(baseUrl + "/calculators/elss", data)
    //   .then((res) => {
    //     setTaxTotalSaved(res.data.data);
    //     window.scrollTo(500, 500);
    //     setLoader("none");
    //     setCalculateCheck(1)
    //   });


      if (investment > 150000) {
        var taxInvestment = 150000;
      } else {
        var taxInvestment = investment;
      }
      var taxValue = (parseFloat(taxInvestment) * parseFloat(taxSlab)) / 100;
      var taxValuePercent = (parseFloat(taxValue) * 4) / 100;
      let  finalValue = taxValue + taxValuePercent;
      finalValue =finalValue.toFixed(0)

      setTaxTotalSaved(getMachine(finalValue));
      window.scrollTo(500, 500);
      setLoader("none");
      setCalculateCheck(1)

  };
  let incInvestment = () => {
    if (investment < 150000) {
      setInvestment(Number(investment) + 500);
    } else if (investment == 0) {
      setInvestment(Number(investment) + 500);
    }
  };
  let decInvestment = () => {
    if (investment >= 500) {
      setInvestment(investment - 500);
    } else if (investment < 499) {
      setInvestment(0);
    }
  };

  return (
    <>

      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}


          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Calculators</li>
            </ol>
          </nav>

          <Loader loader={loader} />

          <div className="container-fluid  px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">

                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row">
                                  <div className="col-md-6 text-center">
                                    <label for="m-saving" className="text-label fw-500 fs-14 py-2">
                                      Investment Amount<span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decInvestment}></img>
                                      <input type="text" className="form-control" name="m-saving" value={investment}

                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 150000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setInvestment(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setInvestment(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 150000) {
                                            setInvestment(150000);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incInvestment}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-6 text-center">
                                    <label for="year" className="text-label fw-500 fs-14 py-2">
                                      Select Your Tax Slab
                                    </label>
                                    <Select className='inputf transcard' placeholder={`${taxSlab}%`} onChange={(e) => setTaxSlab(e.value)} options={slab} />
                                  </div>
                                </div>
                                <div className="col-md-12 pt-2 text-lg-end text-center">
                                  <button className="btn-custom mt-3" onClick={calculateElss}> {calculateCheck == 0 ? "Calculate" : "Re-Calculate"}</button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-3 p-lg-5">
                                <div className="col-md-12">
                                  <div className="result-title text-center py-lg-3 pt-2">
                                    <h2>Result</h2>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="result-content">
                                    <ul className="text-center lst-none ps-0">
                                      <li>
                                        <div className="text-label fw-500 py-2 mt-lg-5 ">
                                          Total tax saved u/s 80(c)<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-light-red py-2">{taxTotalSaved}</div>
                                        <p id="cases">*Inclusive of all cess</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-8">

                                  <div className="text-center">
                                    <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/elssc.png"} className="img-fluid mw-220" alt='' />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row px-lg-5 pt-3">
                            <div className="col-md-12 text-lg-end text-center">
                              <button className="btn-custom ">Invest Now</button>
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default ElssCalculator