import React, { useContext, useEffect, useState ,} from "react";
import Cart_Delete from "../components/delete-confirmation";
import Otp_Input from "../components/otp";
import { Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import {
  sendPanOtpAddfamily,
  verifiyPanOtpAddfamily,
  getFamilyList,
  deleteFamilyMember,
} from "../apisMethods";
import { notify } from "./toastCreater";
import { globalInfo } from "../App";

const AddFamilyMember = () => {
  const navigate = useNavigate();
  const { setLoader } = useContext(globalInfo)
  const [Family, setFamily] = useState("d-block");
  const [Otp, setOtp] = useState("d-none");
  const [otpInput, setOtpInput] = useState("");
  const [memberPan, setMemberPan] = useState("");
  const [memberPanError, setMemberPanError] = useState("");
  const [familyRelation, setFamilyRelation] = useState();
  const [familyRelationError, setFamilyRelationError] = useState("");
  const [headerState, setHeaderState] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [familyMemberData, setFamilyMemberData] = useState([]);

  const [userComes, setUserComes] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.pan) {
      setUserDetails(user);
      getFamilyDataCall(user?.pan, headers);
    } else {
      navigate("/dashboard/Profile-creation");
    }
  }, []);

  const getFamilyDataCall = async (pan, headers) => {
    setLoader("block")
    let res = await getFamilyList({ adminPan: pan }, headers);
    if (res.data) {
      setFamilyMemberData(res.data?.data);
    } else {
      setFamilyMemberData([]);
    }
    setLoader("none")
  };

  const generateOtp = async () => {
    if (!memberPan && !familyRelation) {
      setFamilyRelationError("Please select relation");

      setMemberPanError("Please enter PAN");
      return;
    }
    if (!memberPan) {
      setMemberPanError("Please enter PAN");
      setFamilyRelationError("");
      return;
    }
    if (!familyRelation) {
      setFamilyRelationError("Please select relation");
      setMemberPanError("");
      return;
    }
    if (memberPan.length !== 10) {
      setMemberPanError("Please enter valid PAN");

      setFamilyRelationError("");
      return;
    }
    if (memberPan === userDetails?.pan) {
      setMemberPanError("Pan cannot be same as user's Pan");
      return;
    }
    // if(user?.pan)
    let reqBody = {
      adminPan: userDetails?.pan,
      memberPan,
      memberRelation: familyRelation,
    };
    setLoader("block")
    sendPanOtpAddfamily(reqBody, headerState).then((res) => {
      if (res.data?.success === false) {
        notify("error", res.data?.msg);
        setMemberPanError(res.data?.msg);
      } else {
        notify("success", "OTP sent on family member's registered email Id ");
        // setFamilyRelation('')
        // setMemberPan('')
        setFamilyRelationError("");
        setMemberPanError("");
        setUserComes("start");
        setOtp("d-block");
        setFamily("d-none");
      }
      setLoader("none")
    });
  };

  const checkPan = (value) => {
    let regExpe =
      /^([A-Z]){3}(C|P|H|F|A|T|B|L|J|G){1}([A-Z]){1}([0-9]){4}([A-Z]){1}?$/;
    return regExpe.test(value);
  };
  const panHandler = (e) => {
    let value = e.target.value.toUpperCase();
    if (value.length <= 10) {
      if (checkPan(value) && value.length === 10) {
        setMemberPanError("");
      } else if (value.length === 10) {
        setMemberPanError("invalid pan");
      } else {
        setMemberPanError("");
      }
      setMemberPan(value);
    }
  };
  const addFamilyMember = () => {
    if (otpInput) {
      let reqBody = {
        adminPan: userDetails?.pan,
        memberPan,
        otp: otpInput,
      };
      verifiyPanOtpAddfamily(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("error", "OTP not matched");
        } else {
          getFamilyDataCall(userDetails?.pan, headerState);
          setOtpInput("");
          setFamilyRelation("");
          setMemberPan("");
          setOtp("d-none");
          setFamily("d-block");
          notify("success", "Family member added successfully");

          setUserComes(false);
        }
      });
    } else {
      notify("error", "please enter otp");
    }
  };
  const deleteData = (i) => {
    if (!i) {
      notify("error", "Miner can't be deleted");
      return;
    }
    deleteFamilyMember(
      { adminPan: userDetails?.pan, memberPan: i },
      headerState
    ).then((res) => {
      if (!res.data) {
        notify("error", "not Deleted ");
      } else {
        notify("success", "Deleted Succesfully");
        let isProfileDataDeleted = JSON.parse(localStorage.getItem("portfolioData"))
       if(isProfileDataDeleted?.pan===i){
         localStorage.removeItem("portfolioData");
       }
        getFamilyDataCall(userDetails?.pan, headerState);
      }
    });
  };
  return (
    <>
      <div className="wrapper">
        <div className=" px-lg-5">
          {/* Page Heading */}

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Family Member
              </li>
            </ol>
          </nav>

          <section>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-6 col-10 my-5  px-lg-5 py-4  bg-gray shadow-custom ">
                  <div className={`col-12 text-start px-lg-5 px-3 ${Family}`}>
                    <div className="form-group">
                      <label className="text-label ">Enter PAN</label>
                      <input
                        type="text"
                        className="form-control border-0 bg-c"
                        name="memberPan"
                        value={memberPan}
                        onChange={panHandler}
                        placeholder="Enter Pan"
                      />
                      <span className="text-danger">{memberPanError}</span>
                    </div>

                    <div className="form-group ">
                      <Form.Label>
                        Select Relation <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={familyRelation}
                        placeholder={"Select Relation"}
                        onChange={(e) => setFamilyRelation(e.target.value)}
                      >
                        <option value="">Select Relation</option>
                        <option value={"Father"}>Father </option>
                        <option value={"Mother"}>Mother </option>
                        <option value={"Wife"}>Wife </option>
                        <option value={"Husband"}>Husband </option>
                        <option value={"Son"}>Son</option>
                        <option value={"Daughter"}>Daughter </option>
                        <option value={"Brother"}>Brother </option>
                        <option value={"Sister"}>Sister </option>
                        <option value={"Other"}>Other</option>
                      </Form.Select>
                      <small className="text-danger">
                        <span> {familyRelationError}</span>
                      </small>
                    </div>

                    <div className={`mb-3 text-center mt-3`}>
                      <button
                        className="btn-custom col-lg-7"
                        onClick={generateOtp}
                      >
                        Generate OTP
                      </button>
                    </div>
                  </div>
                  <div className={`col-md-12 mb-3 ${Otp}`}>
                    <Otp_Input
                      OTP={otpInput}
                      setOTP={setOtpInput}
                      sendOtp={generateOtp}
                      userComes={userComes}
                    />
                    <div className="text-center mt-4">
                      <button
                        className="btn-custom col-md-7 col-8`"
                        onClick={addFamilyMember}
                      >
                        Add Family Member
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6 col-11 shadow-custom bg-gray table-responsive mb-4">
                  <table className="table custom text-center">
                    <tr>
                      <th className="text-red wd-11rem">Name</th>
                      <th className="text-red">Pan</th>
                      <th className="text-red">Relation</th>
                      <th className="text-red">Action</th>
                    </tr>
                    {familyMemberData.length > 0
                      ? familyMemberData.map((data) => {
                        return (
                          <tr className="text-black">
                            <td>{data.name}</td>
                            <td>{data.memberPan}</td>
                            <td>{data.memberRelation}</td>
                            <td>
                              {" "}
                              <Cart_Delete
                                id={data.memberPan ? data.memberPan : ""}
                                deleteData={deleteData}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default AddFamilyMember;
