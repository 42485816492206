import React, { useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';


const ConfirmationProfileChangePopup = ({ show, setShow, massageContent,profileChangeHandler,profileTargetValue}) => {
   const profileChangeYes = ()=>{
    profileChangeHandler(profileTargetValue)
    setShow(false)
   }
    return (
        <>

            <Modal show={show} dialogClassName="modal-37w" onHide={() => setShow(false)} centered className="animate__animated animate__zoomIn animate__fast">
                <Modal.Body className="text-center py-4">
                    <p className="fw-500 text-center">
                        {massageContent}
                    </p>
                </Modal.Body>

                <div className=" order-b d-flex justify-content-around pb-4">
                    <div className="">
                        <button className='rounded border-dash px-3 py-1 single-drop'onClick={()=>setShow(false)} >No</button>
                    </div>
                    <div className="">
                        <button className='rounded border-dash single-drop px-3 py-1' onClick={profileChangeYes}>Yes</button>
                    </div>
                </div>
            </Modal>
           
        </>
    )
}
export default ConfirmationProfileChangePopup;