import React, { useContext, useEffect, useState } from "react";
import Nav_Chart from "./nav-chart";
import { useLocation,useNavigate } from "react-router-dom";
import { getHistoricNav, schemeFromNse } from "../apisMethods";
import { globalInfo } from "../App";
import { inString } from "../currentMonth/FinancialYear";
import { yearInString } from "../pages/helpers/goalEnum";
import { apis } from "../request";
import LumpsumForm from "../components/lumpsum-form";
import { useCount } from "../pages/helpers/CartCountProvide";
const NavSingle = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {addToCart} = useCount();
  const {setLoader} = useContext(globalInfo)
  const [headers,setHeaders] = useState({})
  const [schemeDetail,setSchemeDetail] = useState({})
  const [activeYear,setActiveYear] =useState(12)
  const [yearType,setYearType] =useState("m")
  const [rightSchemes,setRightSchemes] =useState([])
  const [lumpsumForm,setLumpsumForm] =useState(false)
  const [series, setSeries] = useState([{
    name: '₹',
    data: []
  }])

  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers:{ Authorization: `Bearer ${token}` }};
    setHeaders(header);
    console.log("ssssssssssssssssssssssss",location?.state);
    if(location?.state?.ISIN){
      fetchSchemeFromNse(header,{ISIN:location?.state?.ISIN})
    }else{
      navigate("/dashboard")
    }
  }, [])

  const fetchGraphData = (header,body) => {
  
    if (body?.schemecode) {
      setLoader("block")
      getHistoricNav(body, header).then((res) => {
        if (res.data) {
          let dates = []
          for (let i = res.data.navhistory.length-1; i > 0; i--) {
            dates.push({x:new Date(res.data?.navhistory[i]?.navdate)?.toLocaleDateString('en-GB'), y:Number(res.data.navhistory[i].adjustednav_c.toFixed(2))})
          }
          // setSeries(dates)
          series[0].data = dates
          console.log("datesss",series);
          setSeries([...series])
          setLoader("none")
        }else{
          setLoader("none")
        }
      })

    } else {
      navigate("/dashboard")
    }
  }

  const fetchSchemeFromNse = (header,body) => {
      setLoader("block")
      schemeFromNse(body, header).then((res) => {
        if (res.data) {
          setSchemeDetail(res.data.data)
          setLoader("none")
          let body ={
            schemecode:res.data?.data?.Schemecode,
            duration:"12",
            type:"m"
            }
          fetchGraphData(header,body)
        }else{
          setLoader("none")
        }
      })

   
  }

  const yearHandler = (duration, type) => {
    setYearType(type)
    setActiveYear(duration)
    let body ={
      schemecode:schemeDetail?.Schemecode,
      duration:type ==="y" || type ==="ALL" ? new Date().getFullYear()-duration:duration,
      type:type
      }
      fetchGraphData(headers,body)
  }

  const investInPurchase = ()=>{
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      navigate("/");
      return;
    }

    if (user?.pan && user?.iin) {
      setRightSchemes([schemeDetail]);
      setLumpsumForm(true)
    } else {
      navigate("/dashboard/Profile-creation")
    }
  }
  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="home">Home</a></li>
              <li className="breadcrumb-item active">Nav</li>
            </ol>
          </nav>
          <section>
            <div className="col-md-10 offset-md-1 card shadow-custom my-5 text-left m-sm-auto" id="single_div">
              <div className="card-body">
                <div className='col-md-12 py-3 table-responsive'>
                  <table className='w-100'>
                    <tr>
                      <td className='px-lg-4 '><img src={`${apis.imageLink}${schemeDetail?.AMC_CODE}.png`} alt="" className='img-fluid min-w-3em mx-wd-84' /></td>
                      <td className='px-lg-4 wd-sm-28'>{schemeDetail?.PRODUCT_LONG_NAME}
                      </td>
                      <td className='px-4' style={{ color: "#2E3192" }}>
                        <h3>{schemeDetail[yearInString[location?.state?.annualizedYear]]?.toFixed(2) || "NA"}%</h3>
                      </td>
                      <td className='px-4 wd-11rem'>{location?.state?.annualizedYear || "NA"}Y annualised</td>
                    </tr>
                  </table>
                </div>
                <div className='col-md-12'>
                  <div className="pt-4">
                    <Nav_Chart  series={series}/>
                  </div>
                </div>
                <div className="col-md-8 offset-md-2">
                  <div className='nav d-flex justify-content-between nav-h'>
                    <div className={`year-num ${ activeYear===1 ? "active":""}`} onClick={() => yearHandler(1, "m")}>
                      <div >1M</div>
                    </div>
                    <div className={`year-num ${ activeYear===3 && yearType==="m" ? "active":""}`} onClick={() => yearHandler(3, "m")}>
                      <div>3M</div>
                    </div>
                    <div className={`year-num ${ activeYear===12  ? "active":""}`} onClick={() => yearHandler(12, "m")}>
                      <div>1Y</div>
                    </div>
                    <div className={`year-num ${ activeYear===3 && yearType==="y" ? "active":""}`} onClick={() => yearHandler(3, "y")}>
                      <div>3Y</div>
                    </div>
                    <div className={`year-num ${ activeYear===5  && yearType==="y" ?"active":""}`} onClick={() => yearHandler(5, "y")}>
                      <div>5Y</div>
                    </div>
                    <div className={`year-num ${ activeYear===15  && yearType==="ALL" ? "active":""}`} onClick={() => yearHandler(15, "ALL")}>
                      <div>All</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 py-4 ps-5 table-responsive">
                  <table>
                    <tr>
                      <td className='px-lg-5 text-label'>NAV: {inString(schemeDetail?.navdate)}</td>
                      <td className='px-lg-5 text-label'>Min. SIP amount </td>
                      <td className='px-5 text-label wd-11rem'>Min. Purchase amount</td>
                    </tr>
                    <tr>
                      <td className='px-5 pt-3'>₹ {schemeDetail?.navrs}</td>
                      <td className='px-5 pt-3'>₹1000</td>
                      <td className='px-5 pt-3'>₹5000 </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-12 py-5">
                  <button className='btn-custom float-left' onClick={investInPurchase}>Invest Now</button>
                  <button className='btn-custom float-right' onClick={() =>addToCart(schemeDetail)}>Add to Cart</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <LumpsumForm
          show={lumpsumForm}
          setShow={setLumpsumForm}
          rightSchemes={rightSchemes}
        />
    </>
  )
}
export default NavSingle