import { FV, pmtvalue, presentValue } from "./calculatorHelper"
import { interestRates } from "./goalEnum"

export const goalCalculater =async (amount,tenure)=>{
    let ir = Number(tenure) > 10 ? 16 : interestRates[tenure]
    let irpercent = (ir / 100) / 12
    let totmonth = tenure * 12
    let futurevalue = await FV(amount, 6, tenure);
   let lumpsumRequired = Math.round(futurevalue)
    let newsipamt = await pmtvalue(irpercent, totmonth, 0, -Math.round(futurevalue), 1)
    newsipamt = Math.round(newsipamt)
    // let lumpsumRate = ir / 100;
    // let lumpsumRequired = await presentValue(lumpsumRate, tenure, 0, Math.round(amount));
    // lumpsumRequired =Math.round(lumpsumRequired)
    return {newsipamt,lumpsumRequired}  
}
export const wealthGoalCalculater =async (amount,tenure)=>{
    let ir = Number(tenure) > 10 ? 16 : interestRates[tenure]
    let irpercent = (ir / 100) / 12
    let totmonth = tenure * 12
    let newsipamt = await pmtvalue(irpercent, totmonth, 0, -Math.round(amount), 1)
    newsipamt = Math.round(newsipamt)
    let lumpsumRate = ir / 100;
    let lumpsumRequired = await presentValue(lumpsumRate, tenure, 0, Math.round(amount));
    lumpsumRequired =Math.round(lumpsumRequired)
    return {newsipamt,lumpsumRequired}
}