import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "animate.css";
import "../src/assets/css/responsive.css";
import { Routes, Route, useLocation } from "react-router-dom";
import LoginWithOption from "./pages/login-with-option";
import Login_With_Mobile from "./pages/login-with-mobile";
import Login_With_Email from "./pages/login-with-email";
import Login_With_Google from "./pages/login-with-google";
import Registration from "./pages/registration";
import Forgot_Password from "./pages/forgot-password";
import Dashboard from "./pages/dashboard";
import Sipstpswp_Report from "./pages/sipstpswp-report";
import Transaction_Report from "./pages/transaction-report";
import Tax_Saving_Investments from "./pages/tax-saving-investments";
import Dividends from "./pages/dividends";
import Portfolio from "./pages/portfolio";
import Folio_Detail from "./pages/folio-detail";
import My_Orders from "./pages/my-orders";
import Bank_Mandate from "./pages/bank-and-mandate";
import Add_Bank from "./pages/add-bank";
import CreateEmandate from "./pages/create-mandate";
import SimplySave from "./pages/simply-save";
import Wrap from "./components/wrap";
import SignUp_With_Google from "./pages/signup-with-google";
import Forgot_Pin from "./pages/forgot-pin";
import { useEffect, useState, createContext } from "react";
import Tax_Planning from "./pages/tax-planning";
import NavSingle from "./components/nav";
import GetRightScheme from "./pages/get-right-scheme";
import KnowYourRiskProfile from "./pages/Know-your-risk-profile";
import SipCalculator from "./pages/sip-calculator";
import MarriageCalculator from "./pages/marriage-calculator";
import EducationCalculator from "./pages/education-calculator";
import FutureValueCalculator from "./pages/future-value-calculator";
import RetirementCalculator from "./pages/retirement-calculator";
import EmiCalculator from "./pages/emi-calculator";
import FdCalculator from "./pages/fd-calculator";
import ElssCalculator from "./pages/elss-calculator";
import AddFamilyMember from "./pages/add-family-member";
import ProfileCreation from "./pages/profile-creation-iin";
import RequiredSteps from "./pages/required-steps";
import RequiredDetailsForm from "./pages/required-details-form";
import Transact from "./pages/transact";
import RetirementGoal from "./pages/retirement-goal";
import GoalSummary from "./pages/goal-summary";
import GoalWiseScheme from "./pages/goal-wise-scheme";
import ChildEducationGoal from "./pages/child-education-goal";
import WealthCreationGoal from "./pages/wealth-creation-goal";
import SimplySIP from "./pages/simply-sip";
import PortfolioReview from "./pages/portfolio-review";
import Profile from "./pages/profile";
import SchemePerformance from "./pages/scheme-performance";
import KYCValidation from "./pages/kyc-validation";
import HousePurchaseGoal from "./pages/house-purchase-goal";
import CarPurchaseGoal from "./pages/car-purchase-goal";
import HomeRenovationGoal from "./pages/home-renovetion-goal";
import VacationGoal from "./pages/vacation-goal";
import ChildMarriageGoal from "./pages/child-marriage-goal";
import CustomizeGoal from "./pages/customize-goal";
import NfoLive from "./pages/nfo-live";
import NofDetail from "./pages/nfo-detail";
import { ToastContainer } from "react-toastify";
import Guard from "./utils/custom.guard";
import RequiredStepsFront from "./pages/required-steps-front";
import RequiredDetailsFormFront from "./pages/required-details-form-front";
import CompleteKYC from "./pages/CompleteKYC";
import Loader from "./pages/Loader";
import ErrorBoundary from "./pages/ErrorBoundary";
import { DataProvide } from "./pages/helpers/DataProvide";
import ThankYou from "./pages/thankyou"
import SwpCalculator from "./pages/swp-calculator";
import PortfolioNewuser from "./pages/portfolio-new-user";
import ViewCart from "./pages/view-cart";
import { CartCountProvide } from "./pages/helpers/CartCountProvide";
import NavGraph from "./components/nav-graph"
import {setupNotification} from "./firebase/firebase"

export const globalInfo = createContext();
function App() {
  const location = useLocation();
  const [notification,setNotification] = useState([])
  const [loader, setLoader] = useState("none");


  useEffect(()=>{
    setupNotification(notification,setNotification)
  },[])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
 
  return (
    <div className="App" >
      <ErrorBoundary>
      <globalInfo.Provider value={{ setLoader ,notification,setNotification}}>
        <DataProvide>
        <CartCountProvide>
        <Loader loader={loader} />
        <ToastContainer />
        <Routes>
          {Guard(Login_With_Mobile, "/login-with-mobile", {
            unAuthRequire: true,
          })}
          {Guard(Login_With_Email, "/login-with-email", {
            unAuthRequire: true,
          })}
          {Guard(Login_With_Google, "/login-with-google", {
            unAuthRequire: true,
          })}
          {Guard(Registration, "/registration", {
            unAuthRequire: true,
          })}
          {Guard(LoginWithOption, "/", {
            unAuthRequire: true,
          })}
          {Guard(Forgot_Pin, "/forgot-pin", {
            unAuthRequire: true,
          })}
          {Guard(SignUp_With_Google, "/signup-with-google", {
            unAuthRequire: true,
          })}
          {/* {Guard(Wrap, "/", { authRequire: true }, { Component: Dashboard })} */}
          <Route element={<Wrap Component={Dashboard} />} path="/dashboard" />

       

          {Guard(
            Wrap,
            "/complete-kyc",
            { authRequire: true },
            { Component: CompleteKYC }
          )}

        
            <Route
            element={<Wrap Component={Tax_Planning} />}
            path="/dashboard/tax-planning"
          />
              <Route
            element={<Wrap Component={NavSingle} />}
            path="/dashboard/nav-single"
          />
           
          {Guard(
            Wrap,
            "/dashboard/nav-graph",
            { authRequire: true },
            { Component: NavGraph }
          )}
            <Route
            element={<Wrap Component={GetRightScheme} />}
            path="/dashboard/get-right-scheme"
          />
          {/* {Guard(
            Wrap,
            "/dashboard/know-your-risk-profile",
            { authRequire: true },
            { Component: KnowYourRiskProfile }
          )} */}
           <Route
            element={<Wrap Component={KnowYourRiskProfile} />}
            path="/dashboard/know-your-risk-profile"
          />
           <Route
            element={<Wrap Component={AddFamilyMember} />}
            path="/dashboard/add-family-member"
          />
         
          {/* {Guard(
            Wrap,
            "/dashboard/Profile-creation",
            { authRequire: true },
            { Component: ProfileCreation },
            )}  {Guard(
              Wrap,
              "/dashboard/required-steps",
              { authRequire: true },
              { Component: RequiredSteps },
              true
              )} 
              {Guard(
                Wrap,
                "/dashboard/required-details-form",
                { authRequire: true },
                { Component: RequiredDetailsForm },
                true
              )} */}
              <Route
               element={<Wrap Component={ProfileCreation} />}
               path="/dashboard/Profile-creation"
             />
            <Route
            element={<Wrap Component={RequiredSteps} />}
            path="/dashboard/required-steps"
          />
            <Route
            element={<Wrap Component={RequiredDetailsForm} />}
            path="/dashboard/required-details-form"
          />
          {/* {Guard(
            Wrap,
            "/dashboard/transact",
            { authRequire: true },
            { Component: Transact },
            true 
          )}*/}
           <Route
            element={<Wrap Component={Transact} />}
            path="/dashboard/transact"
          />
         
            <Route
            element={<Wrap Component={Sipstpswp_Report} />}
            path="/dashboard/sipstpswp-report"
          />
            <Route
            element={<Wrap Component={Transaction_Report} />}
            path="/dashboard/transaction-report"
          />
            <Route
            element={<Wrap Component={Tax_Saving_Investments} />}
            path="/dashboard/tax-saving-investments"
          />
            <Route
            element={<Wrap Component={Dividends} />}
            path="/dashboard/dividends"
          />
          {/* {Guard(
            Wrap,
            "/dashboard/portfolio",
            { authRequire: true },
            { Component: Portfolio },
            true
          )} */}
           <Route
            element={<Wrap Component={Portfolio} />}
            path="/dashboard/portfolio"
          />
          {Guard(
            Wrap,
            "/dashboard/folio-detail",
            { authRequire: true },
            { Component: Folio_Detail }
          )}
          {Guard(
            Wrap,
            "/dashboard/my-orders",
            { authRequire: true },
            { Component: My_Orders },
            true
          )}
          {Guard(
            Wrap,
            "/dashboard/bank-and-mandate",
            { authRequire: true },
            { Component: Bank_Mandate },
            true
          )}
          {Guard(
            Wrap,
            "/dashboard/add-bank",
            { authRequire: true },
            { Component: Add_Bank }
          )}
          {Guard(
            Wrap,
            "/dashboard/create-mandate",
            { authRequire: true },
            { Component: CreateEmandate }
          )}
          {Guard(
            Wrap,
            "/dashboard/simply-save",
            { authRequire: true },
            { Component: SimplySave },
            true
          )}
            <Route
            element={<Wrap Component={RetirementGoal} />}
            path="/dashboard/retirement-goal"
          />
          
          <Route
            element={<Wrap Component={GoalSummary} />}
            path="/dashboard/goal-summary"
          />
          {Guard(
            Wrap,
            "/dashboard/goal-wise-scheme",
            { authRequire: true },
            { Component: GoalWiseScheme }
          )}
          <Route
            element={<Wrap Component={ChildEducationGoal} />}
            path="/dashboard/child-education-goal"
          />
          <Route
            element={<Wrap Component={WealthCreationGoal} />}
            path="/dashboard/wealth-creation-goal"
          />
         
           <Route
            element={<Wrap Component={SimplySIP} />}
            path="/dashboard/simply-sip"
          />
          {Guard(
            Wrap,
            "/dashboard/portfolio-review",
            { authRequire: true },
            { Component: PortfolioReview }
          )}
          {Guard(
            Wrap,
            "/dashboard/profile",
            { authRequire: true },
            { Component: Profile }
          )}
          {Guard(
            Wrap,
            "/dashboard/scheme-performance",
            { authRequire: false },
            { Component: SchemePerformance }
          )}
          <Route
            element={<Wrap Component={HousePurchaseGoal} />}
            path="/dashboard/house-purchase-goal"
          />
          {Guard(
            Wrap,
            "/dashboard/simply-sip",
            { authRequire: true },
            { Component: SimplySIP }
          )}
          {Guard(
            Wrap,
            "/dashboard/portfolio-review",
            { authRequire: true },
            { Component: PortfolioReview }
          )}
          <Route
            element={<Wrap Component={CarPurchaseGoal} />}
            path="/dashboard/car-purchase-goal"
          />
          <Route
            element={<Wrap Component={HomeRenovationGoal} />}
            path="/dashboard/home-renovetion-goal"
          />
          <Route
            element={<Wrap Component={VacationGoal} />}
            path="/dashboard/vacation-goal"
          />
          <Route
            element={<Wrap Component={ChildMarriageGoal} />}
            path="/dashboard/child-marriage-goal"
          />
          <Route
            element={<Wrap Component={CustomizeGoal} />}
            path="/dashboard/customize-goal"
          />

           <Route
            element={<Wrap Component={NfoLive} />}
            path="/dashboard/nfo-live"
          />
           <Route
            element={<Wrap Component={NofDetail} />}
            path="/dashboard/nfo-detail"
          />
          
          {Guard(
            Wrap,
            "/dashboard/nfo-detail",
            { authRequire: true },
            { Component: NofDetail }
          )}
          {Guard(
            Wrap,
            "/required-steps-front",
            { authRequire: true },
            { Component: RequiredStepsFront }
          )}
          {Guard(
            Wrap,
            "/required-details-form-front",
            { authRequire: true },
            { Component: RequiredDetailsFormFront }
          )}

          {/* {Guard(
            Wrap,
            "/kyc-validation",
            { authRequire: true },
            { Component: KYCValidation }
          )} */}
           {Guard(
            Wrap,
            "/dashboard/thank-you",
            { authRequire: true },
            { Component: ThankYou }
          )}
          {Guard(
            Wrap,
            "/thank-you",
            { authRequire: true },
            { Component: ThankYou }
          )}

          <Route element={<Forgot_Password />} path="/forgot-password" />
          <Route
            element={<Wrap Component={SipCalculator} />}
            path="/dashboard/sip-calculator"
          />
            <Route
            element={<Wrap Component={ViewCart} />}
            path="/dashboard/view-cart"
          />
          <Route
            element={<Wrap Component={MarriageCalculator} />}
            path="/dashboard/marriage-calculator"
          />
          <Route
            element={<Wrap Component={EducationCalculator} />}
            path="/dashboard/education-calculator"
          />
          <Route
            element={<Wrap Component={FutureValueCalculator} />}
            path="/dashboard/future-value-calculator"
          />
          <Route
            element={<Wrap Component={RetirementCalculator} />}
            path="/dashboard/retirement-calculator"
          />
          <Route
            element={<Wrap Component={EmiCalculator} />}
            path="/dashboard/emi-calculator"
          />
          <Route
            element={<Wrap Component={FdCalculator} />}
            path="/dashboard/fd-calculator"
          />
          <Route
            element={<Wrap Component={ElssCalculator} />}
            path="/dashboard/elss-calculator"
          />
           <Route
            element={<Wrap Component={SwpCalculator} />}
            path="/dashboard/swp-calculator"
          />
           <Route
          element={<Wrap Component={PortfolioNewuser} />}
          path="/dashboard/portfolionewuser"
        />
          <Route element={<Wrap Component={Dashboard} />} path="*" />
        </Routes>
        </CartCountProvide>
        </DataProvide>
      </globalInfo.Provider>
     </ErrorBoundary>
    </div>
  );
}

export default App;
