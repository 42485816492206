import React, { useState } from "react";
import SIPTransact from "../components/sip-transact";
import NewPurchaseTransact from "../components/new-purchase-transact";
import AddPurchaseTransact from "../components/add-purchase-transact";
import SwitchTransact from "../components/switch-transact";
import STPTransact from "../components/stp-transact";
import SWPTransact from "../components/swp-transact";
import RedemptionTransact from "../components/redemption-transact";
import { useEffect } from "react";
import { getAmcList, userProfile } from "../apisMethods";
import { Link ,useNavigate} from "react-router-dom";
import { CheckPathRediction } from "./helpers/redirection";

// import success from "../../assets/images/icons/others/successfully.png";

const Transact = () => {
  const navigate = useNavigate()
  const [sipShow, setSipShow] = useState(false);
  const [newPurchaseShow, setNewPurchaseShow] = useState(false);
  const [addPurchaseShow, setAddPurchaseShow] = useState(false);
  const [switchShow, setSwitchShow] = useState(false);
  const [stpShow, setStpShow] = useState(false);
  const [swpShow, setSwpShow] = useState(false);
  const [redemptionShow, setRedemptionShow] = useState(false);
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  }, []);


  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if(!res.data){
        setUserProfileData([])
      }else{
        setUserProfileData(res.data?.data)
      }
    })

  }
const componentOpen = (setShowPopup)=>{
  const isLoggedIn = CheckPathRediction()
  if (!isLoggedIn?.token) {
    navigate("/");
    return
  }else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
    navigate("/dashboard/Profile-creation");
  }  else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
    navigate("/dashboard/Profile-creation");
  } else {
    setShowPopup(true)
  } 
   
}
  return (
    <>
      <div className="wrapper">
        <div className="px-lg-5 px-smc-1">
          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Transact
              </li>
            </ol>
          </nav>
          <section className="pb-4 transact">
            <div className="container bg-light-red tp">
              <div className="row align-items-stretch">
                <div className="col-md-3 align-self-center mb-4">
                  <div className=" transactcard  py-lg-5 py-3 mx-lg-3  roundedc bg-white transact-link" onClick={()=>componentOpen(setSipShow)}>

                    <div className="goal-card text-center">
                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/bar_chart.png"} className="mr-2 new-icon  p-3" alt="" />
                      <br />
                      <h4 className="pt-4">SIP</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4 pt-xs-2 mb-4">
                      <div className="transactcard py-3 mx-lg-3 roundedc bg-white transact-link" onClick={()=>componentOpen(setNewPurchaseShow)}>
                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/new_puchase.png"}
                            className="mr-2 new-icon p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">New Purchase</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pt-xs-2">
                      <div className="transactcard py-3 mx-lg-3 roundedc mb-4 bg-white transact-link" onClick={() => componentOpen(setAddPurchaseShow)}>

                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add_puchase.png"}
                            className="mr-2 new-icon  p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">Additional Purchase</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pt-xs-2">
                      <div className=" transactcard py-3 mx-lg-3 roundedc bg-white transact-link" onClick={() => componentOpen(setSwitchShow)}>

                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/switch.png"}
                            className="mr-2 new-icon  p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">Switch</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pt-4">
                      <div className="transactcard py-3 mx-lg-3 roundedc bg-white transact-link" onClick={() => componentOpen(setStpShow)}>

                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/STP.png"}
                            className="mr-2 new-icon  p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">STP</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pt-4">
                      <div className="transactcard py-3 mx-lg-3 roundedc bg-white transact-link" onClick={() => componentOpen(setSwpShow)}>

                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/withdraw.png"}
                            className="mr-2 new-icon  p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">SWP</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pt-4">
                      <div className="transactcard py-3 mx-lg-3 roundedc bg-white transact-link" onClick={() => componentOpen(setRedemptionShow)}>

                        <div className="goal-card text-center">
                          <img
                            src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/redemption.png"}
                            className="mr-2 new-icon  p-3"
                            alt=""
                          />
                          <br />
                          <h4 className="pt-4">Redemption</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <SIPTransact
              show={sipShow}
              setShow={setSipShow}
              amcList={amcList}
              userProfileData={userProfileData}

            />
            <NewPurchaseTransact
              show={newPurchaseShow}
              setShow={setNewPurchaseShow}
              amcList={amcList}
              userProfileData={userProfileData}
              purchase={"New Purchase"}
            />
            <AddPurchaseTransact
              show={addPurchaseShow}
              setShow={setAddPurchaseShow}
              amcList={amcList}
              userProfileData={userProfileData}
            />
            <SwitchTransact
              show={switchShow}
              setShow={setSwitchShow}
              amcList={amcList}
              userProfileData={userProfileData}
            />
            <STPTransact
              show={stpShow}
              setShow={setStpShow}
              amcList={amcList}
              userProfileData={userProfileData}
            />
            <SWPTransact
              show={swpShow}
              setShow={setSwpShow}
              amcList={amcList}
              userProfileData={userProfileData}
            />
            <RedemptionTransact
              show={redemptionShow}
              setShow={setRedemptionShow}
              amcList={amcList}
              userProfileData={userProfileData}
            />
          </section>
        </div>
      </div>
    </>
  );
};
export default Transact;
