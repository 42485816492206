import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Opps from "../assets/images/others/oops.png";
import { Link } from "react-router-dom";

const SWW = ({show,setShows,errorMsg})=>{
return (
    <>

       <Modal show={show}  onHide={()=>setShows(false)} centered  className="animate__animated animate__zoomIn animate__fast  ">
        <Modal.Body className="text-center pb-0">
       <img src={Opps} className="img-fluid mw-35"/>
       <p class="text-black fw-400 py-3 mb-0">{errorMsg}</p>
        </Modal.Body>
        <div class="modal-footer border-0 justify-content-center pt-0">
        <button className="btn-custom mt-3" onClick={()=>setShows(false)}>OK</button>
       
       </div>
      </Modal>
    </>
  )
}
export default SWW;