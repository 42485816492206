
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { addBank, bankListApi, getIfscDetails, updateNseMultiUserBankdetails, uploadBankProof } from "../apisMethods";
import { validation } from "./helpers/validation";
import { notify } from "./toastCreater";
import { globalInfo } from "../App";
import { useLocation } from "react-router-dom"
import Thanku from '../components/thanku';

const Add_Bank = () => {
  const { setLoader } = useContext(globalInfo)
  const [headers, setHeaders] = useState({})
  const [bankListData, setBankListData] = useState([])
  const [typeHandler, setTypeHandler] = useState("text")
  const [confirmShow2, setConfirmShow2] = useState(false)
  const [successMessage2, setSuccessMessage2] = useState("")


  const { state: userDetails } = useLocation();

  // const [bankDetails, setBankDetails] = useState({})

  const [inputValues, setInputValues] = useState({
    ifscCode: "",
    bankType: "",
    accountNumber: "",
    confirmAccountNumber: "",
    bankName: "",
    fileType: "",
    bankProof: "",
    branchName: "",
    branchAddress1: "",
    bankFullName: "",
    micrNo:""


  })
  const [error, setError] = useState({
    ifscCode: "",
    bankType: "",
    accountNumber: "",
    confirmAccountNumber: "",
    bankName: "",
    fileType: "",
    bankProof: "",
    micrNo:""
  })
  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    // setUserDetails(userdata);
    setHeaders(header);
    BankListFun(header)
  }, [])

  const BankListFun = async (header) => {
    bankListApi(header).then((res) => {
      if (!res.data) {
      } else {
        setBankListData(res?.data?.data);
      }
    });
  };
  const changeHandler = (e) => {
    const { name, value } = e.target
    validation(name, value, setError)
    setInputValues({ ...inputValues, [name]: value })
    if (name === "ifscCode") {
      setInputValues({ ...inputValues, [name]: value.toUpperCase() })
      forIfscCode(name, value)
    }
    if (name === "bankName" && value) {
      let tempBankData = JSON.parse(value)
      setInputValues({ ...inputValues, ["bankFullName"]: tempBankData?.BANK_NAME, ["bankCode"]: tempBankData?.BANK_CODE, [name]: value })
      console.log(tempBankData);
      forIfscCode(name, value)
    }
  }



  const forIfscCode = (name, value) => {
    if (name === "ifscCode" && value.length === 11) {
      setLoader("block")
      getIfscDetails({ ifsc: value }, headers).then(res => {
        if (res.data?.success === false) {
          notify("error", res.data?.msg)
          setError({ ...error, ifscCode: "IFSC is not valid" })
          setInputValues({ ...inputValues, ["branchAddress1"]: "", ["branchName"]: "", [name]: value.toUpperCase() })
        } else {
          setError({ ...error, ifscCode: "" })
          setInputValues({ ...inputValues, ["branchAddress1"]: res.data.data.ADDRESS, ["branchName"]: res.data.data.BRANCH, [name]: value.toUpperCase() })
          // ["bank_codes"]: res.data.data.BANKCODE,["bank_codes"]: res.data.data.BANK,
        }
        setLoader("none")
      })
    } else if (name === "ifscCode") {
      setInputValues({ ...inputValues, ["branchAddress1"]: "", ["branchName"]: "", [name]: value.toUpperCase() })
    }
  }
  const fileChangeHandler = (e) => {
    const { name } = e.target
    const maxFileSizeInMB = 1;
    const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
    if (e?.target?.files[0]?.type == "application/pdf") {
      if (e?.target?.files[0]?.size > maxFileSizeInKB) {
        e.target.value = null;
        setInputValues({ ...inputValues, [name]: "" })
        notify("warn", "File size should be less than 1MB");
        return
      }
      // setBankProof(e.target.files[0]);
      setInputValues({ ...inputValues, [name]: e.target.files[0] })
    } else {
      e.target.value = null
      setInputValues({ ...inputValues, [name]: "" })
      notify("warn", "Please upload PDF file only")
    }
  }
  const submitBank = (e) => {

    e.preventDefault()
    let tempObj = {
      ifscCode: "",
      bankType: "",
      accountNumber: "",
      confirmAccountNumber: "",
      bankName: "",
      fileType: "",
      bankProof: "",
      branchAddress1: "",
      branchName: ""
    }

    let flag = false
    if (!inputValues?.ifscCode) {
      tempObj.ifscCode = "Mandatory Field"
      flag = true
    } else if (!inputValues?.branchName) {
      tempObj.ifscCode = "IFSC is not valid"
      flag = true

    }
    if (!inputValues?.accountNumber) {
      tempObj.accountNumber = "Mandatory Field"
      flag = true
    }
    if (!inputValues?.confirmAccountNumber) {
      tempObj.confirmAccountNumber = "Mandatory Field"
      flag = true
    }

    if (!inputValues?.bankType) {
      tempObj.bankType = "Mandatory Field"
      flag = true
    }
    if (!inputValues?.bankName) {
      tempObj.bankName = "Mandatory Field"
      flag = true
    }
    if (!inputValues?.fileType) {
      tempObj.fileType = "Mandatory Field"
      flag = true
    }
    if (!inputValues?.bankProof) {
      tempObj.bankProof = "Mandatory Field"
      flag = true
    }

    if (inputValues?.micrNo.length>=1 && inputValues?.micrNo.length<9 ) {
      tempObj.micrNo = "MICR No should be 9 digit"
      flag = true
    }
   
    if (inputValues?.accountNumber !== inputValues?.confirmAccountNumber) {
      tempObj.confirmAccountNumber = "Account Number & confirm Account Number don't match."
      flag = true
    }


    setError(tempObj)
    if (!flag) {
      // setBankDetails(inputValues)
      finalSubmitBank(inputValues)
    }
  }

  const finalSubmitBank = (inputValues) => {
    const reqBody = {
      iin: userDetails?.iin,
      acc_no: inputValues?.accountNumber,
      acc_type: inputValues?.bankType,
      ifsc_code: inputValues?.ifscCode,
      micr_no: inputValues?.micrNo ? inputValues?.micrNo:"",
      bank_name: inputValues?.bankCode,
      branch_name: inputValues?.branchName,
      branch_address1: "",
      // branch_address1: inputValues?.branchAddress1,
      proof_of_account: inputValues?.fileType,
      branch_address2: "",
      branch_address3: "",
      branch_city: "",
      branch_country: "",
      branch_pincode: "",
      acc_rel: ""
    }
    const formData = new FormData()
    formData.append("ImageType", "B");
    formData.append("CustomerID", userDetails?.iin);
    formData.append("ImageFormat", "PDF");
    formData.append("fileUpload", inputValues?.bankProof);
    formData.append("AccNo", inputValues?.accountNumber);
    formData.append("BankCode", inputValues?.bankCode);
    setLoader("block")

    addBank(reqBody, headers).then(res => {
      if (res.data?.success === true && res.data?.data?.Status_Desc?.includes("successfully")) {
        uploadBankProof(formData, headers).then((res) => {
          if (res.data?.success === true) {
            updateNseMultiUserBankdetails({ iin: userDetails?.iin }, headers).then((res) => {
              if (res.data?.success === true) {
                setSuccessMessage2('New bank details have been successfully added. Please note that it may take 1-2 working days for the changes to reflect in your account after scrutiny.')
                setConfirmShow2(true)
                setInputValues({
                  ifscCode: "",
                  bankType: "",
                  accountNumber: "",
                  confirmAccountNumber: "",
                  bankName: "",
                  fileType: "",
                  bankProof: null,
                  branchName: "",
                  branchAddress1: "",
                  bankFullName: "",
                  micrNo:""
                })
                document.getElementById("bankProof").value = ""
                setLoader("none")
              } else {
                setLoader("none")
                notify("error", res.data?.msg)
              }
            })
          } else {
            notify("error", res.data?.data?.Status_Desc)
          }
          setLoader("none")
        })

      } else {
        setLoader("none")
        notify("error", res.data?.data?.Status_Desc)
      }
    })
  }
  return (
    <>
      <div className="wrapper px-3 pe-4">
        <div className="report px-lg-5 ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Add Bank</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="container-fluid mt-5">
            <div className="row justify-content-around mx-lg-4">
              <div className="col-md-7 shadow-custom bg-gray text-start m-0 p-0">
                <div className="form-title px-3">
                  <h6 className="text-red fw-bold py-3 mb-0">Add Bank</h6>
                </div>
                <Form className="p-4" onSubmit={submitBank}>
                  <Row className="mb-3">
                    <Form.Group className="col-12 col-md-6" controlId="formGridEmail">
                      <Form.Label>Enter IFSC Code</Form.Label>
                      <Form.Control type="text" maxLength={11} placeholder='Enter IFSC Code' className="bg-white border-0" name="ifscCode" value={inputValues.ifscCode} onChange={changeHandler} />
                      <small className="text-red pull-left">
                        {error?.ifscCode}
                      </small>
                    </Form.Group>
                    <Form.Group className="col-12 col-md-6" controlId="formGridPassword">
                      <Form.Label>Select Bank Type</Form.Label>
                      <Form.Select
                        className="bg-c"
                        name="bankType"
                        value={inputValues.bankType}
                        onChange={changeHandler}
                      >
                        <option value="">Select Account Type</option>
                        <option value="SB">Savings Account</option>
                        <option value="NRE">Non-Resident External Account</option>
                        <option value="CC">Cash/Credit</option>
                        <option value="CA">Current Account</option>
                        <option value="FCNR">Foreign Currency Non-Resident</option>
                        <option value="NRO">Non-Resident Ordinary</option>
                        <option value="OD">Overdraft Account</option>
                        <option value="PSB">Post Office Saving Account</option>
                      </Form.Select>
                      <small className="text-red pull-left">
                        {error.bankType}
                      </small>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group className="col-12 col-md-6" controlId="formGridEmail">
                      <Form.Label>Enter Account Number</Form.Label>
                      <Form.Control type={typeHandler} placeholder='Enter Account Number' className="bg-white" maxLength="16" onFocus={() => setTypeHandler("text")}
                        minLength="10" name="accountNumber" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} value={inputValues.accountNumber} onChange={changeHandler} />
                      <small className="text-red pull-left">
                        {error.accountNumber}
                      </small>
                    </Form.Group>
                    <Form.Group className="col-12 col-md-6" controlId="formGridEmail">
                      <Form.Label>Re-Enter Account Number</Form.Label>
                      <Form.Control type="text" className="bg-white" maxLength="16"
                        onFocus={() => setTypeHandler("password")}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        minLength="10" name="confirmAccountNumber" placeholder='Enter Confirm Account Number' value={inputValues.confirmAccountNumber} onChange={changeHandler} />
                      <small className="text-red pull-left">
                        {error.confirmAccountNumber}
                      </small>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group className="col-12 col-md-6" controlId="formGridPassword">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Select name="bankName" value={inputValues.bankName} onChange={changeHandler} >
                        <option value="">Select Bank Name</option>
                        {bankListData &&
                          bankListData.map((data, i) => {
                            return (<>
                              <option value={JSON.stringify({ BANK_CODE: data.BANK_CODE, BANK_NAME: data.BANK_NAME })} key={i}>{data.BANK_NAME}</option></>
                            );
                          })}
                      </Form.Select>
                      <small className="text-red pull-left">
                        {error.bankName}
                      </small>
                    </Form.Group>
                    <Form.Group className="col-12 col-md-6" controlId="formGridPassword">
                      <Form.Label>Select File Type</Form.Label>
                      <Form.Select name="fileType" value={inputValues?.fileType} onChange={changeHandler} >
                        <option value="">Select File Type</option>
                        <option value="Original cancelled cheque">Cancelled cheque</option>
                        <option value="Attested copy of bank passbook frontpage">Bank passbook</option>
                        <option value="Attested copy of bank statement">Bank statement</option>
                      </Form.Select>
                      <small className="text-red pull-left">
                        {error.fileType}
                      </small>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} sm={6} controlId="formGridPassword">
                      <Form.Label>Upload Bank Proof</Form.Label>
                      <Form.Control type="file" className="bg-white" accept='.pdf' id='bankProof' name="bankProof" onChange={fileChangeHandler} />
                      <small className="text-red pull-left">
                        {error.bankProof}
                      </small>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} controlId="formGridPassword">
                      <Form.Label>MICR NO.(Optional)</Form.Label>
                      <Form.Control type="text" className="bg-white" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} placeholder='MICR NO.' name="micrNo" maxLength={9}  value={inputValues.micrNo} onChange={changeHandler} />
                      <small className="text-red pull-left">
                        {error.micrNo}
                      </small>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="text-lg-end text-center">
                      <button type="submit" className="btn-custom">Submit</button>
                    </Col>
                  </Row>
                </Form>
              </div>
              {/* {bankDetails?.accountNumber ? <div className="col-md-4 shadow-custom text-start m-0 p-0">
                <div className="form-title px-3">
                  <h6 className="text-red fw-bold py-3 mb-0">Bank Details</h6>
                </div>
                <Table>
                  <tbody className="bank-detail">
                    <tr>
                      <th className="min-width-10" >Bank Name</th>
                      <td>{bankDetails?.bankFullName}</td>
                    </tr>
                    <tr>
                      <th className="min-width-10">Bank Address</th>
                      <td>{bankDetails?.branchAddress1}</td>
                    </tr>
                    <tr>
                      <th className="min-width-10">Branch Name</th>
                      <td>{bankDetails?.branchName}</td>
                    </tr>
                    <tr>
                      <th className="min-width-10">Account No.</th>
                      <td>{bankDetails?.accountNumber}</td>
                    </tr>
                    <tr>
                      <th className="min-width-10">IFSC</th>
                      <td>{bankDetails?.ifscCode}</td>
                    </tr>

                    <div className="row">
                      <div className="col-md-12 text-end pt-4">
                        <button type="button" className="btn-custom" onClick={finalSubmitBank}>Confirm and Submit</button>
                      </div>
                    </div>
                  </tbody>

                </Table>

              </div> : ""} */}

            </div>
          </div>
        </section>

      </div>
      <Thanku
        show={confirmShow2}
        setShow={setConfirmShow2}
        successMessage={successMessage2}
      />

    </>
  )
}
export default Add_Bank;