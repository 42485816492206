import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Thanku from "./thanku";
import { notify } from "../pages/toastCreater";
import {
  contactUs
} from "../apisMethods";
const ContactUs = ({ show, setShow }) => {
  const [confirmShow, setConfirmShow] = useState(false);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const submitHandler = (e) => {
    e.preventDefault()
    if (!email) {
      notify("error", "Please enter email")
      return
    }

    var regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Converting the email to lowercase
    if (regexp.test(String(email).toLowerCase()) == false) {
      notify("error", "Please enter valid email")
      return
    }
    if (!mobile) {
      notify("error", "Please enter mobile number")
      return
    }
    if (mobile.length!==10) {
      notify("error", "Please enter valid mobile number")
      return
    }
    if (!query) {
      notify("error", "Please select your query")
      return
    }
    if (!message) {
      notify("error", "Please enter your query message")
      return
    }
    contactUs({mobile,email,query,message}).then(res=>{
      if(!res.data){
        notify("error", "Your form is not submitted, Please try again")
      }else{
        setEmail('')
        setMobile('')
        setMessage('')
        setQuery('')
        setSuccessMessage("We have received your query, we will get back to you shortly.")
        setShow(false)
        setConfirmShow(true)

      }
    })
  }
  const mobileHandler = (e) => {
    const inputValue = e.target.value;
    if(inputValue.length <=10){
      const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
      console.log(sanitizedValue)
      setMobile(sanitizedValue);
    }

  };
  const emailHandler = (e) =>{
   let value=e.target.value.replace(/\s/g, "");
    if(value.length <40){
      setEmail(value)
    }
  }
  const messageHandler = (e)=>{
    let value = e.target.value
    if(value.length<200){
      setMessage(value)
    }
  }
  const querys = [
    { value: "", label: "--Select Query--" },
    { value: "SIP/SWP/STP Cancellation", label: "SIP/SWP/STP Cancellation" },
    { value: "Mandate Creation", label: "Mandate Creation" },
    { value: "IIN Related Query", label: "IIN Related Query" },
    { value: "Non-Individual KYC", label: "Non-Individual KYC" },
    { value: "KYC Registration", label: "KYC Registration"},
    { value: "Difference in Portfolio Valuation", label: "Difference in Portfolio Valuation" },
    { value: "Investment account-Non-individual", label: "Investment account-Non-individual" },
    { value: "Capital gain statement", label: "Capital gain statement" },
    { value: "Portfolio Review", label: "Portfolio Review" },
    { value: "Delete Prsonal Data", label: "Delete Personal Data" },
    { value: "Correction of Data", label: "Correction of Data" },
    { value: "Other", label: "Other" },
  ];
  return (
    <>
      <Modal show={show} onHide={() =>
        setShow(false)} className="border-red" centered  >
        <Modal.Header closeButton >
          <Modal.Title className="fs-5">Please send us your query or feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form onSubmit={submitHandler}>
            <div className="">
              <Form.Group className="mb-2">
                <Form.Label>Email id<span className="text-danger">*</span></Form.Label>
                <Form.Control className="single-drop border-0" type="email" required placeholder="Enter Your Email" value={email} onChange={emailHandler} />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label> Mobile Number <span className="text-danger">*</span></Form.Label>
                <Form.Control className="single-drop border-0" type="text" placeholder="Enter Your Mobile" required value={mobile} onChange={mobileHandler}/>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>   Query  <span className="text-danger">*</span></Form.Label>
                {/* <p>Query regarding FDs</p> */}
                <Select className='p-q single-drop  ' options={querys} placeholder="Choose Query" required onChange={(e) => setQuery(e.value)} />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>   Query Message  <span className="text-danger">*</span></Form.Label>
                <Form.Control as="textarea" className="single-drop border-0" type="text" placeholder="Please Mention Your Query ! " value={message} onChange={messageHandler} required/>
              </Form.Group>
              <Form.Group >
                <button type='submit' className="otp-btn mt-3 br-15 float-right"  >Submit</button>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0'>
        </Modal.Footer>
      </Modal>
      <Thanku show={confirmShow} setShow={setConfirmShow} successMessage={successMessage}/>
    </>
  )
}
export default ContactUs