import React, { useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { portfolioCapitalReview } from "../apisMethods";
import { globalInfo } from "../App";
import { notify } from "../pages/toastCreater";
import Thanku from "./thanku";

const ConfirmationPopUP = ({ show, setShow, successMessage }) => {
    const { setLoader } = useContext(globalInfo)
    const [userDetails, setUserDetails] = useState({})
    const [confirmShow2, setConfirmShow2] = useState("")
    const [successMessage2, setSuccessMessage2] = useState("")

    const sendMailForReview = () => {
        let token = localStorage.getItem("token");
        const header = { headers: { Authorization: `Bearer ${token}` } };
        // setHeaders(header);
        const userdata = JSON.parse(localStorage.getItem("user"));
        setUserDetails(userdata);
        if (!userdata?.pan) {
            notify("warn", "Please Complete Your KYC")
            return
        }
        let reqBody = {
            primaryName: userdata?.displayName,
            phone: userdata?.mobile,
            name: userdata?.displayName,
            email: userdata?.email,
            pan: userdata?.pan,
            type: "portfolioReview",
        };
        setLoader("block")
        portfolioCapitalReview(reqBody, header).then((res) => {
            if (!res.data) {
                notify("error", "something went wrong");
            } else {
                // notify("success", "Mail send successfully")
                setShow(false)
                setConfirmShow2(true);
                setSuccessMessage2(
                    "Your request has been received and acknowledged at our end, your query shall be responded shortly."
                );
            }
            setLoader("none")
        });
    };

    return (
        <>

            <Modal show={show} dialogClassName="modal-37w" onHide={() => setShow(false)} centered className="animate__animated animate__zoomIn animate__fast">
                <Modal.Body className="text-center py-4">

                    {/* <h3 className="text-successc fw-500 py-3"> Thankyou !!!</h3> */}
                    <p className="fw-500 text-center">
                        {/* We have received your query, we will get back to you shortly. */}
                        {successMessage}
                    </p>
                </Modal.Body>

                <div className=" order-b d-flex justify-content-around pb-4">
                    <div className="">
                        <button className='rounded border-dash px-3 py-1 single-drop'onClick={()=>setShow(false)} >No</button>
                    </div>
                    <div className="">
                        <button className='rounded border-dash single-drop px-3 py-1' onClick={sendMailForReview}>Yes</button>
                    </div>
                </div>
            </Modal>
            <Thanku
                show={confirmShow2}
                setShow={setConfirmShow2}
                successMessage={successMessage2}
            />
        </>
    )
}
export default ConfirmationPopUP;