import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SIP_Form from "../components/sip-form";
import LumpsumForm from "../components/lumpsum-form";
import { getAmcList, taxPlanningNseSchemes, userProfile } from "../apisMethods";
import Cart_Delete from '../components/delete-confirmation';
import img_no_investment_transaction from "../../src/assets/images/img_no_investment_transaction.png";
import { globalInfo } from "../App";
import { CheckPathRediction } from "./helpers/redirection";
import NewPurchaseTransact from "../components/new-purchase-transact";
import SIPTransact from "../components/sip-transact";
import { apis } from "../request";

function Tax_Planning() {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo)
  const [sipformShow, setSipformShow] = useState(false);
  const [lumpsumformShow, setLumpsumformShow] = useState(false);
  const [headerState, setHeaderState] = useState({});
  const [recomndedSchemes, setRecomndedSchemes] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [newPurchaseShow, setNewPurchaseShow] = useState(false);
  const [userProfileData, setUserProfileData] = useState([]);
  const [amcList, setAmcList] = useState([]);
  const [isLookingForAnother, setIsLookingForAnother] = useState(false);
  const [sipShow, setSipShow] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userdata)
    setHeaderState(header)
    getRightSchemeDurationRiskCall(header)
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, header)
      getAmcList(header).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  }, [])
  const getRightSchemeDurationRiskCall = (header) => {
    setLoader("block")
    taxPlanningNseSchemes(header).then(res => {
      if (!res.data) {
        setRecomndedSchemes([])
      } else {
        setRecomndedSchemes(res.data?.data)
      }
      setLoader("none")
    })
  }
  const deleteData = (id) => {
    recomndedSchemes.splice(id, 1);
    setRecomndedSchemes([...recomndedSchemes])
  }
  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if (!res.data) {
        setUserProfileData([])
      } else {
        setUserProfileData(res.data?.data)
      }
    })

  }
  const componentOpen = (setShowPopup) => {
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    } else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
      navigate("/dashboard/Profile-creation");
    } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      setShowPopup(true)
    }

  }
  const continueFromBothSide = (check) => {
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    } else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
      navigate("/dashboard/Profile-creation");
    } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      if (check === 1) {
        setLumpsumformShow(true)
      } else {
        setSipformShow(true)
      }
    }
  }
  const anotherScheme = (setPurchasePopup) => {
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    } else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
      navigate("/dashboard/Profile-creation")
    } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation")
    } else {
      setPurchasePopup(true)
      setIsLookingForAnother(true)
    }
  }

  const showGraph = (item,year)=>{
    console.log("=========",item);
    item["annualizedYear"] =year
        navigate("/dashboard/nav-single",{state:item})
  }  
  
  return (

    <>

      <div className="wrapper">
        <div className="px-lg-5 px-smc-1">

          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active">Tax Planning</li>
            </ol>
          </nav>

          <section>



            <Tabs>
              <div className="row justify-content-center pt-4 mb-4">
                <div className="col-md-6  table-responsive ">
                  <TabList>
                    <Tab>Lumpsum</Tab>
                    <Tab>SIP</Tab>
                  </TabList>
                </div>
              </div>

              <TabPanel>
                <div className="row">
                  <div className="col-xl-10 col-lg-10 offset-lg-1 col-md-12">
                    <div className="card shadow-custom bg-gray">

                      <div className="card-body custom-tab-bg">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table class="table custom text-table-black text-start">
                              <thead>

                                <tr>
                                  <th scope="col" colSpan="2" className='text-red text-start'>Recommended Schemes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {recomndedSchemes.length > 0 ? recomndedSchemes.map((data, i) => (
                                  <tr>
                                    <td><img src={
                                      `${apis.imageLink}${data?.AMC_CODE}.png`
                                    } alt="" className='img-fluid min-w-3em mx-wd-45' /></td>
                                    <td className='min-w-35em onHover'onClick={()=>showGraph(data,3)}>{data.PRODUCT_LONG_NAME}
                                    </td>
                                    {recomndedSchemes.length > 1 ?
                                    <td className='min-w-35em'> <Cart_Delete id={i} deleteData={deleteData} />
                                    </td>:""}
                                  </tr>
                                )) : <section >
                                  <div className="container-fluid">
                                    <div className="row justify-content-center">
                                      <div className="col-md-11 py-3">
                                        <div className="sipstpswp_error py-5 text-center">
                                          <div className="sipstpswp_error-img">
                                            <img src={img_no_investment_transaction} className="img-fluid mw-170" />
                                          </div>
                                          <h5 className="pt-3 text-red fw-600">No schemes available</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>}
                              </tbody>
                            </table>
                          </div>

                        </div>
                        <div className="row">
                          <div
                            className="underline fs-14 text-red pt-4 col-6 text-start "
                            onClick={() => anotherScheme(setNewPurchaseShow)}
                          >
                            <b className="ps-3">  Looking for another schemes ?</b>
                          </div>
                          {recomndedSchemes.length > 0 ?
                            <div className="col-6 mt-4 mb-3 text-lg-end text-center">

                              <a className="btn-custom text-color" onClick={() => continueFromBothSide(1)}>Continue</a>

                            </div> : ""}
                        </div>
                      </div>
                    </div>
                    {/* disclaimer */}
                    <div class="text-center pt-4 mb-0" role="alert">
                      <p className="text-disclaimer  fs-13 fs-sm-10 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row ">
                  <div className=" col-lg-10 offset-lg-1 ">
                    <div className="card shadow-custom bg-light-red">

                      <div className="card-body custom-tab-bg">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table class="table custom text-table-black text-start">
                              <thead>

                                <tr>
                                  <th scope="col" colSpan="2" className='text-red text-start'>Recommended Schemes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {recomndedSchemes.length > 0 ? recomndedSchemes.map((data, i) => (
                                  <tr>
                                    <td><img src={
                                      // `${apis.imageLink}growth.png`
                                      `${apis.imageLink}${data?.AMC_CODE}.png`
                                    } alt="" className='img-fluid min-w-3em mx-wd-45' /></td>
                                    <td className='min-w-35em onHover'onClick={()=>showGraph(data,3)}>{data.PRODUCT_LONG_NAME}
                                    </td>
                                    {recomndedSchemes.length > 1 ?
                                    <td className='min-w-35em'> <Cart_Delete id={i} deleteData={deleteData} />
                                    </td>:""}
                                  </tr>
                                )) : <section >
                                  <div className="container-fluid">
                                    <div className="row justify-content-center">
                                      <div className="col-md-11 py-3">
                                        <div className="sipstpswp_error py-5 text-center">
                                          <div className="sipstpswp_error-img">
                                            <img src={img_no_investment_transaction} className="img-fluid mw-170" />
                                          </div>
                                          <h5 className="pt-3 text-red fw-600">No schemes available</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>}
                              </tbody>
                            </table>
                          </div>

                        </div>  <div className="row">
                          <div
                            className="underline fs-14 text-red pt-4 col-6 text-start "
                            onClick={() => anotherScheme(setSipShow)}
                          >
                            <b className="ps-3">  Looking for another schemes ?</b>
                          </div>
                          {recomndedSchemes.length > 0 ?
                            <div className="col-6 mt-4 mb-3 text-lg-end text-center">
                              <a className="btn-custom text-color" onClick={() => continueFromBothSide(2)}>Continue</a>

                            </div> : ""}
                        </div>
                      </div>
                    </div>
                    {/* disclaimer */}
                    <div class="text-center pt-4 mb-0" role="alert">
                      <p className="text-disclaimer  fs-13 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>

          </section>
          <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={recomndedSchemes} />
          <LumpsumForm show={lumpsumformShow} setShow={setLumpsumformShow} rightSchemes={recomndedSchemes} />
          <NewPurchaseTransact
            show={newPurchaseShow}
            setShow={setNewPurchaseShow}
            amcList={amcList}
            userProfileData={userProfileData}
            purchase={"New Purchase"}
            isLookingForAnother={isLookingForAnother}
          />
          <SIPTransact
            show={sipShow}
            setShow={setSipShow}
            amcList={amcList}
            userProfileData={userProfileData}
            isLookingForAnother={isLookingForAnother}

          />
        </div>
      </div>

    </>
  )
}
export default Tax_Planning