import { apis, request } from "../request";

export const getSipStpSwpReport = async (body,headers) => {
  return await request(apis.post_reportsSipStpSwp, body,headers);
};

export const getTransactionReport = async (body,headers) => {
  return await request(apis.post_getReportTransaction, body,headers);
};

export const getTaxSavingReport = async (body,headers) => {
  return await request(apis.post_getTaxSavingReport, body,headers);
};

export const getDividendReport = async (body,headers) => {
  return await request(apis.post_getDevidendReport, body,headers);
};
