
import { apis, request } from "../request";

export const rightSchemeDurationRisk = async (headers) => {
    return await request(apis.get_RightSchemeDurationRisk, null, headers);
  };

  export const getRightSchemesNse = async (body,headers) => {
    return await request(apis.post_getRightSchemesNse, body,headers);
  };

  export const simplySipDuration = async (headers) => {
    return await request(apis.get_simplySipDuration, null, headers);
  };
  export const simplySipSchemes = async (body,headers) => {
    return await request(apis.post_simplySipSchemes, body,headers);
  };
  export const taxPlanningNseSchemes = async (body,headers) => {
    return await request(apis.get_taxPlanningNseSchemes, body,headers);
  };
  export const simplySaveNseSchemes = async (headers) => {
    return await request(apis.get_simplySaveNseSchemes, null, headers);
  };
  export const getHistoricNav = async (body,headers) => {
    return await request(apis.post_getHistoricNav, body,headers);
  };
  export const schemeFromNse = async (body,headers) => {
    return await request(apis.post_schemeFromNse, body,headers);
  };