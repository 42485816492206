import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FaEyeSlash, FaInfo, FaEye } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { validatorsMethods, Validator } from "../validations";
import { resetPassword, verifyResetOtp } from "../apisMethods";
import Otp_Input from "./otp";
import { notify } from "../pages/toastCreater";
import { useNavigate } from "react-router-dom";

const initialFormState = {
  newPassword: "",
  confirm_password: "",
};
const ChangePassword = ({ show, setShow, sendOtp, userComes }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [formError, setFormError] = useState(initialFormState);
  const [passwordType, setPasswordType] = useState("password");
  const [Otpfield, setOtpfield] = useState("d-block");
  const [Change, setChange] = useState("d-none");
  const [OTP, setOTP] = useState("");

  useEffect(() => {
    if (show === false) {
      setChange("d-none");
      setOtpfield("d-block");
      setOTP("");
      setFormData(initialFormState);
    }
  }, [show]);
  const otp = () => {
    setChange("d-block");
    setOtpfield("d-none");
  };
  const changeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "confirm_password" || name === "newPassword") {
      value = value.replace(/\s/g, "");
    }
    if (value.length < 50) {
      setFormData({
        ...formData,
        [e.target.name]: value,
      });
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    const newPassword = await Validator(
      formData.newPassword,
      "newPassword",
      (a, b) => {
        validatorsMethods.start(a, b).isRequired().checkPassword();
      }
    );
    const confirmPassword = await Validator(
      formData.confirm_password,
      "confirm_password",
      (a, b) => {
        validatorsMethods
          .start(a, b)
          .isRequired()
          .checkConfirmPassword(formData.newPassword);
      }
    );
    if (newPassword.message || confirmPassword.message) {
      setFormError({
        [newPassword.field]: newPassword.message,
        [confirmPassword.field]: confirmPassword.message,
      });
      return;
    }
    let token = localStorage.getItem("token");
    resetPassword(
      { password: formData.newPassword },
      { headers: { Authorization: `Bearer ${token}` } }
    ).then((res) => {
      if (!res.data) {
        notify("error", res.error.response.data.msg);
      } else {
        setChange("d-none");
        setOtpfield("d-block");
        setOTP("");
        setFormData(initialFormState);
        localStorage.removeItem("userFamily");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/");
        setShow(false);
      }
    });
  };

  const verifyEmailOtp = (e) => {
    e.preventDefault();
    if (!OTP) {
      notify("warn", "Please enter your OTP");
      return;
    }
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.email) {
      verifyResetOtp({
        email: user.email,
        otp: OTP,
        formpin: false,
      }).then((res) => {
        if (!res.data) {
          notify("error", res.error.response.data.msg);
        } else {
          localStorage.setItem("token",res.data?.data)
          otp();
        }
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        className="border-red"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form>
            <div className={`text-center ${Otpfield}`}>
              <Form.Group>
                <Otp_Input
                  OTP={OTP}
                  setOTP={setOTP}
                  sendOtp={sendOtp}
                  userComes={userComes}
                />
              </Form.Group>
              <Form.Group>
                <button
                  type="submit"
                  className="otp-btn mt-3 br-15 float-right"
                  onClick={verifyEmailOtp}
                >
                  Verify OTP
                </button>
              </Form.Group>
            </div>
            <div className={` ${Change}`}>
              {/* <Form.Group className="mb-2">
                <Form.Label> Exiting Password <span className="text-danger">*</span></Form.Label>
                <Form.Control className="single-drop border-0" type="text" placeholder="Enter Exiting Password" name="oldPassword" value={formData.oldPassword} onChange={changeHandler} />
              </Form.Group> */}
              <Form.Group className="mb-2">
                <Form.Label>
                 
                  New Password <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="single-drop border-0"
                  type="password"
                  placeholder="Enter New Password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={changeHandler}
                />
               
                {/* <div className="bg-info rounded-circle d-icon text-center">
                  <FaInfo data-tooltip-id="app-title" className="text-white" />
                </div> */}
                {/* <ReactTooltip
                  id="app-title"
                  place="top"
                  effect="solid"
                  className="px-1 toolTip"
                >
                  <div className="fs-10">
                    The password <br /> should Alpha- <br />
                    numeric with <br /> atleast 1 Capital <br /> & 1 Special{" "}
                    <br /> character
                  </div>
                </ReactTooltip> */}
              </Form.Group>
              <small className="text-danger">
                  {formError.newPassword }
                  
                </small>
              <Form.Group className="mb-2 ">
               
                <Form.Label>
                  {" "}
                  Confirm Password <span className="text-danger">*</span>
                </Form.Label>
                <div className="position-relative">
                <Form.Control
                  className="single-drop border-0"
                  type={passwordType}
                  placeholder="Enter Confirm Password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={changeHandler}
                />
                <small className="text-danger ">
                  {formError.confirm_password}
                </small>
               
                <div
                  className="rounded-circle d-icon newpass"
                  onClick={() =>
                    passwordType == "password"
                      ? setPasswordType("text")
                      : setPasswordType("password")
                  }
                >
                  {passwordType === "password" ? (
                    <FaEyeSlash className="fs-4" toggle="#password-field" />
                  ) : (
                    <FaEye className="fs-4" toggle="#password-field" />
                  )}
                </div>
                </div>
                <p className="text-gray fs-12 text-start pt-2">
                              <b>Note:</b> <br /> The password should have at least eight characters , alpha-numeric, at least one capital & one special character.
                            </p>
              </Form.Group>
              <Form.Group>
                <button
                  type="button"
                  onClick={updatePassword}
                  className="otp-btn mt-3 br-15 float-right"
                >
                  Submit
                </button>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
      </Modal>
    </>
  );
};
export default ChangePassword;
