import React, { useEffect, useState, useContext } from "react";
import Cart_Delete from "../components/delete-confirmation";
import { Link, useNavigate } from "react-router-dom";
import { getUserBasket, removeBasketData } from "../apisMethods";
import { MdRemoveShoppingCart } from "react-icons/md";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { globalInfo } from "../App";
import { IoCloseSharp } from "react-icons/io5";
import { apis } from "../request";
import { notify } from "./toastCreater";
import LumpsumForm from "../components/lumpsum-form";
import SIP_Form from "../components/sip-form";
import { useCount } from "./helpers/CartCountProvide";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";

const View_Cart = () => {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo)
  const [sipFormShow, setSipFormShow] = useState(false);
  const [lumpsumFormShow, setLumpsumFormShow] = useState(false);
  const [rightSchemes, setRightSchemes] = useState(false);
  const { deleteData, getCartData, totalCart,cartData, sipCart, lumpsumCart } = useCount()

  useEffect(() => {
    getCartData()
  }, [])



  const openModelForLumpum = (el) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/");
      return;
    }

    if (user?.iin) {
      setRightSchemes(lumpsumCart);
      setLumpsumFormShow(true)
    } else {
      navigate("/dashboard/Profile-creation")
    }

  }
  const openModelForSip = (el) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/");
      return;
    }

    if (user?.iin) {
      setRightSchemes(sipCart);
      setSipFormShow(true)
    } else {
      navigate("/dashboard/Profile-creation")
    }

  }

  return (
    <>



      <div className="wrapper">
        <div className="px-lg-5 px-3">

          {/* Page Heading */}
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb-nav d-flex  ps-2">
              <li className="breadcrumb-item "><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Cart</li>
            </ol>
          </nav>

          <section>
            <Tabs>
              <div className="row justify-content-center pt-4 mb-4">
                <div className="col-md-6  table-responsive ">
                  <TabList>
                    <Tab>Lumpsum</Tab>
                    <Tab>SIP</Tab>
                  </TabList>
                </div>
              </div>

              <TabPanel>
                <div className="row justify-content-center ">
                  <div className="col-md-10 col-lg-8 mb-4">
                    <div className="table-responsive">
                      <table className='table cart '>
                        {lumpsumCart.length > 0 ? lumpsumCart.map((item) => {
                          return <> <tr className='bg-c bg-gray rounded'>
                            <td className='wd-5rem ps-3'><img src={`${apis.imageLink}${item?.AMC_CODE}.png`} alt="" className='img-fluid mx-6em' /></td>
                            <td className='py-4 wd-20rem'><div className="text-black ">{item.s_name} </div>

                            </td>
                            <td className=" py-4 wd-5rem">
                              <Cart_Delete id={item.ISIN} deleteData={deleteData} />
                            </td>
                          </tr>
                            <br /></>
                        }) : <section >
                          <div className="container-fluid">
                            <div className="row justify-content-center">
                              <div className="col-md-11 py-3">
                                <div className="sipstpswp_error py-5 text-center">
                                  <div className="sipstpswp_error-img">
                                    <MdRemoveShoppingCart size={100} className="text-red" />
                                  </div>
                                  <h5 className="mt-4  fw-500">Your cart is empty!</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>}


                      </table>
                    </div>
                  </div>
                </div>
                {cartData> 0?   <div className="col-10  mb-3 text-lg-end text-center" onClick={() => openModelForLumpum()}>
                  <a className="btn-custom text-color" >Continue</a>

                </div>:""}
              
              </TabPanel>
              <TabPanel>
                <div className="row justify-content-center ">
                  <div className="col-md-10 col-lg-8 mb-4">
                    <div className="table-responsive">
                      <table className='table cart '>
                        {sipCart.length > 0 ? sipCart.map((item) => {
                          return <> <tr className='bg-c bg-gray rounded'>
                            <td className='wd-5rem ps-3'><img src={`${apis.imageLink}${item?.AMC_CODE}.png`} alt="" className='img-fluid mx-6em' /></td>
                            <td className='py-4 wd-20rem'><div className="text-black ">{item.s_name} </div>

                            </td>
                            {/* <td>
                        <td onClick={() => openModelForSip(item)}><div class="btn-invest crPointer"> SIP <IoCloseSharp size={20}/></div></td>

                          { <td onClick={() => openModel(item)}><div class="btn-invest crPointer"> LUMPSUM  <FaCheck /></div> </td> }

                        </td> */}
                            <td className=" py-4 wd-5rem">
                              <Cart_Delete id={item.ISIN} deleteData={deleteData} />
                            </td>
                          </tr>
                            <br /></>
                        }) : <section >
                          <div className="container-fluid">
                            <div className="row justify-content-center">
                              <div className="col-md-11 py-3">
                                <div className="sipstpswp_error py-5 text-center">
                                  <div className="sipstpswp_error-img">
                                    <MdRemoveShoppingCart size={100} className="text-red" />
                                  </div>
                                  <h5 className="mt-4  fw-500">Your cart is empty!</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>}


                      </table>
                    </div>
                  </div>
                </div>
                {cartData > 0 ?<div className="col-10  mb-3 text-lg-end text-center" onClick={() => openModelForSip()}>
                  <a className="btn-custom text-color" >Continue</a>

                </div>:""}
               
              </TabPanel>

            </Tabs>
          </section>


        </div>
      </div>


      <LumpsumForm
        show={lumpsumFormShow}
        setShow={setLumpsumFormShow}
        rightSchemes={rightSchemes}
      />
      <SIP_Form show={sipFormShow} setShow={setSipFormShow} sipSchemes={rightSchemes} />

    </>
  )

}
export default View_Cart
