import React, { useState, useContext } from "react";
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
import CalculatorHeader from "../components/calculator-header";
import { notify } from "./toastCreater";
import { Link } from "react-router-dom";
import { swpCalculator } from "../apisMethods";
import { globalInfo } from "../App";
import Form from 'react-bootstrap/Form';


const SwpCalculator = () => {
  const { setLoader } = useContext(globalInfo);
  const [monthlyWithdrawl, setMonthlyWithdrawl] = useState(10000);
  const [monthlyPercentage, setMonthlyPercentage] = useState(15.38);
  const [period, setPeriod] = useState(5);
  const [interestRate, setInterestRate] = useState(20);
  const [lumpsumAmount, setLumpsumAmount] = useState(650000);
  const [totalProfit, setTotalProfit] = useState("6,67,838");
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState("6,00,000");
  const [totalBalanceAmount, setTotalBalanceAmount] = useState("7,17,838");
  const [loaded, setLoaded] = useState(true);
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [withdrawalBy, setWithdrawalBy] = useState(1);




  const sipCalculate = (e) => {
    e.preventDefault();
    if (lumpsumAmount < 500) {
      notify("warn", "Lumpsum Amount should not be less than 500")
      return
    }
    if (monthlyWithdrawl < 500) {
      notify("warn", "Monthly Withrawl Amount should not be less than 500")
      return
    }
    if (period < 1) {
      notify("warn", "Period should not be less than 1")
      return
    }

    if (interestRate < 1 || interestRate==".") {
      notify("warn", "Expected Return  should not be less than 1")
      return
    }

    setLoaded(!loaded);
    let reqbody = {
      monthlyWithdrawl:Math.round(monthlyWithdrawl),
      period,
      interestRate,
      lumpsum: Math.round(lumpsumAmount)
    }
    setLoader("block");
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    swpCalculator(reqbody, headers).then((res) => {
      if (!res.data) {
        console.log(res);
      } else {
        let options = { style: 'decimal', useGrouping: true, maximumFractionDigits: 2 };
        setTotalBalanceAmount(res.data?.data?.total_balance_amount.toLocaleString('en-IN', options))
        setTotalWithdrawalAmount(res.data?.data?.total_withdrawal_amount.toLocaleString('en-IN', options))
        setTotalProfit(res.data?.data?.total_profit.toLocaleString('en-IN'))
      }
      setLoader("none");
      document.getElementById('scroll').scrollIntoView(true);
    })

    setCalculateCheck(1)
    // setLoader("none");
  }

  const setWithdrawlHandler = (e)=>{
    setWithdrawalBy(+e.target.value)
    setMonthlyPercentage(0)
    setMonthlyWithdrawl(0)
  }

  let incNum = () => {
    let perc = ((+monthlyWithdrawl + 500) * 100) / lumpsumAmount
    if (perc <= 100) {
      if (monthlyWithdrawl < 1000000) {
        setMonthlyWithdrawl(Number(monthlyWithdrawl) + 500);
        calculatePercentage(Number(monthlyWithdrawl) + 500, lumpsumAmount, null, 1)
      } else if (monthlyWithdrawl == 0) {
        setMonthlyWithdrawl(Number(monthlyWithdrawl) + 500);
        calculatePercentage(Number(monthlyWithdrawl) + 500, lumpsumAmount, null, 1)
      }
    } else {
      notify("warn", "Monthly Withdrawl Amount can not exceed Lumpsum Amount")
    }

  };
  let decNum = () => {

    if (monthlyWithdrawl >= 500) {
      setMonthlyWithdrawl(monthlyWithdrawl - 500);
      calculatePercentage(monthlyWithdrawl - 500, lumpsumAmount, null, 1)
    } else if (monthlyWithdrawl < 499) {
      calculatePercentage(0, lumpsumAmount, null, 1)
      setMonthlyWithdrawl(0);
    }
  };

  let incLumpsumAmount = () => {
    if (lumpsumAmount < 100000000) {
      setLumpsumAmount(Number(lumpsumAmount) + 500);
      calculatePercentage(monthlyWithdrawl, Number(lumpsumAmount) + 500, null, 2)
    } else if (lumpsumAmount == 0) {
      setLumpsumAmount(Number(lumpsumAmount) + 500);
      calculatePercentage(monthlyWithdrawl, Number(lumpsumAmount) + 500, null, 2)
    }
  };
  let decLumpsumAmount = () => {
    if (lumpsumAmount >= 500 && monthlyWithdrawl < lumpsumAmount - 500) {
      setLumpsumAmount(lumpsumAmount - 500);
      calculatePercentage(monthlyWithdrawl, lumpsumAmount - 500, null, 2)
    } else if (lumpsumAmount < 499 && monthlyWithdrawl < lumpsumAmount - 500) {
      calculatePercentage(monthlyWithdrawl, 0, null, 2)
      setLumpsumAmount(0);
    }
  };

  let incMonth = () => {
    if (period < 50) {
      setPeriod(Number(period) + 1);
    }
  };
  let decMonth = () => {
    if (period > 1) {
      setPeriod(period - 1);
    }
  };

  let incPer = () => {
    if (isNaN(interestRate)) {
      return setInterestRate(0);
    }
    if (interestRate < 50) {
      setInterestRate(Number(interestRate) + 0.5);
    }
  };
  let decPer = () => {
    if (interestRate > 1) {
      setInterestRate(interestRate - 0.5);
    }
  };

  let incMonthlyPer = () => {
    // if (isNaN(monthlyPercentage)) {
    //   calculatePercentage(null, lumpsumAmount, 0, 3)
    //   return setMonthlyPercentage(0);
    // }
    if (monthlyPercentage < 100) {
      calculatePercentage(null, lumpsumAmount, Number(monthlyPercentage) + 0.5, 3)
      setMonthlyPercentage((Number(monthlyPercentage) + 0.5).toFixed(2));
    }
  };
  let decMonthlyPer = () => {
    if (monthlyPercentage- 0.5 > 1) {
      setMonthlyPercentage(monthlyPercentage - 0.5);
      calculatePercentage(null, lumpsumAmount, monthlyPercentage - 0.5, 3)
    }
  };

  const calculatePercentage = (monthlyAmount, lumpsumAmounts, percentage, isMonthlyWithdrawl) => {
    if (isMonthlyWithdrawl === 1) {
      let perc = (monthlyAmount * 100) / lumpsumAmounts
      console.log("ss", perc);
      setMonthlyPercentage(perc.toFixed(2))
    } else if (isMonthlyWithdrawl === 2) {
      console.log(monthlyAmount , lumpsumAmounts);
      if(+monthlyAmount > +lumpsumAmounts){
         calculateConditionaly(monthlyAmount, lumpsumAmounts, percentage, isMonthlyWithdrawl)
         return
      }
      let perc2 = (monthlyAmount * 100) / lumpsumAmounts
      setMonthlyPercentage(perc2.toFixed(2))
    } else if (isMonthlyWithdrawl === 3) {
      let ress = (percentage / 100) * lumpsumAmounts
      setMonthlyWithdrawl(ress.toFixed(2))
    }

  }
  const calculateConditionaly = (monthlyAmount, lumpsumAmounts, percentage, isMonthlyWithdrawl)=>{
    if(lumpsumAmounts==0 || !lumpsumAmounts){
      setMonthlyWithdrawl(0)
      setMonthlyPercentage(0)
      return
    }
    let ress = (percentage / 100) * lumpsumAmounts
    setMonthlyWithdrawl(ress.toFixed(2))
  } 
  return (
    <>

      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}


          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Calculators</li>
            </ol>
          </nav>

          {/* <Loader loader={loader} /> */}

          <div className="container-fluid px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class="mt-3">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-4 pb-5">
                              <section className="pt-5 pb-5">
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 fs-14 py-2">
                                      Lumpsum Amount
                                    </label>
                                    <div className="d-flex inputf transcard">

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decLumpsumAmount} ></img>
                                      <input type="text" className="form-control " name="return" value={lumpsumAmount}
                                        onChange={(e) => {
                                          if (
                                            e.target.value <= 100000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setLumpsumAmount(
                                                Math.round(parseInt(e.target.value))
                                              );
                                                calculatePercentage(monthlyWithdrawl, e.target.value, monthlyPercentage, 2)
                                              
                                            } else {
                                              setLumpsumAmount(e.target.value != "" ? parseInt(e.target.value) : 0);
                                                calculatePercentage(monthlyWithdrawl, e.target.value, monthlyPercentage, 2)
                                               if(e.target.value ==0){
                                                setMonthlyPercentage(0)
                                               }
                                            }
                                          } else if (e.target.value > 100000000) {
                                            setLumpsumAmount(100000000);
                                            calculatePercentage(monthlyWithdrawl, 100000000, monthlyPercentage, 2)
                                          }
                                          
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incLumpsumAmount}></img>
                                    </div>
                                  </div>
                               
                                  <div className="col-md-4 text-center">
                                    <label for="year" className="text-label fs-14 fw-500 py-2">
                                      Period (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decMonth}></img>
                                      <input type="text" className="form-control " name="year" value={period}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              // setPeriod(
                                              //   Math.round(parseInt(e.target.value))
                                              // );
                                            } else {
                                              setPeriod(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 50) {
                                            setPeriod(50);
                                          }
                                        }} />

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incMonth}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 fs-14 py-2">
                                      Expected Return  (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decPer} ></img>
                                      <input type="text" className="form-control " name="return" value={interestRate}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                            let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                              setInterestRate(value)
                                          }
                                           else if(e.target.value >50) {
                                            setInterestRate(50);
                                          }else if(e.target.value=="."){
                                            setInterestRate(e.target.value);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incPer}></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-1">

                                  <div className="col-md-4 text-center align-self-center ">
                                    <Form.Group controlId="formBasicRadio">
                                      <label className="text-label  fw-500 fs-14 py-2">
                                        Set Withdrawal By 
                                      </label>
                                      <div className="d-flex justify-content-center">
                                        <Form.Check
                                          type="radio"
                                          label="Amount"
                                          name="withdrawal"
                                          value={"1"}
                                          id="amount"
                                          checked={withdrawalBy === 1}
                                          onChange={setWithdrawlHandler}
                                          className="pe-3"
                                        />
                                        <Form.Check
                                          type="radio"
                                          label="Percent(%)"
                                          name="withdrawal"
                                          value={"2"}
                                          id="debt"
                                          checked={withdrawalBy === 2}
                                          onChange={setWithdrawlHandler}
                                          className="pe-3"
                                        />

                                      </div>
                                    </Form.Group>

                                  </div>
                                  {withdrawalBy===2?  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 fs-14 py-2">
                                      Monthly Withdrawal  (% p.m)
                                    </label>
                                    <div className="d-flex inputf transcard">

                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decMonthlyPer} ></img>
                                      <input type="text" className="form-control " name="monthlyPercentage" value={monthlyPercentage}
                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 4)
                                          if(lumpsumAmount==0|| lumpsumAmount==""){
                                            setMonthlyPercentage(0)
                                            return
                                          }
                                          if (0 <= e.target.value && e.target.value < 100 ) {
                                            setMonthlyPercentage(value)
                                            calculatePercentage(null, lumpsumAmount, value, 3)
                                          } else if(e.target.value >= 100){
                                            setMonthlyPercentage(100)
                                            calculatePercentage(null, lumpsumAmount, 100, 3)
                                          }else if(value=="."){
                                            setMonthlyPercentage(value)
                                          }

                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incMonthlyPer}></img>

                                    </div>
                               <small>Amount: {monthlyWithdrawl}</small>

                                  </div>:   <div className="col-md-4 text-center">
                                    <label for="m-saving" className="text-label fw-500 fs-14 py-2">
                                      Monthly Withdrawal <span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decNum}></img>
                                      <input type="text" className="form-control " name="m-saving" value={monthlyWithdrawl}
                                        onChange={(e) => {
                                          if(lumpsumAmount==0|| lumpsumAmount==""){
                                            setMonthlyPercentage(0)
                                            return
                                          }
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= lumpsumAmount
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              // setMonthlyWithdrawl(
                                              //   Math.round(parseInt(e.target.value))
                                              // );
                                              // calculatePercentage( Math.round(parseInt(e.target.value)), lumpsumAmount, null, 1)
                                            } else {
                                              setMonthlyWithdrawl(e.target.value != "" ? parseInt(e.target.value) : "");
                                              calculatePercentage(e.target.value, lumpsumAmount, null, 1)
                                            }
                                          } else if (e.target.value > lumpsumAmount) {
                                            setMonthlyWithdrawl(lumpsumAmount);
                                            calculatePercentage(lumpsumAmount, lumpsumAmount, null, 1)
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incNum}></img>
                                    </div>
                               <small>Percent: {monthlyPercentage.toLocaleString('en-IN')} %</small>

                                  </div>}
                                 
                                </div>
                                
                                <div className="col-md-12 pt-2 mt-1 text-lg-end text-center">
                                  <button className="btn-custom mt-3" onClick={(e) => sipCalculate(e)}>{calculateCheck == 0 ? "Calculate" : "Re-Calculate"} </button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-lg-3" id="scroll">
                                <div className="col-md-4">
                                  <div className="result-content result-content-shadow">
                                    <ul className="text-center lst-none ps-0">
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Total Balance Amount<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">{totalBalanceAmount}</div>
                                      </li>
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Total Withdrawal Amount<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2"> {totalWithdrawalAmount}</div>
                                      </li>
                                      <li className="pb-3">
                                        <div className="text-label fw-500 py-2">
                                          Total Profit<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-white py-2">  {totalProfit}</div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-8 mt-5">
                                  <div className="text-center">
                                    <img
                                      src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/fds.png"}
                                      className="img-fluid mw-220"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* <div className="row px-lg-5 pt-3" onClick={()=>investInSip(totalSaving)}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">Invest Now</button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )

}
export default SwpCalculator