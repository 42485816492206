import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "react-tooltip/dist/react-tooltip.css";

const TextDetailsPopup = ({ show, setShow }) => {

    return (
        <>
            <Modal show={show} size="lg"  onHide={() =>
                setShow(false)} className="border-red" centered scrollable={true} >
                <Modal.Header closeButton >
                    <Modal.Title className="fs-21 fs-sm-16">Guidelines under the
                        Prevention of Money Laundering Act, 2002</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4 text-justify fs-sm-13">
                    As per the provisions of the Prevention of Money Laundering Act (PMLA), every banking company, financial institution (which includes chit fund company, a co-operative bank, a housing finance institution and a non-banking financial company) and intermediary [includes a stock-broker, sub-broker, share transfer agent, banker to an issue, trustee to a trust deed, registrar to an issue, asset management company, depository participant, merchant banker, underwriter, portfolio manager, investment adviser and any other intermediary associated with the securities market and registered under Section 12 of the Securities and Exchange Board of India Act, 1992 (SEBI Act)] shall have to adhere to client account opening procedures and maintain records of such transactions as prescribed by the PMLA and rules notified there under.
                </Modal.Body>
                <Modal.Footer className='border-0'>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default TextDetailsPopup