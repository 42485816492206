import React, { useState,useContext } from "react";
import SIP_Form from "../components/sip-form";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Cart_Delete from '../components/delete-confirmation';
import img_no_investment_transaction from "../../src/assets/images/img_no_investment_transaction.png";
import { simplySipDuration, simplySipSchemes } from "../apisMethods";
import { useNavigate } from "react-router-dom";
import { globalInfo } from "../App";
import { CheckPathRediction } from "./helpers/redirection";
import { apis } from "../request";
const SimplySIP = () => {
  const navigate = useNavigate()
  const {setLoader} = useContext(globalInfo)
  const [sipformShow, setSipformShow] = useState(false);
  const [headerState, setHeaderState] = useState({});
  const [years, setYears] = useState([])
  const [anchoring, setAnchoring] = useState('')
  const [sipSchemes, setSipSchemes] = useState([])
  const [userDetails, setUserDetails] = useState({})


  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userdata)
    setHeaderState(header)
    // if (!userdata?.pan) {
    //   navigate('kyc-validation')
    // }
    getRightSchemeDurationRiskCall(header)
    setLoader("block")
    simplySipSchemes({ durationValues: "3" }, headerState).then(res => {
      if (!res.data) {
        setSipSchemes([])
      } else {
        setSipSchemes(res.data?.data)
      }
      setLoader('none')
    })

  }, [])




  const getRightSchemeDurationRiskCall = (header) => {
    simplySipDuration(header).then(res => {
      if (!res.data) {
      } else {
        let finalArray = [];
        res.data?.data.forEach(el => {
          finalArray.push({ label: el.duration, value: el.durationValues })
        })
        finalArray.sort((a, b) => a.value - b.value);
        setYears(finalArray)
      }
    })
  }

  const anchoringHandler = (e) => {
    setLoader('block')
    setAnchoring(e.value)
    simplySipSchemes({ durationValues: e.value.toString() }, headerState).then(res => {
      if (!res.data) {
        setSipSchemes([])
      } else {
        setSipSchemes(res.data?.data)
      }
      setLoader('none')
    })
  }
  const continueForSip = ()=>{
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    }else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
      navigate("/dashboard/Profile-creation");
    }  else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      
      setSipformShow(true)
    }
  }

  const deleteData = (id) => {
    sipSchemes.splice(id, 1);
    setSipSchemes([...sipSchemes])
  }
  return (

    <>
      <div className="wrapper">
        <div className="px-lg-5 px-smc-1">

          {/* Page Heading */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active">Simply Sip</li>
            </ol>
          </nav>

          <section>
            <div className="row pb-f">
              <div className=" col-lg-10 offset-lg-1 pe-5">
                <div className="col-md-7 col-lg-5 pb-4 ">
                  <label className='text-label mb-2'>Select Anchoring (Investment Horizon)</label>
                  <Select className='single-drop ' options={years} placeholder={"3 Years"} onChange={anchoringHandler} />
                </div>
                <div className="card shadow-custom bg-light-red">

                  <div className="card-body custom-tab-bg pb-4">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table class="table custom text-table-black text-start">

                          <thead>

                            <tr>
                              <th scope="col" colSpan="2" className='text-red text-start'>Recommended Schemes</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sipSchemes.length > 0 ? sipSchemes.map((data, i) => {
                              return <tr>
                                <td><img src={
                                // `${apis.imageLink}growth.png`
                                `${apis.imageLink}${data?.AMC_CODE}.png`
                              } alt="" className='img-fluid min-w-3em mx-wd-45' /></td>
                                <td className='min-w-35em'><Link to='#'>{data.PRODUCT_LONG_NAME}</Link>
                                </td>
                                {sipSchemes.length > 1 ? 
                                <td className='min-w-35em'> <Cart_Delete id={i} deleteData={deleteData} />
                                </td>:""}

                              </tr>
                            }) :anchoring ?  <section >
                              <div className="container-fluid">
                                <div className="row justify-content-center">
                                  <div className="col-md-11 py-3">
                                    <div className="sipstpswp_error py-5 text-center">
                                      <div className="sipstpswp_error-img">
                                        <img src={img_no_investment_transaction} className="img-fluid mw-170" />
                                      </div>
                                      <h5 className="pt-3 text-red fw-600">No schemes available</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>:""}


                          </tbody>
                        </table>
                      </div>

                    </div>
                    {sipSchemes.length > 0 ? <div className="col-12 mt-4  text-lg-end text-center">
                      <button className="btn-custom text-color" onClick={continueForSip}>Continue</button>
                    </div> : ""}

                  </div>
                </div>
                {/* disclaimer */}
                <div class="text-center pt-4 mb-0" role="alert">
                  <p className="text-disclaimer  fs-13 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
                </div>
              </div>
            </div>



          </section>

        </div>
      </div>
      <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={sipSchemes} />
    </>
  )
}
export default SimplySIP