import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import success from "../assets/images/successfully.png";
import Video_blog from './video-blog';
import Cart_Delete from './delete-confirmation';
import { notify } from '../pages/toastCreater';
import { BsArrowLeft } from 'react-icons/bs';
import { redeemMulti, saveNseTransaction } from '../apisMethods';
import { globalInfo } from "../App";
import TransactSuccess from './transact-success';
import SWW from './something-went-wrong';
import PortfolioContent from './portfolio-content';
const content = " <p>Bravo! That was a praiseworthy initiative given the level of financial literacy and the lack of interest in investors to know about it. We have to hand it to you that you are one of those curious and courageous investor.</p> <p>We provide suggestion of redemption for the following reasons:</p><p>1.	The scheme is not performing well in comparison to its peer schemes.</p> <p>2.	None of the scheme in the same AMC is performing such so as to allow us to shift in that scheme.</p> <p>3.	We do not have conviction over the fund management style and methodology of the AMC.</p>  <p>We understand that taking out money in your bank and investing again is a cumbersome process but, please understand that we suggest “redemption” as the last resort.<p>Thanking you again for reading this….</p><p>We might sound greedy….</p>"
 const heading="Redemption"
const RedemptionConfirm = ({ show, setShow, redumptionData, singleFamilyData }) => {

  
  const [isPortfolioContentShow, setIsPortfolioContentShow] = useState(false)
  const { setLoader } = useContext(globalInfo)
  const [Videoblogshow, setVideoblogShow] = useState(false);
  const [purchaseFormPopUp, setPurchaseFormPopUp] = useState("d-block");
  const [checkConfirmationPopUp, setCheckConfirmationPopUp] = useState("d-none");
  const [schemeList, setSchemeList] = useState()
  const [headers, setHeaders] = useState({});
  const [loggedInData, setLoggedInData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [redumptionBody, setRedumptionBody] = useState([{
    email: singleFamilyData?.EMAIL,
    tax_status: singleFamilyData?.TAX_STATUS,
    trxn_acceptance: "ALL", // Static
    amc: "",
    product_code: "",
    amt_unit: "",
    remark: "",
    folio: "",
    amt_unit_type: "Unit",
    amountsType: "Unit",
    // amt_unit_type: switchType === "Y" || switchType === "Unit" ? "Unit" : "Amount",
    // all_units: switchType !== "Y" ? "N" : "Y",
    all_units: "Y",
    input_ref_no: "",
    iin: singleFamilyData?.IIN
  }])

  useEffect(() => {
    setSchemeList(redumptionData)
    console.log("redumptionData", redumptionData);
    let arr = []
    redumptionData.map((el, index) => {
      arr.push({
        email: singleFamilyData?.EMAIL,
        tax_status: singleFamilyData?.TAX_STATUS,
        trxn_acceptance: "ALL", // Static
        amc: el?.NSE_AMC_CODE,
        product_code: el?.NSE_PRODUCT_CODE,
        s_name: el?.s_name,
        amt_unit: el?.unit,
        remark: "",
        folio: el?.folio,
        amt_unit_type: "Unit",
        // amt_unit_type: switchType === "Y" || switchType === "Unit" ? "Unit" : "Amount",
        // all_units: switchType !== "Y" ? "N" : "Y",
        all_units: "Y",
        amountsType: "Y" + index,
        input_ref_no: "",
        iin: singleFamilyData?.IIN
      })
    })
    setRedumptionBody(arr)
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    setHeaders(header)
    const userdata = JSON.parse(localStorage.getItem("user"));
    setLoggedInData(userdata)
    setCheckConfirmationPopUp("d-none")
    setPurchaseFormPopUp("d-block")

  }, [redumptionData])

  const redeemAllSchemes = () => {
    for (let i = 0; i < redumptionBody.length; i++) {
      if (!redumptionBody[i].amt_unit) {
        notify("error", "Please enter amount or unit..!")
        return
      }
    }

    for (let i = 0; i < redumptionBody.length; i++) {
      if (redumptionBody[i].amt_unit_type === "Unit" && redumptionBody[i].amt_unit > schemeList[i].unit) {
        notify("error", "Unit Can not exceed from your available units..!")
        return
      } else if (redumptionBody[i].amt_unit_type === "Amount" && redumptionBody[i].amt_unit > schemeList[i].currentvalue) {
        notify("error", "Amount Can not exceed from your available Amount..!")
        return
      }

    }
    // setRedumptionBody(tempRedumptionBody)
    setCheckConfirmationPopUp("d-block")
    setPurchaseFormPopUp("d-none")
  }
  const finalRedeem = () => {
    setLoader("block")
    let reqBody = {
      iin: singleFamilyData?.IIN,
      input_ref_no: "",
      trxn_acceptance: "ALL",
      data: redumptionBody
    }
    redeemMulti(reqBody, headers).then((res) => {
      if (!res.data) {
        setErrorConfirmationShow(true)
        setErrorMsg(res.error.response.data?.msg ? res.error.response.data?.msg : "Something went wrong..!")
      } else {
        if (res.data?.status === 200 || res.data?.success) {
          setLoader("none")
          res.data?.data.map((item) => {
            let nseData = {
              Unique_No: item.Unique_No,
              Trxn_No: item.Trxn_No,
              Application_No: item.Application_No,
              Fund: item.Fund,
              Scheme: item.Scheme,
              Scheme_Name: item.Scheme_Name,
              Amt: item.Amt,
              Status_Desc: item.Status_Desc,
              Status_code: item.Status_code,
              Folio: item.Folio,
              Platform: "Web",
              transaction_type: "Redemption",
              iin: singleFamilyData.IIN,
              Pan: singleFamilyData.PAN,
              Gpan: singleFamilyData?.GUARD_PAN ? singleFamilyData?.GUARD_PAN : "",
              Joint1pan: singleFamilyData.JOINT1_PAN,
              Joint2pan: singleFamilyData.JOINT1_PAN,
              name: singleFamilyData.NAME,
            };
            // setConfirmationData(res.data?.data);
            setConfirmShow(true);
            setShow(false)
            setCheckConfirmationPopUp("d-none")
            setPurchaseFormPopUp("d-none")
            setConfirmationDataArr(res.data?.data);
            saveNseTransaction(nseData, headers)
          })

        } else {
          setErrorMsg(res.data?.msg)
          setErrorConfirmationShow(true)
        }
      }
      setLoader("none")
    })
  }

  const openInnerModal = () => {
    setVideoblogShow(true);
    setShow(false)
  }

  const closeInnerModal = () => {
    setVideoblogShow(false);
    setShow(true)
  }
  const deleteData = (id) => {
    schemeList.splice(id, 1);
    redumptionBody.splice(id, 1);
    setSchemeList([...schemeList])
    for (let i = id; i < redumptionBody.length; i++) {
      redumptionBody[i].amountsType = "Y" + i
      redumptionBody[i].amt_unit_type = "Unit"

    }
    setRedumptionBody([...redumptionBody])
  }
  const amountHandler = (e, i) => {
    const inputValue = e.target.value
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters

      const updatedData = [...redumptionBody];
      updatedData[i].amt_unit = updatedData[i].amt_unit_type === "Amount" ? Math.round(sanitizedValue) : sanitizedValue;
      setRedumptionBody(updatedData);

    } else if (!isNaN(inputValue)) {
      const updatedData = [...redumptionBody];
      updatedData[i].amt_unit = 10000000;
      setRedumptionBody(updatedData);
    }
  }
  const typeHandler = (e, index, amount) => {
    console.log("index", index);
    let updateData = [...redumptionBody]
    updateData[index].amountsType = e.target.value
    updateData[index].amt_unit_type = e.target.value === "Y" + index || e.target.value === "Unit" + index ? "Unit" : "Amount"
    updateData[index].all_units = e.target.value !== "Y" + index ? "N" : "Y"
    updateData[index].amt_unit = 0
    if (e.target.value === "Y" + index) {
      updateData[index].amt_unit = amount
    }
    setRedumptionBody(updateData)
  }

  const goBack = () => {
    setCheckConfirmationPopUp("d-none")
    setPurchaseFormPopUp("d-block")
  }
  return (

    <div >
      <Modal show={show} onHide={() =>
        setShow(false)} centered >
        <div className={` rounded ${purchaseFormPopUp}`}>
          <Modal.Header closeButton >
            <Modal.Title className='fs-6'>Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3">

            {schemeList?.length > 0 ? schemeList?.map((item, index) => {

              return <Form key={index}>
                <div className="row mx-1   bg-c br-15">
                  <div className="col-12 bg-gray bg-gray-raduis px-3 pt-3 ">
                    <div className="row">
                      <div className="col-11">
                        <Form.Group>
                          <p className=' text-red '> {item.s_name}| {item.folio}</p>
                        </Form.Group>
                      </div>
                      {schemeList?.length > 1 ? <div className="col-1 px-0" >
                        <span className='delbtn'>
                          <Cart_Delete id={index} deleteData={deleteData} />
                        </span>
                      </div> : ""}

                    </div>

                  </div>
                  {item?.unit >= 0 ? (
                    <small className="text-red pull-left">
                      <i>
                        {`Available  Units: ${item?.unit},     Total Amount:  Rs ${item?.currentvalue} `}
                      </i>
                    </small>
                  ) : null}
                  <div className="col-12  px-3 pt-3">

                    <Form.Group>

                      <div className="d-flex mb-2 justify-content-center">
                        <Form.Check type="radio" label="By Amount" name={"formHorizontalRadios" + index} value={"Amount" + index}
                          onChange={(e) => typeHandler(e, index)}
                          checked={redumptionBody[index]?.amountsType === "Amount" + index} id={"ByAmount" + index} className="pe-3 fs-sm-12" />
                        <Form.Check type="radio" label="By Units" name={"formHorizontalRadios" + index} value={"Unit" + index}
                          onChange={(e) => typeHandler(e, index)}
                          checked={redumptionBody[index]?.amountsType === "Unit" + index} id={"ByUnits" + index} className="pe-3 fs-sm-12" />
                        <Form.Check type="radio" label="All Units" name={"formHorizontalRadios" + index} value={"Y" + index}
                          onChange={(e) => typeHandler(e, index, item?.unit)}
                          checked={redumptionBody[index]?.amountsType === "Y" + index} id={"AllUnits" + index} className="pe-3 fs-sm-12" />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 mb-3">
                    <Form.Group>
                      <Form.Label className='text-black'>{redumptionBody[index].amountsType == "Y" + index ? "All Units" : redumptionBody[index].amountsType == "Unit" + index ? "Enter Unit" : "Enter Amount"}<span className="text-danger">*</span></Form.Label>
                      <Form.Control className="bg-c-b" type="text" placeholder={redumptionBody[index].amountsType == "Y" + index ? "All Units" : redumptionBody[index].amountsType == "Unit" + index ? "Enter Unit" : "Enter Amount"} onChange={redumptionBody[index].amountsType == "Y" + index ? () => { } : (e) => amountHandler(e, index)} value={redumptionBody[index]?.amt_unit} />

                    </Form.Group>
                  </div>
                </div>
              </Form>
            }) : ""}

          </Modal.Body>
          <Modal.Footer className='border-0'>
            <p className='fs-10 text-justify'>Populated schemes do not meet the criteria laid out by our in house theory called
              “Reverse Pyramid”. Since there are no alternate scheme in the same fund house, we are
              suggesting Redemption.
              <span className='text-red crPointer' onClick={()=>setIsPortfolioContentShow(true)}> Read more..</span>
            </p>
            <p className='fs-10'>Suggested Action: Redeem the mentioned schemes.</p>
            <button className="btn btn-danger " type="button" onClick={redeemAllSchemes}   >Continue</button>
          </Modal.Footer>
        </div>
        <div className={`rounded ${checkConfirmationPopUp}`}>

          <Modal.Header closeButton >
            <Modal.Title className='fs-6'><span onClick={goBack}>
              <BsArrowLeft className="sip-popup-rignt-icon" />
            </span>Confirmation Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <div className="col mb-3 border-bottom">
              <h6 className='text-red'>{singleFamilyData?.NAME}</h6>
            </div>{redumptionBody.map((item) => {
              return <>
                <div className="col">
                  <p className='text-red fs-13 mt-2'>{item?.s_name}</p>
                </div>
                <table className='mx-auto'>
                  <tr className='text-center fs-13'>
                    <td className='pe-4'>Folio </td>
                    :
                    <td className='ps-4'>{item?.folio}</td>
                  </tr>
                  <tr className='text-center fs-13'>
                    <td className='pe-4'>Amount/Unit  </td>
                    :
                    <td className='ps-4'>{item?.amt_unit}</td>
                  </tr>

                </table>
                <div className="col border-top mt-4">
                </div>
              </>
            })}
          </Modal.Body>
          <Modal.Footer className='border-0'>
            <button className="btn btn-danger" type="button" onClick={finalRedeem}   >Order</button>
          </Modal.Footer>
        </div>

      </Modal>

      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
      <Video_blog show={Videoblogshow} setShow={closeInnerModal} />
      <PortfolioContent show={isPortfolioContentShow} setShow={setIsPortfolioContentShow} content={content} heading ={heading}/>
            
    </div>


  )
}
export default RedemptionConfirm