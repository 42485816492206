import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import ContactUs from "../components/contact-us";
import { notify } from "./toastCreater";
import { createAchMandate } from "../apisMethods";
import {  addThirtyDaysFromCurrent, addThirtyFromGivenDate, addYears, currentDateForRemovePast, formatDateString } from "../currentMonth/FinancialYear";
import { bankShortName } from "./helpers/bankShortName";
import { validation } from "./helpers/validation";
import Thanku from "../components/thanku";

const CreateEmandate = () => {


  const location = useLocation()
  const [contact, setContactShow] = useState(false);
  const [mandateAmount, setMandateAmount] = useState('')
  const [channelType, setChannelType] = useState("")
  const [headers, setHeaders] = useState({})
  const [untilCancelled, setUntilCancelled] = useState("Y")
  const [mandateToDate, setMandateToDate] = useState("")
  const [mandateFromDate, setMandateFromDate] = useState("")
  const [currentDate, setCurrentDate] = useState("")
  const [futureRemoveDate, setFutureRemoveDate] = useState("")
  const [error, setError] = useState({
    amount: "",
    channelType: "",
    mandateToDate: "",
    mandateFromDate: ""
  })
  const [confirmShow2, setConfirmShow2] = useState(false)
  const [successMessage2, setSuccessMessage2] = useState("")


  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    setHeaders(header);
    setCurrentDate(currentDateForRemovePast());
  }, [])
  const mandateAmountHandler = (e) => {
    const inputValue = e.target.value;
    validation(e.target?.name, inputValue, setError)
    if (inputValue < 10000000 && !inputValue.includes(".")) {
      const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
      setMandateAmount(sanitizedValue);
    } else if (!isNaN(inputValue) && !inputValue.includes(".")) {
      setMandateAmount(10000000);
    }
  }

  const mandateDateHandler = (e) => {
    const { name, value } = e.target
    validation(name, value, setError)
    if (name === "mandateFromDate") {
      setMandateFromDate(value)
      console.log(value);
      setMandateToDate("")
      setFutureRemoveDate(addThirtyFromGivenDate(value))
      console.log(futureRemoveDate);
    } else if (name === "mandateToDate") {
      setMandateToDate(value)
    }
  }
  const channelTypeHandler = (e) => {
    const { name, value } = e.target
    validation(name, value, setError)
    setChannelType(value)
  }





  const submitHandler = async (e) => {
    e.preventDefault()
    let tempErrorObj = {
      amount: "",
      channelType: "",
      mandateToDate: "",
      mandateFromDate: ""
    }
    let flag = false
    if (!mandateAmount) {
      tempErrorObj.amount = "Mandatory Field"
      flag = true
    }else if(mandateAmount<1000){
      tempErrorObj.amount = "Mandate amount should not be less than 1000 "
      flag = true
    }
    if (!channelType) {
      tempErrorObj.channelType = "Mandatory Field"
      flag = true
    }
    if (!mandateFromDate) {
      tempErrorObj.mandateFromDate = "Mandatory Field"
      flag = true
    }
    if (!mandateToDate && untilCancelled === "N") {
      tempErrorObj.mandateToDate = "Mandatory Field"
      flag = true
    }
    setError(tempErrorObj)
    if (!flag) {
      const dates = addYears(mandateFromDate,30)
      const { CUSTOMER_ID, AC_NO, AC_TYPE, BANK_CODE, MICR_NO, BRANCH_ADDRESS1, BRANCH_NAME, BROKER_CODE, IFSC_CODE, bankHolderName } = location?.state
      const reqBody = {
        iin: CUSTOMER_ID,
        acc_no: AC_NO,
        acc_type: bankShortName[AC_TYPE],
        ifsc_code: IFSC_CODE,
        micr_no: MICR_NO ? MICR_NO : "",
        bank_name: BANK_CODE,
        branch_name: BRANCH_NAME,
        Bank_holder_name: bankHolderName,
        ach_fromdate: formatDateString(mandateFromDate),
        ach_todate: untilCancelled === "N" ? formatDateString(mandateToDate) : dates,
        ach_amount: mandateAmount,
        channel_type: channelType,
        uc:untilCancelled
      }

      createAchMandate(reqBody, headers).then((res) => {
        if (res.data?.data?.Service_return_code === "0") {
          setSuccessMessage2('Mandate Registration request received. The mandate will be registered subject to successful verification. Activation TAT: 3 to 4 business days.')
                setConfirmShow2(true)
          setChannelType("")
          setMandateAmount("")
          setMandateFromDate("")
          setMandateToDate("")
          setUntilCancelled("Y")
          window.open(res.data?.data?.eMandatelink)
        } else {
          notify("error", res.data?.data?.Status_Desc.split("|")[0])
        }
      })
    }

  }
  const addAmount = (value) => {
    value = Number(value)
    if (value <= 10000000 && Number(mandateAmount) + value <= 10000000) {
      validation("amount", value, setError)
      setMandateAmount(Number(mandateAmount) + value);
    } else {
      setMandateAmount(10000000);
    }
  }
  return (
    <>
      <div className="wrapper px-3 pe-4">
        <div className="report px-lg-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb fs-sm-11">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item ">
                <Link to="/dashboard/bank-and-mandate">
                  Bank Details and Mandate
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create E-Mandate
              </li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="container-fluid mt-5">
            <div className="row justify-content-around mx-lg-4">
              <div className="col-md-9 shadow-custom bg-gray text-start m-0 p-0">
                <div className="form-title px-3 py-3">
                  <Link
                    to="/dashboard/bank-and-mandate"
                    className="btn btn-danger shadow-sm"
                  >
                    Back to bank details
                  </Link>
                </div>
                <Form className="p-4 pb-0" onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <Form.Group className="col-md-6 col-12" controlId="formGridEmail">
                      <Form.Label>Enter Mandate Amount <span className="text-red">*</span></Form.Label>
                      <Form.Control type="text" className="bg-white bg-c" name="amount" placeholder="Enter Amount" value={mandateAmount} onChange={mandateAmountHandler} />
                      <small className="text-red pull-left">
                        {error?.amount}
                      </small>
                      <div className="row price-sugg-btn mt-3 pull-left">
                        <div className="col-4 pxc-1">
                          <button className="btn btn-outline w-100 py-2" type="button" onClick={() => addAmount(100000)}>+1,00,000</button>
                        </div>
                        <div className="col-4 pxc-1">
                          <button className="btn btn-outline w-100" type="button" onClick={() => addAmount(200000)}>+2,00,000</button>
                        </div>
                        <div className="col-4 pxc-1">
                          <button className="btn btn-outline w-100" type="button" onClick={() => addAmount(500000)}>+5,00,000</button>
                        </div>
                      </div>

                    </Form.Group>
                    <Form.Group className="col-md-6 col-12" controlId="formGridPassword">
                      <Form.Label>Select Mode <span className="text-red">*</span></Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={channelType}
                        name="channelType"
                        onChange={channelTypeHandler}
                      >
                        <option value={""}>Select Mode</option >
                        <option value={"DC"}>Debit Card</option >
                        <option value={"NET"}>Net Banking</option >
                      </Form.Select>
                      <small className="text-red pull-left">
                        {error?.channelType}
                      </small>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group className="col-md-6 col-12" controlId="formGridEmail">
                      <Form.Label>Mandate Date <span className="text-red">*</span></Form.Label>
                      <Form.Control type="date" className="bg-white bg-c" min={currentDate} name="mandateFromDate" value={mandateFromDate} onChange={mandateDateHandler} />
                      <small className="text-red pull-left">
                        {error?.mandateFromDate}
                      </small>
                    </Form.Group>
                    {untilCancelled === "N" ? <Form.Group className="col-md-6 col-12" controlId="formGridPassword">
                      <Form.Label>Mandate To Date <span className="text-red">*</span></Form.Label>
                      <Form.Control type="date" name="mandateToDate" min={mandateFromDate ?addThirtyDaysFromCurrent(mandateFromDate) :addThirtyDaysFromCurrent(currentDate)} className="bg-white bg-c" max={futureRemoveDate} value={mandateToDate} onChange={mandateDateHandler} />
                      <small className="text-red pull-left">
                        {error?.mandateToDate}
                      </small>

                    </Form.Group> : ""}

                  </Row>


                  <Form.Group
                    className="mb-3 d-flex"
                    controlId="formBasicRadio"
                  >
                    <Form.Check
                      label="Until Cancelled"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                      className="pe-3"
                      checked={untilCancelled === "Y"}
                      value={untilCancelled}
                      onClick={(e) => {
                        setUntilCancelled(e.target.value === "N" ? "Y" : "N")
                        setMandateToDate("")
                      }
                      }
                    />
                  </Form.Group>
                  <Row>
                    <Col className="text-lg-end text-center">
                      <button type="submit" className="btn-custom">
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Form>

                <div className="row">
                  <div className="col-md-12 p-4 pt-0">
                    <p className="text-danger">Note:- </p>
                    <p>
                      Please <Link to="https://www.npci.org.in/PDF/nach/live-members-e-mandates/Live-Banks-in-API-E-Mandate.pdf" target="_blank"> click here 
                      </Link> for a list of eligible banks for E-Mandate. If your bank
                      is not eligible please <Link to="#" onClick={() => setContactShow(true)}> raise a query </Link> to initiate the offline process of mandate registration.
                    </p>
                    <p>
                      The debit mandate amount is the daily maximum limit per
                      transaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactUs show={contact} setShow={setContactShow} />
      <Thanku
        show={confirmShow2}
        setShow={setConfirmShow2}
        successMessage={successMessage2}
      />
    </>
  );
};
export default CreateEmandate;
