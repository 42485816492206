import { request, apis } from "../request";


export const getGoalPlanning = async (body, headers) => {
  return await request(apis.post_getGoalPlanning, body, headers);
};

export const saveGoalPlanning = async (body, headers) => {
  return await request(apis.post_saveGoalPlanning, body, headers);
};

export const getAllPlans = async (headers) => {
  return await request(apis.get_getAllGoalPlans, null, headers);
};

export const deleteGoalPlan = async (body, headers) => {
  return await request(apis.post_deleteGoalPlan, body, headers);
};

export const updateGoalPlan = async (body, headers) => {
  return await request(apis.post_updateGoalPlan, body, headers);
};
export const swpCalculator = async (body, headers) => {
  return await request(apis.post_swpCalculator, body, headers);
};

export const getGoalPlanningSchemes = (body) => {
  return request(apis.post_GoalPlanningSchemes, body);
};
