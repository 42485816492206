import React, { useState,useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import CalculatorHeader from "../components/calculator-header";
import Loader from "./Loader";
import { notify } from "./toastCreater";
import { Link ,useNavigate} from "react-router-dom";
import { getMachine } from "./helpers/calculatorHelper";
import { getAmcList, userProfile } from "../apisMethods";
import NewPurchaseTransact from "../components/new-purchase-transact";
const FutureValueCalculator = () => {
  const history = useNavigate()
  const [investment, setInvestment] = useState(5000);
  const [period, setPeriod] = useState(10);
  const [expectedRateRetuen, setExpectedRateRetuen] = useState(3);
  const [lumpsums, setLumpsums] = useState('6,719');
  const [sgains, setSgains] = useState('1,719');
  const [resultnvestment, setResultnvestment] = useState(5000);

  const [chartPresentValue, setChartPresentValue] = useState(1719);
  const [chartGain, setChartGain] = useState('1,719');
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [newPurchaseShow, setNewPurchaseShow] = useState(false);
  const [amountForLumpsum, setAmountForLumpsum] = useState("5000");



  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  
  }, []);

  
  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if(!res.data){
        setUserProfileData([])
      }else{
        setUserProfileData(res.data?.data)
      }
    })

  }

  const investInLumpsum = ()=>{
    const token = localStorage.getItem("token");
    const userdata = JSON.parse(localStorage.getItem("user"));
    if(!token){
      history("/")
    }else if(!userdata?.IIINStatus){
      history("/dashboard/Profile-creation")
    }
    let value =Number(resultnvestment?.toString().replace(/\,/g,''))
    let roundedNumber = Math.ceil(value / 100) * 100;
    setAmountForLumpsum(roundedNumber)
    setNewPurchaseShow(true)
  }


  const data = {
    datasets: [
      {
        data: [chartPresentValue, chartGain],
        backgroundColor: ["#F06D70", "#97C5FB"],
      }
    ],
    labels: ["Present Value", "Gain"]
  }

  const lumpsumCalculate = () => {
    if (investment < 500) {
      notify("warn", "Investment should not be less than 500")
      return
    }
    if (period < 1) {
      notify("warn", "Period should not be less than 1")
      return
    }
    if (expectedRateRetuen < 1) {
      notify("warn", "Expected Rate of Return should not be less than 1")
      return
    }
    setLoader("block");
   



    var i = (expectedRateRetuen / 100) / 12;

    var lumpsums = investment * Math.pow((1 + expectedRateRetuen / 100), period);
    var lumpsum = parseInt(lumpsums).toFixed()
    var gains = lumpsum - investment
    setResultnvestment(getMachine(investment));
    setLumpsums(getMachine(lumpsum));
    setSgains(getMachine(gains));
    window.scrollTo(500, 500);
    setCalculateCheck(1)
    setLoader("none");
    setChartPresentValue(investment);
    setChartGain(gains);

   

  }
  let incInvestment = () => {
    if (investment < 10000000) {
      setInvestment(Number(investment) + 500);
    }
    else if (investment == 0) {
      setInvestment(Number(investment) + 500);
    }

  };

  let decInvestment = () => {
    if (investment >= 500) {

      setInvestment(investment - 500);
    }
    else if (investment < 499) {
      setInvestment(0);
    }
  }

  let incPeriod = () => {
    if (period < 50) {
      setPeriod(Number(period) + 1);
    }
  };
  let decPeriod = () => {
    if (period > 1) {
      setPeriod(period - 1);
    }
  }

  let incExpectedRateRetuen = () => {
    if (isNaN(expectedRateRetuen)) {
      return setExpectedRateRetuen(0)
    }
    if (expectedRateRetuen < 50) {
      setExpectedRateRetuen(Number(expectedRateRetuen) + .5);
    }
  };
  let decExpectedRateRetuen = () => {
    if (expectedRateRetuen > 1) {
      setExpectedRateRetuen(expectedRateRetuen - .5);
    }
  }


  return (
    <>

      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}


          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Calculators</li>
            </ol>
          </nav>

          <Loader loader={loader} />
          <div className="container-fluid  px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">

                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row">
                                  <div className="col-md-4 text-center">
                                    <label for="m-saving"
                                      className="text-label fw-500 py-2">
                                      Investment<span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" onClick={decInvestment}
                                        className="img-fluid max-27"></img>
                                      <input type="text" className="form-control" value={investment} onChange={(e) => {
                                        if (e.target.value >= 0 && e.target.value <= 10000000) {
                                          if (e.target.value.includes('.')) {
                                            setInvestment(Math.round(parseInt(e.target.value)))
                                          } else {
                                            setInvestment(e.target.value != "" ? parseInt(e.target.value) : "")
                                          }
                                        }
                                        else if (e.target.value > 10000000) {
                                          setInvestment(10000000)

                                        }
                                      }}
                                        name="m-saving" />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt=""
                                        className="img-fluid max-27" onClick={incInvestment}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="year"
                                      className="text-label fw-500 py-2">
                                      Period (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" onClick={decPeriod}
                                        className="img-fluid max-27"></img>
                                      <input type="text" className="form-control" value={period} onChange={(e) => {
                                        if (e.target.value >= 0 && e.target.value <= 50) {
                                          if (e.target.value.includes('.')) {
                                            setPeriod(Math.round(parseInt(e.target.value)))
                                          } else {
                                            setPeriod(e.target.value != "" ? parseInt(e.target.value) : "")
                                          }
                                        }
                                        else if (e.target.value > 50) {
                                          setPeriod(50)

                                        }
                                      }}
                                        name="year" />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" onClick={incPeriod}
                                        className="img-fluid max-27"></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="return"
                                      className="text-label fw-500 py-2">
                                      Expected Rate of Return (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" onClick={decExpectedRateRetuen}
                                        className="img-fluid max-27"></img>
                                      <input type="text" className="form-control" value={expectedRateRetuen}
                                        name="return" onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (e.target.value >= 0 && e.target.value <= 50) {
                                              setExpectedRateRetuen(value)
                                          }
                                          else if(e.target.value>50){
                                            setExpectedRateRetuen(50)
                                          }if(e.target.value=="."){
                                            setExpectedRateRetuen(e.target.value);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" onClick={incExpectedRateRetuen}
                                        className="img-fluid max-27"></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 pt-2 mt-1 text-lg-end text-center">
                                  <button className="btn-custom mt-3" onClick={lumpsumCalculate}> {calculateCheck == 0 ? "Calculate" : "Re-Calculate"}</button>
                                </div>
                              </section>
                              <div className="row shadowc  br-50 mx-3 ">
                                <div className="col-md-4">
                                  <div className="result-content result-content-shadow">
                                    <ul className="text-center lst-none ps-0">
                                      <li>
                                        <div
                                          className="text-label fw-500 py-2">
                                          Present Value<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div
                                          className="inputf transcard bg-white py-2">
                                          {resultnvestment}
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          className="text-label fw-500 py-2">
                                          Gain<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div
                                          className="inputf transcard bg-white py-2">
                                          {sgains}
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          className="text-label fw-500 py-2">
                                          Future Value<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div
                                          className="inputf transcard bg-white py-2">
                                          {lumpsums}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="col-md-8  pt-5">
                                  <div className="result-title text-center">
                                    <h2>Result</h2>
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <div className="pt-4 future">
                                      <Doughnut data={data} />
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="row px-lg-5 pt-3" onClick={investInLumpsum}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">Invest Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewPurchaseTransact
              show={newPurchaseShow}
              setShow={setNewPurchaseShow}
              amcList={amcList}
              userProfileData={userProfileData}
              purchase={"New Purchase"}
              amountForLumpsum = {amountForLumpsum}

            />
    </>
  )

}
export default FutureValueCalculator