import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';

const Welcome = ({show,setShow,successMessage })=>{
  
return (
    <>

       <Modal show={show}  onHide={()=>setShow(false)} centered  className="animate__animated animate__zoomIn animate__fast  ">
        <Modal.Body className="">
       
        <h4 className="text-successc fw-400 py-3 text-center">Welcome to Prodigy Pro!</h4>
        <small className="text-black">Hi User, </small><br/>
        <small className="text-black">Prodigy Pro is far ahead of other mutual fund applications. It not only helps you on mutual fund investments but also gives you options to </small>
        <li className="small">Get your existing portfolios reviewed  </li>
        <li className="small">Plan your financial goals  </li>
        <li className="small">Calculations through advanced financial calculators.  </li>
        <li className="small">Hand Holding by a virtual relationship manager.  </li>
        <li className="small">Round the clock customer support (whatsapp on: +917347700888)  </li><br/>
        <small className="text-black">To enjoy seamless & unique features of this App please complete sign-up. To know more about the sign-up process <a href="https://youtu.be/75fHxHjhvZs" target="_blank">click here</a>.</small><br/>
        <small className="text-black">And yes, don't worry. We are a DPDP Act, 2023 complied organization, so your data is perfectly safe with us.</small>
        </Modal.Body>
        <div className="bg-red text-center py-2 order-b">
       <div style={{cursor: "pointer"}} class="text-white font-weight-bold" onClick={()=>setShow(false)}><h4>OK</h4></div>
       </div>
      </Modal>
    </>
  )
}
export default Welcome;