import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import { FaPen, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Cart_Delete from "../components/delete-confirmation";
import { getAllPlans, deleteGoalPlan } from "../apisMethods";
import { GoalsImages, GoalRouting } from "./helpers/goalEnum";
import Sipstpswp_Error from "../../src/assets/images/no-money.png";
import { globalInfo } from "../App";

const GoalSummary = () => {
  const { setLoader } = useContext(globalInfo);
  const navigate = useNavigate();
  const [goalDataList, setGoalDataList] = useState([]);
  const [headerState, setHeaderState] = useState({});
  let [totalSip, setTotalSip] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    setHeaderState(headers);
    getAllPlans(headers).then((res) => {
      setLoader("block");
      if (res.data?.success) {
        setGoalDataList(res.data?.data);
        total(res.data?.data);
      } else {
        setGoalDataList([]);
      }
      setLoader("none");
    });
  }, []);

  const deleteData = (id, sipAmount) => {
    deleteGoalPlan({ id }, headerState).then((res) => {
      if (res.data.success) {
        // window.location.reload();
        const filteredList = goalDataList.filter((el) => el._id != id);
         totalSip -= sipAmount
         setTotalSip(totalSip)
        setGoalDataList(filteredList)
      }
    });
  };

  const total = (data) => {
    let val = 0;
    data.forEach((e) => {
      val += Number(e.SIPAmount);
    });

    setTotalSip(val);
  };



  const errorShow = ()=>{
      if(goalDataList.length === 0){
       (
          <section>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-12 py-3 text-center">
                  <div className="sipstpswp_error-img">
                    <img src={Sipstpswp_Error} />
                  </div>

                  <h5 className="pt-3 text-red fw-600">
                    You have no added goals
                  </h5>
                </div>
              </div>
            </div>
          </section>
       )
      }
  }
  return (
    <>
      <div className="wrapper">
        <div className="report px-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Goal Summary
              </li>
            </ol>
          </nav>
        </div>

        <section className="goal-summary">
          <div className="container-fluid">
            <div className="row justify-content-center py-5 me-3">
              <div className="col-md-8">
                <div className="shadow-table p-1" id="yourDiv">
                  <Table responsive>
                    <thead>
                      <tr className="bg-gray text-red text-center">
                        <th></th>
                        <th className="wd-8rem">Goal</th>
                        <th className="wd-5rem">Tenure</th>
                        <th className="wd-8rem">SIP / Month </th>
                        <th className="wd-8rem">Target Amount </th>
                        <th className="wd-8rem"> </th>
                        <th className="wd-7rem">Action </th>
                        <th className="wd-8rem">Proceed</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {goalDataList.length > 0
                        ? goalDataList.map((data, i) => {
                            return (
                              <tr key={data._id}>
                                <td>
                                  <img
                                    className="img-fluid new-icon"
                                    src={require(`../../src/assets/images/${
                                      data.goalKey === "9"
                                        ? "othergoal"
                                        : GoalsImages[data.goal]
                                    }.png`)}
                                    alt="no img"
                                  />
                                </td>
                                <td>
                                  {data.goalKey !== "9"
                                    ? data.goal
                                    : data.goalDetails}
                                </td>
                                <td>{data.tenure}</td>
                                <td>₹ {data.SIPAmount}</td>
                                <td>₹ {data.goalAmount}</td>
                                <td>
                              
                                </td>
                                <td>
                                  <div className="d-flex  justify-content-around" title="Edit">
                                    <div>
                                      <Link
                                        to={`/dashboard/${
                                          GoalRouting[data.goal]
                                        }`}
                                        state={{
                                          id: data._id,
                                          goalAmount: data.goalAmount,
                                          sipAmount: data.SIPAmount,
                                          currentAmount: data.currentAmount,
                                          tenure: data.tenure,
                                          goalDetails: data.goalDetails,
                                        }}
                                      >
                                        <FaPen className="" />
                                      </Link>
                                    </div>
                                    <div>
                                      <Cart_Delete
                                        id={data._id}
                                        sipAmount={data.SIPAmount}
                                        deleteData={deleteData}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    onClick={() => {
                                      navigate("/dashboard/goal-wise-scheme", {
                                        state: data.tenure,
                                      });
                                    }}
                                    style={{
                                      fontSize: "10px",
                                      padding: "5px 12px ",
                                      borderRadius: "20px",
                                      background: "#f06d70",
                                      border: "none",
                                      color: "white",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Proceed
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : null}

                     
                    </tbody>
                  </Table>
                </div>
                {errorShow()}
                {/* {goalDataList.length === 0 ? (
                  <section>
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-md-12 py-3 text-center">
                          <div className="sipstpswp_error-img">
                            <img src={Sipstpswp_Error} />
                          </div>

                          <h5 className="pt-3 text-red fw-600">
                            You have no added goals
                          </h5>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )} */}
              </div>

              <div className="col-md-8 text-center py-5 fw-500">
                <span> Total incremental SIP p.m :</span>
                <span>₹ {totalSip.toFixed(2)}</span>

                <div className="row justify-content-between pt-4">
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                      className="btn-custom"
                    >
                      Add More Goal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default GoalSummary;
