import React, { useEffect } from "react";
import Retirement from "../../src/assets/images/retiment.png";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useLocation, json } from "react-router-dom";
import { useState, useContext } from "react";
import { FaArrowRight } from "react-icons/fa";
import { globalInfo } from "../App";
import {
  getGoalPlanning,
  saveGoalPlanning,
  updateGoalPlan,
} from "../apisMethods";
import { notify } from "./toastCreater";
import { Goals } from "./helpers/goalEnum";
import { pmtvalue, presentValue } from "./helpers/calculatorHelper";
import { goalCalculater } from "./helpers/goalCalculator";

const RetirementGoal = () => {
  const { setLoader } = useContext(globalInfo)
  const navigate = useNavigate();
  const { state } = useLocation();

  const [amount, setAmount] = useState(state?.currentAmount || "");
  const [lumpsum, setLumpsum] = useState(state?.goalAmount || "");
  const [sipValue, setSipValue] = useState(state?.sipAmount || "");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [tenureError, setTenureError] = useState("");
  const [userDetails, setUserDetails] = useState({});

  // const getProperDate = (year) => {
  //   var d1 = new Date();
  //   var y1 = d1.getFullYear();
  //   var m1 = d1.getMonth() + 1;
  //   if (m1 < 10) m1 = "0" + m1;
  //   var dt1 = d1.getDate();
  //   if (dt1 < 10) dt1 = "0" + dt1;
  //   var d2 = Number(y1) + Number(year) + "-" + m1 + "-" + dt1;
  //   return d2;
  // };

  const [tenure, setTenure] = useState(
    state?.tenure || ""
  );

  // const getYear = (add) => {
  //   const year = new Date().getFullYear();
  //   return year + add;
  // };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    setUserDetails(user)
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    if (!amount && !tenure) {
      setTenureError("mandatory field")
      setAmountError("mandatory field")
      return
    }
    if (!amount) {
      setTenureError("")
      setAmountError("mandatory field")
      setLumpsum("")
      return;
    }
    if (!tenure) {
      setTenureError("mandatory field")
      setAmountError("")
      setLumpsum("")
      return;
    }

    if (Number(amount) < 100000 ) {
      notify("warn", "Amount should not be less than 100000");
      return;
    }
    setLoader("block");
    // const yearDiffrence = Number(tenure.split("-")[0]) - getYear(0);
    // getGoalPlanning({ amount, tenure: tenure }).then((res) => {

    const goalValue = await goalCalculater(amount, tenure)
    console.log("goalValue", goalValue);
    setLumpsum(goalValue?.lumpsumRequired);
    setSipValue(goalValue?.newsipamt);
    setCalculateCheck(1)
    setLoader("none");
    // });
  };

  // const dateAny = (id) => {
  //   const inputDateElement = document.getElementById(id);
  //   inputDateElement.showPicker();
  // };

  const apiSwitcher = () => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    const reqBody = {
      goal: 1,
      goalDetails: Goals[1],
      goalAmount: lumpsum,
      currentAmount: amount,
      SIPAmount: sipValue,
      tenure: tenure,
    };
    if (state?.id) {
      reqBody.id = state?.id;
      delete reqBody.goal;
      return updateGoalPlan(reqBody, headers);
    } else {
      return saveGoalPlanning(reqBody, headers);
    }
  };

  const addRetirementGoal = async () => {
    // if(state.id)
    if (lumpsum) {
      await apiSwitcher();
      navigate("/dashboard/goal-summary");
    } else {
      notify("warn", "Please calculate first");
    }
  };

  const amountHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
    let convertInNumber = Number(sanitizedValue)
    if (convertInNumber <= 10000000000) {
      setAmount(convertInNumber);
    } else {
      setAmount(10000000000);
    }
  };

  const tenureHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
    let convertInNumber = Number(sanitizedValue)
    if (convertInNumber <= 75) {
      setTenure(convertInNumber);
    } else {
      setTenure(75)
    }
  }

  const checkButton = () => {
    if (!userDetails?.pan) {
      return true
    }
    if (state?.id) {
      if (lumpsum != state?.goalAmount) {
        return false
      } else if (amount != state?.currentAmount || state?.tenure != tenure || amount == "") {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <>
      <div className="wrapper">
        <div className="report px-lg-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Retirement Goal
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 bg-light-red mb-5">
                <div className="row justify-content-center py-2 px-5">
                  <div className="col-md-3 d-flex justify-content-between align-items-baseline">
                    <div className="">
                      <img
                        src={Retirement}
                        className="img-fluid mx-8 mw-sm-5"
                      />
                    </div>
                    <div className="">
                      <h4 className="text-red">Retirement</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="container-fluid">

            <div className="row mb-4 justify-content-center">
              <div className="col-6 text-end">
                {checkButton() ? "" : <Link to="/dashboard/goal-summary" className="text-red fs-15 show-goal" >
                  View Added Goals<FaArrowRight className="ms-1" />
                </Link>}

                <Form
                  className="text-start pb-5 px-lg-5"
                  onSubmit={submitHandler}
                >
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <h6>
                      How much money would you need to achieve this goal?
                    </h6>
                    <Form.Label>(In today’s terms)</Form.Label>
                    <Form.Control
                      type="text"
                      value={amount}
                      placeholder="Enter Amount"
                      onChange={amountHandler}
                    />
                    <small className="text-danger ">
                      {amount ? "" : amountError}
                    </small>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Enter Goal Tenure (Years)</Form.Label>
                    <Form.Control
                      // min={`${getYear(1)}-01-01`}
                      type="text"
                      id="goal1"
                      placeholder="Enter tenure"
                      value={tenure}
                      onChange={tenureHandler}
                    // onClick={() => dateAny("goal1")}
                    />
                    <small className="text-danger ">
                      {tenure ? "" : tenureError}
                    </small>
                  </Form.Group>
                  {lumpsum ? (
                    <>
                      <div className="result-goal text-center">
                        <div class="text-black fw-500"> Future value of amount needed</div>
                        <div class="text-red fw-500"><span className="text-roboto">₹</span> {lumpsum}</div>
                      </div>
                      <div className="result-goal text-center">
                        <div class="text-black fw-500"> Require SIP p.m.</div>
                        <div class="text-red fw-500"><span className="text-roboto">₹</span> {sipValue}</div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* {sipValue ? (
                    <div className="result-goal text-center">
                      <div class="text-black fw-500"> Sip Value</div>
                      <div class="text-red fw-500">₹ {sipValue}</div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="row mt-4">
                    <div className="col-6">
                      <button className="btn-custom" type="submit">
                        {calculateCheck == 0 ? "Calculate" : "Re-Calculate"}
                      </button>
                    </div>
                    <div
                      className="col-6 text-lg-end text-center"
                      onClick={addRetirementGoal}

                    >
                      <button className={`btn-custom ${checkButton() ? "opacity-50" : ""}`}
                        disabled={checkButton()}
                        type="button">
                        {state?.id ? "Update Goal" : "Add Goal"}
                      </button>
                    </div>
                  </div>



                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default RetirementGoal;
