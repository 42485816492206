import React, { useState ,useEffect} from "react";
import CalculatorHeader from "../components/calculator-header";
import Loader from "./Loader";
import { Link,useNavigate } from "react-router-dom";
import {
  decFifty,
  incPointFiveFifty,
  decPointFive,
  incFifty,
} from "./helpers/validators";
import { notify } from "./toastCreater";
import { getMachine, pmtvalue, presentValue } from "./helpers/calculatorHelper";
import { getAmcList, userProfile } from "../apisMethods";
import SIPTransact from "../components/sip-transact";


const MarriageCalculator = () => {

 const history = useNavigate()
  const [childAgeToday, setChildAgeToday] = useState(8);
  const [getMarriedAge, setGetMarriedAge] = useState(18);
  const [amountRequired, setAmountRequired] = useState(1000000);
  const [annualSaving, setAnnualSaving] = useState(30000);
  const [expectedRateReturn, setExpectedRateReturn] = useState(12);
  const [expectedInflation, setExpectedInflation] = useState(6);
  const [inflaction, setInflaction] = useState("17,90,848");
  const [futureValue, setFutureValue] = useState("5,26,462");
  const [af, setAf] = useState("12,64,386");
  const [lumpsum, setLumpsum] = useState("4,07,098");
  const [data, setData] = useState("5,496");
  const [additionContent, setAdditionContent] = useState(
    "Additional funds required to meet expenses"
  );
  const [loader, setLoader] = useState("none");
  const [calculateCheck, setCalculateCheck] = useState(0);
  const [addCss,setAddCss] = useState("")
  const [amcList, setAmcList] = useState([]);
  const [userProfileData, setUserProfileData] = useState([]);
  const [sipShow, setSipShow] = useState(false);
  const [yearForSip, setYearForSip] = useState(10);
  const [amountForSip, setAmountForSip] = useState("5496");



  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
      getAmcList(headers).then((res) => {
        setAmcList(res.data?.data);
      });
    }
  
  }, []);

  
  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if(!res.data){
        setUserProfileData([])
      }else{
        setUserProfileData(res.data?.data)
      }
    })

  }

  const investInSip = ()=>{
    const token = localStorage.getItem("token");
    const userdata = JSON.parse(localStorage.getItem("user"));
    if(!token){
      history("/")
    }else if(!userdata?.IIINStatus){
      history("/dashboard/Profile-creation")
    }
    let value =Number(data?.toString().replace(/\,/g,''))
    let roundedNumber = Math.ceil(value / 100) * 100;
    if(roundedNumber <1000){
      setAmountForSip(1000)
    }else{
      setAmountForSip(roundedNumber)
    }
    setYearForSip(getMarriedAge-childAgeToday)
    setSipShow(true)
  }

  const calculateMarrige =async () => {

    if (childAgeToday < 1) {
      notify("warn", "Current age should not be less than 1")
      return
    }
    if (getMarriedAge < 1) {
      notify("warn", "Current age should not be greater than marriage age")
      return
    }
    if (childAgeToday >= getMarriedAge) {
      notify("warn", "Current age should not be greater than or equal to marriage age")
      return
    }
    if (amountRequired < 100) {
      notify("warn", "Amount should not be less than 100")
      return
    }
    if (annualSaving < 1) {
      notify("warn", "Annual saving should not be less than 1")
      return
    }
    if (expectedRateReturn < 1) {
      notify("warn", "Expected rate of return should not be less than 1")
      return
    }
    if (expectedInflation < 1) {
      notify("warn", "Expected inflation rate  should not be less than 1")
      return
    }

    setLoader("block");

    // axios.post(baseUrl + "/calculators/marrige", data).then((res) => {
    //   setInflaction(res.data.inflationAdjustedCost);
    //   setFutureValue(res.data.futureValue);
    //   setAf(res.data.additionalFunds);
    

  
        let years = getMarriedAge - childAgeToday;
  
        // Adjust the amount required for inflation
        let adjustedAmountRequired =
        amountRequired * Math.pow(1 + expectedInflation / 100, years);
  
        // Calculate the future value of annual savings
        let futureValue =
          (annualSaving * (Math.pow(1 + expectedRateReturn / 100, years) - 1)) /
          (expectedRateReturn / 100);
  
        // Calculate the value of annual savings required
        let PlanningRequiredForAmount = Number(adjustedAmountRequired) - Number(futureValue);
  
  
        let nperiod = (years * 12)
        let rate = (expectedRateReturn / 12) / 100
  
        let calculatedvlaues =await pmtvalue(rate, nperiod, 0, -PlanningRequiredForAmount, 0)
  
        let lumpsumRate = expectedRateReturn / 100;
        let lumpsumRequired =await presentValue(lumpsumRate, years, 0, PlanningRequiredForAmount);

        setInflaction(getMachine(Math.round(adjustedAmountRequired)));
        setFutureValue(getMachine(Math.round(futureValue)));
        setAf(getMachine(Math.round(PlanningRequiredForAmount)));


        setAdditionContent("Additional funds required to meet expenses")
        let updatedMonthlyInvestment = getMachine(Math.round(calculatedvlaues))
        let updatedLumpsum=  getMachine(Math.round(lumpsumRequired))
        let updatedAdditional =  getMachine(Math.round(PlanningRequiredForAmount))
        if (updatedMonthlyInvestment.length === 5 && updatedMonthlyInvestment.includes('-')) {
          setData(updatedMonthlyInvestment.replace(",", ""))
        } else {
          setData(updatedMonthlyInvestment)
        }
        if(PlanningRequiredForAmount <0 && PlanningRequiredForAmount>-999){
          setAf(PlanningRequiredForAmount.toFixed(0))
        }
        setLumpsum(updatedLumpsum);
        setCalculateCheck(1)
        setAddCss("")
        if (lumpsumRequired < 0 && lumpsumRequired>=-999) {
          setLumpsum(lumpsumRequired.toFixed(0));
          setAdditionContent("Excess to meet expenses");
          setAddCss("pb-4 mb-1")
        } else if(lumpsumRequired < 0){
          setLumpsum(updatedLumpsum);
          setAdditionContent("Excess to meet expenses");
          setAddCss("pb-4 mb-1")
        }
  
        setLoader("none");  
        document.getElementById('scroll').scrollIntoView(true);


  };

  let incChildAgeToday = () => {
    if (childAgeToday < 35) {
      setChildAgeToday(Number(childAgeToday) + 1);
    }
  };
  let decChildAgeToday = () => {
    if (childAgeToday > 1) {
      setChildAgeToday(childAgeToday - 1);
    }
  };

  // for Approx cost

  let incAmountRequired = () => {
    if (amountRequired < 100000000) {
      setAmountRequired(Number(amountRequired) + 500);
    } else if (amountRequired > 100000000) {
      setAmountRequired(Number(100000000));
    } else if (amountRequired == 0) {
      setAmountRequired(Number(amountRequired) + 500);
    }
  };
  let decAmountRequired = () => {
    if (amountRequired >= 500) {
      setAmountRequired(amountRequired - 500);
    } else if (amountRequired < 499) {
      setAmountRequired(100);
    }
  };

  // for Start college Age

  let incGetMarriedAge = () => {
    if (getMarriedAge < 45) {
      setGetMarriedAge(Number(getMarriedAge) + 1);
    }
  };
  let decGetMarriedAge = () => {
    if (getMarriedAge > 1) {
      setGetMarriedAge(getMarriedAge - 1);
    }
  };

  let incAnnualSaving = () => {
    if (annualSaving < 2500000) {
      setAnnualSaving(Number(annualSaving) + 500);
    } else if (annualSaving == 0) {
      setAnnualSaving(Number(annualSaving) + 500);
    }
  };
  let decAnnualSaving = () => {
    if (annualSaving >= 500) {
      setAnnualSaving(annualSaving - 500);
    } else if (annualSaving < 499) {
      setAnnualSaving(0);
    }
  };
  return (
    <>

      <div className="wrapper">
        <div className="px-lg-5 px-2">
          {/* Page Heading */}


          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Calculators</li>
            </ol>
          </nav>
          <Loader loader={loader} />

          <div className="container-fluid  px-lg-5 pb-5 calculator">
            <div className="row">
              <div className="col-md-12">
                {/* =============Calculators Nav Start================ */}
                <div class="container-calculator pb-5">
                  <CalculatorHeader />
                  {/* =============Calculators Nav End================ */}
                  <div class=" mt-3 ">
                    <div class="" role="tabpanel">
                      <section>
                        <div className="results pt-5">
                          <div className="px-lg-5">
                            <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                              <section className="pt-5 pb-5 px-3">
                                <div className="row" id="marriage-1">
                                  {/*first*/}
                                  <div className="col-md-4 text-center">
                                    <label for="m-saving" className="text-label fw-500 py-2 fs-12">
                                      Child's age today (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decChildAgeToday}></img>
                                      <input type="text" className="form-control" name="m-saving"  value={childAgeToday}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 35
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setChildAgeToday(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setChildAgeToday(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 35) {
                                            setChildAgeToday(35);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incChildAgeToday}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="year" className="text-label fw-500 py-2 fs-12">
                                      Child will get married at the age of (Years)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decGetMarriedAge}></img>
                                      <input type="text" className="form-control" name="year"  value={getMarriedAge}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 45
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setGetMarriedAge(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setGetMarriedAge(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 45) {
                                            setGetMarriedAge(45);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incGetMarriedAge}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 py-2 fs-12">
                                      Amount required for wedding as on today<span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decAmountRequired}></img>
                                      <input type="text" className="form-control" name="return"  value={amountRequired}

                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 100000000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setAmountRequired(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setAmountRequired(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 100000000) {
                                            setAmountRequired(100000000);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incAmountRequired}></img>
                                    </div>
                                  </div>
                                </div>
                                {/*second*/}
                                <div className="row" >
                                  <div className="col-md-4 text-center">
                                    <label for="m-saving" className="text-label fw-500 py-2 fs-12">
                                      Annual Saving<span className="text-roboto">(₹)</span>
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={decAnnualSaving}></img>
                                      <input type="text" className="form-control" name="m-saving"  value={annualSaving}

                                        onChange={(e) => {
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 2500000
                                          ) {
                                            if (e.target.value.includes(".")) {
                                              setAnnualSaving(
                                                Math.round(parseInt(e.target.value))
                                              );
                                            } else {
                                              setAnnualSaving(e.target.value != "" ? parseInt(e.target.value) : "");
                                            }
                                          } else if (e.target.value > 2500000) {
                                            setAnnualSaving(2500000);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={incAnnualSaving}></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="year" className="text-label fw-500 py-2 fs-12">
                                      Expected rate of return(%)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={() =>
                                        decPointFive(
                                          expectedRateReturn,
                                          setExpectedRateReturn
                                        )
                                      }></img>
                                      <input type="text" className="form-control" name="year"  value={expectedRateReturn}

                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50 
                                          ) {
                                              setExpectedRateReturn(value);
                                          } else if(e.target.value>50){
                                            setExpectedRateReturn(50);
                                          }else if(e.target.value=="."){
                                            setExpectedRateReturn(e.target.value);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={() =>
                                        incPointFiveFifty(
                                          expectedRateReturn,
                                          setExpectedRateReturn
                                        )
                                      }></img>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <label for="return" className="text-label fw-500 py-2 fs-12">
                                      Expected inflation rate (% p.a)
                                    </label>
                                    <div className="d-flex inputf transcard">
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/minus.png"} alt="" className="img-fluid max-27" onClick={() =>
                                        decFifty(
                                          expectedInflation,
                                          setExpectedInflation
                                        )
                                      }></img>
                                      <input type="text" className="form-control" name="return"  value={expectedInflation}

                                        onChange={(e) => {
                                          let value = e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                                          if (
                                            e.target.value >= 0 &&
                                            e.target.value <= 50
                                          ) {
                                              setExpectedInflation(value )
                                          } else if (e.target.value > 50) {
                                            setExpectedInflation(50);
                                          }else if(e.target.value=="."){
                                            setExpectedInflation(e.target.value);
                                          }
                                        }} />
                                      <img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/add.png"} alt="" className="img-fluid max-27" onClick={() =>
                                        incFifty(
                                          expectedInflation,
                                          setExpectedInflation
                                        )
                                      }></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mt-3  text-lg-end text-center">
                                  <button className="btn-custom" onClick={calculateMarrige}>{calculateCheck == 0 ? "Calculate" : "Re-Calculate"}</button>
                                </div>
                              </section>
                              <div className="row shadowc br-50 mx-3 p-lg-5" id="scroll">
                                <div className="col-md-12 px-lg-5 pt-5">
                                  <div className="col-md-12 result-title text-center">
                                    <h3>Result</h3>
                                  </div>
                                  <div className="result-content text-center">
                                    <div className="row pt-3">
                                      <div className="col-md-4">
                                        <div className="text-label fw-500 py-2 pb-4 mb-1">
                                          Inflation Adjusted Cost<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard  py-2 bg-light-red"> {inflaction}</div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="text-label fw-500 py-2  pb-4 mb-1">
                                          Future value of saving <span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard py-2 bg-light-red">   {futureValue}</div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className={`text-label fw-500 py-2 ${addCss}`}>
                                          {additionContent}<span className="text-roboto">(₹)</span>
                                        </div>
                                        <div className="inputf transcard bg-light-red py-2">  {af}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 px-lg-5 ">
                                  <div className="col-md-12 result-title text-center pt-4">
                                    <h3>Plan of action Required</h3>
                                  </div>
                                  <div className=" row result-content text-center pb-3">
                                    <div className="col-md-5">
                                      <div className="text-label fw-500 py-2 pb-4 mb-1">
                                        One time investment required<span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">   {lumpsum}</div>
                                    </div>
                                    <div className="col-md-2"><div className="text-label fw-500 py-2"><strong className="text-black">OR</strong></div></div>
                                    <div className="col-md-5">
                                      <div className="text-label fw-500 py-2 pb-4 mb-1">
                                        Monthly investment required<span className="text-roboto">(₹)</span>
                                      </div>
                                      <div className="inputf transcard bg-light-red py-2">   {data}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row px-lg-5 pt-3" onClick={investInSip}>
                                <div className="col-md-12 text-lg-end text-center">
                                  <button className="btn-custom ">Invest Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SIPTransact
              show={sipShow}
              setShow={setSipShow}
              amcList={amcList}
              userProfileData={userProfileData}
              isLookingForAnother={false}
              amountForSip={amountForSip}
              yearForSip={yearForSip}
              

            />
    </>
  )

}
export default MarriageCalculator