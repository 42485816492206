import { apis, request } from "../request";

export const familySnapshot = async (body, headers) => {
    return await request(apis.post_familySnapshot, body, headers);
  };
  export const familyWisePortfolio = async (body, headers) => {
    return await request(apis.post_familyWisePortfolio, body, headers);
  };
  export const detailedPortfolio = async (body, headers) => {
    return await request(apis.post_detailedPortfolio, body, headers);
  };
  export const transactionDetail = async (body, headers) => {
    return await request(apis.post_transactionDetail, body, headers);
  };
  export const personalDetail = async (body, headers) => {
    return await request(apis.post_personalDetail, body, headers);
  };
  export const personalDetailByFolio = async (body, headers) => {
    return await request(apis.post_personalDetailByFolio, body, headers);
  };
  export const userProfile = async (body, headers) => {
    return await request(apis.post_userProfile, body, headers);
  };
  export const userProfileIIN = async (body, headers) => {
    return await request(apis.post_userProfileIIN, body, headers);
  };
  export const getUserIinsPending = async ( headers) => {
    return await request(apis.get_getUserIinsPending,  null, headers);
  };
  export const portfolioFamilyData = async (body, headers) => {
    return await request(apis.post_portfolioFamilyData,  body, headers);
  };
  export const setTime = async (body, headers) => {
    return await request(apis.post_setTime,  body, headers);
  };
 
  export const getTime = async (headers) => {
    return await request(apis.get_getTime, null, headers);
  };
  export const recommendationRedemptionTransact = async (body,headers) => {
    return await request(apis.post_recommendationRedemptionTransact, body, headers);
  };
  export const recommendationSatisfactory = async (body,headers) => {
    return await request(apis.post_recommendationSatisfactory, body, headers);
  };
  export const recommendationUnderwatch = async (body,headers) => {
    return await request(apis.post_recommendationUnderwatch, body, headers);
  };
  export const recommendationSwitch = async (body,headers) => {
    return await request(apis.post_recommendationSwitch, body, headers);
  };
 